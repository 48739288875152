import request from '@/utils/request';

export async function queryPiracy(params) {
  params.include = 'company';

  return request('/piracy', {
    params,
  });
}
export async function fetchDeviceTypesData(params) {
  return request('/piracy/device-type', {
    params,
  });
}
export async function fetchManagementTypesData(params) {
  return request('/piracy/management-type', {
    params,
  });
}
export async function fetchPiracyReason(params) {
  return request('/piracy/show-reason', {
    params,
  });
}
export async function fetchReasonName(params) {
  return request('/piracy/get-reasons', {
    params,
  });
}
