import {
  queryNetCompany,
  queryNotNetCompany,
  querySubCompany,
  queryCompanyStbHardTypes,
} from '@/services/company';

export default {
  namespace: 'CompanyList_company',

  state: {
    data: {},
    stbHardTypes: {},
  },

  effects: {
    *fetchNet({ payload }, { call, put }) {
      const { status, data } = yield call(queryNetCompany, payload);

      if (status === 200) {
        yield put({
          type: 'save',
          payload: {
            list: data.data,
            pagination: {
              total: data.meta.total,
              pageSize: data.meta.per_page,
              current: data.meta.current_page,
            },
          },
        });
      }
    },

    *fetchNotNet({ payload }, { call, put }) {
      const { status, data } = yield call(queryNotNetCompany, payload);

      if (status === 200) {
        yield put({
          type: 'save',
          payload: {
            list: data.data,
            pagination: {
              total: data.meta.total,
              pageSize: data.meta.per_page,
              current: data.meta.current_page,
            },
          },
        });
      }
    },

    *fetchSubCompany({ payload }, { call, put }) {
      const { status, data } = yield call(querySubCompany, payload);

      if (status === 200) {
        yield put({
          type: 'save',
          payload: {
            list: data.data,
            pagination: {
              total: data.meta.total,
              pageSize: data.meta.per_page,
              current: data.meta.current_page,
            },
          },
        });
      }
    },

    *fetchStbHardTypes(next, { call, put }) {
      const { status, data } = yield call(queryCompanyStbHardTypes);

      if (status === 200) {
        yield put({
          type: 'saveStbHardTypes',
          payload: data,
        });
      }
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        data: payload,
      };
    },

    saveStbHardTypes(state, { payload }) {
      return {
        ...state,
        stbHardTypes: payload,
      };
    },
  },
};
