import * as companyAppService from '@/services/companyApp';
import * as appManufacturer from '@/services/appManufacturer';

export default {
  namespace: 'CompanyAppManager_CompanyAppList',
  state: {
    result: {
      list: [],
      total: 0,
      page: 1,
      pagesize: 10,
    },
    initTranceFormData: {
      use_type: null,
      targetKeys: [],
      tranceData: [],
    },
    manufacturerData: [],
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        result: payload,
      };
    },

    saveTranceData(state, { payload }) {
      return {
        ...state,
        initTranceFormData: payload,
      };
    },
    saveManufacturerData(state, { payload }) {
      return {
        ...state,
        manufacturerData: payload,
      };
    },
  },

  effects: {
    *fetchManufactory({ payload }, { call, put }) {
      const {
        data: { list },
      } = yield call(appManufacturer.getManufacturerList, payload);
      yield put({
        type: 'saveManufacturerData',
        payload: list,
      });
    },
    *create({ payload }, { call }) {
      yield call(companyAppService.createAppBase, payload);
    },

    *fetch({ payload }, { call, put }) {
      const { data } = yield call(companyAppService.getAppBaseList, {
        payload,
      });
      yield put({
        type: 'save',
        payload: data,
      });
    },

    // *remove({ payload: id }, { call, put, select }) {
    //   yield call(companyAppService.remove, id);
    //   const page = yield select(state => state.users.page);
    //   yield put({ type: 'fetch', payload: { page } });
    // },

    *transeform({ payload }, { call, put }) {
      const { data } = yield call(companyAppService.getAppTranseInitData, {
        payload,
      });
      // console.log('in transform???');
      // console.log(data);
      // console.log('in transform!!!');
      yield put({
        type: 'saveTranceData',
        payload: { data },
      });
    },

    *searchAppTransData({ payload }, { call, put }) {
      const { data } = yield call(companyAppService.searchAppTransData, {
        payload,
      });
      // console.log('in search???');
      // console.log(data);
      // console.log('in search!!!');
      yield put({
        type: 'saveTranceData',
        payload: { data },
      });
    },

    *setBlackOrWhite({ payload }, { call }) {
      yield call(companyAppService.setBlackOrWhite, {
        payload,
      });
    },

    *setAppState({ payload, callback }, { call }) {
      const { data } = yield call(companyAppService.setAppState, {
        payload,
      });
      callback(data);
    },
  },
  // subscriptions: {
  //     setup({ dispatch, history }) {
  //         console.log(1);
  //         return history.listen(
  //             ({ pathname, query }) => {f
  //                 console.log(2);
  //                 if (pathname === '/company-app-manager/app-base-list') {
  //                     console.log(3);
  //                     dispatch({ type: 'fetch', payload: query });
  //                 }
  //             }
  //         );
  //     },
  // },
};
