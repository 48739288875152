import {
  getUpgradePackageVerifyStatusProcess,
  getVerifyCompaniesList,
  createVerifyCompanies,
  deleteUpgradePackageJob,
  getUpgradePackageVerifyCanBindCompanies,
} from '@/services/remoteUpgrade';

export default {
  namespace: 'VerifyCompanies_verifyCompanies',

  state: {
    jobList: [],
    response: '',
    statusProcess: {},
    canBindCompanies: [],
  },

  effects: {
    *fetchList({ payload }, { call, put }) {
      const { status, data } = yield call(getVerifyCompaniesList, payload);

      if (status === 200) {
        yield put({
          type: 'queryList',
          payload: {
            list: data.data,
            pagination: {
              total: data.meta.total,
              pageSize: parseInt(data.meta.per_page),
              current: data.meta.current_page,
            },
          },
        });
      }
    },
    *fetchPackageStatusProcess({ payload }, { call, put }) {
      const { data } = yield call(
        getUpgradePackageVerifyStatusProcess,
        payload
      );

      yield put({
        type: 'saveStatusProcess',
        payload: data,
      });
    },
    *fetchUpgradePackageCanBindCompanies({ payload }, { call, put }) {
      const { status, data } = yield call(
        getUpgradePackageVerifyCanBindCompanies,
        payload
      );

      if (status === 200) {
        yield put({
          type: 'saveCanBindCompanies',
          payload: {
            list: data.data,
            pagination: {
              total: data.meta.total,
              pageSize: parseInt(data.meta.per_page),
              current: data.meta.current_page,
            },
          },
        });
      }
    },
    *create({ payload }, { call, put }) {
      const { status } = yield call(createVerifyCompanies, payload);
      yield put({
        type: 'saveResponse',
        payload: status,
      });
    },
    *delete({ payload }, { call, put }) {
      const { status } = yield call(deleteUpgradePackageJob, payload);
      yield put({
        type: 'saveResponse',
        payload: status,
      });
    },
  },

  reducers: {
    queryList(state, action) {
      return {
        ...state,
        jobList: action.payload,
      };
    },
    saveResponse(state, { payload }) {
      return {
        ...state,
        response: payload,
      };
    },
    saveStatusProcess(state, { payload }) {
      return {
        ...state,
        statusProcess: payload,
      };
    },
    saveCanBindCompanies(state, { payload }) {
      return {
        ...state,
        canBindCompanies: payload,
      };
    },
  },
};
