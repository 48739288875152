import {
  queryVodRoomDateChannels,
  queryVodRoomDateChannelStatistics,
} from '@/services/netChorusFee';

export default {
  namespace: 'NetChorusFee_VodRoomDateChannel',

  state: {
    data: {
      list: [],
      pagination: {},
      statistics: {},
    },
  },

  effects: {
    *fetchVodRoomDateChannels({ payload }, { call, put }) {
      const { status, data } = yield call(queryVodRoomDateChannels, payload);
      if (status === 200) {
        yield put({
          type: 'save',
          payload: {
            list: data.data,
            pagination: {
              total: data.meta.total,
              pageSize: data.meta.per_page,
              current: data.meta.current_page,
            },
          },
        });
      }
    },
    *fetchVodRoomDateChannelStatistics({ payload }, { call, put }) {
      const { status, data } = yield call(
        queryVodRoomDateChannelStatistics,
        payload
      );
      if (status === 200) {
        yield put({
          type: 'save',
          payload: {
            statistics: data || {},
          },
        });
      }
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};
