import request from '@/utils/request';
import { pickBy } from 'lodash';

// 环幕联网商家列表
export async function queryRsNetCompany(params) {
  return request(`/company-service/customer/netCustomers`, {
    params,
  });
}

// 环幕-商家可用素材
export async function queryCompanyResource(params) {
  const { companyId } = params;
  return request(`/rsbox/company/${companyId}/scenes`, {
    params,
  });
}

// 环幕-商家所属盒子
export async function queryCompanyRsbox(params) {
  const { companyId } = params;
  return request(`/rsbox/company/${companyId}/rsboxs`, {
    params,
  });
}

// 环幕—商家素材推送
export async function pushResultCompanyResource(params) {
  const { companyId } = params;
  const { code } = params;
  return request.post(`/rsbox/company/${companyId}/scene/${code}`, {
    ...params,
  });
}

// 环幕—单个素材结算
export async function SettleAccountResourceBuy(params) {
  const { mac } = params;
  const { code } = params;
  return request.post(`/rsbox/device/${mac}/buy/${code}`, {
    ...params,
  });
}

// 环幕—批量素材结算
export async function SettleAccountResourceListBuy(params) {
  const { mac } = params;
  return request.post(`/rsbox/device/${mac}/batchBuy`, {
    ...params,
  });
}

// 环幕-盒子素材购买获取所有素材
export async function queryResourceToMac(params) {
  const { mac } = params;
  return request(`/rsbox/device/scenes/${mac}`, {
    params,
  });
}

// 环幕-盒子素材购买获取所有素材
export async function RsBoxTransfer(params) {
  console.log('授权转移-》', params);
  return request('/rsbox/device/authorization/transfer', {
    method: 'POST',
    params,
  });
}

// 环幕-盒子素材购买记录
export async function queryBuyLog(params) {
  const { mac } = params;
  return request(`/rsbox/device/buySceneLogs/${mac}`, {
    params,
  });
}

// 环幕-盒子素材推送记录
export async function queryPushLog(params) {
  const { companyId } = params;
  return request(`/rsbox/company/pushScenelogs/${companyId}`, {
    params,
  });
}

// 环幕-盒子可用素材
export async function queryBoxUsable(params) {
  const { mac } = params;
  console.log('7878_>', mac);
  return request(`/rsbox/device/availableScenes/${mac}`, {
    params,
  });
}

// 素材歌曲类型
export async function querySongTypesData(params) {
  return request('/rsbox/scene/songTypes', { params });
}

// 素材歌曲类型列表
export async function querySongTypesDataList(params) {
  const { mac, sceneId } = params;

  return request(`/rsbox/${mac}/scene/${sceneId}/songTypes`, { params });
}

// 新增素材歌曲类型
export async function addSongTypeBind(params) {
  const { mac, sceneId } = params;
  return request(`/rsbox/${mac}/scene/${sceneId}/songTypes`, {
    method: 'POST',
    data: params,
  });
}

// 删除素材歌曲类型
export async function delSongTypeBindDestroy(params) {
  const { bindId } = params;
  return request(`/rsbox/device/scene/songTypes/${bindId}`, {
    method: 'DELETE',
    data: params,
  });
}

// 素材歌曲列表
export async function querySongDataList(params) {
  const { mac, sceneCode } = params;
  return request(`/rsbox/${mac}/scene/${sceneCode}/songs`, { params });
}

// 新增素材歌曲
export async function addSongBind(params) {
  const { mac, sceneCode } = params;
  return request(`/rsbox/${mac}/scene/${sceneCode}/songs`, {
    method: 'POST',
    data: params,
  });
}

// 删除素材歌曲
export async function delSongBindDestroy(params) {
  const { bindId } = params;
  return request(`/rsbox/device/scene/songs/${bindId}`, {
    method: 'DELETE',
    data: params,
  });
}

// 素材私人定制类型
export async function queryTailorsDataList(params) {
  return request('/rsbox/scene/tailors', { params });
}

// 素材私人定制列表
export async function queryTailorIndex(params) {
  const { mac, sceneCode } = params;
  return request(`/rsbox/${mac}/scene/${sceneCode}/tailors`, { params });
}

// 新增私人定制
export async function addTailorsBind(params) {
  const { mac, sceneCode } = params;
  return request(`/rsbox/${mac}/scene/${sceneCode}/tailors`, {
    method: 'POST',
    data: params,
  });
}

// 删除私人定制
export async function delTailorsBindDestroy(params) {
  const { bindId } = params;
  return request(`/rsbox/device/scene/tailors/${bindId}`, {
    method: 'DELETE',
    data: params,
  });
}

// 获取订阅包年/包月价格queryBuyResourceData
export async function queryScribePriceData(params) {
  return request('/rsbox/subscribePrice', { params });
}

// 商家订阅年确认
export async function YearSubscriptionOk(params) {
  console.log('year', params);
  const { company_id } = params;
  return request(`/rsbox/subscribe/year/${company_id}`, {
    method: 'POST',
    params,
  });
}

// 商家订阅月确认
export async function MonthSubscriptionOk(params) {
  console.log('month', params);
  const { company_id } = params;
  return request(`/rsbox/subscribe/month/${company_id}`, {
    method: 'POST',
    params,
  });
}

// 代理商余额
export async function queryAgentBalance(params) {
  const { userId } = params;
  return request(`/agent/accountInfo/${userId}`, {
    params,
  });
}

// 根据登录人ID获取该用户所有代理商信息
export async function queryAgentList(params) {
  return request(`/agent/adminUser/debitAccounts`, {
    params,
  });
}

// 代理商充值详情
export async function queryAgentPayList(params) {
  const { agentId } = params;
  return request(`/agent/rechargeOrders/${agentId}`, {
    params: pickBy(params),
  });
}

// 代理商消费详情
export async function queryAgentConsumptionList(params) {
  const { agentId } = params;
  return request(`/agent/consumeOrders/${agentId}`, { params: pickBy(params) });
}

// 代理商流水列表
export async function queryAgentChangeList(params) {
  const { agentId } = params;
  return request(`/agent/balanceChangeLogs/${agentId}`, {
    params: pickBy(params),
  });
}

// 停用
export async function setDisableResource(params) {
  return request.post(`/rsbox/device/${params.mac}/disable/scenes`, {
    ...params,
  });
}

// 启用
export async function setEnableResource(params) {
  return request.post(`/rsbox/device/${params.mac}/enable/scenes`, {
    ...params,
  });
}

// 根据code获取素材信息
export async function queryBuyResourceData(params) {
  return request(`/rsbox/device/${params.mac}/getSceneByCodes`, { params });
}

// 根据URL获取XML
export async function queryXMLToURL(params) {
  return request(`/rsbox/getConfigFileContent`, {
    params,
  });
}

// 设置用户扣款代理商
export async function setDebitAccountBind(params) {
  return request(`/agent/adminUser/debitAccounts`, {
    method: 'POST',
    data: params,
  });
}

// 环幕—商家素材多选推送
export async function pushMultiResultCompanyResource(params) {
  const { companyId } = params;
  return request.post(`/rsbox/push/multi/${companyId}`, {
    ...params,
  });
}

// 环幕—商家素材全部推送
export async function pushAllResultCompanyResource(params) {
  const { companyId } = params;
  return request.post(`/rsbox/push/all/${companyId}`, {
    ...params,
  });
}

// 新增商家盒子
export async function addRsBox(params) {
  return request(`/rsbox/add-rsBox`, {
    method: 'POST',
    data: params,
  });
}

// 修改商家自己添加的盒子
export async function editRsBox(params) {
  return request(`/rsbox/edit-rsBox`, {
    method: 'POST',
    data: params,
  });
}

// 删除商家自己添加的盒子
export async function delRsBox(params) {
  return request(`/rsbox/delete-rsBox`, {
    method: 'POST',
    data: { id: params.id },
  });
}

// 强制删除商家自己添加的盒子
export async function forceDelRsBox(params) {
  return request(`/rsbox/device/forceDeleteMac`, {
    method: 'POST',
    data: { id: params.id },
  });
}

// 拷贝素材
export async function copyScenes(data) {
  return request(`/rsbox/device/copyScenes`, {
    method: 'POST',
    data,
  });
}

// 场所素材启停用
export async function post_ableSceneByCompany(params) {
  const { company_id } = params;
  return request(`/rsbox/device/ableSceneByCompany/${company_id}`, {
    method: 'POST',
    data: params,
  });
}

// 盒子在场所间转移
export async function post_transferRsboxMac(params) {
  return request(`/rsbox/device/macTransferCompany`, {
    method: 'POST',
    data: params,
  });
}

// 获取盒子授权信息
export async function get_device_getAuthBySn(params) {
  return request(`/business-service/api/device/getAuthBySn/${params.sn}`);
}
