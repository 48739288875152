import request from '@/utils/request';

// 服务器类型树状图
export function getUpgradePackageTargetTypes() {
  return request('/upgrade-package-target-types-has-package', {
    method: 'GET',
  });
}

// 获取版本号列表
export function getApiUpgradePackageAll(params) {
  return request('/upgrade-package/all', {
    method: 'GET',
    params,
  });
}

// 获取版本号详情
export function getApiUpgradePackageIdDetail(id) {
  return request(`/upgrade-package/${id}/detail`, {
    method: 'GET',
  });
}

// 获取评论列表
export function get_upgradePackageComment_id_list(id, params) {
  return request(`/upgrade-package-comment/${id}/list`, {
    method: 'GET',
    params,
  });
}

// 新增评论
export function post_upgradePackageComment_create(data) {
  return request('/upgrade-package-comment/create', {
    method: 'POST',
    data,
  });
}

// 编辑评论
export function post_upgradePackageComment_edit(data) {
  return request('/upgrade-package-comment/edit', {
    method: 'POST',
    data,
  });
}

// 删除评论
export function post_upgradePackageComment_id_delete(id) {
  return request(`/upgrade-package-comment/${id}/delete`, {
    method: 'POST',
  });
}

// 上架指定升级列表
export function getShelveSpecificCompanyJobs(params) {
  const { upgrade_package_id } = params;
  delete params.upgrade_package_id;
  params.include = 'company';
  return request(
    `/upgrade-package/${upgrade_package_id}/shelves-specific-company-jobs`,
    {
      params,
    }
  );
}

// 验证指定升级列表
export function getVerifyCompaniesList(params) {
  const { upgrade_package_id } = params;
  params.include = 'company';
  return request(`/upgrade-package/${upgrade_package_id}/verify-jobs`, {
    params,
  });
}

// 试点指定升级列表
export function getPilotCompaniesList(params) {
  const { upgrade_package_id } = params;
  params.include = 'company';
  return request(`/upgrade-package/${upgrade_package_id}/pilot-jobs`, {
    params,
  });
}

// 获取升级包依赖环境配置
export async function getUpgradePackageMatchRules(params) {
  const { upgrade_package_id } = params;
  delete params.upgrade_package_id;
  return request(`/upgrade-package/${upgrade_package_id}/match-rule`, {
    params,
  });
}

// 获取可升级基础版本相关的设备型号和设备类型
export async function getUpgradePackageEnvironmentDeviceConfigureList() {
  return request('/upgrade-package-environment-device-configure-list');
}

// 拷贝升级包所有依赖
export function post_upgradePackage_CopyMatchRule(data) {
  return request(`/upgrade-package/Copy-match-rule`, {
    method: 'POST',
    data,
  });
}

// 编辑升级包单个依赖
export async function editUpgradePackageMatchRules(params) {
  const { upgrade_package_id, rule_id } = params;
  delete params.upgrade_package_id;
  delete params.rule_id;
  return request(
    `/upgrade-package/${upgrade_package_id}/match-rule/${rule_id}`,
    {
      method: 'PUT',
      data: {
        ...params,
      },
    }
  );
}

// 新增升级包服务器组 or 服务器组新增依赖
export async function createUpgradePackageMatchRules(params) {
  const { upgrade_package_id } = params;
  delete params.upgrade_package_id;
  return request(`/upgrade-package/${upgrade_package_id}/match-rule`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

// 删除升级包服务器组 or 删除升级包依赖
export async function deleteUpgradePackageMatchRules(data) {
  const { upgrade_package_id, match_rule_id } = data;
  delete data.upgrade_package_id;
  delete data.match_rule_id;
  return request(
    `/upgrade-package/${upgrade_package_id}/match-rule/${match_rule_id}`,
    {
      method: 'DELETE',
      data,
    }
  );
}
