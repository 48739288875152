import {
  saveDeviceAdapter,
  getDeviceAdapterDetail,
  getAvailableAdapterOptions,
} from '@/services/companyApp';

export default {
  namespace: 'CompanyAppManager_AppStbAdapter_appStbAdapter',

  state: {},

  effects: {
    *saveDeviceAdapter({ payload, callback }, { call }) {
      yield call(saveDeviceAdapter, payload);
      callback();
    },
    *getDeviceAdapterDetail({ payload, callback }, { call, put }) {
      const { data } = yield call(getDeviceAdapterDetail, payload);
      yield put({
        type: 'save',
        payload: {
          [data.adapter_type]: data.detail_json,
        },
      });

      callback();
    },

    *getAvailableAdapterOptions({ payload }, { call, put }) {
      const { data } = yield call(getAvailableAdapterOptions, payload);

      yield put({
        type: 'save',
        payload: {
          availableAdapterOptions: data,
        },
      });
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};
