import {
  queryStbSdkPermissionList,
  stbSdkPermissionCreate,
  stbSdkPermissionEdit,
  stbSdkPermissionDelete,
  configAppStbSdkPermission,
  appStbSdkPermission,
} from '@/services/devices';

export default {
  namespace: 'CompanyAppManager_stbSdkPermission',

  state: {
    data: {},
    result: {},
  },

  effects: {
    *fetch({ payload }, { call, put }) {
      const {
        data: { data, meta },
      } = yield call(queryStbSdkPermissionList, payload);

      yield put({
        type: 'save',
        payload: {
          list: data,
          pagination: {
            total: meta.total,
            pageSize: meta.per_page,
            current: meta.current_page,
          },
        },
      });
    },
    *create({ payload }, { call, put }) {
      const { data } = yield call(stbSdkPermissionCreate, payload);
      yield put({
        type: 'saveResult',
        payload: data,
      });
    },
    *edit({ payload }, { call, put }) {
      const { data } = yield call(stbSdkPermissionEdit, payload);
      yield put({
        type: 'saveResult',
        payload: data,
      });
    },
    *delete({ payload }, { call, put }) {
      const { data } = yield call(stbSdkPermissionDelete, payload);
      yield put({
        type: 'saveResult',
        payload: data.result,
      });
    },
    *fetchInfo({ payload }, { call, put }) {
      const { data } = yield call(appStbSdkPermission, payload);
      yield put({
        type: 'saveResult',
        payload: data.result,
      });
    },
    *configApp({ payload }, { call, put }) {
      const { data } = yield call(configAppStbSdkPermission, payload);
      yield put({
        type: 'saveResult',
        payload: data.result,
      });
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        data: payload,
      };
    },
    saveResult(state, { payload }) {
      return {
        ...state,
        result: payload,
      };
    },
  },
};
