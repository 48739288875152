import request from '@/utils/request';

// 商家所有盒子
export async function queryCompanyAllRsbox(params) {
  return request(`/sy/device-list`, {
    params,
  });
}

// 获取通道
export async function get_fire_channel() {
  return request(`/fire/channel`);
}

// 获取设备类型
export async function get_fire_deviceType() {
  return request(`/fire/device-type`);
}

// 获取融合分类
export async function get_fire_fuseClassify() {
  return request(`/fire/fuse-classify`);
}

// 获取某设备的融合分类
export async function get_sy_device_id_fuseClassify(productionId) {
  return request(`/sy/device/${productionId}/fuse-classify`);
}

// 获取视频层数
export async function get_fire_videoPly() {
  return request(`/fire/video-ply`);
}

// 添加盒子
export async function post_sy_device(data) {
  return request(`/sy/device`, {
    method: 'POST',
    data,
  });
}

// 锁定设备
export async function put_sy_device_lock(params) {
  const { production_id, lock } = params;
  return request(`/sy/device/lock/${production_id}/${lock}`, {
    method: 'PUT',
  });
}

// 设备控制器修改
export async function put_sy_device_controlType(params) {
  const { production_id, control_type_value } = params;
  return request(
    `/sy/device/controlType/${production_id}/${control_type_value}`,
    {
      method: 'PUT',
    }
  );
}

// 设备自动更新时间修改
export async function put_sy_device_mandate(params) {
  const { production_id, ...sendData } = params;
  return request(`/sy/device/mandate/${production_id}`, {
    method: 'PUT',
    params: sendData,
  });
}

// 设备删除
export async function delete_sy_device(params) {
  const { production_id } = params;
  return request(`/sy/device/${production_id}`, {
    method: 'DELETE',
  });
}

// 设备修改
export async function put_sy_device(params) {
  const { production_id, ...sendData } = params;
  return request(`/sy/device/${production_id}`, {
    method: 'PUT',
    params: sendData,
  });
}

// 获取设备未绑定素材列表
export async function get_fodder_getDeviceMaterialVideoRelationBySerialNo(
  params
) {
  const { productId, ...sendData } = params;
  return request(
    `/fodder/getDeviceMaterialVideoRelationBySerialNo/${productId}`,
    {
      params: sendData,
    }
  );
}

// 获取设备已绑定素材列表
export async function get_fodder_getDeviceMaterialVideoBindingBySerialNo(
  params
) {
  const { productId } = params;
  return request(
    `/fodder/getDeviceMaterialVideoBindingBySerialNo/${productId}`
  );
}

// 获取素材分类
export async function get_fire_materialClassify() {
  return request(`/fire/material-classify`);
}

// 给设备选择素材
export async function post_sy_device_saveDeviceMaterialVideo(params) {
  const { productId, ...sendData } = params;
  return request(`sy/device/saveDeviceMaterialVideo/${productId}`, {
    method: 'POST',
    data: sendData,
  });
}
