import {
  agentRoleTemplateType,
  agentRoleCreate,
  agentRoleEdit,
} from '@/services/agent';

export default {
  namespace: 'BusinessCenter_roleTemplate',

  state: {
    list: [],
  },

  effects: {
    *queryagentRoleTemplate({ payload }, { call, put }) {
      const {
        data: { data },
      } = yield call(agentRoleTemplateType, payload);
      yield put({
        type: 'save',
        payload: { list: data },
      });
    },

    *edit({ payload }, { call, put }) {
      const { data } = yield call(agentRoleEdit, payload);
      return data;
    },

    *create({ payload }, { call, put }) {
      const { data } = yield call(agentRoleCreate, payload);
      return data;
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};
