import {
  defaultTailorIndex,
  defaultTailorBind,
  defaultTailorBindDestroy,
  defaultTailors,
} from '@/services/RSMarket/defaultTailors';
import * as companyresource from '@/services/companyresource';

export default {
  namespace: 'CompanyResource_CResourceTailors',

  state: {
    indexData: {
      list: [],
      pagination: {},
    },
    showData: {},
    tailors: [],
  },

  effects: {
    *index({ payload }, { call, put }) {
      const { status, data } = yield call(
        companyresource.queryTailorIndex,
        payload
      );

      if (status === 200) {
        yield put({
          type: 'saveIndexData',
          payload: {
            list: data.data,
            pagination: {
              total: data.total,
              pageSize: data.per_page,
              current: data.current_page,
            },
          },
        });
      }
    },

    *tailors({ payload }, { call, put }) {
      const { status, data } = yield call(
        companyresource.queryTailorsDataList,
        payload
      );

      if (status === 200) {
        yield put({
          type: 'saveTailorsData',
          payload: {
            data,
          },
        });
      }
    },

    *bind({ payload, callback }, { call, put }) {
      const { status } = yield call(companyresource.addTailorsBind, payload);

      if (status === 200) {
        if (callback) callback();
      }
    },

    *delTailorsBind({ payload, callback }, { call, put }) {
      const { status, data } = yield call(
        companyresource.delTailorsBindDestroy,
        payload
      );

      if (status === 204) {
        if (callback) callback();
      }
    },
  },

  reducers: {
    saveIndexData(state, action) {
      return {
        ...state,
        indexData: action.payload,
      };
    },
    saveShowData(state, action) {
      return {
        ...state,
        showData: action.payload,
      };
    },

    saveTailorsData(state, action) {
      return {
        ...state,
        tailors: action.payload.data,
      };
    },
  },
};
