import _ from 'lodash';
import { OperationKey } from './config';

export { OperationKey };

let operations = [];

export const initCanUseOperation = data => {
  operations = data;
};

function versionTest(currentVersion, versionExpressions) {
  if (currentVersion === '*' || versionExpressions === 'default') return true;
  const expressionPattern = /(>=|<=|>|<|=)?(\d+\.(?:\d+|x)\.(?:\d+|x))/;
  const expressions = versionExpressions.split(';');
  const currentVersionNumbers = currentVersion
    .split('.')
    .map(item => parseInt(item, 10) || 0);

  return expressions.some(expression => {
    const [success, symbol, version] =
      expression.match(expressionPattern) || [];

    if (success === undefined) return false;
    // 按位比较版本 如:4.4.6 > 4.4.4, 4.3.10 < 4.4.1
    const data = version.split('.').reduce(
      (acc, val, index) => {
        const { end } = acc;
        if (end) return acc;
        if (val === 'x') return { result: true, end: true };
        const cur = currentVersionNumbers[index];
        if (cur === undefined) return { result: true, end: true };
        const num = parseInt(val, 10) || 0;
        if (symbol === undefined && num === cur) {
          return { result: true, end: false };
        }
        if ((symbol === '>=' || symbol === '<=') && num === cur) {
          return { result: true, end: false };
        }
        if ((symbol === '>' || symbol === '>=') && cur > num) {
          return { result: true, end: true };
        }
        if ((symbol === '<' || symbol === '<=') && cur < num) {
          return { result: true, end: true };
        }
        return { result: false, end: true };
      },
      {
        result: true,
        end: false,
      }
    );
    return data.result;
  });
}

export const canUseOperation = _.curry(
  (hardware, currentVersion, operationKey) =>
    operations.some(
      item =>
        item.hardware_model === hardware &&
        item.action === operationKey &&
        versionTest(currentVersion, item.device_version_restrict)
    )
);

/**
 * 为不同版本选择不同操作
 * @param hardware 当前设备的 型号
 * @param currentVersion 当前设备版本
 * @param operationKey 操作
 * @param versionExpressions
 */
export const switchVersionExecute = _.curry(
  (hardware, currentVersion, operationKey, versionExecutionConfig) => {
    const config = operations.filter(
      item => item.hardware_model === hardware && item.action === operationKey
    );
    if (
      config
        .reduce((acc, val) => {
          const version = val.device_version_restrict.split(';');
          return [...acc, ...version];
        }, [])
        .every(ver => !versionTest(currentVersion, ver))
    ) {
      return;
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const item in versionExecutionConfig) {
      if (
        typeof versionExecutionConfig[item] === 'function' &&
        versionTest(currentVersion, item)
      ) {
        return versionExecutionConfig[item].call(this);
      }
    }
  }
);
