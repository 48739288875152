import request from '@/utils/request';

export async function defaultSongIndex(params) {
  return request('/rsbox/scene/songs', { params });
}

export async function defaultSongBind(params) {
  return request(`/rsbox/scene/songs/${params.id}`, {
    method: 'POST',
    data: params,
  });
}

export async function defaultSongBindDestroy(params) {
  return request(`/rsbox/scene/songs/${params.id}`, {
    method: 'DELETE',
    data: params,
  });
}
