import * as companyresource from '@/services/companyresource';

export default {
  namespace: 'CompanyResource_CompanyResource',
  state: {
    data: {},
  },
  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        data: payload,
      };
    },
  },
  effects: {
    *fetchCompanyResource({ payload }, { call, put }) {
      const { status, data } = yield call(
        companyresource.queryCompanyResource,
        payload
      );
      if (status === 200) {
        yield put({
          type: 'save',
          payload: {
            list: data.data,
            pagination: {
              total: data.total,
              pageSize: data.per_page,
              current: data.current_page,
            },
          },
        });
      }
    },

    *storeCompanyResource({ payload, callback }, { call, put }) {
      console.log(payload);
      const { status } = yield call(
        companyresource.pushResultCompanyResource,
        payload
      );

      if (status === 201) {
        if (callback) callback();
      }
    },
    *pushMultiCompanyResource({ payload, callback }, { call, put }) {
      const { status } = yield call(
        companyresource.pushMultiResultCompanyResource,
        payload
      );

      if (status === 201) {
        if (callback) callback();
      }
    },
    *pushAllCompanyResource({ payload, callback }, { call, put }) {
      const { status } = yield call(
        companyresource.pushAllResultCompanyResource,
        payload
      );

      if (status === 201) {
        if (callback) callback();
      }
    },
    *post_ableSceneByCompany({ payload, callback }, { call, put }) {
      const { status } = yield call(
        companyresource.post_ableSceneByCompany,
        payload
      );

      if (status === 200) {
        if (callback) callback();
      }
    },
  },
};
