import {
  getLocalReleaseCompanies,
  createLocalRelease,
  getCanBindLocalReleaseCompanies,
  deleteLocalRelease,
} from '@/services/appBase';

export default {
  namespace: 'CompanyAppManager_localRelease',

  state: {
    releaseCompanyList: [],
    canBindCompanies: [],
    result: '',
  },

  effects: {
    *fetchLocalReleaseCompanies({ payload }, { call, put }) {
      const { status, data } = yield call(getLocalReleaseCompanies, payload);

      if (status === 200) {
        yield put({
          type: 'saveList',
          payload: {
            list: data.data,
            pagination: {
              total: data.meta.total,
              pageSize: parseInt(data.meta.per_page),
              current: data.meta.current_page,
            },
          },
        });
      }
    },
    *fetchCanBindLocalReleaseCompanies({ payload }, { call, put }) {
      const { status, data } = yield call(
        getCanBindLocalReleaseCompanies,
        payload
      );

      if (status === 200) {
        yield put({
          type: 'saveCanBindCompanies',
          payload: {
            list: data.data,
            pagination: {
              total: data.meta.total,
              pageSize: parseInt(data.meta.per_page),
              current: data.meta.current_page,
            },
          },
        });
      }
    },
    *createLocalRelease({ payload, callback }, { call, put }) {
      const { status, data } = yield call(createLocalRelease, payload);

      if (status === 200) {
        yield put({
          type: 'saveResult',
          payload: data,
        });
      }
      callback(data);
    },
    *deleteLocalRelease({ payload }, { call, put }) {
      const { status, data } = yield call(deleteLocalRelease, payload);

      if (status === 200) {
        yield put({
          type: 'saveResult',
          payload: data,
        });
      }
    },
  },

  reducers: {
    saveList(state, { payload }) {
      return {
        ...state,
        releaseCompanyList: payload,
      };
    },
    saveResult(state, { payload }) {
      return {
        ...state,
        result: payload,
      };
    },
    saveCanBindCompanies(state, { payload }) {
      return {
        ...state,
        canBindCompanies: payload,
      };
    },
  },
};
