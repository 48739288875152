import {
  queryRoleList,
  queryUserList,
  queryLampList,
  queryAgentList,
  createLamp,
  createAgent,
  createRole,
  deleteRole,
  editRole,
  editCompany,
  bindRole,
  bindLmap,
  editAgent,
  unbindRole,
  allocateLamp,
  allocateAgent,
  editLamp,
  createSecondAgent,
  editSecondAgent,
  queryCompanyRoleList,
  queryRoleType,
  openLightData,
  closeLightData,
  deleteAgentUserRole,
  deleteLampUserRole,
  userAgentDataFilter,
  userAgentNotDataFilter,
  userLampDataFilter,
  userLampNoDataFilter,
} from '@/services/lampControl';

const LIGHT_FACTORY = 'LIGHT_FACTORY';
const LIGHT_AGENT = 'LIGHT_AGENT';

/**
 * 获取树型结构的 api 适配器
 * @param {Function} query
 * @param {Function} fn 对每个子元素的处理
 */
const queryListAdapter = query => async payload => {
  const {
    data: {
      data: { current_page, total, per_page, data },
    },
  } = await query(payload);
  return {
    data,
    meta: {
      current_page,
      total,
      per_page,
    },
  };
};

export default {
  namespace: 'lampRole',
  state: {
    roelType: '',
  },
  effects: {
    *queryRoleType(_, { call, put }) {
      const { data } = yield call(queryRoleType);
      return data;
    },
    *queryCompanyRoleList({ payload }, { call, put }) {
      const { data } = yield call(queryCompanyRoleList, payload);
      return data;
    },
    /** 获取已分配的数据 */
    *queryCompanyList({ payload }, { call, put }) {
      const { role_type } = payload;
      const { data } =
        role_type === LIGHT_FACTORY
          ? yield call(userLampDataFilter, payload)
          : yield call(userAgentDataFilter, payload);
      // eslint-disable-next-line no-unused-vars
      return data;
    },
    /** 获取未分配的数据 */
    *queryNoCompanyList({ payload }, { call, put }) {
      const { role_type } = payload;
      const { data } =
        role_type === LIGHT_FACTORY
          ? yield call(userLampNoDataFilter, payload)
          : yield call(userAgentNotDataFilter, payload);
      // eslint-disable-next-line no-unused-vars
      data.data = data.data.map(({ children, ...rest }) => ({ ...rest }));
      return data;
    },
    *queryRoleList({ payload }, { call, put }) {
      const { data } = yield call(queryRoleList, payload);
      return data;
    },
    *queryUserList({ payload }, { call, put }) {
      const { data } = yield call(queryUserList, payload);
      return data;
    },
    *createLamp({ payload }, { call, put }) {
      const { data } = yield call(createLamp, payload);
      return data;
    },
    *createAgent({ payload }, { call, put }) {
      const { data } = yield call(createAgent, payload);
      return data;
    },
    *createRole({ payload }, { call, put }) {
      const { data } = yield call(createRole, payload);
      return data;
    },
    *deleteRole({ payload }, { call, put }) {
      const { data } = yield call(deleteRole, payload);
      return data;
    },
    *editRole({ payload }, { call, put }) {
      const { data } = yield call(editRole, payload);
      return data;
    },
    *editLamp({ payload }, { call }) {
      const { data } = yield call(editLamp, payload);
      return data;
    },
    *editAgent({ payload }, { call }) {
      const { data } = yield call(editAgent, payload);
      return data;
    },
    *bindRole({ payload }, { call }) {
      const { data } = yield call(bindRole, payload);
      return data;
    },
    *unbindRole({ payload }, { call }) {
      const { data } = yield call(unbindRole, payload);
      return data;
    },
    *allocateLamp({ payload }, { call }) {
      const { data } = yield call(allocateLamp, payload);
      return data;
    },
    *allocateAgent({ payload }, { call }) {
      const { data } = yield call(allocateAgent, payload);
      return data;
    },
    *createSecondAgent({ payload }, { call }) {
      const { data } = yield call(createSecondAgent, payload);
      return data;
    },
    *editSecondAgent({ payload }, { call }) {
      const { data } = yield call(editSecondAgent, payload);
      return data;
    },
    *openLightData({ payload }, { call }) {
      const { data } = yield call(openLightData, payload);
      return data;
    },
    *closeLightData({ payload }, { call }) {
      const { data } = yield call(closeLightData, payload);
      return data;
    },
    *deleteAgentUserRole({ payload }, { call }) {
      const { data } = yield call(deleteAgentUserRole, payload);
      return data;
    },
    *deleteLampUserRole({ payload }, { call }) {
      const { data } = yield call(deleteLampUserRole, payload);
      return data;
    },
    *userAgentDataFilter({ payload }, { call }) {
      const { data } = yield call(userAgentDataFilter, payload);
      return data;
    },
    *userAgentNotDataFilter({ payload }, { call }) {
      const { data } = yield call(userAgentNotDataFilter, payload);
      return data;
    },
    *userLampDataFilter({ payload }, { call }) {
      const { data } = yield call(userLampDataFilter, payload);
      return data;
    },
    *userLampNoDataFilter({ payload }, { call }) {
      const { data } = yield call(userLampNoDataFilter, payload);
      return data;
    },
  },
  reducers: {
    save(state, { payload }) {
      return {
        ...payload,
        ...state,
      };
    },
  },
};
