import {
  getCompanyDeviceLinks,
  getCompanyBoxDeviceLinks,
  getManagementPeripheralLinks,
} from '@/services/company';

export default {
  namespace: 'CompanyList_hardwardlink',
  state: {
    linkDetail: [],
  },

  effects: {
    /** 获取场所系链路数据 */
    *fetchComponyDeviceLinks({ payload }, { call, put }) {
      const { data } = yield call(getCompanyDeviceLinks, payload);
      yield put({
        type: 'saveLink',
        payload: data,
      });
    },
    /** 获取包厢系统链路 */
    *fetchComponyBoxDeviceLinks({ payload }, { call, put }) {
      const { data } = yield call(getCompanyBoxDeviceLinks, payload);
      yield put({
        type: 'saveLink',
        payload: data,
      });
    },
    /** 获取管理管理系统终端外设链路 */
    *fetchManagementPeripheralLinks({ payload }, { call, put }) {
      const { data } = yield call(getManagementPeripheralLinks, payload);
      yield put({
        type: 'saveLink',
        payload: data,
      });
    },
  },

  reducers: {
    saveLink(state, action) {
      return {
        ...state,
        data: action.payload,
      };
    },
  },
};
