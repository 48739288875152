import request from '@/utils/request';

export async function queryDevices(params) {
  return request('/devices/search', {
    params,
  });
}

export async function queryExceptions(params) {
  return request('/abnormalLog/search', {
    params,
  });
}
