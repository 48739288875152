import { setToken, setHeader } from '@/utils/request/';
import { generateLoginUrl } from '@/utils/utils';
import cookie from 'js-cookie';
import { setPermissionList } from '@/utils/authority';

import { userTokenCookie, userTokenCookieDomain } from '@/utils/constKey';

export default {
  namespace: 'login',

  state: {
    status: null,
    access_token: null,
  },

  effects: {
    *logout(_, { put }) {
      // 退出
      yield put({
        type: 'changeLoginStatus',
        payload: {
          status: null,
          access_token: null,
        },
      });
      cookie.remove(userTokenCookie, { domain: userTokenCookieDomain });
      setPermissionList([]);
      // 跳到登录页面
      window.location.href = generateLoginUrl(window.location.origin);
      // history.push('/auth/login');
    },

    *checkUserToken({ res }, { put, select }) {
      // 获取 state 中的 access_token 的值
      const accessToken = yield select(state => state.access_token);
      if (!accessToken) {
        // 不存在，从 cookie 中获取 token,
        const token = cookie.get(userTokenCookie);
        // 取到 token, 就设置 token
        if (token) {
          yield put({
            type: 'changeLoginStatus',
            payload: {
              status: 'success',
              access_token: token,
              currentAuthority: 'admin',
            },
          });
          // if (pathname === '/auth/login') {
          // history.push('/dashboard');
          // }
        } else {
          // 否则，跳到登录页面
          window.location.href = generateLoginUrl();
        }
      }
    },
  },

  reducers: {
    changeLoginStatus(state, { payload }) {
      // 设置 axios token
      setToken(payload.access_token || null);
      setHeader('X-Platform', payload.platform || null);
      // 设 localStorage 的 token

      return {
        ...state,
        ...payload,
      };
    },
  },

  subscriptions: {
    setup({ dispatch, history }) {
      history.listen(({ pathname }) => {
        dispatch({
          type: 'checkUserToken',
          pathname,
        });
      });
    },
  },
};
