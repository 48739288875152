import { stringify } from 'qs';
import request from '@/utils/request';
import axios from 'axios';

export async function changePwd(params) {
  return request(`/user/changePassword`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function changeCurrentUserInfo(params) {
  return request(`/user/updateSelf`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function queryCurrent() {
  return request('/currentUser');
}

export async function queryUserList(params) {
  return request(`/user?${stringify(params)}`);
}

export async function queryUserInfo(userId) {
  return request(`/user/${userId}`);
}

export async function userCreate(params) {
  return request(`/user/create`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function userEdit(params) {
  const { id } = params;
  delete params.id;
  return request(`/user/${id}/edit`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function userDelete(params) {
  const { id } = params;
  delete params.id;
  return request(`/user/${id}/delete`);
}

export async function changeGlobalMenu(params) {
  const { id } = params;
  delete params.id;
  return request(`/user/${id}/changeGlobalMenu`);
}

export async function changeGlobalData(params) {
  const { id } = params;
  delete params.id;
  return request(`/user/${id}/changeGlobalData`);
}

export async function changeGlobalUser(params) {
  const { id } = params;
  delete params.id;
  return request(`/user/${id}/changeGlobalUser`);
}

export async function getPermission() {
  return request(`/user/getPermission`);
}

export async function getMenuList() {
  return request('/user/getMenuList');
}

export async function saveUserRoles(params) {
  const { id } = params;
  delete params.id;
  return request(`/user/${id}/saveUserRoles`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function saveUserMenus(params) {
  const { id } = params;
  delete params.id;
  return request(`/user/${id}/saveUserMenus`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function saveUserRegionDataFilter(params) {
  const { id } = params;
  delete params.id;
  return request(`/user/${id}/saveUserRegionDataFilter`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function addUserCompanyDataFilter(params) {
  const { id } = params;
  delete params.id;
  return request(`/user/${id}/addUserCompanyDataFilter`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function removeUserCompanyDataFilter(params) {
  const { id } = params;
  delete params.id;
  return request(`/user/${id}/removeUserCompanyDataFilter`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}
export async function bindAgentData(params) {
  delete params.id;
  return request(`/change-user-agent `, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}
export async function queryUserCompanyDataFilter(params) {
  console.log(params);
  return request(`/userCompanyDataFilter?${stringify(params)}`);
}

export async function queryUserCompanyNotDataFilter(params) {
  return request(`/userCompanyNotDataFilter?${stringify(params)}`);
}

export async function attemptLogin(params) {
  return request('/oauth/token', {
    method: 'POST',
    data: {
      grant_type: 'password',
      client_id: process.env.APP_AUTH_CLIENT_ID,
      client_secret: process.env.APP_AUTH_CLIENT_SECRET,
      ...params,
    },
  });
}

export async function changeAgentSuper(data) {
  return request('/agent/change-agent-super', {
    method: 'POST',
    data,
  });
}

// 账号绑定代理商
export async function bindAgentIdData(params) {
  console.log('999', params);
  return request(`/change-user-agent`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}
