import request from '@/utils/request';

export async function materialIndex(params) {
  return request('/rsbox/scenes', { params });
}

export async function materialShow({ id }) {
  return request(`/rsbox/scenes/${id}`, {
    method: 'GET',
  });
}

export async function materialStore(params) {
  return request('/rsbox/scenes', {
    method: 'POST',
    data: params,
  });
}

export async function materialUpdate(params) {
  const { id, ...others } = params;
  return request(`/rsbox/scenes/${id}`, {
    method: 'PUT',
    data: others,
  });
}

export async function teacherDestroy({ id }) {
  return request(`/teachers/${id}`, {
    method: 'DELETE',
  });
}

export async function materialOnline(params) {
  return request.post(`/rsbox/scenes/${params.id}/setOnline`, { params });
}

export async function materialOffline(params) {
  return request.post(`/rsbox/scenes/${params.id}/setOffline`, { params });
}

// 禁用
export async function materialDisable(params) {
  return request.post(`/rsbox/scenes/${params.id}/setDisable`, { params });
}

// 启用
export async function materialUseable(params) {
  return request.post(`/rsbox/scenes/${params.id}/setUseable`, { params });
}

// 删除
export async function materialDelete(params) {
  const { id } = params;
  return request(`/rsbox/scenes/${id}`, {
    method: 'DELETE',
  });
}

// 素材信息过滤
export async function materialNewScenesIndex(params) {
  return request('/rsbox/new-scenes', { params });
}
