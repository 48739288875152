import request from '@/utils/request';

export async function defaultSongTypeIndex(params) {
  return request('/rsbox/scene/usongTypes', { params });
}

export async function defaultSongTypes(params) {
  return request('/rsbox/scene/songTypes', { params });
}

export async function defaultSongTypeShow({ id }) {
  return request(`/rsbox/scenes/${id}`, {
    method: 'GET',
  });
}

export async function defaultSongTypeBind(params) {
  return request(`/rsbox/scene/songTypes/${params.id}`, {
    method: 'POST',
    data: params,
  });
}

export async function defaultSongTypeBindDestroy(params) {
  return request(`/rsbox/scene/songTypes/${params.id}`, {
    method: 'DELETE',
    data: params,
  });
}

export async function defaultSongTypeUpdate(params) {
  const { id, ...others } = params;
  return request(`/rsbox/scenes/${id}`, {
    method: 'PUT',
    data: others,
  });
}

export async function defaultSongTypeDestroy({ id }) {
  return request(`/teachers/${id}`, {
    method: 'DELETE',
  });
}
