import {
  queryRoleList,
  queryUserList,
  queryAgentList,
  createLamp,
  createAgent,
  createRole,
  deleteRole,
  editRole,
  bindRole,
  editAgent,
  unbindRole,
  allocateLamp,
  allocateAgent,
  editLamp,
  createSecondAgent,
  queryCompanyRoleList,
  queryRoleType,
  editSecondAgent,
  editAgentState,
} from '@/services/lampControl';
import { agentCompanyList } from '@/services/company';
import {
  secondAgentList,
  changeBatchCompanyAgent,
  auditSecondAgent,
  batchBindEncryptionDog,
} from '@/services/agent';

export default {
  namespace: 'BusinessCenter_agentManage',
  state: {
    data: {},
    personlist: {},
  },
  effects: {
    *queryRoleType(_, { call, put }) {
      const { data } = yield call(queryRoleType);
      return data;
    },
    *queryCompanyRoleList({ payload }, { call, put }) {
      const { data } = yield call(queryCompanyRoleList, payload);
      return data;
    },
    *queryAgentList({ payload }, { call, put }) {
      const { data } = yield call(queryAgentList, payload);
      return data;
    },
    *queryRoleList({ payload }, { call, put }) {
      const { data } = yield call(queryRoleList, payload);
      return data;
    },
    *queryUserList({ payload }, { call, put }) {
      const { data } = yield call(queryUserList, payload);
      return data;
    },
    *querySecondAgentList({ payload }, { call, put }) {
      const { data } = yield call(secondAgentList, payload);
      return data;
    },
    *fetchAgentCompanyList({ payload }, { call, put }) {
      const { data, status } = yield call(agentCompanyList, payload);

      if (status === 200) {
        yield put({
          type: 'save',
          payload: {
            list: data.data,
            pagination: {
              total: data.meta.total,
              pageSize: data.meta.per_page,
              current: data.meta.current_page,
            },
          },
        });
      }
    },
    *createLamp({ payload }, { call, put }) {
      const { data } = yield call(createLamp, payload);
      return data;
    },
    *createAgent({ payload }, { call, put }) {
      const { data } = yield call(createAgent, payload);
      return data;
    },
    *createRole({ payload }, { call, put }) {
      const { data } = yield call(createRole, payload);
      return data;
    },
    *deleteRole({ payload }, { call, put }) {
      const { data } = yield call(deleteRole, payload);
      return data;
    },
    *editRole({ payload }, { call, put }) {
      const { data } = yield call(editRole, payload);
      return data;
    },
    *editLamp({ payload }, { call }) {
      const { data } = yield call(editLamp, payload);
      return data;
    },
    *editAgent({ payload }, { call }) {
      const { data } = yield call(editAgent, payload);
      return data;
    },
    *bindRole({ payload }, { call }) {
      const { data } = yield call(bindRole, payload);
      return data;
    },
    *unbindRole({ payload }, { call }) {
      const { data } = yield call(unbindRole, payload);
      return data;
    },
    *allocateLamp({ payload }, { call }) {
      const { data } = yield call(allocateLamp, payload);
      return data;
    },
    *allocateAgent({ payload }, { call }) {
      const { data } = yield call(allocateAgent, payload);
      return data;
    },
    *createSecondAgent({ payload }, { call }) {
      const { data } = yield call(createSecondAgent, payload);
      return data;
    },
    *editSecondAgent({ payload }, { call }) {
      const { data } = yield call(editSecondAgent, payload);
      return data;
    },
    *auditSecondAgent({ payload }, { call }) {
      const { data } = yield call(auditSecondAgent, payload);
      return data;
    },
    *changeBatchCompanyAgent({ payload }, { call, put }) {
      const { data } = yield call(changeBatchCompanyAgent, payload);
      return data;
    },

    *batchBindEncryptionDog({ payload }, { call, put }) {
      const { data } = yield call(batchBindEncryptionDog, payload);
      return data;
    },
    *fetchPersonlist(next, { call, put }) {
      const { status, data } = yield call(agentCompanyList);

      if (status === 200) {
        yield put({
          type: 'savePersonlist',
          payload: data,
        });
      }
    },
    *changeAgentState({ payload }, { call, put }) {
      const { data } = yield call(editAgentState, payload);
      return data;
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        data: payload,
      };
    },
    savePersonlist(state, { payload }) {
      return {
        ...state,
        personlist: payload,
      };
    },
  },
};
