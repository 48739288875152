import * as appManufacturer from '@/services/appManufacturer';

export default {
  namespace: 'CompanyAppManager_appManufacturer',
  state: {
    result: {},
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        result: payload,
      };
    },
  },

  effects: {
    *fetch({ payload }, { call, put }) {
      const { data } = yield call(appManufacturer.getManufacturerList, payload);
      yield put({
        type: 'save',
        payload: data,
      });
    },

    *set({ payload }, { call }) {
      console.log('------');
      console.log(payload);
      console.log('++++++');
      yield call(appManufacturer.setManufacturer, payload);
    },

    *del({ payload }, { call }) {
      yield call(appManufacturer.delManufacturer, payload);
    },
  },
  // subscriptions: {
  //     setup({ dispatch, history }) {
  //         console.log(1);
  //         return history.listen(
  //             ({ pathname, query }) => {
  //                 console.log(2);
  //                 if (pathname === '/company-app-manager/app-base-list') {
  //                     console.log(3);
  //                     dispatch({ type: 'fetch', payload: query });
  //                 }
  //             }
  //         );
  //     },
  // },
};
