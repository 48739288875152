import request from '@/utils/request';

export async function queryPlatformList(params) {
  return request('/platforms', { method: 'GET', params });
}

export async function addPlatforms(data) {
  return request('add-platform', { method: 'POST', data });
}

export async function editPlatform(data) {
  return request('edit-platform', { method: 'POST', data });
}

export async function deletePlatform(data) {
  return request('delete-platform', { method: 'POST', data });
}

export async function platformPermission(data) {
  return request('platform-permission', { method: 'POST', data });
}
