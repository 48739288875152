// use localStorage to store the authority info, which might be sent from server in actual project.
import { userPermissionStorageKey, userMenuList } from './constKey';

export function getAuthority(str) {
  // return localStorage.getItem('antd-pro-authority') || ['admin', 'user'];
  const authorityString =
    typeof str === 'undefined'
      ? localStorage.getItem('antd-pro-authority')
      : str;
  // authorityString could be admin, "admin", ["admin"]
  let authority;
  try {
    authority = JSON.parse(authorityString);
  } catch (e) {
    authority = authorityString;
  }
  if (typeof authority === 'string') {
    return [authority];
  }
  return authority || ['admin'];
}

export function setAuthority(authority) {
  const proAuthority = typeof authority === 'string' ? [authority] : authority;
  return localStorage.setItem(
    'antd-pro-authority',
    JSON.stringify(proAuthority)
  );
}

export function getPermissionList() {
  const permissionString = localStorage.getItem(userPermissionStorageKey);
  let permissionList;
  try {
    permissionList = JSON.parse(permissionString);
  } catch (error) {
    permissionList = [];
  }
  return Array.isArray(permissionList) ? permissionList : [];
}

export function setPermissionList(permissionList) {
  if (Array.isArray(permissionList)) {
    localStorage.setItem(
      userPermissionStorageKey,
      JSON.stringify(permissionList)
    );
  }
}

export function getMenuData() {
  const menuString = localStorage.getItem(userMenuList);
  let menuList;
  try {
    menuList = JSON.parse(menuString);
  } catch (error) {
    menuList = [];
  }
  return Array.isArray(menuList) ? menuList : [];
}

export function setMenuData(menuList) {
  if (Array.isArray(menuList)) {
    localStorage.setItem(userMenuList, JSON.stringify(menuList));
  }
}
