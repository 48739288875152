import {
  queryPunishCompany,
  queryPunishLog,
  queryCompanyInfo,
  queryPunishInfo,
  saveJobs,
  queryPunishMobile,
  getStdDevice,
  getCommonTask,
} from '@/services/punish';

export default {
  namespace: 'Piracy_company',

  state: {
    data: {},
    visible: false,
    current: [],
    sortedInfo: '',
    result: {},
    info: {},
    companies: {},
    jobs: [],
    mobile: '',
    devices: [],
    tasks: [],
  },

  effects: {
    *fetchCompany({ payload }, { call, put }) {
      const { status, data } = yield call(queryPunishCompany, payload);
      if (status === 200) {
        yield put({
          type: 'saveCompanies',
          payload: {
            list: data.data,
            pagination: {
              total: data.meta.total,
              pageSize: data.meta.per_page,
              current: data.meta.current_page,
            },
          },
        });
      }
    },
    *fetchPunishLog({ payload }, { call, put }) {
      const { status, data } = yield call(queryPunishLog, payload);
      if (status === 200) {
        yield put({
          type: 'save',
          payload: {
            list: data.data,
            pagination: {
              total: data.meta.total,
              pageSize: data.meta.per_page,
              current: data.meta.current_page,
            },
          },
        });
      }
    },
    *fetchCompanyInfo({ payload }, { call, put }) {
      const { status, data } = yield call(queryCompanyInfo, payload);
      if (status === 200) {
        yield put({
          type: 'saveCompany',
          payload: data,
        });
      }
    },
    *fetchPunishInfo({ payload }, { call, put }) {
      const { status, data } = yield call(queryPunishInfo, payload);
      if (status === 200) {
        yield put({
          type: 'saveJobs',
          payload: data,
        });
      }
    },
    *savePunishJobs({ payload }, { call, put }) {
      const { status, data } = yield call(saveJobs, payload);
      if (status === 200) {
        yield put({
          type: 'saveResult',
          payload: data,
        });
      }
    },
    *fetchStdDevice({ payload }, { call, put }) {
      const { status, data } = yield call(getStdDevice, payload);
      if (status === 200) {
        yield put({
          type: 'saveDevices',
          payload: data.data,
        });
      }
    },
    *fetchCommonTask({ payload }, { call, put }) {
      const { status, data } = yield call(getCommonTask, payload);
      if (status === 200) {
        yield put({
          type: 'saveTasks',
          payload: {
            list: data.data,
            pagination: {
              total: data.meta.total,
              pageSize: data.meta.per_page,
              current: data.meta.current_page,
            },
          },
        });
      }
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        data: payload,
      };
    },
    saveResult(state, { payload }) {
      return {
        ...state,
        result: payload,
      };
    },
    saveJobs(state, { payload }) {
      return {
        ...state,
        jobs: payload,
      };
    },
    saveCompany(state, { payload }) {
      return {
        ...state,
        info: payload,
      };
    },
    saveCompanies(state, { payload }) {
      return {
        ...state,
        data: payload,
        companies: {
          ...state.companies,
          ...payload,
        },
      };
    },
    saveDevices(state, { payload }) {
      return {
        ...state,
        devices: payload,
      };
    },
    saveTasks(state, { payload }) {
      return {
        ...state,
        tasks: payload,
      };
    },
  },
};
