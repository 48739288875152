import { getDistributionStragegyCompanies } from '@/services/remoteUpgrade';

export default {
  namespace: 'DistributionStrategyCompanies_distributionStrategyCompanies',

  state: {
    canBindCompanies: [],
  },

  effects: {
    *fetchCanBindCompanies({ payload }, { call, put }) {
      const { status, data } = yield call(
        getDistributionStragegyCompanies,
        payload
      );

      if (status === 200) {
        yield put({
          type: 'saveList',
          payload: {
            list: data.data,
            pagination: {
              total: data.meta.total,
              pageSize: parseInt(data.meta.per_page),
              current: data.meta.current_page,
            },
          },
        });
      }
    },
  },

  reducers: {
    saveList(state, { payload }) {
      return {
        ...state,
        canBindCompanies: payload,
      };
    },
  },
};
