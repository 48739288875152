import memoizeOne from 'memoize-one';
import isEqual from 'lodash/isEqual';
import { formatMessage } from 'umi';
import { getPermission, getMenuList } from '@/services/user';
import { setPermissionList } from '@/utils/authority';
import menu from '@/locales/en-US/menu';

// Conversion router to menu.
function formatter(data, parentClassify, parentName) {
  return data
    .map(item => {
      if (!item.name || !item.path) {
        return null;
      }

      let locale = 'menu';
      if (parentName) {
        locale = `${parentName}.${item.name}`;
      } else {
        locale = `menu.${item.name}`;
      }

      const result = {
        ...item,
        originName: item.name,
        name: formatMessage({ id: locale, defaultMessage: item.name }),
        classify: parentClassify || (item.isTop && item.name),
        locale,
      };

      if (item.routes) {
        const children = formatter(item.routes, result.classify, locale);
        // Reduce memory usage
        result.children = children;
      }
      delete result.routes;
      return result;
    })
    .filter(item => item);
}

/**
 * 过滤出顶层菜单
 */
const filtertopMenuData = menuList =>
  menuList
    .filter(item => item.pid === 0)
    .sort((a, b) => a.sort_order - b.sort_order)
    .map(item => ({
      key: item.permission_name,
      tab: item.name,
      locale: item.locale,
    }));

/**
 * 获取路由中的面包屑页面
 * @param {*} router
 */
const getExtraBreadcrumbItems = router => {
  if (!router) {
    return {};
  }
  const routerMap = {};

  const flattenMenuData = data => {
    data.forEach(menuItem => {
      if (menuItem.routes) {
        flattenMenuData(menuItem.routes);
      }
      if (menuItem.meta && menuItem.meta.name) {
        menuItem.name = menuItem.meta.name;
        routerMap[menuItem.path] = menuItem;
      }
    });
  };
  flattenMenuData(router);
  return routerMap;
};

/**
 * 获取远程菜单中面包屑映射
 * @param {Object} menuData 右边菜单
 * @param {String} classify 分类
 * @param {Object} topMenuData 顶部菜单
 * @param {Object} router 菜单
 */
const getBreadcrumbNameMap = (menuData, classify, topMenuData) => {
  const routerMap = {};
  const flattenMenuData = data => {
    data.forEach(menuItem => {
      if (menuItem.children) {
        flattenMenuData(menuItem.children);
      }
      // Reduce memory usage
      routerMap[menuItem.path] = menuItem;
    });
  };
  flattenMenuData(menuData);
  routerMap[`/${classify}`] = {
    name: topMenuData.filter(item => item.key === classify)[0].tab,
  };
  return routerMap;
};

const memoizeOneGetBreadcrumbNameMap = memoizeOne(
  getBreadcrumbNameMap,
  isEqual
);

const memoizeOneGetExtraBreadcrumbItems = memoizeOne(
  getExtraBreadcrumbItems,
  isEqual
);

// 构造菜单树数据
const getTreeData = (pid, menus) =>
  menus
    .filter(item => item.pid === pid)
    .map(item => ({
      name: item.name,
      permission_name: item.permission_name,
      path: item.path,
      icon: item.icon,
      children: getTreeData(item.id, menus),
    }));

/**
 * 获取当前的分类
 */
export const getCurrentTab = () => {
  const reg = /\/(\S+?)\//;
  // eslint-disable-next-line no-restricted-globals
  const result = reg.exec(location.pathname);
  if (result && result.length >= 2) {
    return result[1];
  }
};

/**
 * 获取菜单列表
 */
const getMenuListCache = (() => {
  let menuListCache = [];
  /**
   * @param {update} 是否刷新缓存
   */
  return async update => {
    if (!menuListCache || menuListCache.length === 0 || update) {
      const { data } = await getMenuList();
      menuListCache = data;
    }
    return menuListCache;
  };
})();

export default {
  namespace: 'menu',

  state: {
    menuData: [],
    tabList: undefined,
    breadcrumbList: [],
    breadcrumbNameMap: {},
    currentActiveKey: null,
    defaultActiveKey: getCurrentTab(),
  },

  effects: {
    *updateLocalPermission({ payload }, { call }) {
      const {
        data: { permission },
      } = yield call(getPermission, payload);
      setPermissionList(permission);
    },
    *getTopMenuData(_, { call, put }) {
      const { menuList } = yield call(getMenuListCache);
      const topMenuData = filtertopMenuData(menuList);
      yield put({
        type: 'save',
        payload: { tabList: topMenuData },
      });
    },
    *getMenuData({ payload }, { put, call }) {
      const { classify, routes } = payload;
      const { menuList } = yield call(getMenuListCache);
      /** BEGIN **: NOTE: 根据route解析出数据，将这行注释可以打开功能
      const newRoute = [];
      let id = 1;
      // 获取menu data
      const trans = (list, routeList, parentName) => {
        list.forEach(item => {
          const route = {};
          if (item && item.hideInMenu) return;
          let locale = 'menu';
          if (Object.keys(item).indexOf('name') >= 0) {
            if (parentName) {
              locale = `${parentName}.${item.name}`;
            } else {
              locale = `menu.${item.name}`;
            }
            route.permission_namee = item.name;
            route.name = formatMessage({
              id: locale,
              defaultMessage: item.name,
            });
            route.path = item.path;
            route.icon = item.icon;
            // eslint-disable-next-line no-plusplus
            route.id = id++;
          }
          if (
            Object.keys(item).indexOf('routes') &&
            Array.isArray(item.routes)
          ) {
            route.routes = [];
            trans(item.routes, route.routes, locale);
          }
          if (Object.entries(route).length > 0) routeList.push(route);
        });
      };
      trans(routes, newRoute);
      // 分析
      const database = [];
      const analyze = (node, pid) => {
        if (!node && !node.name) return;
        database.push({
          // eslint-disable-next-line no-plusplus
          id: node.id,
          pid,
          name: node.name,
          permission_namee: node.permission_namee,
          path: node.path,
          icon: node.icon,
          type: 1,
        });

        if (Array.isArray(node.routes)) {
          node.routes.forEach(item => {
            analyze(item, node.id);
          });
        }
      };
      newRoute.forEach(item => analyze(item, 0));
      /* = END = */
      const menuTree = getTreeData(0, menuList);
      const topMenuData = filtertopMenuData(menuList);
      const menuDataList = menuTree.filter(
        item => true && item.permission_name === classify
      );
      if (menuDataList && menuDataList.length > 0) {
        const menuData = menuDataList[0].children;
        const breadcrumbNameMap = Object.assign(
          memoizeOneGetExtraBreadcrumbItems(routes),
          memoizeOneGetBreadcrumbNameMap(menuData, classify, topMenuData)
        );
        yield put({
          type: 'save',
          payload: {
            menuData,
            breadcrumbNameMap,
            tabList: topMenuData,
            currentActiveKey: classify,
          },
        });
      }
    },
    *setBreadcrumbList({ payload }, { put, call }) {
      yield put({
        type: 'save',
        payload: { breadcrumbList: payload },
      });
    },
  },

  reducers: {
    save(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
};
