import {
  queryPiracy,
  fetchDeviceTypesData,
  fetchPiracyReason,
  fetchManagementTypesData,
  fetchReasonName,
} from '@/services/piracy';

export default {
  namespace: 'PiracyList',

  state: {
    data: {},
    stbHardTypes: {},
    visible: false,
    current: [],
    sortedInfo: '',
    reason: '',
    reasonNames: [],
  },

  effects: {
    *getFyPiracy({ payload }, { call, put }) {
      const { status, data } = yield call(queryPiracy, payload);

      if (status === 200) {
        yield put({
          type: 'save',
          payload: {
            list: data.data,
            pagination: {
              total: data.meta.total,
              pageSize: data.meta.per_page,
              current: data.meta.current_page,
            },
          },
        });
      }
    },
    *getSystemPiracy({ payload }, { call, put }) {
      const { status, data } = yield call(queryPiracy, payload);

      if (status === 200) {
        yield put({
          type: 'save',
          payload: {
            list: data.data,
            pagination: {
              total: data.meta.total,
              pageSize: data.meta.per_page,
              current: data.meta.current_page,
            },
          },
        });
      }
    },
    *fetchFyHardTypes(next, { call, put }) {
      const { status, data } = yield call(fetchDeviceTypesData, { type: 0 });
      if (status === 200) {
        yield put({
          type: 'saveStbHardTypes',
          payload: data,
        });
      }
    },

    *fetchSystemHardTypes(next, { call, put }) {
      const { status, data } = yield call(fetchManagementTypesData);
      if (status === 200) {
        yield put({
          type: 'saveStbHardTypes',
          payload: data,
        });
      }
    },

    *fetchReason(payload, { call, put }) {
      const { status, data } = yield call(fetchPiracyReason, payload);
      if (status === 200) {
        yield put({
          type: 'get',
          reason: data,
        });
      }
    },
    *fetchAllReasonName(payload, { call, put }) {
      const { status, data } = yield call(fetchReasonName, payload);
      if (status === 200) {
        yield put({
          type: 'saveReasonNames',
          reasonNames: data,
        });
      }
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        data: payload,
      };
    },

    saveStbHardTypes(state, { payload }) {
      return {
        ...state,
        stbHardTypes: payload,
      };
    },

    get(state, { reason }) {
      return {
        ...state,
        reason,
      };
    },
    saveReasonNames(state, { reasonNames }) {
      return {
        ...state,
        reasonNames,
      };
    },
  },
};
