export const userTokenStorageKey = 'APP_USER_TOKEN';
export const userTokenCookie = process.env.TOKEN_COOKIE_KEY;
export const userTokenCookieDomain = process.env.TOKEN_COOKIE_DOMAIN;
export const userPermissionStorageKey = 'APP_USER_PERMISSION';
export const userMenuList = 'USER_MENU_LIST';

// commonOperationAuthorize 的类型 与后端配置一致
export const OperationAuthorizeType = {
  COMPANY_AUTHENTICATION: 'COMPANY_AUTHENTICATION',
};

// 增值 -> 素材商城 -> 客户列表 -> 设备盒子
export const userMaterialShopStorageKey = 'MATERIAL_SHOP_RSBOX_INFO';
