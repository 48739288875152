import { queryVodList, createVod, editVod } from '@/services/vodControl';

export default {
  namespace: 'VodManage',
  state: {},
  effects: {
    *queryVodList({ payload, callback }, { call, put }) {
      const { data } = yield call(queryVodList, payload);

      if (callback) {
        callback(data);
      }
      return data;
    },
    *createVod({ payload }, { call, put }) {
      const { data } = yield call(createVod, payload);
      return data;
    },
    *editVod({ payload }, { call, put }) {
      const { data } = yield call(editVod, payload);
      return data;
    },
  },
};
