import * as companyAppService from '@/services/companyApp';
import * as appManufacturer from '@/services/appManufacturer';

export default {
  namespace: 'CompanyAppManager_AppBaseDetail',
  state: {
    loading: false,
    appInfo: {},
    manufacturer: {},
    vod_hardware: [],
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        appInfo: payload,
      };
    },
    setManufacturer(state, { payload }) {
      return {
        ...state,
        manufacturer: payload,
      };
    },
    setVodHardWare(state, { payload }) {
      return {
        ...state,
        vod_hardware: payload.vod_hardware,
      };
    },
  },

  effects: {
    *fetchOssConfig({ payload, callback }, { call, put }) {
      const { data } = yield call(companyAppService.getOssUpload, payload);
      callback(data);
    },

    *fetchDetail({ payload }, { call, put }) {
      const { data } = yield call(companyAppService.getAppBaseDetail, payload);
      yield put({
        type: 'save',
        payload: data,
      });
    },

    *create({ payload, callback }, { call }) {
      const { data } = yield call(companyAppService.createAppBase, payload);
      callback(data);
    },

    *saveAppBase({ payload }, { call }) {
      yield call(companyAppService.saveAppBase, payload);
    },
    *fetchAvailableVersion({ payload }, { call, put }) {
      const { data } = yield call(appManufacturer.getManufacturerList, payload);
      yield put({
        type: 'setManufacturer',
        payload: data,
      });
    },
    *fetchAvailableVodHardWare({ payload }, { call, put }) {
      const { data } = yield call(
        companyAppService.getDeviceAdapterDetail,
        payload
      );
      // console.log('in AppBaseDetailModel!');
      // console.log(data);
      // console.log('in AppBaseDetailModel!');
      yield put({
        type: 'setVodHardWare',
        payload: {
          vod_hardware: data.detail_json,
        },
      });
      return data;
    },
    *editAppBase({ payload }, { call }) {
      console.log(999);
      yield call(companyAppService.editAppBase, payload);
    },
  },
};
