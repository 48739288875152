import * as companyresource from '@/services/companyresource';

export default {
  namespace: 'CompanyResource_CResourceSongType',

  state: {
    indexData: {
      list: [],
      pagination: {},
    },
    showData: {},
    songTypes: [],
  },

  effects: {
    *index({ payload }, { call, put }) {
      const { status, data } = yield call(
        companyresource.querySongTypesDataList,
        payload
      );

      if (status === 200) {
        yield put({
          type: 'saveIndexData',
          payload: {
            list: data.data,
            pagination: {
              total: data.total,
              pageSize: data.per_page,
              current: data.current_page,
            },
          },
        });
      }
    },

    *songTypes({ payload }, { call, put }) {
      const { status, data } = yield call(
        companyresource.querySongTypesData,
        payload
      );

      if (status === 200) {
        yield put({
          type: 'saveSongTypesData',
          payload: {
            data,
          },
        });
      }
    },

    *bind({ payload, callback }, { call, put }) {
      console.log('bind->', payload);
      const { status } = yield call(companyresource.addSongTypeBind, payload);

      if (status === 200) {
        if (callback) callback();
      }
    },

    *delSongTypeBind({ payload, callback }, { call, put }) {
      const { status, data } = yield call(
        companyresource.delSongTypeBindDestroy,
        payload
      );

      if (status === 204) {
        if (callback) callback();
      }
    },
  },

  reducers: {
    saveIndexData(state, action) {
      return {
        ...state,
        indexData: action.payload,
      };
    },
    saveShowData(state, action) {
      return {
        ...state,
        showData: action.payload,
      };
    },
    saveSongTypesData(state, action) {
      return {
        ...state,
        songTypes: action.payload.data,
      };
    },
  },
};
