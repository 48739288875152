import request from '@/utils/request';
import { stringify } from 'qs';

export async function submitAppInstallTypeChange(params) {
  const { appId, appChangeTypeBefore } = params;
  return request(
    `/app/changeAppInstallType/${appId}/installType/${appChangeTypeBefore}`,
    {
      method: 'post',
      data: {
        ...params,
      },
    }
  );
}

export async function submitKmReviewOrder(params) {
  return request('/app/submitApkReview', {
    method: 'post',
    data: {
      ...params,
    },
  });
}

export async function getAppMarktReviewOrders(params) {
  return request('/appMarkert/reviewOrder', { params });
}

// 工单详情 根据reviewOrderNo获取数据
export async function getAppReviewDetail(params) {
  const { id } = params;
  return request(`/appMarkert/reviewOrder/${id}`, {
    method: 'get',
    data: {
      ...params,
    },
  });
}

// 工单详情 根据reviewOrderNo获取数据
export async function getdeviceAppInfo(params) {
  const { DeviceName } = params;
  // console.log('sdfnjskdanfnjsdfgkls21i9u82u4jwivnjksl');
  // console.log(params);
  // console.log(DeviceName);
  return request(`/appMarket/deviceAppInfo/${DeviceName}`, {
    method: 'get',
    data: {
      ...params,
    },
  });
}

// 硬件平台应用获取数据
export async function getHardwareApplicationList(params) {
  return request('/app/getHardwareApplicationList', {
    method: 'get',
    data: {
      ...params,
    },
  });
}

// 硬件平台应用变更按钮，修改is_limit_change
export async function updateIsLimitChange(params) {
  const { package_name, HardwareName } = params;
  console.log('565656565656', params);
  return request(
    `/appMarket/submitInstallTypeChange/${HardwareName}/application/${package_name}`,
    {
      method: 'POST',
      data: { ...params },
    }
  );
}
