import {
  queryCompanyFengyunConfiguration,
  postCompanyFengyunConfiguration,
  putCompanyFengyunConfiguration,
  pushCompanyFengyunConfiguration,
  pullCompanyFengyunConfiguration,
} from '@/services/company';

export default {
  namespace: 'Devices_fengyunConfiguration',

  state: {
    data: {},
  },

  effects: {
    *fetch({ payload, callback }, { call, put }) {
      const { status, data } = yield call(
        queryCompanyFengyunConfiguration,
        payload
      );

      if (status === 200) {
        yield put({
          type: 'save',
          payload: data.data,
        });

        console.log('get data!!!');
        callback(data.data);
      }
    },

    *post({ payload, callback }, { call }) {
      yield call(postCompanyFengyunConfiguration, payload);
      callback();
    },

    *put({ payload, callback }, { call }) {
      yield call(putCompanyFengyunConfiguration, payload);
      callback();
    },

    *pull({ payload, callback }, { call }) {
      yield call(pullCompanyFengyunConfiguration, payload);
      callback();
    },

    *push({ payload, callback }, { call }) {
      yield call(pushCompanyFengyunConfiguration, payload);
      callback();
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        data: payload,
      };
    },
  },
};
