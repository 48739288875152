import {
  getStragegyList,
  createStragegy,
  deleteStragegy,
  getStrategyInfo,
  editStragegy,
} from '@/services/remoteUpgrade';

export default {
  namespace: 'DistributionStrategy_distributionStrategy',

  state: {
    stragegyList: [],
    response: '',
    strategyInfo: {},
  },

  effects: {
    *fetchList({ payload }, { call, put }) {
      const { status, data } = yield call(getStragegyList, payload);

      if (status === 200) {
        yield put({
          type: 'saveList',
          payload: {
            list: data.data,
            pagination: {
              total: data.meta.total,
              pageSize: parseInt(data.meta.per_page),
              current: data.meta.current_page,
            },
          },
        });
      }
    },
    *fetchStrategyInfo({ payload }, { call, put }) {
      const { status, data } = yield call(getStrategyInfo, payload);

      if (status === 200) {
        yield put({
          type: 'saveInfo',
          payload: data.data,
        });
      }
    },

    *create({ payload, callback }, { call, put }) {
      const { status } = yield call(createStragegy, payload);
      if (status === 201) {
        yield put({
          type: 'saveResponse',
          payload: status,
        });

        callback();
      }
    },

    *edit({ payload, callback }, { call, put }) {
      const { status } = yield call(editStragegy, payload);

      if (status === 200) {
        yield put({
          type: 'saveResponse',
          payload: 201,
        });

        callback();
      }
    },

    *delete({ payload }, { call, put }) {
      const { status } = yield call(deleteStragegy, payload);
      yield put({
        type: 'saveResponse',
        payload: status,
      });
    },
  },

  reducers: {
    saveList(state, { payload }) {
      return {
        ...state,
        stragegyList: payload,
      };
    },
    saveResponse(state, { payload }) {
      return {
        ...state,
        response: payload,
      };
    },
    saveInfo(state, { payload }) {
      return {
        ...state,
        strategyInfo: payload,
      };
    },
  },
};
