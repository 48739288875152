import {
  getShelveSpecificCompanyJobs,
  getShelveSpecificCanBindCompanies,
  creategShelveSpecificCanBindCompanyJob,
  deleteUpgradePackageJob,
} from '@/services/remoteUpgrade';

export default {
  namespace: 'RemoteUpgrade_specificCompany',

  state: {
    jobList: [],
    canBindCompanies: [],
    response: '',
  },

  effects: {
    *fetchShelveSpecificCompanyJobs({ payload }, { call, put }) {
      const { status, data } = yield call(
        getShelveSpecificCompanyJobs,
        payload
      );

      if (status === 200) {
        yield put({
          type: 'saveList',
          payload: {
            list: data.data,
            pagination: {
              total: data.meta.total,
              pageSize: parseInt(data.meta.per_page),
              current: data.meta.current_page,
            },
          },
        });
      }
    },
    *fetchShelveSpecificCanBindCompanies({ payload }, { call, put }) {
      const { status, data } = yield call(
        getShelveSpecificCanBindCompanies,
        payload
      );
      if (status === 200) {
        yield put({
          type: 'saveCanBindCompanies',
          payload: {
            list: data.data,
            pagination: {
              total: data.meta.total,
              pageSize: parseInt(data.meta.per_page),
              current: data.meta.current_page,
            },
          },
        });
      }
    },
    *create({ payload }, { call, put }) {
      const { status } = yield call(
        creategShelveSpecificCanBindCompanyJob,
        payload
      );
      yield put({
        type: 'saveResponse',
        payload: status,
      });
    },
    *delete({ payload }, { call, put }) {
      const { status } = yield call(deleteUpgradePackageJob, payload);
      yield put({
        type: 'saveResponse',
        payload: status,
      });
    },
  },

  reducers: {
    saveList(state, { payload }) {
      return {
        ...state,
        jobList: payload,
      };
    },
    saveResponse(state, { payload }) {
      return {
        ...state,
        response: payload,
      };
    },
    saveCanBindCompanies(state, { payload }) {
      return {
        ...state,
        canBindCompanies: payload,
      };
    },
  },
};
