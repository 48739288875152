import request from '@/utils/request';

export function queryVodRoomDateChannels(params) {
  return request('VodRoomDate/channels', {
    method: 'GET',
    params,
  });
}

export function queryVodRoomDateChannelLogs(params) {
  const { id } = params;
  delete params.id;
  return request(`VodRoomDate/channelLogs/${id}`, {
    method: 'GET',
    params,
  });
}

export function queryVodRoomDateChannelStatistics(params) {
  return request(`VodRoomDate/channel/statistics`, {
    method: 'GET',
    params,
  });
}
