import { getJobsStatusLogs } from '@/services/remoteUpgrade';

export default {
  namespace: 'RemoteUpgrade_JobStatus',

  state: {
    logList: [],
  },

  effects: {
    *fetchJobStatusLogs({ payload }, { call, put }) {
      const { status, data } = yield call(getJobsStatusLogs, payload);

      if (status === 200) {
        yield put({
          type: 'saveLogs',
          payload: data,
        });
      }
    },
  },

  reducers: {
    saveLogs(state, { payload }) {
      return {
        ...state,
        logList: payload,
      };
    },
  },
};
