import { setAppInstallType } from '@/services/company';

export default {
  namespace: 'CompanyAppManager_appInstall',

  state: {
    result: {},
  },

  effects: {
    *setAppInstallType({ payload }, { call, put }) {
      const response = yield call(setAppInstallType, payload);

      yield put({
        type: 'setresult',
        payload: response.data,
      });
    },
  },

  reducers: {
    setresult(state, { payload }) {
      return {
        result: payload,
      };
    },
  },
};
