import request from '@/utils/request';

// 新增产品授权
export async function createCode(params) {
  console.log('!!!!!!!!!');
  console.log(params);
  return request('/authorizeManager/createAuthorizationCode', {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function batchCreate(params) {
  return request('/authorizeManager/batchImportDevice', {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function getAuthorizeList(params) {
  return request(`/authorizeManager/factoryDevices`, {
    params,
  });
}

export async function setActiveState(params) {
  return request('/authorizeManager/setAuthState', {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function getManufactuers() {
  return request(`/authorizeManager/getFactoryDeviceBrands`);
}

export async function getStbHardTypes() {
  return request(`/authorizeManager/getFactoryDeviceTypes`);
}
