import { stringify } from 'qs';
import request from '@/utils/request';

// 查询角色列表
export async function queryRoleList(params) {
  return request(`/role?${stringify(params)}`);
}
export async function allRoleList() {
  return request(`/allRole`);
}
export async function roleCreate(params) {
  return request(`/role/create`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}
export async function roleEdit(params) {
  const { id } = params;
  delete params.id;
  return request(`/role/${id}/edit`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}
export async function roleDelete(params) {
  const { id } = params;
  delete params.id;
  return request(`/role/${id}/delete`);
}
