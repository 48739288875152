import request from '@/utils/request';

export async function defaultTailorIndex(params) {
  return request('/rsbox/scene/uptailors', { params });
}

export async function defaultTailors(params) {
  return request('/rsbox/scene/tailors', { params });
}

export async function defaultTailorBind(params) {
  return request(`/rsbox/scene/tailors/${params.id}`, {
    method: 'POST',
    data: params,
  });
}

export async function defaultTailorBindDestroy(params) {
  return request(`/rsbox/scene/tailors/${params.id}`, {
    method: 'DELETE',
    data: params,
  });
}
