import * as companyresource from '@/services/ITMarket/companyresource';
import { materialDisable, materialUseable } from '@/services/RSMarket/material';

export default {
  namespace: 'CompanyITResource_BoxUsable',
  state: {
    data: {},
  },
  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        data: payload,
      };
    },
  },
  effects: {
    // 盒子可用素材
    *fetchBoxUsable({ payload }, { call, put }) {
      const { status, data } = yield call(
        companyresource.queryBoxUsable,
        payload
      );
      if (status === 200) {
        yield put({
          type: 'save',
          payload: {
            list: data.data,
            pagination: {
              total: data.total,
              pageSize: data.per_page,
              current: data.current_page,
            },
          },
        });
      }
    },

    // 启用
    *enableResource({ payload, callback }, { call, put }) {
      const { status } = yield call(companyresource.setEnableResource, payload);

      if (status === 200) {
        if (callback) callback();
      }
    },

    // 停用
    *disableResource({ payload, callback }, { call, put }) {
      const { status } = yield call(
        companyresource.setDisableResource,
        payload
      );

      if (status === 200) {
        if (callback) callback();
      }
    },
  },
};
