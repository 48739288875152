import {
  getUpgradePackageStrategyList,
  createUpgradePackageStrategy,
  enableUpgradePackageStrategy,
  disableUpgradePackageStrategy,
  enableUpgradePackageStrategyAgain,
} from '@/services/remoteUpgrade';

export default {
  namespace: 'RemoteUpgrade_upgradePackageStrategy',

  state: {
    upgradePackagestragegyList: [],
    response: '',
  },

  effects: {
    *fetchUpgradePackageStrategyList({ payload }, { call, put }) {
      const { status, data } = yield call(
        getUpgradePackageStrategyList,
        payload
      );

      if (status === 200) {
        yield put({
          type: 'saveList',
          payload: data,
        });
      }
    },
    *createUpgradePackageStrategy({ payload }, { call, put }) {
      const { status } = yield call(createUpgradePackageStrategy, payload);
      yield put({
        type: 'saveResponse',
        payload: status,
      });
    },
    *enableUpgradePackageStrategy({ payload }, { call, put }) {
      const { status } = yield call(enableUpgradePackageStrategy, payload);
      yield put({
        type: 'saveResponse',
        payload: status,
      });
    },
    *disableUpgradePackageStrategy({ payload }, { call, put }) {
      const { status } = yield call(disableUpgradePackageStrategy, payload);
      yield put({
        type: 'saveResponse',
        payload: status,
      });
    },

    *enableUpgradePackageStrategyAgain({ payload }, { call, put }) {
      const { status } = yield call(enableUpgradePackageStrategyAgain, payload);
      yield put({
        type: 'saveResponse',
        payload: status,
      });
    },
  },

  reducers: {
    saveList(state, { payload }) {
      return {
        ...state,
        upgradePackagestragegyList: payload,
      };
    },
    saveResponse(state, { payload }) {
      return {
        ...state,
        response: payload,
      };
    },
  },
};
