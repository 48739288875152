import request from '@/utils/request';

/** 灯光厂家列表 */
export function queryStereoList(params) {
  return request(`/company-service/stereo-vendors`, { method: 'GET', params });
}

export function createStereo(data) {
  return request(`/company-service/stereo-vendors`, { method: 'POST', data });
}

export function editStereo(data) {
  return request(`/company-service/stereo-vendors/${data.id}`, {
    method: 'PUT',
    data,
  });
}
