import request from '@/utils/request';
import { stringify } from 'qs';

// 获取场所PAD广告方案
export async function getCompanyAdvert(params) {
  const { id } = params;
  return request.get(`/padAdverts/getCompanyAdvert/${id}`);
}

// 获取PAD广告方案
export async function getpadAdverts(params) {
  const { id } = params;
  return request.get(`/padAdverts`);
}

// 获取PAD广告方案详情
export async function getCompanyAdvertDetail(params) {
  const { id } = params;
  return request.get(`/padAdverts/${id}`);
}

// 创建PAD广告方案
export async function addpadAdverts(data) {
  return request.post(`/padAdverts`, data);
}

// 设置场所PAD广告方案
export async function setCompanyAdvert(data) {
  const { id } = data;
  return request.post(`/padAdverts/setCompanyAdvert/${id}`, data);
}

// 更新PAD广告方案
export async function updatepadAdverts(data) {
  const { id } = data;
  return request.post(`/padAdverts/${id}`, data);
}

// 停用
export async function stopuse(data) {
  const { id } = data;
  return request.post(`/padAdverts/disable/${id}`);
}

// 启用
export async function startuse(data) {
  const { id } = data;
  return request.post(`/padAdverts/enable/${id}`);
}

// 删除广告方案
export async function delete_activity(data) {
  const { id } = data;
  return request.delete(`/padAdverts/${id}`);
}

// 获取oss配置信息
export async function getOssConfigs(params) {
  return request.get(`/advert/ossConfigs`);
}
