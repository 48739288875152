import * as messageService from '@/services/messageCenter';

export default {
  namespace: 'MessageManager_messageCenter',

  state: {
    data: {},
  },

  effects: {
    *fetchRoles({ payload, callback }, { call, put }) {
      const { status, data } = yield call(messageService.fetchRoles, payload);
      if (status === 200) {
        callback(data);
      }
    },
    *getDetail({ payload, callback }, { call, put }) {
      const { status, data } = yield call(
        messageService.getTemplateInfo,
        payload
      );
      if (status === 200) {
        callback(data);
      }
    },
    *fetch({ payload }, { call, put }) {
      const { status, data } = yield call(messageService.getTemplates, payload);
      if (status === 200) {
        yield put({
          type: 'save',
          payload: {
            list: data.data,
            pagination: {
              total: data.meta.total,
              pageSize: data.meta.per_page,
              current: data.meta.current_page,
            },
          },
        });
      }
    },
    *create({ payload, callback }, { call, put }) {
      const { status, data } = yield call(
        messageService.createTempplate,
        payload
      );
      callback(data);
    },
    *update({ payload, callback }, { call, put }) {
      const { status, data } = yield call(
        messageService.updateTemplate,
        payload
      );
      callback(data);
    },
    *delete({ payload, callback }, { call, put }) {
      const { status, data } = yield call(
        messageService.deleteTemplate,
        payload
      );
      callback(data);
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        data: payload,
      };
    },
  },
};
