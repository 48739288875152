import {
  defaultSongIndex,
  defaultSongBind,
  defaultSongBindDestroy,
} from '@/services/RSMarket/defaultSongs';

export default {
  namespace: 'ValueAddedCenter_RSMarket_defaultSongs',

  state: {
    indexData: {
      list: [],
      pagination: {},
    },
    showData: {},
    songs: [],
  },

  effects: {
    *index({ payload }, { call, put }) {
      const { status, data } = yield call(defaultSongIndex, payload);

      if (status === 200) {
        yield put({
          type: 'saveIndexData',
          payload: {
            list: data.data,
            pagination: {
              total: data.total,
              pageSize: parseInt(data.per_page),
              current: parseInt(data.current_page),
            },
          },
        });
      }
    },

    *bind({ payload, callback }, { call, put }) {
      const { status } = yield call(defaultSongBind, payload);

      if (status === 200) {
        if (callback) callback();
      }
    },

    *destroyBind({ payload, callback }, { call, put }) {
      const { status, data } = yield call(defaultSongBindDestroy, payload);

      if (status === 204) {
        if (callback) callback();
      }
    },
  },

  reducers: {
    saveIndexData(state, action) {
      return {
        ...state,
        indexData: action.payload,
      };
    },
    saveShowData(state, action) {
      return {
        ...state,
        showData: action.payload,
      };
    },
  },
};
