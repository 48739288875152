import request from '@/utils/request';
import { pickBy } from 'lodash';

export async function orderList(params) {
  return request('/rechageOrders', { params: pickBy(params) });
}

export async function accountList(params) {
  return request('/agent/accounts', { params });
}

export async function consumeList(params) {
  return request('/consumeOrders', { params: pickBy(params) });
}

export async function balanceChangedList(params) {
  return request('/balanceChangeLogs', { params });
}

export async function AccountListBind(params) {
  console.log('1827367->', params);
  return request('/agent/account/recharge', {
    method: 'POST',
    data: params,
  });
}
