import {
  queryRoleList,
  allRoleList,
  roleCreate,
  roleEdit,
  roleDelete,
} from '@/services/role';

export default {
  namespace: 'SystemManager_role',

  state: {
    data: {},
    roleData: {},
    result: {},
  },

  effects: {
    *fetch({ payload }, { call, put }) {
      const {
        data: { data, meta },
      } = yield call(queryRoleList, payload);

      yield put({
        type: 'save',
        payload: {
          list: data,
          pagination: {
            total: meta.total,
            pageSize: meta.per_page,
            current: meta.current_page,
          },
        },
      });
    },
    *fetchAll({ payload }, { call, put }) {
      const {
        data: { data },
      } = yield call(allRoleList, payload);

      yield put({
        type: 'saveRoleData',
        payload: {
          list: data,
        },
      });
    },
    *create({ payload }, { call, put }) {
      const { data } = yield call(roleCreate, payload);
      yield put({
        type: 'saveResult',
        payload: data,
      });
    },
    *edit({ payload }, { call, put }) {
      const { data } = yield call(roleEdit, payload);
      yield put({
        type: 'saveResult',
        payload: data,
      });
    },
    *delete({ payload }, { call, put }) {
      const { data } = yield call(roleDelete, payload);
      yield put({
        type: 'saveResult',
        payload: data.result,
      });
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        data: payload,
      };
    },
    saveRoleData(state, { payload }) {
      return {
        ...state,
        roleData: payload,
      };
    },
    saveResult(state, { payload }) {
      return {
        ...state,
        result: payload,
      };
    },
  },
};
