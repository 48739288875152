import {
  getSong,
  deleteSong,
  getAvailableSong,
  saveSong,
  uploadTorrent,
  updateTorrent,
  getOssConfig,
  exportSongs,
  createMultiSong,
  delMultiSong,
  importSong,
  regenerateCompanySongBook,
} from '@/services/MusicLibrary/remoteResource';

export default {
  namespace: 'Song',

  state: {
    data: {},
    visible: false,
    current: [],
    sortedInfo: '',
    result: {},
    available_song: [],
    oss: {},
  },

  effects: {
    *fetch({ payload }, { call, put }) {
      const { status, data } = yield call(getSong, payload);

      if (status === 200) {
        yield put({
          type: 'save',
          payload: {
            list: data.data,
            pagination: {
              total: data.meta.total,
              pageSize: data.meta.per_page,
              current: data.meta.current_page,
            },
          },
        });
      }
    },
    *del({ payload }, { call, put }) {
      const { status, data } = yield call(deleteSong, payload);
      if (status === 200) {
        yield put({
          type: 'saveResult',
          payload: data,
        });
      }
    },
    *edit({ payload }, { call, put }) {
      const { status, data } = yield call(saveSong, payload);
      if (status === 200) {
        yield put({
          type: 'saveResult',
          payload: data,
        });
      }
    },

    *songImport({ payload }, { call, put }) {
      yield call(importSong, payload);
    },

    *fetchAvailableSong({ payload }, { call, put }) {
      const { status, data } = yield call(getAvailableSong, payload);
      if (status === 200) {
        yield put({
          type: 'saveAvailable',
          payload: {
            list: data.data,
            pagination: {
              total: data.meta.total,
              pageSize: data.meta.per_page,
              current: data.meta.current_page,
            },
          },
        });
      }
    },
    *uploadTorrentFile({ payload }, { call, put }) {
      const { status, data } = yield call(uploadTorrent, payload);
      if (status === 201) {
        console.log('songdata--', data);
        yield put({
          type: 'saveResult',
          payload: data,
        });
      }
    },

    *updateTorrentFile({ payload }, { call, put }) {
      const { status, data } = yield call(updateTorrent, payload);
      if (status === 201) {
        console.log('songdata--', data);
        yield put({
          type: 'saveResult',
          payload: data,
        });
      }
    },

    *fetchOssConfig({ payload }, { call, put }) {
      const { status, data } = yield call(getOssConfig, payload);
      if (status === 200) {
        yield put({
          type: 'saveOss',
          payload: data,
        });
      }
    },
    *exportFile({ payload }, { call, put }) {
      const { status, data } = yield call(exportSongs, payload);
      if (status === 200) {
        yield put({
          type: 'saveResult',
          payload: data,
        });
      }
    },
    *addMulty({ payload }, { call, put }) {
      const { status, data } = yield call(createMultiSong, payload);
      if (status === 200) {
        yield put({
          type: 'saveResult',
          payload: data,
        });
      }
    },
    *deleteMulty({ payload }, { call, put }) {
      const { status, data } = yield call(delMultiSong, payload);
      if (status === 200) {
        yield put({
          type: 'saveResult',
          payload: data,
        });
      }
    },

    *regenerateCompanySongBook({ payload, callback }, { call, put }) {
      yield call(regenerateCompanySongBook, payload);
      callback();
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        data: payload,
      };
    },
    saveResult(state, { payload }) {
      return {
        ...state,
        result: payload,
      };
    },
    saveAvailable(state, { payload }) {
      return {
        ...state,
        available_song: payload,
      };
    },
    saveOss(state, { payload }) {
      return {
        ...state,
        oss: payload,
      };
    },
  },
};
