import request from '@/utils/request';

// 环幕联网商家列表
export async function queryRsNetCompany(params) {
  return request(`/company-service/customer/netCustomers`, {
    params,
  });
}

// 环幕-商家所属盒子
export async function queryCompanyRsbox(params) {
  const { customerCode } = params;
  return request(`/rsbox/company/${customerCode}/get-high-rsboxs`, {
    params,
  });
}

// 环幕—单个素材结算
export async function SettleAccountResourceBuy(params) {
  const { mac } = params;
  const { code } = params;
  return request.post(`/rsbox/high-device/${mac}/buy/${code}`, {
    ...params,
  });
}

// 环幕—批量素材结算
export async function SettleAccountResourceListBuy(params) {
  const { mac } = params;
  return request.post(`/rsbox/high-device/${mac}/batchBuy`, {
    ...params,
  });
}

// 环幕-盒子素材购买获取所有素材
export async function queryResourceToMac(params) {
  const { mac } = params;
  return request(`/rsbox/high-device/scenes/${mac}`, {
    params,
  });
}

// 环幕-盒子素材购买获取所有素材
export async function RsBoxTransfer(params) {
  console.log('授权转移-》', params);
  return request('/rsbox/high-device/authorization/transfer', {
    method: 'POST',
    params,
  });
}

// 环幕-盒子素材购买记录
export async function queryBuyLog(params) {
  const { mac } = params;
  return request(`/rsbox/high-device/buySceneLogs/${mac}`, {
    params,
  });
}

// 环幕-盒子可用素材
export async function queryBoxUsable(params) {
  const { mac } = params;
  return request(`/rsbox/high-device/availableScenes/${mac}`, {
    params,
  });
}

// 停用
export async function setDisableResource(params) {
  return request.post(`/rsbox/iteract-device/${params.mac}/disable/scenes`, {
    ...params,
  });
}

// 启用
export async function setEnableResource(params) {
  return request.post(`/rsbox/iteract-device/${params.mac}/enable/scenes`, {
    ...params,
  });
}

// 根据code获取素材信息
export async function queryBuyResourceData(params) {
  return request(`/rsbox/high-device/${params.mac}/getSceneByCodes`, {
    params,
  });
}

// 新增商家盒子
export async function addRsBox(params) {
  return request(`/rsbox/add-rsBox`, {
    method: 'POST',
    data: params,
  });
}

// 修改商家自己添加的盒子
export async function editRsBox(params) {
  return request(`/rsbox/edit-rsBox`, {
    method: 'POST',
    data: params,
  });
}

// 强制删除商家自己添加的盒子
export async function forceDelRsBox(params) {
  return request(`/rsbox/delete-rsBox/forceDeleteRsBox`, {
    method: 'DELETE',
    data: { id: params.id },
  });
}

// 删除商家自己添加的盒子
export async function delRsBox(params) {
  return request(`/rsbox/delete-rsBox`, {
    method: 'POST',
    data: { id: params.id },
  });
}

// 环幕-商家所属盒子
export async function queryCompanyAllRsbox(params) {
  const { customerCode } = params;
  return request(`/rsbox/company/${customerCode}/high-rsboxs`, {
    params,
  });
}

// 停用
export async function setUnforceResource(params) {
  return request.post(`/rsbox/iteract-device/${params.mac}/force/scenes`, {
    ...params,
  });
}

// 启用
export async function setForceResource(params) {
  return request.post(`/rsbox/iteract-device/${params.mac}/unforce/scenes`, {
    ...params,
  });
}

// 场所素材启停用
export async function post_ableSceneByCompany(params) {
  const { customer_code } = params;
  params.hardware_model = 'R61H';
  return request(`/rsbox/iteract-device/ableSceneByCompany/${customer_code}`, {
    method: 'POST',
    data: params,
  });
}

// 61H-商家可用素材
export async function queryCompanyResource(params) {
  const { customerCode } = params;
  return request(`/rsbox/high-company/${customerCode}/scenes`, {
    params,
  });
}

// 拷贝素材
export async function copyScenes(data) {
  return request(`/rsbox/high-device/copyScenes`, {
    method: 'POST',
    data,
  });
}

export async function editRoomName(params) {
  return request('/rsbox/edit-rsBox-room-name', {
    method: 'POST',
    data: params,
  });
}

// 获取歌曲联动配置
export async function querySongLinkXML(params) {
  return request(`/rsbox/iteract-scene/song-link-file`, {
    params,
  });
}

export async function setLogStatus(params) {
  return request(`/rsbox/open-rsbox-log`, {
    method: 'POST',
    data: params,
  });
}

// R61H、Z66盒子在场所间转移
export async function post_transferRsboxMac(params) {
  return request(`/rsbox/high-device/authorization/transferRsboxMac`, {
    method: 'POST',
    data: params,
  });
}
