import {
  queryNetCompany,
  queryNotNetCompany,
  querySubCompany,
  queryCompanyStbHardTypes,
  changeCompanyAgent,
  agentCompanyRelationList,
  update,
  changeSongType,
  changeCloudSongPass,
} from '@/services/company';
import { agentCompanyMenuList } from '@/services/menu';
import {
  queryCompanyRoleList,
  queryAgentUserList,
} from '@/services/lampControl';
import {
  clearCompanySecondAgent,
  changeCompanySecondAgent,
  changeCompanyOpsAgent,
} from '@/services/agent';

export default {
  namespace: 'business_company',

  state: {
    data: {},
    stbHardTypes: {},
    agentData: {},
    companyMenuData: [],
    agentUserData: {},
  },

  effects: {
    *fetchNet({ payload }, { call, put }) {
      const { status, data } = yield call(agentCompanyRelationList, payload);

      if (status === 200) {
        yield put({
          type: 'save',
          payload: {
            list: data.data,
            pagination: {
              total: data.meta.total,
              pageSize: data.meta.per_page,
              current: data.meta.current_page,
            },
          },
        });
      }
    },

    *fetchAgentList({ payload }, { call, put }) {
      const { status, data } = yield call(queryCompanyRoleList, payload);

      if (status === 200) {
        yield put({
          type: 'saveAgent',
          payload: {
            list: data.data,
            pagination: {
              total: data.meta.total,
              pageSize: data.meta.per_page,
              current: data.meta.current_page,
            },
          },
        });
      }
    },

    *fetchAgentCompanyMenuList({ payload }, { call, put }) {
      const {
        data: { data: menuData },
      } = yield call(agentCompanyMenuList, payload);
      yield put({
        type: 'saveCompanyMenuData',
        payload: menuData,
      });
    },

    *fetchSubCompany({ payload }, { call, put }) {
      const { status, data } = yield call(querySubCompany, payload);

      if (status === 200) {
        yield put({
          type: 'save',
          payload: {
            list: data.data,
            pagination: {
              total: data.meta.total,
              pageSize: data.meta.per_page,
              current: data.meta.current_page,
            },
          },
        });
      }
    },

    *fetchStbHardTypes(next, { call, put }) {
      const { status, data } = yield call(queryCompanyStbHardTypes);

      if (status === 200) {
        yield put({
          type: 'saveStbHardTypes',
          payload: data,
        });
      }
    },

    *changeCompanyAgent({ payload }, { call, put }) {
      const { status, data } = yield call(changeCompanyAgent, payload);
      return data;
    },

    *agentCompanyRelationList({ payload }, { call, put }) {
      const { status, data } = yield call(agentCompanyRelationList, payload);
    },

    *update({ payload, callback }, { call, put }) {
      const { status } = yield call(update, payload);
      if (status === 204 && callback) {
        callback();
      }
    },

    *changeCompanySecondAgent({ payload, callback }, { call, put }) {
      const { status, data } = yield call(changeCompanySecondAgent, payload);
      return data;
    },

    *clearCompanySecondAgent({ payload, callback }, { call, put }) {
      const { status, data } = yield call(clearCompanySecondAgent, payload);
      return data;
    },

    *changeCompanyOpsAgent({ payload, callback }, { call, put }) {
      const { status, data } = yield call(changeCompanyOpsAgent, payload);
      return data;
    },
    *changeSongType({ payload, callback }, { call, put }) {
      const { status, data } = yield call(changeSongType, payload);
      if (callback) {
        callback();
      }
      return data;
    },
    *changeCloudSongPass({ payload, callback }, { call, put }) {
      const { status, data } = yield call(changeCloudSongPass, payload);
      if (callback) {
        callback();
      }
      return data;
    },
    *fetchAgentUserList({ payload }, { call, put }) {
      const { status, data } = yield call(queryAgentUserList, payload);

      if (status === 200) {
        yield put({
          type: 'saveAgentUser',
          payload: {
            list: data.data,
          },
        });
      }
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        data: payload,
      };
    },

    saveAgent(state, { payload }) {
      return {
        ...state,
        agentData: payload,
      };
    },

    saveStbHardTypes(state, { payload }) {
      return {
        ...state,
        stbHardTypes: payload,
      };
    },
    saveCompanyMenuData(state, { payload }) {
      return {
        ...state,
        companyMenuData: payload,
      };
    },
    saveAgentUser(state, { payload }) {
      return {
        ...state,
        agentUserData: payload,
      };
    },
  },
};
