import {
  queryCurrent,
  changePwd,
  changeCurrentUserInfo,
} from '@/services/user';
import { queryRoleType } from '@/services/lampControl';

export default {
  namespace: 'user',

  state: {
    currentUser: {},
    lampRoleType: {},
  },

  effects: {
    // *fetch(_, { call, put }) {
    //   const response = yield call(queryUsers);
    //   yield put({
    //     type: 'save',
    //     payload: response,
    //   });
    // },
    *fetchCurrent(_, { call, put }) {
      const { status, data } = yield call(queryCurrent);
      if (status === 200) {
        yield put({
          type: 'saveCurrentUser',
          payload: data,
        });
      }
    },
    *fetchLampRoleType(_, { call, put }) {
      const { status, data } = yield call(queryRoleType);
      if (status === 200) {
        yield put({
          type: 'saveLampRoleType',
          payload: data?.data,
        });
      }
    },
    *changePwd({ payload, callback }, { call }) {
      const { status } = yield call(changePwd, payload);
      callback(status);
    },
    *changeCurrentUserInfo({ payload, callback }, { call, put }) {
      const { status, data } = yield call(changeCurrentUserInfo, payload);
      if (
        status === 200 &&
        (data.name !== null || data.name !== 'undefined' || data.name !== '')
      ) {
        yield put({
          type: 'saveCurrentUser',
          payload: data,
        });
      }
      callback(status);
    },
  },

  reducers: {
    save(state, action) {
      return {
        ...state,
        list: action.payload,
      };
    },
    saveCurrentUser(state, action) {
      return {
        ...state,
        currentUser: action.payload || {},
      };
    },
    changeNotifyCount(state, action) {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          notifyCount: action.payload.totalCount,
          unreadCount: action.payload.unreadCount,
        },
      };
    },
    saveLampRoleType(state, action) {
      return {
        ...state,
        lampRoleType: action.payload,
      };
    },
  },
};
