import * as companyresource from '@/services/companyresource';

export default {
  namespace: 'CompanyResource_CompanyList',
  state: {
    data: {},
    ScribePrice: [],
  },
  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        data: payload,
      };
    },
    saveScribePriceData(state, action) {
      return {
        ...state,
        ScribePrice: action.payload.data,
      };
    },
  },
  effects: {
    *fetchNet({ payload }, { call, put }) {
      const { status, data } = yield call(
        companyresource.queryRsNetCompany,
        payload
      );
      if (status === 200) {
        yield put({
          type: 'save',
          payload: {
            list: data.data,
            pagination: {
              total: data.meta.total,
              pageSize: data.meta.per_page,
              current: data.meta.current_page,
            },
          },
        });
      }
    },

    // 获取订阅包年/包月价格
    *subScribePrice({ payload }, { call, put }) {
      const { status, data } = yield call(
        companyresource.queryScribePriceData,
        payload
      );

      if (status === 200) {
        yield put({
          type: 'saveScribePriceData',
          payload: {
            data,
          },
        });
      }
    },

    // 年订阅确认
    *storeYearSubscriptionOk({ payload, callback }, { call, put }) {
      const { status } = yield call(
        companyresource.YearSubscriptionOk,
        payload
      );

      if (status === 201) {
        if (callback) callback();
      }
    },

    // 月订阅确认
    *storeMonthSubscriptionOk({ payload, callback }, { call, put }) {
      const { status } = yield call(
        companyresource.MonthSubscriptionOk,
        payload
      );

      if (status === 201) {
        if (callback) callback();
      }
    },
  },
};
