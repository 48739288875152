import request from '@/utils/request';

// 获取oss-临时上传参数
export async function getOssUpload(params) {
  return request('/appMarket/ossUploadConfig', {
    method: 'GET',
  });
}

export async function queryCurrent() {
  return request('/api/currentUser');
}
/* 基础应用相关 */

// 获取基础应用
export async function getAppBaseList(params) {
  const { payload } = params;
  const { manufacturer = 'all' } = { ...payload };
  return request.get(`/appMarket/appBaseList/${manufacturer}`, {
    params: payload,
  });
}

// 获取基础详情
export async function getAppBaseDetail(params) {
  const { appBaseId } = { ...params };
  return request(`/appMarket/appBaseDetail/${appBaseId}`, {
    method: 'GET',
    data: {
      ...params,
    },
  });
}

// 穿梭框搜索
export async function searchAppTransData(params) {
  // console.log('++++');
  // console.log(params);
  // console.log('++++');
  const { payload } = params;
  // payload.include = '';
  return request.post(`/appMarket/getAppCompanyLimitList/searchCompany`, {
    payload,
  });

  // const { keyWords,appId,use_type,targetKeys } = params;

  // return request(
  //   `/appMarket/getAppCompanyLimitList/searchCompany`, {
  //     a:'b'
  //   }
  // );
}

export async function setAppState(params) {
  const { payload } = { ...params };
  const { verification_code, verification_key } = payload;
  return request.post(`/appMarket/setAppState`, {
    data: {
      payload,
    },
    verification_code,
    verification_key,
  });
}

export async function setBlackOrWhite(params) {
  // console.log('????');
  // console.log(params);
  // console.log('????');
  const { payload } = { ...params };
  return request.post(`/appMarket/setAppBlackOrWhite`, {
    data: {
      payload,
    },
  });
}

// 获取穿梭框的初始化数据
export async function getAppTranseInitData(params) {
  return request(`/appMarket/getAppLimitData/${params.payload.appBaseId}`, {
    method: 'GET',
  });
}

// 获取应用 场所限制名单
export async function getAppCompanyLimitList(params) {
  return request(`/appMarket/getAppCompanyLimitList`, {
    method: 'GET',
    data: {
      ...params,
    },
  });
}

// 获取场所黑名单
export async function getAppBlackCompany(params) {
  const { appBaseId } = { params };
  return request(`/appMarket/getAppBlackCompany/${appBaseId}`, {
    method: 'GET',
  });
}

// 获取场所白名单
export async function getAppWhiteCompany(params) {
  const { appBaseId } = { params };
  return request(`/appMarket/getAppWhiteCompany/${appBaseId}`, {
    method: 'GET',
    data: {
      ...params,
    },
  });
}

// 获取试点场所
export async function getAppPilotCompany(params) {
  const { id } = { ...params.payload.version };
  // console.log('----');
  // console.log(id);
  // console.log('@@@@');
  return request(`/appMarket/getAppPilotInstallCompany/${id}`, {
    method: 'GET',
    data: {
      ...params,
    },
  });
}

// ----------------post基础应用--------------
// 创建基础应用
export async function createAppBase(params) {
  return request('/appMarket/createAppBase', {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

// 设置基础应用状态
export async function setAppBaseState(params) {
  const { appBaseId, state } = { params };
  return request(`/appMarket/setAppBaseState/${appBaseId}/state/${state}`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

// 编辑基础版本信息
export async function saveAppBase(params) {
  const { appBaseId } = { ...params };
  delete params.appBaseId;
  return request(`/appMarket/saveAppBase/${appBaseId}`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

// 设置场所黑名单
export async function setAppBlackCompany(params) {
  const { appBaseId } = { params };
  return request(`/appMarket/setAppBlackCompany/${appBaseId}`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

// 设置场所白名单
export async function setAppWhiteCompany(params) {
  const { appBaseId } = { params };
  return request(`/appMarket/setAppBlackCompany/${appBaseId}`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

// 删除应用
export async function delAppBase(params) {
  const { appBaseId } = { params };
  return request(`/appMarket/setAppBlackCompany/${appBaseId}`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

/* 版本相关 */
// get
export async function checkVersionAdapter(params) {
  const { versionId } = params;
  return request(`/appMarket/checkVersionAdapter/${versionId}`);
}

// 获取版本信息
export async function getAppVersionList(params) {
  const { appBaseId } = params;
  return request(`/appMarket/appVersionList/${appBaseId}`, {
    params,
  });
}

// 获取版本详情
export async function getAppVersionDetail(params) {
  const { id } = params;
  return request(`/appMarket/appVersionDetail/${id}`, {
    method: 'GET',
    data: {
      ...params,
    },
  });
}

// 获取试点场所
export async function getVersionPilotInstallCompany(params) {
  const { appVersion } = { params };
  return request(`/appMarket/getVersionPilotInstallCompany/${appVersion}`, {
    method: 'GET',
    data: {
      ...params,
    },
  });
}

/* POST */

// 创建版本
export async function appCreateVersion(params) {
  return request(`/appMarket/appCreateVersion`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

// 编辑版本
export async function appSaveVersion(params) {
  const { id } = params;
  return request(`/appMarket/appSaveVersion/${id}`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

/**
 * 版本适配参数
 */
export async function saveDeviceAdapter(params) {
  const { adapter_type: adapterType } = params;
  delete params.adapterType;
  return request(`/appMarket/deviceAdapter/${adapterType}`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function getDeviceAdapterDetail(params) {
  const { adapter_type: adapterType } = params;
  delete params.adapterType;
  return request(`/appMarket/deviceAdapterDetail/${adapterType}`, {
    data: {
      ...params,
    },
  });
}

export async function getAvailableAdapterOptions(params) {
  return request(`/appMarket/adapterOptions`, {
    data: {
      ...params,
    },
  });
}

/**
 * 版本适配的配置
 */
export async function versionAdapterInfo(params = {}) {
  return request(`/appMarket/getVersionAdapterInfo`, { params });
}

export async function versionStatusLocalRelease(params) {
  const { id } = params;
  delete params.id;
  return request(`/appMarket/appVersion/${id}/statusLocalRelease`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function versionStatusGlobalRelease(params) {
  const { currentVersion } = params;
  const { id } = currentVersion;
  delete params.id;
  return request(`/appMarket/appVersion/${id}/statusGlobalRelease`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function versionStatusDown(params) {
  // const { id } = params;
  const { currentVersion } = params;
  const { id } = currentVersion;
  delete params.id;
  return request(`/appMarket/appVersion/${id}/statusDown`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function versionStatusDel(params) {
  const { id } = params;
  delete params.id;
  return request(`/appMarket/appVersion/${id}/statusDel`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function versionRegions(params) {
  const { id } = params;
  delete params.id;
  return request(`/appMarket/appVersion/${id}/regions`, {
    data: {
      ...params,
    },
  });
}

// 设置版本状态
export async function appSetVersionState(params) {
  const { versionId, state } = params;
  console.log('appVersionStateService ???');
  console.log(versionId);
  console.log(state);
  console.log('appVersionStateService !!!');
  return request(`/appMarket/appSetVersionState/${versionId}/state/${state}`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

// 设置试点场所
export async function setPilotCompany(params) {
  console.log('aaa');
  console.log(params);
  return request('/appMarket/setPilotCompany', {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

/* 场所应用相关 */
// get
// 获取场所永久安装应用列表
export async function getCompanyPermanentInstallApp(params) {
  const { companyId } = { params };
  return request(`/appMarket/setCompanyPermanentInstallApp/${companyId}`, {
    method: 'GET',
    data: {
      ...params,
    },
  });
}

// 获取试点应用
export async function getPilotApps(params) {
  const { companyCode } = { params };
  return request(`/appMarket/getPilotApps/${companyCode}`, {
    method: 'GET',
    data: {
      ...params,
    },
  });
}

// 获取应用安装概述
export async function getCompanyOverView(params) {
  const { companyCode } = { params };
  return request(`/appMarket/getCompanyOverView/${companyCode}`, {
    method: 'GET',
    data: {
      ...params,
    },
  });
}

// post
// 设置场所永久安装应用
export async function setCompanyPermanentInstallApp(params) {
  const { companyId } = { params };
  return request(`/appMarket/setCompanyPermanentInstallApp/${companyId}`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

/* 机顶盒相关 */
// get
// 获取机顶盒安装日志
export async function getStbInstallLog(params) {
  const { deviceId } = { params };
  return request(`/appMarket/getStbInstallLog/${deviceId}`, {
    method: 'GET',
    data: {
      ...params,
    },
  });
}

// 获取机顶盒安装app
export async function getStbInstallApp(params) {
  const { deviceId } = { params };
  return request(`/appMarket/getStbInstallApp/${deviceId}`, {
    method: 'GET',
    data: {
      ...params,
    },
  });
}

// 保存OrderStatus
export async function saveOrderStatus(params) {
  const { payload } = params;
  console.log('5656565656565656');
  console.log(params);
  console.log(payload);
  return request(`/app/auditFinished`, {
    method: 'POST',
    data: {
      ...payload,
    },
  });
}

// OrderStatus弹出框数据初始化
export async function OrderStatusList(params) {
  // console.log('QQQQQQQQQQ');
  // console.log(params);
  const { payload } = params;
  const { orderNo } = payload;
  // console.log('xxxxxsdds');
  // console.log(orderNo);
  return request(`/appMarket/reviewOrder/resultInfo/${orderNo}`, {
    method: 'GET',
    data: {
      ...params,
    },
  });
}

// 工单详情弹出框数据初始化
export async function getOrderProgress(params) {
  // console.log('QQQQQQQQQQ');
  // console.log(params);
  const { payload } = params;
  const { orderNo } = payload;
  // console.log('xxxxxsdds');
  // console.log(orderNo);
  return request(`/appMarket/getOrderProgress/${orderNo}`, {
    method: 'GET',
    data: {
      ...params,
    },
  });
}

// 获取版本信息
export async function getAdapterCheckboxList(params) {
  return request(`/appMarket/getAvaliableAppRunEnv`, {
    method: 'GET',
    params,
  });
}

// 保存应用审核商家配置
export async function setVersionPilotAdapter(params) {
  const { payload } = params;
  return request('/appReview/veryfiyKmApp', {
    method: 'POST',
    data: {
      ...payload,
    },
  });
}

// 编辑基础版本信息
export async function editAppBase(params) {
  console.log('11', params);
  return request(`/appMarket/editAppBase`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}
