import {
  materialIndex,
  materialShow,
  materialStore,
  materialUpdate,
  materialOnline,
  materialOffline,
  materialNewScenesIndex,
  materialDelete,
  getSceneFileTypes,
  getSceneTypes,
} from '@/services/ITMarket/material';
import { get } from 'lodash';

export default {
  namespace: 'ValueAddedCenter_ITMarket_materialMgr',

  state: {
    indexData: {
      list: [],
      pagination: {},
    },
    showData: {},
    hardwareSupportedData: {},
    rsboxAdapterInfo: {
      hardwareType: [],
      softwareSupport: [],
    },
    indexNewData: {
      list: [],
      pagination: {},
    },
    sceneFileTypes: {},
    sceneTypes: {},
  },

  effects: {
    *index({ payload }, { call, put }) {
      const { status, data } = yield call(materialIndex, payload);

      if (status === 200) {
        yield put({
          type: 'saveIndexData',
          payload: {
            list: data.data,
            pagination: {
              total: data.total,
              pageSize: parseInt(data.per_page),
              current: parseInt(data.current_page),
              // total: data.meta.total,
              // pageSize: parseInt(data.meta.per_page),
              // current: parseInt(data.meta.current_page),
            },
          },
        });
      }
    },

    *indexNewScenes({ payload }, { call, put }) {
      const { status, data } = yield call(materialNewScenesIndex, payload);

      if (status === 200) {
        yield put({
          type: 'saveIndexNewScenesData',
          payload: {
            list: data.data,
            pagination: {
              total: data.total,
              pageSize: parseInt(data.per_page),
              current: parseInt(data.current_page),
              // total: data.meta.total,
              // pageSize: parseInt(data.meta.per_page),
              // current: parseInt(data.meta.current_page),
            },
          },
        });
      }
    },

    *show({ payload, callback }, { call, put }) {
      const { status, data } = yield call(materialShow, payload);

      if (status === 200) {
        yield put({
          type: 'saveShowData',
          payload: data.data,
        });
        if (callback) callback(data.data);
      }
    },

    *store({ payload, callback }, { call, put }) {
      const { status } = yield call(materialStore, payload);

      if (status === 201) {
        if (callback) callback();
      }
    },

    *update({ payload, callback }, { call, put }) {
      const { status, data } = yield call(materialUpdate, payload);

      if (status === 201) {
        if (callback) callback();
      }
    },
    *setOnline({ payload, callback }, { call, put }) {
      const { status } = yield call(materialOnline, payload);

      if (status === 200) {
        if (callback) callback();
      }
    },

    *setOffline({ payload, callback }, { call, put }) {
      const { status } = yield call(materialOffline, payload);

      if (status === 200) {
        if (callback) callback();
      }
    },

    // 删除
    *delete({ payload, callback }, { call }) {
      const { status } = yield call(materialDelete, payload);

      if (status === 204) {
        callback();
      }
    },
    *sceneFileTypes({ payload }, { call, put }) {
      const { status, data } = yield call(getSceneFileTypes, payload);

      if (status === 200) {
        yield put({
          type: 'saveSceneFileTypeDatas',
          payload: data.data,
        });
      }
    },
    *sceneTypes({ payload }, { call, put }) {
      const { status, data } = yield call(getSceneTypes, payload);

      if (status === 200) {
        yield put({
          type: 'saveSceneTypeDatas',
          payload: data.data,
        });
      }
    },
  },

  reducers: {
    saveIndexData(state, action) {
      return {
        ...state,
        indexData: action.payload,
      };
    },
    saveIndexNewScenesData(state, action) {
      return {
        ...state,
        indexNewData: action.payload,
      };
    },
    saveShowData(state, action) {
      return {
        ...state,
        showData: action.payload,
      };
    },
    saveRsboxAdapterInfo(state, action) {
      return {
        ...state,
        rsboxAdapterInfo: action.payload,
      };
    },
    saveSceneFileTypeDatas(state, action) {
      return {
        ...state,
        sceneFileTypes: action.payload,
      };
    },
    saveSceneTypeDatas(state, action) {
      return {
        ...state,
        sceneTypes: action.payload,
      };
    },
  },
};
