import {
  distributionStrategySendSms,
  distributionStrategyValidatorPhone,
} from '@/services/configurations';

export default {
  namespace: 'RemoteUpgrade_DistributionStrategyValidate',

  state: {
    data: {},
    code: {},
  },

  effects: {
    *validatorPhone({ payload }, { call, put, select }) {
      const old = yield select(
        state => state.RemoteUpgrade_DistributionStrategyValidate.data
      );

      if (Object.keys(old).length > 0) {
        return;
      }

      const { status, data } = yield call(
        distributionStrategyValidatorPhone,
        payload
      );

      if (status === 200) {
        yield put({
          type: 'save',
          payload: data,
        });
      }
    },

    *sendValidatorCode({ payload, callback }, { call, put }) {
      const { status, data } = yield call(distributionStrategySendSms, payload);

      if (status === 201) {
        yield put({
          type: 'saveCode',
          payload: data,
        });
      }

      callback();
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        data: payload,
      };
    },

    saveCode(state, { payload }) {
      return {
        ...state,
        code: payload,
      };
    },
  },
};
