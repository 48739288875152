import request from '@/utils/request';

export async function querySchemes(params) {
  return request('/punish/get-scheme', {
    params,
  });
}
export async function editSchemes(params) {
  return request(`/punish/edit-scheme`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}
export async function queryMeasure(params) {
  return request(`/punish/get-measure`, {
    method: 'get',
    data: {
      ...params,
    },
  });
}
export async function addScheme(params) {
  return request(`/punish/create-scheme`, {
    method: 'post',
    data: {
      ...params,
    },
  });
}
export async function queryPunishCompany(params) {
  return request('/punish/get-company', {
    params,
  });
}
export async function queryPunishLog(params) {
  return request('/punish/punishLogs', {
    params,
  });
}
export async function queryCompanyInfo(params) {
  return request(`/company/${params.company_id}`, {
    params,
  });
}
export async function queryPunishInfo(params) {
  return request(`/punish/punishInfo`, {
    params,
  });
}
export async function queryAvailableScheme(params) {
  return request(`/punish/getAllScheme`, {
    params,
  });
}
export async function saveJobs(params) {
  return request(`/punish/savePunishJobs`, {
    method: 'post',
    data: {
      ...params,
    },
  });
}
export async function queryHighLevelMeasure(params) {
  return request(`/punish/get-high-measure`, {
    method: 'get',
    data: {
      ...params,
    },
  });
}
export async function queryPunishMobile(params) {
  return request(`/punish/getVerifyMobile`, {
    method: 'get',
    data: {
      ...params,
    },
  });
}
export async function getVerifyCode(params) {
  return request('/punish/sendSmsVerifyCode', {
    params,
  });
}
export async function getStdDevice(params) {
  return request('/punish/getStdDevice', {
    params,
  });
}
export async function getCommonTask(params) {
  return request('/punish/getCommonTask', {
    params,
  });
}
export async function managementSqlInjection(params) {
  return request(`/punish/managementSqlInjection`, {
    method: 'post',
    data: {
      ...params,
    },
  });
}
export async function getTaskResponse(params) {
  return request(`/punish/getTaskResponse`, {
    method: 'post',
    data: {
      ...params,
    },
  });
}
