export const upgradePackageType = {
  '0': '', // 标准版
  '1': '（定制版）',
  '2': '（国际版）',
};

// 远程升级树状数据转换选择下拉框数据
export const upgradePackageTreeChange = data => {
  const targetTypes = []; // 所有大类型数组数据
  const serverTypes = {}; // 根据targetType.value获得对应的小类型数组
  const targetTypesDataMap = {};
  const serverTypesDataMap = {}; // 可能不好使

  data.forEach(item => {
    const targetType = item.target_type;

    // 通过value和当前版本唯一确定
    const unique = `${targetType.value}-${targetType.package_type || '0'}`;

    targetType.key += upgradePackageType[targetType.package_type || '0'];
    targetType.unique = unique;

    targetTypes.push(targetType);

    serverTypes[unique] = item.server_types;

    targetTypesDataMap[unique] = targetType;

    item.server_types.forEach(serverType => {
      serverTypesDataMap[serverType.value] = serverType;
    });
  });

  return {
    targetTypes,
    serverTypes,
    targetTypesDataMap,
    serverTypesDataMap,
  };
};

// 远程升级树状数据转换排序显示
export const upgradePackageTreeSort = data => {
  let upgradePackageSortTree = []; // 排序后的树形数据

  const sortTarget = (a, b) => {
    const { sort: sort1 = 0 } = a.target_type;
    const { sort: sort2 = 0 } = b.target_type;

    return sort1 - sort2;
  };

  const sortServer = (a, b) => {
    const { sort: sort1 = 0 } = a;
    const { sort: sort2 = 0 } = b;

    return sort1 - sort2;
  };

  data.forEach(item => {
    const server_types = item.server_types || [];
    item.server_types = server_types.sort(sortServer);
  });

  upgradePackageSortTree = data.sort(sortTarget);

  return upgradePackageSortTree;
};
