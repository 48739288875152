import { getApiUpgradePackageIdDetail } from '../UpgradePackagesMgr/services.js';

export default {
  namespace: 'RemoteUpgrade_upgradePackagesMgr',

  state: {
    packageInfo: {},
  },

  effects: {
    *fetchApiUpgradePackageIdDetail({ payload }, { call, put }) {
      const { data } = yield call(
        getApiUpgradePackageIdDetail,
        payload.upgrade_package_id
      );
      yield put({
        type: 'savePackageInfo',
        payload: data.data,
      });
    },
  },

  reducers: {
    savePackageInfo(state, { payload }) {
      return {
        ...state,
        packageInfo: payload,
      };
    },
  },
};
