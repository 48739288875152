import request from '@/utils/request';

/*
|--------------------------------------------------------------------------
| 角色模块
|--------------------------------------------------------------------------
*/

/** 编辑角色名称 */
export function editRole(data) {
  return request(`/light/edit-role`, { method: 'POST', data });
}

/** 用户绑定角色 */
export function bindRole(data) {
  return request(`/light/bind-role`, { method: 'POST', data });
}

/** 删除角色 */
export function deleteRole(data) {
  return request(`/light/delete-role`, { method: 'POST', data });
}

/** 用户解绑角色 */
export function unbindRole(data) {
  return request(`/light/unbind-role`, { method: 'POST', data });
}

/** 创建角色 */
export function createRole(data) {
  return request(`/light/create-role`, { method: 'POST', data });
}

/** 创建代理商 */
export function createAgent(data) {
  return request(`/light/create-agent`, { method: 'POST', data });
}

/** 编辑代理商 */
export function editAgent(data) {
  return request(`/light/edit-agent`, { method: 'POST', data });
}

/** 代理商列表 */
export function queryAgentList(params) {
  return request(`/light/agent-list`, { method: 'GET', params });
}

/** 创建二级代理商 */
export function createSecondAgent(data) {
  return request(`/light/create-second-agent`, { method: 'POST', data });
}

/** 编辑二级代理商  */
export function editSecondAgent(data) {
  return request(`/light/edit-second-agent`, { method: 'POST', data });
}

/** 用户角色列表  */
export function queryCompanyRoleList(params) {
  return request(`/light/company-role-list`, { method: 'GET', params });
}

/** 获取角色 */
export function queryRoleList(params) {
  return request(`/light/role-list`, { method: 'GET', params });
}

/** 获取用户列表 */
export function queryUserList(params) {
  return request(`/light/light-user-list`, { method: 'GET', params });
}

/** 获取用户角色 */
export function queryRoleType(params) {
  return request(`/light/role-type`, { method: 'GET', params });
}

/** 给管理分配代理商 */
export function allocateAgent(data) {
  return request(`/light/allocate-agent`, { method: 'POST', data });
}

/** 给管理分配灯光厂家 */
export function allocateLamp(data) {
  return request(`/light/allocate-lamp`, { method: 'POST', data });
}

/** 开启灯控全局权限 */
export function openLightData(data) {
  return request(`/light/open-light-data`, { method: 'POST', data });
}

/** 关闭灯控全局权限 */
export function closeLightData(data) {
  return request(`/light/close-light-data`, { method: 'POST', data });
}

/** 给管理员移除代理商 */
export function deleteAgentUserRole(data) {
  return request(`/light/delete-agent-user-role`, { method: 'POST', data });
}

/** 给管理员移除灯厂 */
export function deleteLampUserRole(data) {
  return request(`/light/delete-lamp-user-role`, { method: 'POST', data });
}

/** 已有的代理商分配数据过滤 */
export function userAgentDataFilter(params) {
  return request(`/light/user-agent-data-filter`, { method: 'GET', params });
}

/** 未有的代理商分配数据过滤 */
export function userAgentNotDataFilter(params) {
  return request(`light/user-agent-not-data-filter`, { method: 'GET', params });
}

/** 已有的灯厂分配数据过滤 */
export function userLampDataFilter(params) {
  return request(`/light/user-lamp-data-filter`, { method: 'GET', params });
}

/** 未有的灯厂分配数据过滤 */
export function userLampNoDataFilter(params) {
  return request(`/light/user-lamp-not-data-filter`, { method: 'GET', params });
}

/*
|--------------------------------------------------------------------------
| 灯光厂家模块
|--------------------------------------------------------------------------
*/

/** 创建灯光厂家 */
export function createLamp(data) {
  return request(`/light/create-lamp`, { method: 'POST', data });
}

/** 编辑灯光厂家 */
export function editLamp(data) {
  return request(`/light/edit-lamp`, { method: 'POST', data });
}

/** 灯光厂家列表 */
export function queryLampList(params) {
  return request(`/light/lamp-list`, { method: 'GET', params });
}

/** 创建灯型号 */
export function createLampType(data) {
  return request(`/light/create-lamp-type`, { method: 'POST', data });
}

/** 编辑灯型号 */
export function editLampType(data) {
  return request(`/light/edit-lamp-type`, { method: 'POST', data });
}

/** 删除灯型号 */
export function deleteLampType(data) {
  return request(`/light/delete-lamp-type`, { method: 'POST', data });
}

/** 灯型号列表 */
export function queryLampTypeList(params) {
  return request(`/light/lamp-type-list`, { method: 'GET', params });
}

/** 创建灯通道 */
export function createLampAisle(data) {
  return request(`/light/create-lamp-aisle`, { method: 'POST', data });
}

/** 编辑灯通道 */
export function editLampAisle(data) {
  return request(`/light/edit-lamp-aisle`, { method: 'POST', data });
}

/** 删除灯通道 */
export function deleteLampAisle(data) {
  return request(`/light/delete-lamp-aisle`, { method: 'POST', data });
}

/** 灯通道列表 */
export function queryLampAisleList(params) {
  return request(`/light/lamp-aisle-list`, { method: 'GET', params });
}

/** 灯通道列表 */
export function querylampAisleValueList(params) {
  return request(`/light/lamp-aisle-value-list`, { method: 'GET', params });
}

/** 创建灯通道值 */
export function createLampAisleValue(data) {
  return request(`/light/create-lamp-aisle-value`, { method: 'POST', data });
}

/** 编辑灯通道值 */
export function editLampAisleValue(data) {
  return request(`/light/edit-lamp-aisle-value`, { method: 'POST', data });
}

/** 删除灯通道值 */
export function deleteLampAisleValue(data) {
  return request(`/light/delete-lamp-aisle-value`, { method: 'POST', data });
}

/** 创建灯分类 */
export function createLampSort(data) {
  return request(`/light/create-lamp-sort`, { method: 'POST', data });
}

/** 编辑灯分类 */
export function editLampSort(data) {
  return request(`/light/edit-lamp-sort`, { method: 'POST', data });
}

/** 删除灯分类 */
export function deleteLampSort(data) {
  return request(`/light/delete-lamp-sort`, { method: 'POST', data });
}

/** 灯分类列表 */
export function queryLampSort(params) {
  return request(`/light/lamp-sort-list`, { method: 'GET', params });
}

/** 创建灯信息 */
export function createLight(data) {
  return request(`/light/create-light`, { method: 'POST', data });
}

/** 编辑灯信息 */
export function editLight(data) {
  return request(`/light/edit-light`, { method: 'POST', data });
}

/** 删除灯信息 */
export function deleteLight(data) {
  return request(`/light/delete-light`, { method: 'POST', data });
}

/** 灯信息列表 */
export function lightList(params) {
  return request(`/light/light-list`, { method: 'GET', params });
}

/** 审核灯信息 */
export function auditLight(data) {
  return request(`/light/audit-light`, { method: 'POST', data });
}

/** 灯信息 */
export function lightInfo(data) {
  return request(`/light/light-info`, { method: 'POST', data });
}

/** 审核工程列表 */
export function storeSaleProjectList(params) {
  return request(`/light/store-sale-project-list`, { method: 'GET', params });
}

/** 审核灯信息列表 */
export function storeSaleLampList(params) {
  return request(`/light/store-sale-lamp-list`, { method: 'GET', params });
}

/*
|--------------------------------------------------------------------------
| 灯光厂家模块
|--------------------------------------------------------------------------
*/

/** 编辑灯效果 */
export function editLampEffect(data) {
  return request(`/light/edit-lamp-effect`, { method: 'POST', data });
}

/** 删除灯效果 */
export function deleteLampEffect(data) {
  return request(`/light/delete-lamp-effect`, { method: 'POST', data });
}

/** 公开灯效果 */
export function openLampEffect(data) {
  return request(`/light/open-lamp-effect`, { method: 'POST', data });
}

/** 灯效果列表 */
export function lampEffectList(params) {
  return request(`/light/lamp-effect-list`, { method: 'GET', params });
}

/** 编辑工程包 */
export function editLampProjectEffect(data) {
  return request(`/light/edit-lamp-project-effect`, { method: 'POST', data });
}

/** 删除工程包 */
export function deleteLampProjectEffect(data) {
  return request(`/light/delete-lamp-project-effect`, {
    method: 'POST',
    data,
  });
}

/** 是否公开工程包 */
export function openLampProjectEffect(data) {
  return request(`/light/open-lamp-project-effect`, { method: 'POST', data });
}

/** 工程包列表 */
export function lampProjectEffectList(params) {
  return request(`/light/lamp-project-effect-list`, { method: 'GET', params });
}

/** 创建工程包厢类型 */
export function createRoomType(data) {
  return request(`/light/create-room-type`, { method: 'POST', data });
}

/** 编辑工程包类型 */
export function editRoomType(data) {
  return request(`/light/edit-room-type`, { method: 'POST', data });
}

/** 删除工程包厢类型 */
export function deleteRoomType(data) {
  return request(`/light/delete-room-type`, { method: 'POST', data });
}

/** 包厢类型列表 */
export function roomTypeList(params) {
  return request(`/light/room-type-list`, { method: 'GET', params });
}

/** 编辑商城灯效果 */
export function editStoreLampEffect(data) {
  return request(`/light/edit-store-lamp-effect`, { method: 'POST', data });
}

/** 审核商城灯效果 */
export function auditStoreLampEffect(data) {
  return request(`/light/audit-store-lamp-effect`, { method: 'POST', data });
}

/** 删除商城灯效果 */
export function deleteStoreLampEffect(data) {
  return request(`/light/delete-store-lamp-effect`, { method: 'POST', data });
}

/** 商城灯效果列表 */
export function storeLampEffectList(params) {
  return request(`/light/store-lamp-effect-list`, { method: 'GET', params });
}

/** 编辑商城工程包 */
export function editStoreProjectEffect(data) {
  return request(`/light/edit-store-project-effect`, {
    method: 'POST',
    data,
  });
}

/** 审核商城工程包列表 */
export function auditStoreProjectEffect(params) {
  return request(`/light/audit-store-project-effect`, {
    method: 'GET',
    params,
  });
}

/** 删除商城工程包 */
export function deleteStoreProjectEffect(params) {
  return request(`/light/delete-store-project-effect`, {
    method: 'POST',
    params,
  });
}

/** 商城工程包列表 */
export function storeLampProjectList(params) {
  return request(`/light/store-lamp-project-list`, { method: 'GET', params });
}

/** 商城灯信息列表 */
export function storeLightList(params) {
  return request(`/light/store-light-list`, { method: 'GET', params });
}

/** 上传工程包cad文件 */
export function uploadProjectCad(data) {
  return request(`/light/upload-project-cad`, { method: 'POST', data });
}

/** 上传工程包视频文件 */
export function uploadProjectMov(data) {
  return request(`/light/upload-project-mov`, { method: 'POST', data });
}

/** 灯信息审核列表 */
export function storeAuditLightList(params) {
  return request(`/light/store-audit-light-list`, { method: 'GET', params });
}

/** 灯效果审核列表 */
export function storeAuditLampList(params) {
  return request(`/light/store-audit-lamp-list`, { method: 'GET', params });
}

/** 工程包审核列表 */
export function storeAuditProjectList(params) {
  return request(`/light/store-audit-project-list`, {
    method: 'GET',
    params,
  });
}

/** 审核灯效果 */
export function auditLampEffect(data) {
  return request(`/light/audit-lamp-effect`, { method: 'POST', data });
}

/** 审核工程包 */
export function auditProjectEffect(data) {
  return request(`/light/audit-project-effect`, { method: 'POST', data });
}

/** 商城灯效果上下架 */
export function saleStoreLampEffect(data) {
  return request(`/light/sale-store-lamp-effect`, { method: 'POST', data });
}

/** 商城工程包上下架 */
export function saleStoreProjectEffect(data) {
  return request(`/light/sale-store-project-effect`, { method: 'POST', data });
}

/*
|--------------------------------------------------------------------------
| 搜索和下载
|--------------------------------------------------------------------------
*/

/** 商城工程包筛选 */
export function storeLampProjectListCondition(params) {
  return request(`/light/store-lamp-project-list-condition`, {
    method: 'GET',
    params,
  });
}

/** 商城灯效果筛选 */
export function storeLampEffectListCondition(params) {
  return request(`/light/store-lamp-effect-list-condition`, {
    method: 'GET',
    params,
  });
}

/** 商城灯信息筛选 */
export function storeLightListCondition(params) {
  return request(`/light/store-light-list-condition`, {
    method: 'GET',
    params,
  });
}

/** 灯效果筛选接口 */
export function queryLampEffectListCondition(params) {
  return request(`/light/lamp-effect-list-condition`, {
    method: 'GET',
    params,
  });
}

/** 工程包筛选接口 */
export function queryLampProjectEffectListCondition(params) {
  return request(`/light/lamp-project-effect-list-condition`, {
    method: 'GET',
    params,
  });
}

/** 灯信息筛选接口 */
export function queryLightListCondition(params) {
  return request(`/light/light-list-condition`, { method: 'GET', params });
}

/** 灯信息下载 */
export function lightInfoPath(data) {
  return request(`/light/light-info-path`, { method: 'POST', data });
}

/** 灯效果下载 */
export function lampEffectDownload(data) {
  return request(`/light/lamp_effect_download`, { method: 'POST', data });
}

/** 商城灯效果下载 */
export function storeLampEffectDownload(data) {
  return request(`/light/store_lamp_effect_download`, { method: 'POST', data });
}

/** 商城灯信息下载 */
export function storeLightInfoPath(data) {
  return request(`/light/store-light-info-path`, { method: 'POST', data });
}

/** 工程包审核筛选条件 */
export function storeAuditProjectListCondition(params) {
  return request(`/light/store-audit-project-list-condition`, {
    method: 'GET',
    params,
  });
}

/** 灯效果审核筛选条件 */
export function storeAuditLampListCondition(params) {
  return request(`/light/store-audit-lamp-list-condition`, {
    method: 'GET',
    params,
  });
}

/** 灯信息审核筛选条件 */
export function storeAuditLightListCondition(params) {
  return request(`/light/store-audit-light-list-condition`, {
    method: 'GET',
    params,
  });
}

/** 更新工程包下载次数 */
export function updateProjectDownload(data) {
  return request(`/light/update-project-download`, {
    method: 'POST',
    data,
  });
}

/** 更新商城工程包下载次数 */
export function updateStoreProjectDownload(data) {
  return request(`/light/update-store-project-download`, {
    method: 'POST',
    data,
  });
}

/** 代理商用户列表  */
export function queryAgentUserList(params) {
  console.log('11', params);
  return request(`/agent-user-list`, { method: 'GET', params });
}

/** 启用停用代理商 */
export function editAgentState(data) {
  return request(`/agent/change-state`, { method: 'POST', data });
}

// /** ———————— */
// export function ——————(params) {
//   return request(`——————`, { method: 'GET', params });
// }

// /** ———————— */
// export function ——————(data) {
//   return request(`——————`, { method: 'POST', data });
// }
