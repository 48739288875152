import request from '@/utils/request';

// 获取磁盘异常信息列表
export async function diskAbnormalList(params) {
  return request('/device-job/abnormal-report-list', { params });
}

// 获取代理商信息
export async function agentIndex(params) {
  return request('/business/agent/list', { params });
}
