import {
  createUpgradePackages,
  updateUpgradePackages,
  deleteUpgradePackages,
  getUpgradePackageInfo,
  getUpgradePackageDescription,
} from '@/services/remoteUpgrade';

export default {
  namespace: 'RemoteUpgrade_upgradePackages',

  state: {
    data: {},
    packageInfo: { package_type: 0, target_type: '', server_type: '' },
    description: '',
  },

  effects: {
    *create({ payload, callback }, { call }) {
      const { status, data } = yield call(createUpgradePackages, payload);

      if (status === 201) {
        callback(data);
      }
    },

    *update({ payload, callback }, { call }) {
      const { status } = yield call(updateUpgradePackages, payload);

      if (status === 200) {
        callback();
      }
    },

    *delete({ payload, callback }, { call }) {
      const { status } = yield call(deleteUpgradePackages, payload);

      if (status === 204) {
        callback();
      }
    },
    *fetchPackageInfo({ payload }, { call, put }) {
      const { data } = yield call(getUpgradePackageInfo, payload);

      yield put({
        type: 'saveInfo',
        payload: data.data,
      });
    },
    *fetchPackageDescription({ payload }, { call, put }) {
      const { data } = yield call(getUpgradePackageDescription, payload);
      yield put({
        type: 'saveDesc',
        payload: data.description,
      });
    },
  },

  reducers: {
    saveInfo(state, { payload }) {
      return {
        ...state,
        packageInfo: payload,
      };
    },
    saveDesc(state, { payload }) {
      return {
        ...state,
        description: payload,
      };
    },
  },
};
