import { queryAppInstallLogs } from '@/services/devices';

export default {
  namespace: 'CompanyAppManager_appInstallLog',

  state: {
    data: {},
  },

  effects: {
    *fetchAppInstallLogs({ payload }, { call, put }) {
      const {
        data: { data, meta },
      } = yield call(queryAppInstallLogs, payload);

      yield put({
        type: 'save',
        payload: {
          list: data,
          pagination: {
            total: meta.total,
            pageSize: meta.per_page,
            current: meta.current_page,
          },
        },
      });
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        data: payload,
      };
    },
  },
};
