import { queryCompanyVoiceBox } from '@/services/company';

export default {
  namespace: 'CompanyList_CompanyDevicesList_voiceBox',

  state: {
    data: {},
  },

  effects: {
    *fetch({ payload }, { call, put }) {
      const { status, data } = yield call(queryCompanyVoiceBox, payload);

      if (status === 200) {
        yield put({
          type: 'save',
          payload: {
            list: data.data,
            pagination: {
              total: data.meta.total,
              pageSize: data.meta.per_page,
              current: data.meta.current_page,
            },
          },
        });
      }
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        data: payload,
      };
    },
  },
};
