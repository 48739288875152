import {
  getSongFileList,
  saveRecalculationMD5,
  saveSongFileExport,
} from '@/services/MusicLibrary/musiclibrary';

export default {
  namespace: 'Song_File',

  state: {
    data: {},
    result: {},
    exportUrl: {},
  },

  effects: {
    *fetchSongFileList({ payload }, { call, put }) {
      const { status, data } = yield call(getSongFileList, payload);

      if (status === 200) {
        yield put({
          type: 'save',
          payload: {
            list: data.data,
            pagination: {
              total: data.meta.total,
              pageSize: data.meta.per_page,
              current: data.meta.current_page,
            },
          },
        });
      }
    },
    *recalculationMD5({ payload, callback }, { call, put }) {
      const { status, data } = yield call(saveRecalculationMD5, payload);
      if (status === 201) {
        callback(status);

        // yield put({
        //   type: 'saveResult',
        //   payload: data,
        // });
      }
    },
    *fetchSongFileExport({ payload }, { call, put }) {
      const { status } = yield call(saveSongFileExport, payload);
      if (status === 201) {
        yield put({
          type: 'saveExportUrl',
          payload: status,
        });
      }
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        data: payload,
      };
    },
    saveResult(state, { payload }) {
      return {
        ...state,
        result: payload,
      };
    },
    saveExportUrl(state, { payload }) {
      return {
        ...state,
        exportUrl: payload,
      };
    },
  },
};
