import * as companyresource from '@/services/ITMarket/companyresource';

export default {
  namespace: 'ITCompanyResource_BuyTasks',
  state: {
    data: {},
  },
  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        data: payload,
      };
    },
  },
  effects: {
    // 素材购买记录
    *fetchBuyLog({ payload }, { call, put }) {
      const { status, data } = yield call(companyresource.queryBuyLog, payload);
      if (status === 200) {
        yield put({
          type: 'save',
          payload: {
            list: data.data,
            pagination: {
              total: data.total,
              pageSize: data.per_page,
              current: data.current_page,
            },
          },
        });
      }
    },
  },
};
