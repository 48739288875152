import * as companyresource from '@/services/companyresource';

export default {
  namespace: 'CompanyResource_AgentBalance',
  state: {
    data: {},
    dataList: {},
  },
  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        data: payload,
      };
    },
    saveList(state, { payload }) {
      return {
        ...state,
        dataList: payload,
      };
    },
  },
  effects: {
    // 根据登录人ID获取单个代理商账户信息
    *fetchAgentBalance({ payload }, { call, put }) {
      const { status, data } = yield call(
        companyresource.queryAgentBalance,
        payload
      );
      if (status === 200) {
        yield put({
          type: 'save',
          payload: {
            data,
          },
        });
      }
    },

    // 根据登录人ID获取该用户所有代理商信息
    *fetchAgentList({ payload }, { call, put }) {
      const { status, data } = yield call(
        companyresource.queryAgentList,
        payload
      );
      if (status === 200) {
        yield put({
          type: 'saveList',
          payload: {
            list: data.data,
            pagination: {
              total: data.total,
              pageSize: data.per_page,
              current: data.current_page,
            },
          },
        });
      }
    },

    *clearPageData({ payload }, { call, put }) {
      yield put({
        type: 'saveList',
        payload: {
          list: [],
          pagination: {
            total: 0,
            pageSize: 10,
            current: 1,
          },
        },
      });
    },

    // 设置用户扣款代理商
    *setDebitAccount({ payload, callback }, { call, put }) {
      const { status, data } = yield call(
        companyresource.setDebitAccountBind,
        payload
      );

      if (status === 201) {
        if (callback) callback();
      }
    },
  },
};
