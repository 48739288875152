export default {
  namespace: 'workparams',

  state: {
    company_code: '',
  },

  effects: {
    *companyCode({ payload }, { put }) {
      const { company_code } = payload;
      yield put({
        type: 'save',
        payload: { company_code },
      });
    },
  },

  reducers: {
    save(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
};
