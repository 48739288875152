import {
  querySchemes,
  editSchemes,
  queryMeasure,
  addScheme,
  queryAvailableScheme,
  queryHighLevelMeasure,
} from '@/services/punish';

export default {
  namespace: 'Punish_shcemes',

  state: {
    data: {},
    visible: false,
    current: [],
    sortedInfo: '',
    reason: '',
    measures: [],
    measures_oringin: [],
    measures_high: [],
    measures_high_oringin: [],
  },

  effects: {
    *getScheme({ payload }, { call, put }) {
      const { status, data } = yield call(querySchemes, payload);

      if (status === 200) {
        yield put({
          type: 'save',
          payload: {
            list: data.data,
            pagination: {
              total: data.meta.total,
              pageSize: data.meta.per_page,
              current: data.meta.current_page,
            },
          },
        });
      }
    },
    *editScheme({ payload }, { call, put }) {
      const { status, data } = yield call(editSchemes, payload);
      if (status === 200) {
        yield put({
          type: 'saveResult',
          payload: data,
        });
      }
    },
    *createScheme({ payload }, { call, put }) {
      const { status, data } = yield call(addScheme, payload);
      if (status === 200) {
        yield put({
          type: 'saveResult',
          payload: data,
        });
      }
    },
    *getMeasure({ payload }, { call, put }) {
      const { status, data } = yield call(queryMeasure, payload);
      if (status === 200) {
        yield put({
          type: 'saveMeasure',
          payload: data,
        });
      }
    },
    *getMeasureOringin({ payload }, { call, put }) {
      const { status, data } = yield call(queryMeasure, payload);
      if (status === 200) {
        yield put({
          type: 'savemeasuresOringin',
          payload: data,
        });
      }
    },
    *getHighMeasure({ payload }, { call, put }) {
      const { status, data } = yield call(queryHighLevelMeasure, payload);
      if (status === 200) {
        yield put({
          type: 'saveHighMeasure',
          payload: data,
        });
      }
    },
    *getAvailableScheme({ payload }, { call, put }) {
      const { status, data } = yield call(queryAvailableScheme, payload);
      if (status === 200) {
        yield put({
          type: 'save',
          payload: data.data,
        });
      }
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        data: payload,
      };
    },
    saveResult(state, { payload }) {
      return {
        ...state,
        result: payload,
      };
    },
    saveMeasure(state, { payload }) {
      return {
        ...state,
        measures: payload,
      };
    },
    savemeasuresOringin(state, { payload }) {
      return {
        ...state,
        measures_oringin: payload,
      };
    },
    saveHighMeasure(state, { payload }) {
      return {
        ...state,
        measures_high: payload,
      };
    },

  },
};
