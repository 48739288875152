import { getDeviceChangeLogList } from '@/services/devices';

export default {
  namespace: 'Devices_changelog',

  state: {
    result: [],
    info: {},
  },

  effects: {
    *fetchList({ payload }, { call, put }) {
      const response = yield call(getDeviceChangeLogList, payload);
      yield put({
        type: 'queryList',
        payload: response.data,
      });
    },
  },

  reducers: {
    queryList(state, action) {
      return {
        ...state,
        result: action.payload,
      };
    },
  },
};
