import {
  queryChainBrandStores,
  createChainBrandStores,
  editChainBrandStores,
} from '@/services/chainBrand';

export default {
  namespace: 'ChainBrandStore',
  state: {},
  effects: {
    *queryChainBrandStores({ payload }, { call, put }) {
      const { data } = yield call(queryChainBrandStores, payload);
      return data;
    },
    *createChainBrandStores({ payload }, { call, put }) {
      const { data } = yield call(createChainBrandStores, payload);
      return data;
    },
    *editChainBrandStores({ payload }, { call, put }) {
      const { data } = yield call(editChainBrandStores, payload);
      return data;
    },
  },
};
