import {
  queryIntelligentControlList,
  createIntelligentControl,
  editIntelligentControl,
} from '@/services/IntelligentControl';

export default {
  namespace: 'IntelligentControlManage',
  state: {},
  effects: {
    *queryIntelligentControlList({ payload }, { call, put }) {
      const { data } = yield call(queryIntelligentControlList, payload);
      return data;
    },
    *createIntelligentControl({ payload }, { call, put }) {
      const { data } = yield call(createIntelligentControl, payload);
      return data;
    },
    *editIntelligentControl({ payload }, { call, put }) {
      const { data } = yield call(editIntelligentControl, payload);
      return data;
    },
  },
};
