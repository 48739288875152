import { getStbLogList } from '@/services/devices';
import { syncLogs } from '@/services/company';

export default {
  namespace: 'Devices_stbLog',

  state: {
    indexData: {
      list: [],
      pagination: {},
    },
  },

  effects: {
    *fetchList({ payload }, { call, put }) {
      const { status, data } = yield call(getStbLogList, payload);
      if (status === 200) {
        yield put({
          type: 'queryList',
          payload: {
            list: data.data,
            pagination: {
              total: data.data.total,
              pageSize: parseInt(data.data.per_page),
              current: parseInt(data.data.current_page),
            },
          },
        });
      }
    },
    // 同步日志接口
    *getLogs({ payload, callback }, { call, put }) {
      const { status, data } = yield call(syncLogs, payload);

      if (status === 200) {
        if (callback) callback(data, status);
      }
    },
  },

  reducers: {
    queryList(state, action) {
      return {
        ...state,
        indexData: action.payload,
      };
    },
  },
};
