import { queryAppVersionInfo } from '@/services/appBase';
import { appSetVersionState } from '@/services/companyApp';

export default {
  namespace: 'CompanyAppManager_versionInfo',

  state: {
    data: {
      app_base: {},
    },
    result: [],
  },

  effects: {
    *fetchAppVersionInfo({ payload }, { call, put }) {
      const { data } = yield call(queryAppVersionInfo, payload);
      yield put({
        type: 'save',
        payload: data,
      });
    },

    *setAppVersionState({ payload, callback }, { call, put }) {
      const { data } = yield call(appSetVersionState, payload);

      callback(data);
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        data: payload,
      };
    },
  },
};
