import { queryCompanyOverView, queryCompanyAppList } from '@/services/company';

export default {
  namespace: 'CompanyAppManager_companyOverview',

  state: {
    data: [],
    appInfo: {
      install_success: 0,
      install_falid: 0,
      uninstall_success: 0,
      uninstall_faild: 0,
    },
  },

  effects: {
    *fetchCompanyOverView({ payload }, { call, put }) {
      const { data } = yield call(queryCompanyOverView, payload);
      yield put({
        type: 'saveAppInfo',
        payload: data,
      });
    },
    *fetchCompanyAppList({ payload }, { call, put }) {
      const { data } = yield call(queryCompanyAppList, payload);

      yield put({
        type: 'save',
        payload: data,
      });
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        data: payload,
      };
    },
    saveAppInfo(state, { payload }) {
      return {
        ...state,
        appInfo: payload,
      };
    },
  },
};
