import {
  queryPunishMobile,
  getVerifyCode,
  managementSqlInjection,
  getTaskResponse,
} from '@/services/punish';

export default {
  namespace: 'punishCommon',

  state: {
    mobile: '',
    code: {},
    result: {},
    taskResult: {},
  },

  effects: {
    *fetchPunishMobile({ payload }, { call, put }) {
      const { status, data } = yield call(queryPunishMobile, payload);
      if (status === 200) {
        yield put({
          type: 'saveMobile',
          payload: data.data.mobile,
        });
      }
    },
    *sendValidatorCode({ payload, callback }, { call, put }) {
      const { status, data } = yield call(
        getVerifyCode,
        payload
      );

      if (status === 201) {
        yield put({
          type: 'saveCode',
          payload: data,
        });
      }
      callback();
    },
    *saveManagementSqlInjection({ payload }, { call, put }) {
      const { data } = yield call(managementSqlInjection, payload);

      yield put({
        type: 'saveResult',
        payload: data,
      });
    },
    *fetchTaskResponse({ payload }, { call, put }) {
      const { data } = yield call(getTaskResponse, payload);

      yield put({
        type: 'saveTaskResult',
        payload: data,
      });
    },
  },

  reducers: {
    saveMobile(state, { payload }) {
      return {
        ...state,
        mobile: payload,
      };
    },
    saveCode(state, { payload }) {
      return {
        ...state,
        code: payload,
      };
    },
    saveResult(state, { payload }) {
      return {
        ...state,
        result: payload,
      };
    },
    saveTaskResult(state, { payload }) {
      return {
        ...state,
        taskResult: payload,
      };
    },
  },
};
