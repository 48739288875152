import {
  appCreateVersion,
  appSaveVersion,
  versionStatusLocalRelease,
  versionStatusGlobalRelease,
  versionStatusDown,
  versionStatusDel,
  versionRegions,
  versionAdapterInfo,
  getAppVersionDetail,
} from '@/services/companyApp';

export default {
  namespace: 'CompanyAppManager_subAppDetail',

  state: {
    data: {},
    regions: [],
    adapterInfo: {},
    result: {},
  },

  effects: {
    *fetch({ payload: id, callback }, { call, put }) {
      const { data } = yield call(getAppVersionDetail, { id });
      // console.log({ data });

      yield put({
        type: 'save',
        payload: data,
      });
      callback(data);
    },

    *createVersion({ payload, callback }, { call }) {
      const { data } = yield call(appCreateVersion, payload);
      console.log(data);
      callback(data);
    },

    *versionAdapterInfo({ payload, callback }, { call, put, select }) {
      const adapterInfo = yield select(
        state => state.CompanyAppManager_subAppDetail.adapterInfo
      );
      if (adapterInfo === undefined || Object.keys(adapterInfo).length > 0) {
        return;
      }
      yield call(versionAdapterInfo, payload);
      const { data } = yield call(versionAdapterInfo);
      console.log('before: ', { data });
      data.device_fireware_support = data.device_fireware_support.reduce(
        (temp, item) => {
          temp[Object.keys(item)[0]] = item[Object.keys(item)];
          return temp;
        },
        {}
      );
      data.device_main_software_support = data.device_main_software_support.reduce(
        (temp, item) => {
          temp[Object.keys(item)[0]] = item[Object.keys(item)];
          return temp;
        },
        {}
      );
      console.log('after ', { data });

      yield put({
        type: 'saveAdapterInfo',
        payload: data,
      });

      // callback();
    },

    *updateVersion({ payload, callback }, { call }) {
      console.log({ payload });
      yield call(appSaveVersion, payload);
      callback();
    },

    *versionAdapter({ payload, callback }, { call }) {
      console.log({ payload });
      yield call(appSaveVersion, payload);
      callback();
    },

    *versionStatusLocalRelease({ payload, callback }, { call }) {
      const { status, data } = yield call(versionStatusLocalRelease, payload);
      if (status === 200) {
        callback(data);
      }
    },

    *versionStatusGlobalRelease({ payload, callback }, { call }) {
      const { status, data } = yield call(versionStatusGlobalRelease, payload);
      if (status === 200) {
        callback(data);
      }
    },

    *versionStatusDown({ payload, callback }, { call }) {
      const { status, data } = yield call(versionStatusDown, payload);
      if (status === 200) {
        callback(data);
      }
    },

    *versionStatusDel({ payload, callback }, { call }) {
      yield call(versionStatusDel, payload);
      callback();
    },

    *fetchRegions({ payload: id, callback }, { call, put }) {
      const {
        data: { regions = [] },
      } = yield call(versionRegions, { id });

      yield put({
        type: 'saveRegions',
        payload: {
          ...regions.filter(region => region),
        },
      });

      callback();
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        data: payload,
      };
    },
    saveRegions(state, { payload }) {
      return {
        ...state,
        regions: payload,
      };
    },

    saveAdapterInfo(state, { payload }) {
      return {
        ...state,
        adapterInfo: payload,
      };
    },
  },
};
