import request from '@/utils/request';

export function agentRoleCreate(data) {
  return request('/agent/role/create', {
    method: 'POST',
    data,
  });
}

export function agentRoleTemplateType(data) {
  return request('/agent/role-template-type', {
    method: 'GET',
    data,
  });
}

export function agentRoleDelete(data) {
  const { id } = data;
  return request(`/agent/role/${id}/delete`, {
    method: 'POST',
    data,
  });
}

export function agentRoleEdit(data) {
  const { id } = data;
  return request(`/agent/role/${id}/edit`, {
    method: 'POST',
    data,
  });
}

/** 二级代理商列表 */
export function secondAgentList(params) {
  return request(`/second-agent-list`, {
    method: 'GET',
    params,
  });
}

/** 绑定二级代理商 */
export function changeCompanySecondAgent(data) {
  return request(`/change-company-second-agent`, {
    method: 'POST',
    data,
  });
}

export function clearCompanySecondAgent(data) {
  return request(`/clear-company-second-agent`, {
    method: 'POST',
    data,
  });
}

/** 批量导入商家 */
export function changeBatchCompanyAgent(params) {
  return request(`/change-batch-company-agent`, { method: 'POST', params });
}

/** 批量导入加密狗 */
export function batchBindEncryptionDog(params) {
  return request(`/business/agent/batch-bind-encryption-dog`, {
    method: 'POST',
    params,
  });
}

/** 转运维 */
export function changeCompanyOpsAgent(data) {
  return request(`/change-company-ops-agent`, {
    method: 'POST',
    data,
  });
}

/** 审核二级代理商 */
export function auditSecondAgent(data) {
  return request(`/light/audit-second-agent`, {
    method: 'POST',
    data,
  });
}

export function queryLevelOneAgent() {
  return request(`/level-one-agent`);
}

/** 获取代理商菜单 */
export function queryAgentMenus({ agentId }) {
  return request(`/light/agent-menus/${agentId}`);
}

export function changeIsHandleWorkOrder(data) {
  return request(`/agent/change-handle-work-order`, {
    method: 'POST',
    data,
  });
}
