import {
  getVerifyTestCompanies,
  createVerifyTestCompanies,
  deleteVerifyTestCompanies,
  getCanAddVerifyTestCompanies,
} from '@/services/remoteUpgrade';

export default {
  namespace: 'VerifyTestCompanies_verifyTestCompanies',

  state: {
    list: [],
    canAddList: [],
    response: '',
  },

  effects: {
    *fetchlist({ payload }, { call, put }) {
      const { status, data } = yield call(getVerifyTestCompanies, payload);

      if (status === 200) {
        yield put({
          type: 'saveList',
          payload: {
            list: data.data,
            pagination: {
              total: data.meta.total,
              pageSize: parseInt(data.meta.per_page),
              current: data.meta.current_page,
            },
          },
        });
      }
    },
    *create({ payload }, { call, put }) {
      const { status } = yield call(createVerifyTestCompanies, payload);
      yield put({
        type: 'saveResponse',
        payload: status,
      });
    },
    *delete({ payload }, { call, put }) {
      const { status } = yield call(deleteVerifyTestCompanies, payload);
      yield put({
        type: 'saveResponse',
        payload: status,
      });
    },
    *fetchCanAddList({ payload }, { call, put }) {
      const { status, data } = yield call(
        getCanAddVerifyTestCompanies,
        payload
      );

      if (status === 200) {
        yield put({
          type: 'saveCanAddList',
          payload: {
            list: data.data,
            pagination: {
              total: data.meta.total,
              pageSize: data.meta.per_page,
              current: data.meta.current_page,
            },
          },
        });
      }
    },
  },

  reducers: {
    saveList(state, action) {
      return {
        ...state,
        list: action.payload,
      };
    },
    saveCanAddList(state, action) {
      return {
        ...state,
        canAddList: action.payload,
      };
    },
    saveResponse(state, { payload }) {
      return {
        ...state,
        response: payload,
      };
    },
  },
};
