import request from '@/utils/request';

/** 灯光厂家列表 */
export function queryVodList(params) {
  return request(`/company-service/vod-vendors`, { method: 'GET', params });
}

export function createVod(data) {
  return request(`/company-service/vod-vendors`, { method: 'POST', data });
}

export function editVod(data) {
  return request(`/company-service/vod-vendors/${data.id}`, {
    method: 'PUT',
    data,
  });
}
