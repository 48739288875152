import * as companyresource from '@/services/FMMarket/ShopFMbox';

export default {
  namespace: 'CompanyFMResource_CompanyRsbox',
  state: {
    selects: {
      channel: [],
      deviceType: [],
      fuseClassify: [],
      videoPly: [],
    },
    valueEnum: {
      channel: {},
      fuseClassify: {},
      videoPly: {},
    },
    data: {},
  },
  reducers: {
    saveSelects(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    saveAll(state, { payload }) {
      return {
        ...state,
        data: payload,
      };
    },
  },
  effects: {
    *fetchSelect({ payload }, { call, put }) {
      const selects = {
        channel: [],
        deviceType: [],
        fuseClassify: [],
        videoPly: [],
      };

      const valueEnum = {
        channel: {},
        fuseClassify: {},
        videoPly: {},
      };

      const fireChannel = yield call(companyresource.get_fire_channel, payload);
      if (fireChannel.status === 200) {
        selects.channel = fireChannel.data.data;
        valueEnum.channel = fireChannel.data.data.reduce(
          (acc, val) => ({ ...acc, [val.value]: { text: val.label } }),
          {}
        );
      }

      const fireDeviceType = yield call(
        companyresource.get_fire_deviceType,
        payload
      );
      if (fireDeviceType.status === 200) {
        selects.deviceType = fireDeviceType.data.data;
      }
      const fireFuseClassify = yield call(
        companyresource.get_fire_fuseClassify,
        payload
      );
      if (fireFuseClassify.status === 200) {
        selects.fuseClassify = fireFuseClassify.data.data;
        valueEnum.fuseClassify = fireFuseClassify.data.data.reduce(
          (acc, val) => ({ ...acc, [val.class_id]: { text: val.label } }),
          {}
        );
      }
      const fireVideoPly = yield call(
        companyresource.get_fire_videoPly,
        payload
      );
      if (fireVideoPly.status === 200) {
        selects.videoPly = fireVideoPly.data.data;
        valueEnum.videoPly = fireVideoPly.data.data.reduce(
          (acc, val) => ({ ...acc, [val.video_id]: { text: val.label } }),
          {}
        );
      }

      yield put({
        type: 'saveSelects',
        payload: {
          selects,
          valueEnum,
        },
      });
    },
    *fetchAllRsbox({ payload }, { call, put }) {
      const { status, data } = yield call(
        companyresource.queryCompanyAllRsbox,
        payload
      );
      if (status === 200) {
        yield put({
          type: 'saveAll',
          payload: {
            list: data.data.items,
            pagination: {
              total: data.data.meta.total,
              pageSize: data.data.meta.per_page,
              current: data.data.meta.current_page,
            },
          },
        });
      }
    },
    *addDevice({ payload, callback }, { call }) {
      const { data } = yield call(companyresource.post_sy_device, payload);
      if (callback) callback(data);
    },
    *editDevice({ payload, callback }, { call }) {
      const { data } = yield call(companyresource.put_sy_device, payload);
      if (callback) callback(data);
    },
    *putMandate({ payload, callback }, { call }) {
      const { data } = yield call(
        companyresource.put_sy_device_mandate,
        payload
      );
      if (callback) callback(data);
    },
    *delDevice({ payload, callback }, { call }) {
      const { data } = yield call(companyresource.delete_sy_device, payload);
      if (callback) callback(data);
    },
    *putLock({ payload, callback }, { call }) {
      const { data } = yield call(companyresource.put_sy_device_lock, payload);
      if (callback) callback(data);
    },
    *putControlType({ payload, callback }, { call }) {
      const { data } = yield call(
        companyresource.put_sy_device_controlType,
        payload
      );
      if (callback) callback(data);
    },
  },
};
