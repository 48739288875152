import { stringify } from 'qs';
import request from '@/utils/request';

/** 获取设备信息变更记录列表 */
export async function getDeviceChangeLogList(params) {
  return request(`/devices/getDeviceChangeLogList?${stringify(params)}`);
}

/** 获取设备异常信息记录列表 */
export async function getDeviceAbnormalLogList(params) {
  return request(
    `/deviceExceptionMessage/getDeviceAbnormalLogList?${stringify(params)}`
  );
}

/** 获取设备详情 */
export async function getDeviceDetailInfo(params) {
  return request(`/devices/getDeviceDetailInfo?${stringify(params)}`);
}

export async function queryStbDeviceApps(params) {
  const { companyId, deviceId } = params;
  delete params.companyId;
  delete params.deviceId;
  return request(
    `/appMarket/${companyId}/getStbInstallApp/${deviceId}?${stringify(params)}`
  );
}

export async function queryStbDeviceAppLogs(params) {
  const { companyId, deviceId } = params;
  delete params.companyId;
  delete params.deviceId;
  return request(
    `/appMarket/${companyId}/getStbInstallLog/${deviceId}?${stringify(params)}`
  );
}

export async function queryAppInstallLogs(params) {
  const { companyId } = params;
  delete params.companyId;
  delete params.deviceId;
  return request(
    `/appMarket/${companyId}/getCompanyAppInstallLog?${stringify(params)}`
  );
}

// 查询机顶盒权限列表
export async function queryStbSdkPermissionList(params) {
  return request(`/stb-sdk-permission?${stringify(params)}`);
}
export async function stbSdkPermissionCreate(params) {
  return request(`/stb-sdk-permission/create`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}
export async function stbSdkPermissionEdit(params) {
  const { id } = params;
  delete params.id;
  return request(`/stb-sdk-permission/${id}/edit`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}
export async function stbSdkPermissionDelete(params) {
  const { id } = params;
  delete params.id;
  return request(`/stb-sdk-permission/${id}/delete`);
}

export async function queryStbSdkPermission(params) {
  const { appId } = params;
  return request(`/stb-sdk-permission/${appId}/appStbSdkPermission`);
}
export async function appStbSdkPermission(params) {
  const { appId } = params;
  return request(`/stb-sdk-permission/${appId}/appStbSdkPermission`);
}

export async function configAppStbSdkPermission(params) {
  const { appId } = params;
  delete params.appId;
  return request(`/stb-sdk-permission/${appId}/configpermission`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

// 获取机顶盒日志列表
export async function getStbLogList(params) {
  return request('/device-job/log/list', { params });
}

// 获取机顶盒日志列表
export function getStbLogDevice(params) {
  return request(`/device-service/stb-log/device/${params.id}`, { params });
}
