import { getDeviceUpgradeLogs } from '@/services/remoteUpgrade';

export default {
  namespace: 'RemoteUpgrade_deviceUpgradeStatusLogs',

  state: {
    deviceUpgradeList: [],
  },

  effects: {
    *fetchDeviceUpgradeLogs({ payload }, { call, put }) {
      const { status, data } = yield call(getDeviceUpgradeLogs, payload);

      if (status === 200) {
        yield put({
          type: 'saveLogs',
          payload: data,
        });
      }
    },
  },

  reducers: {
    saveLogs(state, { payload }) {
      return {
        ...state,
        deviceUpgradeList: payload,
      };
    },
  },
};
