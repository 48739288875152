import {
  getOSSConfig,
  saveIntonationFile,
} from '@/services/MusicLibrary/musiclibrary';
import * as companyAppService from '@/services/companyApp';

export default {
  namespace: 'Song_IntonationFile',

  state: {
    ossConfig: {},
  },

  effects: {
    *save({ payload, callback }, { call }) {
      const { status, data } = yield call(saveIntonationFile, payload);
      if (status === 201) {
        if (callback) {
          callback(data);
        }
      }
    },
    *fetchOSSConfig({ payload, callback }, { call, put }) {
      const { status, data } = yield call(getOSSConfig, payload);
      if (status === 200) {
        callback(data);
        // yield put({
        //   type: 'saveOSSConfig',
        //   payload: data,
        // });
      }
    },
  },

  reducers: {
    saveOSSConfig(state, { payload }) {
      return {
        ...state,
        ossConfig: payload,
      };
    },
  },
};
