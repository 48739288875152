import { updateCompanyAppConfig } from '@/services/company';

export default {
  namespace: 'CompanyAppManager_appconfig',

  state: {
    data: {},
  },

  effects: {
    *fetchCompanyAppConfig({ payload }, { call, put }) {
      const response = yield call(updateCompanyAppConfig, payload);

      yield put({
        type: 'save',
        payload: response.data,
      });
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        data: payload,
      };
    },
  },
};
