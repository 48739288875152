import request from '@/utils/request';

export async function getClassification(params) {
  return request('/music-library/remote-resource/classification', {
    params,
  });
}

export async function deleteClassification(params) {
  return request(`/music-library/remote-resource/classification`, {
    method: 'DELETE',
    data: {
      ...params,
    },
  });
}

export async function saveClassification(params) {
  return request(`/music-library/remote-resource/classification`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function getSong(params) {
  return request('/music-library/remote-resource/song', {
    params,
  });
}

export async function importSong(params) {
  return request.post(
    `/music-library/remote-resource/song/import`,
    params.formData,
    {
      headers: {
        'Content-Type': `multipart/form-data;boundary=${new Date().getTime()}`,
      },
    }
  );
}

export async function regenerateCompanySongBook() {
  return request(`/music-library/remote-resource/regenerateCompanySongBook`, {
    method: 'post',
  });
}

export async function deleteSong(params) {
  return request(`/music-library/remote-resource/song`, {
    method: 'DELETE',
    data: {
      ...params,
    },
  });
}

export async function getAvailableSong(params) {
  return request('/music-library/remote-resource/available-song', {
    params,
  });
}

export async function saveSong(params) {
  return request(`/music-library/remote-resource/song`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function getVipSong(params) {
  return request('/music-library/remote-resource/vip-song', {
    params,
  });
}

export async function delVipSong(params) {
  return request(`/music-library/remote-resource/vip-song`, {
    method: 'DELETE',
    data: {
      ...params,
    },
  });
}

export async function editVipSong(params) {
  return request(`/music-library/remote-resource/vip-song`, {
    method: 'PUT',
    data: {
      ...params,
    },
  });
}

export async function saveMultyVipSong(params) {
  return request(`/music-library/remote-resource/multysave-vip-song`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function getRecommendSong(params) {
  return request('/music-library/remote-resource/recommend-song', {
    params,
  });
}

export async function delRecommendSong(params) {
  return request(`/music-library/remote-resource/recommend-song`, {
    method: 'DELETE',
    data: {
      ...params,
    },
  });
}

export async function editRecommendSong(params) {
  return request(`/music-library/remote-resource/recommend-song`, {
    method: 'PUT',
    data: {
      ...params,
    },
  });
}

export async function saveMultyRecommendSong(params) {
  return request(`/music-library/remote-resource/multysave-recommend-song`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function getClassificationOne(params) {
  return request('/music-library/remote-resource/classification-one', {
    params,
  });
}

export async function uploadTorrentFileToTracker(formData) {
  console.log('in uploadTorrent');
  return request.post(`/music-library/torrent-upload`, formData, {
    headers: {
      'Content-Type': `multipart/form-data;boundary=${new Date().getTime()}`,
    },
  });
}

export async function uploadTorrent(params) {
  console.log('params', params.list);
  return request(`/music-library/song-torrent/${params.list.type}`, {
    method: 'POST',
    data: {
      ...params.list,
    },
  });
}

export async function updateTorrent(params) {
  return request.post(
    `/music-library/song-torrent-update/${params.type}`,
    params.formData,
    {
      headers: {
        'Content-Type': `multipart/form-data;boundary=${new Date().getTime()}`,
      },
    }
  );
}

export async function getOssConfig(params) {
  return request(`/music-library/oss/config`, {
    params,
  });
}

export async function exportSongs(params) {
  return request(`/music-library/remote-resource/song/export`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function createMultiSong(params) {
  return request(`/music-library/remote-resource/song/create-multi`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function delMultiSong(params) {
  return request(`/music-library/remote-resource/song/delete-multi`, {
    method: 'DELETE',
    data: {
      ...params,
    },
  });
}
