import request from '@/utils/request';

export function queryChainBrands(params) {
  return request(`/company-service/chain-brands`, { method: 'GET', params });
}

export function createChainBrands(data) {
  return request(`/company-service/chain-brands`, { method: 'POST', data });
}

export function editChainBrands(data) {
  return request(`/company-service/chain-brands/${data.id}`, {
    method: 'PUT',
    data,
  });
}

export function queryChainBrandStores(params) {
  return request(`/company-service/chain-brand-stores`, {
    method: 'GET',
    params,
  });
}

export function queryChainBrandStoreForId(params) {
  return request(`/company-service/chain-brand-stores/${params.id}`, {
    method: 'GET',
    params,
  });
}

export function createChainBrandStores(data) {
  return request(`/company-service/chain-brand-stores`, {
    method: 'POST',
    data,
  });
}

export function editChainBrandStores(data) {
  return request(`/company-service/chain-brand-stores/${data.id}`, {
    method: 'PUT',
    data,
  });
}

export function deleteChainBrandStores(data) {
  return request(`/company-service/chain-brand-stores/${data.id}`, {
    method: 'DELETE',
    data,
  });
}
