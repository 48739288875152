import { setHeader } from '@/utils/request';
import moment from 'moment';
import { v1 } from 'uuid';
import { flattenDeep, throttle } from 'lodash';
import {
  queryNotices,
  getWsConfig,
  setRead,
  setAllRead,
} from '@/services/messageCenter';
// 通过 path 找到对应的元素
function findMenuLocal(data, path) {
  return data
    .map(item => {
      if (!item.name || !item.path) {
        return null;
      }

      if (item.path === path) {
        // console.log({ item });
        return item.locale;
      }

      if (item.children) {
        // console.log({ item });
        return findMenuLocal(item.children, path);
      }
      // console.log('here', { item });

      return null;
    })
    .filter(item => item && item.length > 0);
}

export default {
  namespace: 'global',

  state: {
    visiableNotice: true,
    collapsed: false,
    notices: [],
  },

  effects: {
    // 标记全部已读
    *setAllRead({ payload }, { call, put }) {
      const { status, data } = yield call(setAllRead, payload);
    },
    // 面板点击
    *changeNoticeReadState({ payload, callback }, { call }) {
      const { data } = yield call(setRead, { message_id: payload });
      callback();
    },
    // 获取ws配置
    *fetchNoticeWsConfig({ payload, callback }, { call }) {
      const { data } = yield call(getWsConfig);
      callback(data);
    },
    // 获取消息模版数据
    *fetchNotices(_, { call, put, select }) {
      const { data } = yield call(queryNotices);
      yield put({
        type: 'saveNotices',
        payload: data.slice(0, 5),
      });
      // const unreadCount = yield select(
      //   state => state.global.notices.filter(item => !item.read).length
      // );
      const unreadCount = data.length;
      yield put({
        type: 'user/changeNotifyCount',
        payload: {
          totalCount: data.length,
          unreadCount,
        },
      });
    },
    *updateAxiosHeader({ payload }, { select }) {
      // const pathname = payload.payload;
      const { pathname } = payload;
      const menuData = yield select(state => state.menu.menuData);
      const result = flattenDeep(findMenuLocal(menuData, pathname)).filter(
        item => item
      );
      const needName = result[0] || '';
      // console.log({ menuData, pathname, result });

      setHeader('X-Page-Pathname', encodeURI(pathname));
      setHeader('X-Page-Pathname-Menu', needName);
      setHeader('X-Page-Uid', v1());
      setHeader('X-Visited-Page-At', moment().format('YYYY-MM-DD HH:mm:ss'));
    },
    *setVisiableNotice({ payload }, { put }) {
      yield put({
        type: 'setVisibleNotice',
        payload,
      });
    },
  },
  reducers: {
    setVisibleNotice(state, { payload }) {
      return {
        ...state,
        visiableNotice: payload.visiableNotice,
      };
    },

    changeLayoutCollapsed(state, { payload }) {
      return {
        ...state,
        collapsed: payload,
      };
    },
    saveNotices(state, { payload }) {
      return {
        ...state,
        notices: payload,
      };
    },
    saveClearedNotices(state, { payload }) {
      return {
        ...state,
        notices: state.notices.filter(item => item.type !== payload),
      };
    },
  },

  subscriptions: {
    setup({ dispatch, history }) {
      // Subscribe history(url) change, trigger `load` action if pathname is `/`
      return history.listen(({ pathname, search }) => {
        if (typeof window.ga !== 'undefined') {
          window.ga('send', 'pageview', pathname + search);
        }
        document.onscroll = throttle(() => {
          const visiableNotice = false;
          dispatch({
            type: 'setVisibleNotice',
            payload: { visiableNotice },
          });
        }, 1000);

        dispatch({
          type: 'updateAxiosHeader',
          payload: { pathname },
        });
      });
    },
  },
};
