import * as companyresource from '@/services/companyresource';

export default {
  namespace: 'CompanyResource_CompanyRsbox',
  state: {
    data: {},
    getXML: {},
  },
  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        data: payload,
      };
    },
    saveXML(state, { payload }) {
      return {
        ...state,
        getXML: payload,
      };
    },
  },
  effects: {
    *fetchRsbox({ payload }, { call, put }) {
      const { status, data } = yield call(
        companyresource.queryCompanyRsbox,
        payload
      );
      if (status === 200) {
        yield put({
          type: 'save',
          payload: {
            list: data.data,
            pagination: {
              total: data.total,
              pageSize: data.per_page,
              current: data.current_page,
            },
          },
        });
      }
    },

    // 授权转移
    *storeRsBoxTransfer({ payload, callback }, { call, put }) {
      const { status } = yield call(companyresource.RsBoxTransfer, payload);

      if (status === 200) {
        if (callback) callback();
      }
    },

    // 根据URL获取XML
    *getXMLToURL({ payload }, { call, put }) {
      const { status, data } = yield call(
        companyresource.queryXMLToURL,
        payload
      );
      if (status === 200) {
        yield put({
          type: 'saveXML',
          payload: {
            XML: data,
          },
        });
      }
    },
    *addBox({ payload, callback }, { call, put }) {
      const { status, data } = yield call(companyresource.addRsBox, payload);
      if (callback) callback(data);
    },
    *editBox({ payload, callback }, { call, put }) {
      const { status, data } = yield call(companyresource.editRsBox, payload);
      console.log('88', data);
      if (callback) callback(data);
    },
    *delBox({ payload, callback }, { call, put }) {
      const { status, data } = yield call(companyresource.delRsBox, payload);
      if (callback) callback(data);
    },
    *forceDelBox({ payload, callback }, { call, put }) {
      const { status, data } = yield call(
        companyresource.forceDelRsBox,
        payload
      );
      if (callback) callback(data);
    },
  },
};
