import {
  deleteAppRunEnv,
  queryRunEnvs,
  createAppRunEnv,
  updateAppRunEnv,
  fetchAppRunEnv,
} from '@/services/AppMarket/appRunEnvService';

export default {
  namespace: 'CompanyAppManager_appRunEnv',
  state: {
    appRunEnvList: [],
  },
  effects: {
    *deleteAppRunEnv({ payload }, { call, put }) {
      const { data, status } = yield call(deleteAppRunEnv, payload);
      return status;
    },

    *fetchAppRunEnv({ payload }, { call, put }) {
      const { data } = yield call(fetchAppRunEnv, payload);
      return data;
    },

    *handleFormSubmit({ payload }, { call, put }) {
      let data = {};

      if (payload.is_copy) {
        data = yield call(createAppRunEnv, payload);
        return data;
      }

      if (payload.id) {
        data = yield call(updateAppRunEnv, payload);
        return data;
      }
      data = yield call(createAppRunEnv, payload);
      return data;
    },

    *queryAppRunEnvList({ payload }, { call, put }) {
      const { data } = yield call(queryRunEnvs, payload);
      return data;
    },
  },
  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};
