import {
  allCompanyMenuList,
  companyMenuCreate,
  companyMenuEdit,
  companyMenuDelete,
} from '@/services/menu';

export default {
  namespace: 'PlatformManager_companyMenu',

  state: {
    data: {},
    menuData: {},
    userInfo: {},
    result: {},
  },

  effects: {
    *fetch({ payload }, { call, put }) {
      const {
        data: { data },
      } = yield call(allCompanyMenuList, payload);

      yield put({
        type: 'save',
        payload: {
          list: data,
        },
      });
    },
    *create({ payload }, { call, put }) {
      const { data } = yield call(companyMenuCreate, payload);
      yield put({
        type: 'saveResult',
        payload: data,
      });
    },
    *edit({ payload }, { call, put }) {
      const { data } = yield call(companyMenuEdit, payload);
      yield put({
        type: 'saveResult',
        payload: data,
      });
    },
    *delete({ payload }, { call, put }) {
      const { data } = yield call(companyMenuDelete, payload);
      yield put({
        type: 'saveResult',
        payload: data,
      });
    },
  },
  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        data: payload,
      };
    },
    saveResult(state, { payload }) {
      return {
        ...state,
        result: payload,
      };
    },
  },
};
