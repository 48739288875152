/**
 * 灯库 工程相关
 */
import {
  storeLampProjectListCondition,
  storeLampEffectListCondition,
  storeLightListCondition,
  saleStoreLampEffect,
  saleStoreProjectEffect,
  storeAuditProjectListCondition,
  storeAuditLampListCondition,
  updateProjectDownload,
  updateStoreProjectDownload,
  storeAuditLightListCondition,
} from '@/services/lampControl';

const whitelist = ['role_type', 'company_id'];

function camelCase(string) {
  if (whitelist.includes(string)) return string;
  return string.replace(/_([a-z])/g, (all, letter) => letter.toUpperCase());
}

function payloadCamelCase(obj) {
  const ret = {};
  Object.entries(obj).forEach(([k, v]) => {
    ret[camelCase(k)] = v;
  });
  return ret;
}

export default {
  namespace: 'lampMarket',
  state: {
    lampProjectListCondition: {}, // 工程
    lampEffectListCondition: {}, // 灯库
    lightListCondition: {}, // 灯  namespace: 'lampMarket',
    auditProjectListCondition: {},
    auditLampListCondition: {},
    storeAuditLightListCondition: {},
  },
  effects: {
    *storeLampProjectListCondition({ payload }, { call, put }) {
      const { data } = yield call(
        storeLampProjectListCondition,
        payloadCamelCase(payload)
      );
      yield put({
        type: 'save',
        payload: {
          lampProjectListCondition: data?.data,
        },
      });
    },
    *storeLampEffectListCondition({ payload }, { call, put }) {
      const { data } = yield call(
        storeLampEffectListCondition,
        payloadCamelCase(payload)
      );
      yield put({
        type: 'save',
        payload: { lampEffectListCondition: data?.data },
      });
    },
    *storeLightListCondition({ payload }, { call, put }) {
      const { data } = yield call(
        storeLightListCondition,
        payloadCamelCase(payload)
      );
      yield put({
        type: 'save',
        payload: { lightListCondition: data?.data },
      });
    },
    *storeAuditProjectListCondition({ payload }, { call, put }) {
      const { data } = yield call(
        storeAuditProjectListCondition,
        payloadCamelCase(payload)
      );
      yield put({
        type: 'save',
        payload: { auditProjectListCondition: data?.data },
      });
    },
    *storeAuditLampListCondition({ payload }, { call, put }) {
      const { data } = yield call(
        storeAuditLampListCondition,
        payloadCamelCase(payload)
      );
      yield put({
        type: 'save',
        payload: { auditLampListCondition: data?.data },
      });
    },
    *storeAuditLightListCondition({ payload }, { call, put }) {
      const { data } = yield call(
        storeAuditLightListCondition,
        payloadCamelCase(payload)
      );
      yield put({
        type: 'save',
        payload: { storeAuditLightListCondition: data?.data },
      });
    },
    *saleStoreLampEffect({ payload }, { call }) {
      const { data } = yield call(saleStoreLampEffect, payload);
      return data;
    },
    *saleStoreProjectEffect({ payload }, { call }) {
      const { data } = yield call(saleStoreProjectEffect, payload);
      return data;
    },
    *updateProjectDownload({ payload }, { call }) {
      const { data } = yield call(updateProjectDownload, payload);
      return data;
    },
    *updateStoreProjectDownload({ payload }, { call }) {
      const { data } = yield call(updateStoreProjectDownload, payload);
      return data;
    },
  },
  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};
