import {
  getSingerList,
  getSingerCount,
  singerExport,
  uploadSingerFile,
  uploadSingerFileDetail,
} from '@/services/MusicLibrary/musiclibrary';

export default {
  namespace: 'Singer_Info',

  state: {
    data: {},
    visible: false,
    current: [],
    sortedInfo: '',
    result: {},
    info: {
      total: 0,
      extra_lager_avatar_total: 0,
      lager_avatar_total: 0,
      middle_avatar: 0,
      small_avatar: 0,
    },
    uploadSingerFileResult: {},
  },

  effects: {
    *fetchSingerList({ payload }, { call, put }) {
      const { status, data } = yield call(getSingerList, payload);

      if (status === 200) {
        yield put({
          type: 'save',
          payload: {
            list: data.data,
            pagination: {
              total: data.meta.total,
              pageSize: data.meta.per_page,
              current: data.meta.current_page,
            },
          },
        });
      }
    },
    *fetchSingerCount({ payload }, { call, put }) {
      const { status, data } = yield call(getSingerCount, payload);

      if (status === 200) {
        yield put({
          type: 'saveCount',
          payload: data,
        });
      }
    },
    *singerExport({ payload, callback }, { call }) {
      const { status } = yield call(singerExport, payload);
      if (status === 201) {
        callback();
      }
    },

    *uploadSingerFile({ payload, callback, errorCallback }, { call, put }) {
      const { status, data } = yield call(uploadSingerFile, payload);
      if (status === 200) {
        callback(data);
      }
      if (status === 400) {
        errorCallback(data);
      }
    },

    *uploadSingerFileDetail({ payload, callback }, { call, put }) {
      const { status, data } = yield call(uploadSingerFileDetail, payload);
      if (status === 201) {
        callback(data);
      }
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        data: payload,
      };
    },
    saveCount(state, { payload }) {
      return {
        ...state,
        info: payload,
      };
    },
    saveUploadSingerFileResult(state, { payload }) {
      return {
        ...state,
        uploadSingerFileResult: payload,
      };
    },
  },
};
