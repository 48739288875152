/**
 * request 网络请求工具
 * 更详细的 api 文档: https://github.com/umijs/umi-request
 */
import axios from 'axios';
import interceptors from './interceptors';
// import interceptors from './interceptors';

const request = axios.create({
  baseURL: `${process.env.APP_API_URL}/api`,
  timeout: 15000,
});

interceptors(request);
const businessRequest = axios.create({
  baseURL: `${process.env.BUSINESS_API_URL}/api`,
  timeout: 10000,
});

interceptors(businessRequest);

const analyzeRequest = axios.create({
  baseURL: `${process.env.ANALYZE_API_URL}`,
  timeout: 10000,
});

interceptors(analyzeRequest);

export function setToken(token) {
  request.defaults.headers.common.Authorization = `Bearer ${token}`;
}

export function setHeader(item, value) {
  request.defaults.headers.common[item] = value;
}

export { businessRequest, analyzeRequest };
export default request;
