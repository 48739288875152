import * as deviceAuthorization from '@/services/deviceAuthorization';

export default {
  namespace: 'deviceAuthorization',
  state: {
    data: {},
    result: [],
    pagination: {},
  },
  reducers: {
    saveResult(state, { payload }) {
      return {
        ...state,
        result: payload.result,
        pagination: payload.pagination,
      };
    },
  },
  effects: {
    // 新增
    *create({ payload, callback }, { call, put }) {
      const { status, data } = yield call(
        deviceAuthorization.createCode,
        payload
      );
      if (!status) {
        return;
      }
      // console.log('##########');
      // console.log(status);
      // console.log(data);
      callback(data);
    },
    *batchUpdate({ payload, callback }, { call, put }) {
      const { status, data } = yield call(
        deviceAuthorization.batchCreate,
        payload
      );
      callback(data);
    },
    *fetch({ payload, callback }, { call, put }) {
      const { status, data, meta } = yield call(
        deviceAuthorization.getAuthorizeList,
        payload
      );
      if (!status) {
        return;
      }
      yield put({
        type: 'saveResult',
        payload: {
          result: data.data,
          pagination: {
            total: data.meta.total,
            pageSize: data.meta.per_page,
            current: data.meta.current_page,
          },
        },
      });
    },
    *set({ payload, callback }, { call, put }) {
      const { status, data } = yield call(
        deviceAuthorization.setActiveState,
        payload
      );
    },

    *getManufactuers({ payload, callback }, { call, put }) {
      const { status, data } = yield call(
        deviceAuthorization.getManufactuers,
        payload
      );
      if (!status) {
        return;
      }
      callback(data);
    },
    *getStbHardTypes({ payload, callback }, { call, put }) {
      const { status, data } = yield call(
        deviceAuthorization.getStbHardTypes,
        payload
      );
      if (!status) {
        return;
      }
      callback(data);
    },
  },
};
