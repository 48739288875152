import {
  getUpgradePackagesTargetTypes,
  createUpgradePackagesTargetTypes,
  editUpgradePackagesTargetTypes,
  deleteUpgraadePackagesTargetTypes,
} from '@/services/remoteUpgrade';

export default {
  namespace: 'UpgradePackagesTargetTypes_upgradePackagesTargetTypes',

  state: {
    result: [],
    response: '',
  },

  effects: {
    *fetchList({ payload }, { call, put }) {
      const { status, data } = yield call(
        getUpgradePackagesTargetTypes,
        payload
      );

      if (status === 200) {
        yield put({
          type: 'queryList',
          payload: data,
        });
      }
    },
    *create({ payload }, { call, put }) {
      const { status } = yield call(createUpgradePackagesTargetTypes, payload);
      yield put({
        type: 'saveResponse',
        payload: status,
      });
    },
    *edit({ payload }, { call, put }) {
      const { status } = yield call(editUpgradePackagesTargetTypes, payload);
      yield put({
        type: 'saveResponse',
        payload: status,
      });
    },
    *delete({ payload }, { call, put }) {
      const { status } = yield call(deleteUpgraadePackagesTargetTypes, payload);
      yield put({
        type: 'saveResponse',
        payload: status,
      });
    },
  },

  reducers: {
    queryList(state, action) {
      return {
        ...state,
        result: action.payload,
      };
    },
    saveResponse(state, { payload }) {
      return {
        ...state,
        response: payload,
      };
    },
  },
};
