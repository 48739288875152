import { queryDataAnalysis } from '@/services/dataCenter';

export default {
  namespace: 'DataCleansing_dataAnalysis',

  state: {
    data: {},
  },

  effects: {
    *fetch({ payload }, { call, put }) {
      const { data } = yield call(queryDataAnalysis, payload);
      yield put({
        type: 'save',
        payload: {
          list: data.data,
        },
      });
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        data: payload,
      };
    },
  },
};
