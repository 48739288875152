import {
  allAgentMenuList,
  secondAgentMenuList,
  agentCompanyMenuList,
  agentMenuCreate,
  agentMenuEdit,
  agentMenuDelete,
  agentAllocateMenu,
  agentBatchAllocateMenu,
  getAllMenuTemplates,
} from '@/services/menu';

export default {
  namespace: 'BusinessCenter_agentManageMenu',

  state: {
    data: {}, // 所有菜单
    secondAgentMenu: {},
    companyMenuData: {}, // 当前菜单
    userInfo: {},
    result: {},
    menuTemplate: [],
  },

  effects: {
    *fetch({ payload }, { call, put }) {
      const {
        data: { data },
      } = yield call(allAgentMenuList, payload);

      yield put({
        type: 'save',
        payload: {
          list: data,
        },
      });
    },
    *fetchSecondAgentMenu({ payload }, { call, put }) {
      const {
        data: { data },
      } = yield call(secondAgentMenuList, payload);

      yield put({
        type: 'saveSecondAgentMenuList',
        payload: {
          list: data || [],
        },
      });
    },
    *fetchAgentCompanyMenuList({ payload }, { call, put }) {
      const {
        data: { data: menuData },
      } = yield call(agentCompanyMenuList, payload);

      yield put({
        type: 'saveAgentCompanyMenuList',
        payload: {
          list: menuData,
        },
      });
    },
    *create({ payload }, { call, put }) {
      const { data } = yield call(agentMenuCreate, payload);
      yield put({
        type: 'saveResult',
        payload: data,
      });
    },
    *edit({ payload }, { call, put }) {
      const { data } = yield call(agentMenuEdit, payload);
      yield put({
        type: 'saveResult',
        payload: data,
      });
    },
    *delete({ payload }, { call, put }) {
      const { data } = yield call(agentMenuDelete, payload);
      yield put({
        type: 'saveResult',
        payload: data.result,
      });
    },
    *saveAgent({ payload }, { call, put }) {
      const { data } = yield call(agentAllocateMenu, payload);
      return data;
    },
    *saveBatchAgent({ payload }, { call, put }) {
      const { data } = yield call(agentBatchAllocateMenu, payload);
      return data;
    },
    *fetchMenuTemplate({ payload }, { call, put }) {
      const {
        data: { data },
      } = yield call(getAllMenuTemplates, payload);

      yield put({
        type: 'saveMenuTemplate',
        payload: data,
      });
    },
  },
  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        data: payload,
      };
    },
    saveSecondAgentMenuList(state, { payload }) {
      return {
        ...state,
        secondAgentMenu: payload,
      };
    },
    saveAgentCompanyMenuList(state, { payload }) {
      return {
        ...state,
        companyMenuData: payload,
      };
    },
    saveResult(state, { payload }) {
      return {
        ...state,
        result: payload,
      };
    },
    saveMenuTemplate(state, { payload }) {
      return {
        ...state,
        menuTemplate: payload,
      };
    },
  },
};
