import {
  getVipSong,
  delVipSong,
  editVipSong,
  saveMultyVipSong,
} from '@/services/MusicLibrary/remoteResource';

export default {
  namespace: 'VipSong',

  state: {
    data: {},
    visible: false,
    current: [],
    sortedInfo: '',
    result: {},
  },

  effects: {
    *fetch({ payload }, { call, put }) {
      const { status, data } = yield call(getVipSong, payload);

      if (status === 200) {
        yield put({
          type: 'save',
          payload: {
            list: data.data,
            pagination: {
              total: data.meta.total,
              pageSize: data.meta.per_page,
              current: data.meta.current_page,
            },
          },
        });
      }
    },
    *del({ payload }, { call, put }) {
      const { status, data } = yield call(delVipSong, payload);
      if (status === 200) {
        yield put({
          type: 'saveResult',
          payload: data,
        });
      }
    },
    *edit({ payload }, { call, put }) {
      const { status, data } = yield call(editVipSong, payload);
      if (status === 200) {
        yield put({
          type: 'saveResult',
          payload: data,
        });
      }
    },
    *saveMulty({ payload }, { call, put }) {
      const { status, data } = yield call(saveMultyVipSong, payload);
      if (status === 200) {
        yield put({
          type: 'saveResult',
          payload: data,
        });
      }
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        data: payload,
      };
    },
    saveResult(state, { payload }) {
      return {
        ...state,
        result: payload,
      };
    },
  },
};
