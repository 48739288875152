import request from '@/utils/request';

export async function fetchConfigurations(params) {
  return request(`/configurations`, {
    params,
  });
}

export async function createConfigurations(params) {
  return request(`/configurations`, {
    method: 'POST',
    data: params,
  });
}

export async function updateConfigurations(params) {
  const { id } = params;
  return request(`/configurations/${id}`, {
    method: 'PUT',
    data: params,
  });
}

export async function deleteConfigurations(params) {
  const { id } = params;
  return request(`/configurations/${id}`, {
    method: 'DELETE',
  });
}
