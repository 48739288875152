import request from '@/utils/request';

export async function materialIndex(params) {
  return request('/rsbox/iteract-scenes', { params });
}

export async function materialShow({ id }) {
  return request(`/rsbox/iteract-scenes/${id}`, {
    method: 'GET',
  });
}

export async function materialStore(params) {
  return request('/rsbox/iteract-scenes', {
    method: 'POST',
    data: params,
  });
}

export async function materialUpdate(params) {
  const { id, ...others } = params;
  return request(`/rsbox/iteract-scenes/${id}`, {
    method: 'PUT',
    data: others,
  });
}

// export async function teacherDestroy({ id }) {
//   return request(`/teachers/${id}`, {
//     method: 'DELETE',
//   });
// }

export async function materialOnline(params) {
  return request.post(`/rsbox/iteract-scenes/${params.id}/setOnline`, {
    params,
  });
}

export async function materialOffline(params) {
  return request.post(`/rsbox/iteract-scenes/${params.id}/setOffline`, {
    params,
  });
}

// 素材信息过滤
export async function materialNewScenesIndex(params) {
  return request('/rsbox/iteract-new-scenes', { params });
}

// 删除
export async function materialDelete(params) {
  const { id } = params;
  return request(`/rsbox/iteract-scenes/${id}`, {
    method: 'DELETE',
  });
}

export async function getSceneFileTypes(params) {
  return request('/rsbox/scene-file-types', { params });
}

export async function getSceneTypes(params) {
  return request('/rsbox/scene-types', { params });
}

export async function getHardwareModelsMap() {
  return request('/rsbox/getHardwareModelsMap');
}
