import { queryVodRoomDateChannelLogs } from '@/services/netChorusFee';

export default {
  namespace: 'NetChorusFee_VodRoomDateChannelLogs',

  state: {
    list: [],
    pagination: {},
  },

  effects: {
    *fetchVodRoomDateChannelLogs({ payload }, { call, put }) {
      const { status, data } = yield call(queryVodRoomDateChannelLogs, payload);
      if (status === 200) {
        yield put({
          type: 'save',
          payload: {
            list: data.data,
            pagination: {
              total: data.meta.total,
              pageSize: data.meta.per_page,
              current: data.meta.current_page,
            },
          },
        });
      }
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};
