import * as companyAppService from '@/services/companyApp';
import * as appManufacturer from '@/services/appManufacturer';
import * as appReviewService from '@/services/appReview';
import { queryUserList, saveUserRoles } from '@/services/user';

export default {
  namespace: 'CompanyAppManager_AppReview',
  state: {
    result: [],
    initTranceFormData: {
      use_type: null,
      targetKeys: [],
      tranceData: [],
    },
    manufacturerData: [],
    submitAppInstallTypeChangeResult: {},
    submitKmReviewOrder: {},
    appReviewOrders: [],
    AppReviewDetailResult: {},
    deviceAppResult: {},
    HardwareApplicationResult: {},
    IsLimitChangeResult: {},
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        result: payload,
      };
    },

    saveTranceData(state, { payload }) {
      return {
        ...state,
        initTranceFormData: payload,
      };
    },
    saveManufacturerData(state, { payload }) {
      return {
        ...state,
        manufacturerData: payload,
      };
    },
    saveAppInstallChangeResult(state, { payload }) {
      return {
        ...state,
        submitAppInstallTypeChangeResult: payload,
      };
    },
    saveAppSubmitOrder(state, { payload }) {
      return {
        ...state,
        submitKmReviewOrder: payload,
      };
    },
    saveAppOrderList(state, { payload }) {
      return {
        ...state,
        result: payload,
      };
    },
    getAppReviewDetailEN(state, { payload }) {
      return {
        ...state,
        AppReviewDetailResult: payload,
      };
    },
    getdeviceAppInfo(state, { payload }) {
      return {
        ...state,
        deviceAppResult: payload,
      };
    },
    HardwareApplicationList(state, { payload }) {
      return {
        ...state,
        HardwareApplicationResult: payload,
      };
    },
    updateIsLimitChangeResult(state, { payload }) {
      return {
        ...state,
        IsLimitChangeResult: payload,
      };
    },
  },

  effects: {
    *submitAppInstallTypeChange({ payload }, { call, put }) {
      // console.log('submitAppInstallTypeChange???');
      // console.log(payload);
      // console.log('submitAppInstallTypeChange!!!');
      const { data } = yield call(
        appReviewService.submitAppInstallTypeChange,
        payload
      );
      yield put({
        type: 'saveAppInstallChangeResult',
        payload: data,
      });
    },
    *submitKmReviewOrder({ payload }, { call, put }) {
      const { data } = yield call(
        appReviewService.submitKmReviewOrder,
        payload
      );
      yield put({
        type: 'saveAppSubmitOrder',
        payload: data,
      });
    },

    // 应用审核 初始化，查询按钮，数据获取
    *getOrderList({ payload }, { call, put }) {
      const { data } = yield call(
        appReviewService.getAppMarktReviewOrders,
        payload
      );
      yield put({
        type: 'saveAppOrderList',
        payload: {
          list: data.data,
          pagination: {
            total: data.meta.total,
            pageSize: parseInt(data.meta.per_page),
            current: data.meta.current_page,
          },
        },
      });
    },

    // 工单详情页面获取数据
    *getAppReviewDetail({ payload }, { call, put }) {
      const { data } = yield call(appReviewService.getAppReviewDetail, payload);
      yield put({
        type: 'getAppReviewDetailEN',
        payload: data,
      });
    },

    // 工单详情页面中根据硬件名称获取应用详情 回调函数直接把值返回给调用的地方
    *getDevice({ payload, callback }, { call, put }) {
      const { data } = yield call(appReviewService.getdeviceAppInfo, payload);
      yield put({
        type: 'getdeviceAppInfo',
        payload: data,
      });
    },

    // 硬件平台应用获取数据
    *getHardwareApplicationList({ payload, callback }, { call, put }) {
      const { data } = yield call(
        appReviewService.getHardwareApplicationList,
        payload
      );
      yield put({
        type: 'HardwareApplicationList',
        payload: data,
      });
    },

    // 硬件平台应用变更按钮，修改is_limit_change
    *updateIsLimitChange({ payload }, { call, put }) {
      console.log('+++b++++++');
      console.log(payload);
      const { data } = yield call(
        appReviewService.updateIsLimitChange,
        payload
      );
      yield put({
        type: 'updateIsLimitChangeResult',
        payload: data,
      });
      console.log('_________a______');
      console.log(data);
    },
  },
};
