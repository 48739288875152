import { queryDashboard } from '@/services/dashboard';
import moment from 'moment';

export default {
  namespace: 'DataOverview',

  state: {
    data: {},
  },

  effects: {
    *fetch({ payload }, { call, put }) {
      const { data } = yield call(queryDashboard, payload);
      yield put({
        type: 'save',
        payload: {
          list: data.data,
        },
      });
    },
  },

  reducers: {
    save(state, { payload }) {
      const { data } = state;
      return {
        ...state,
        data: { ...data, ...payload },
      };
    },
  },
};
