import {
  upgradePackageStatusToStartVerify,
  upgradePackageStatusToStopVerify,
  upgradePackageStatusToSuccessVerify,
  upgradePackageStatusToFailVerify,
  upgradePackageStatusToStartPilot,
  upgradePackageStatusToStopPilot,
  upgradePackageStatusToSuccessPilot,
  upgradePackageStatusToFailPilot,
  upgradePackageStatusToStartShelves,
  upgradePackageStatusToStopShelves,
} from '@/services/remoteUpgrade';

export default {
  namespace: 'RemoteUpgrade_updatePackageStatus',

  state: {},

  effects: {
    *upgradePackageStatusToStartVerify({ payload, callback }, { call }) {
      yield call(upgradePackageStatusToStartVerify, payload);

      callback();
    },

    *upgradePackageStatusToStopVerify({ payload, callback }, { call }) {
      yield call(upgradePackageStatusToStopVerify, payload);

      callback();
    },

    *upgradePackageStatusToSuccessVerify({ payload, callback }, { call }) {
      yield call(upgradePackageStatusToSuccessVerify, payload);

      callback();
    },

    *upgradePackageStatusToFailVerify({ payload, callback }, { call }) {
      yield call(upgradePackageStatusToFailVerify, payload);

      callback();
    },

    *upgradePackageStatusToStartPilot({ payload, callback }, { call }) {
      yield call(upgradePackageStatusToStartPilot, payload);

      callback();
    },

    *upgradePackageStatusToStopPilot({ payload, callback }, { call }) {
      yield call(upgradePackageStatusToStopPilot, payload);

      callback();
    },

    *upgradePackageStatusToSuccessPilot({ payload, callback }, { call }) {
      yield call(upgradePackageStatusToSuccessPilot, payload);

      callback();
    },

    *upgradePackageStatusToFailPilot({ payload, callback }, { call }) {
      yield call(upgradePackageStatusToFailPilot, payload);

      callback();
    },

    *upgradePackageStatusToStartShelves({ payload, callback }, { call }) {
      const { status } = yield call(
        upgradePackageStatusToStartShelves,
        payload
      );

      if (status === 201) {
        callback();
      }
    },

    *upgradePackageStatusToStopShelves({ payload, callback }, { call }) {
      const { status } = yield call(upgradePackageStatusToStopShelves, payload);

      if (status === 201) {
        callback();
      }
    },
  },

  reducers: {},
};
