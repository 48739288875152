import {
  getUpgradePackageStrategyBatchList,
  enableUpgradePackageStrategyBatch,
  disableUpgradePackageStrategyBatch,
} from '@/services/remoteUpgrade';

export default {
  namespace: 'RemoteUpgrade_upgradePackageStrategyBatch',

  state: {
    upgradePackagestragegyBatchList: [],
    response: '',
  },

  effects: {
    *fetchUpgradePackageStrategyBatchList({ payload }, { call, put }) {
      const { status, data } = yield call(
        getUpgradePackageStrategyBatchList,
        payload
      );

      if (status === 200) {
        yield put({
          type: 'saveList',
          payload: data,
        });
      }
    },
    *enableUpgradePackageStrategyBatch({ payload }, { call, put }) {
      const { status } = yield call(enableUpgradePackageStrategyBatch, payload);
      yield put({
        type: 'saveResponse',
        payload: status,
      });
    },
    *disableUpgradePackageStrategyBatch({ payload }, { call, put }) {
      const { status } = yield call(
        disableUpgradePackageStrategyBatch,
        payload
      );
      yield put({
        type: 'saveResponse',
        payload: status,
      });
    },
  },

  reducers: {
    saveList(state, { payload }) {
      return {
        ...state,
        upgradePackagestragegyBatchList: payload,
      };
    },
    saveResponse(state, { payload }) {
      return {
        ...state,
        response: payload,
      };
    },
  },
};
