import {
  defaultSongTypeIndex,
  defaultSongTypes,
  defaultSongTypeBind,
  defaultSongTypeBindDestroy,
} from '@/services/RSMarket/defaultSongTypes';

export default {
  namespace: 'ValueAddedCenter_RSMarket_defaultSongTypes',

  state: {
    indexData: {
      list: [],
      pagination: {},
    },
    showData: {},
    songTypes: [],
  },

  effects: {
    *index({ payload }, { call, put }) {
      const { status, data } = yield call(defaultSongTypeIndex, payload);

      if (status === 200) {
        yield put({
          type: 'saveIndexData',
          payload: {
            list: data.data,
            pagination: {
              total: data.total,
              pageSize: parseInt(data.per_page),
              current: parseInt(data.current_page),
            },
          },
        });
      }
    },

    *songTypes({ payload }, { call, put }) {
      const { status, data } = yield call(defaultSongTypes, payload);

      if (status === 200) {
        yield put({
          type: 'saveSongTypesData',
          payload: {
            data,
          },
        });
      }
    },

    *bind({ payload, callback }, { call, put }) {
      const { status } = yield call(defaultSongTypeBind, payload);

      if (status === 200) {
        if (callback) callback();
      }
    },

    *destroyBind({ payload, callback }, { call, put }) {
      const { status, data } = yield call(defaultSongTypeBindDestroy, payload);

      if (status === 204) {
        if (callback) callback();
      }
    },

    /*
    *show({ payload, callback }, { call, put }) {
      const { status, data } = yield call(materialShow, payload);

      if (status === 200) {
        yield put({
          type: 'saveShowData',
          payload: data.data,
        });
      }
      if (callback) callback();
    },


    *update({ payload, callback }, { call, put }) {
      const { status, data } = yield call(materialUpdate, payload);

      if (status === 200) {
        if (callback) callback();
      }
    },



    *setOnline({ payload, callback }, { call, put }) {
      const { status } = yield call(materialOnline, payload);

      if (status === 200) {
        if (callback) callback();
      }
    },

    *setOffline({ payload, callback }, { call, put }) {
      const { status } = yield call(materialOffline, payload);

      if (status === 200) {
        if (callback) callback();
      }
    },
    */
  },

  reducers: {
    saveIndexData(state, action) {
      return {
        ...state,
        indexData: action.payload,
      };
    },
    saveShowData(state, action) {
      return {
        ...state,
        showData: action.payload,
      };
    },
    saveSongTypesData(state, action) {
      return {
        ...state,
        songTypes: action.payload.data,
      };
    },
  },
};
