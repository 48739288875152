import * as companyresource from '@/services/companyresource';

export default {
  namespace: 'CompanyResource_PushTasks',
  state: {
    data: {},
  },
  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        data: payload,
      };
    },
  },
  effects: {
    // 素材推送记录
    *fetchPushLog({ payload }, { call, put }) {
      const { status, data } = yield call(
        companyresource.queryPushLog,
        payload
      );
      if (status === 200) {
        yield put({
          type: 'save',
          payload: {
            list: data.data,
            pagination: {
              total: data.total,
              pageSize: data.per_page,
              current: data.current_page,
            },
          },
        });
      }
    },
  },
};
