import request from '@/utils/request';

export async function getManufacturerList() {
  return request.get('/appMarket/manufacturer/list');
}

export async function setManufacturer(params) {
  return request('/appMarket/manufacturer/detail', {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function delManufacturer(params) {
  // console.log(params);
  // return false;
  const { id } = params;
  return request(`/appMarket/manufacturer/remove/${id}`, {
    method: 'DELETE',
  });
}
