import * as companyresource from '@/services/companyresource';

export default {
  namespace: 'CompanyResource_AgentChangeList',

  state: {
    indexData: {
      list: [],
      pagination: {},
    },
    showData: {},
    songs: [],
  },

  effects: {
    *index({ payload }, { call, put }) {
      const { status, data } = yield call(
        companyresource.queryAgentChangeList,
        payload
      );

      if (status === 200) {
        yield put({
          type: 'saveIndexData',
          payload: {
            list: data.data,
            pagination: {
              total: parseInt(data.total),
              pageSize: parseInt(data.per_page),
              current: parseInt(data.current_page),
            },
          },
        });
      }
    },

    *bind({ payload, callback }, { call, put }) {
      const { status } = yield call(companyresource.addSongBind, payload);

      if (status === 200) {
        if (callback) callback();
      }
    },

    *delSongBind({ payload, callback }, { call, put }) {
      const { status, data } = yield call(
        companyresource.delSongBindDestroy,
        payload
      );

      if (status === 204) {
        if (callback) callback();
      }
    },
  },

  reducers: {
    saveIndexData(state, action) {
      return {
        ...state,
        indexData: action.payload,
      };
    },
    saveShowData(state, action) {
      return {
        ...state,
        showData: action.payload,
      };
    },
  },
};
