import {
  appVersionStageValidatorPhone,
  appVersionStageVerificationCode,
} from '@/services/appBase';

export default {
  namespace: 'AppVersion_appVersionStageValidate',

  state: {
    data: {},
    code: {},
  },

  effects: {
    *validatorPhone({ payload, callback }, { call, put, select }) {
      const old = yield select(
        state => state.AppVersion_appVersionStageValidate.data
      );

      if (Object.keys(old).length > 0) {
        return;
      }

      const { status, data } = yield call(
        appVersionStageValidatorPhone,
        payload
      );

      if (status === 200) {
        yield put({
          type: 'save',
          payload: data,
        });
      }
      callback(data);
    },

    *sendValidatorCode({ payload, callback }, { call, put }) {
      const { status, data } = yield call(
        appVersionStageVerificationCode,
        payload
      );
      // const status = 200;
      // const data = {
      //   key: 'appVersionVerificationCode_RmApWy9AVlzCHAa',
      //   expired_at: '2019-07-02 15:03:58',
      // };
      if (status === 201) {
        yield put({
          type: 'saveCode',
          payload: data,
        });
      }

      callback(data);
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        data: payload,
      };
    },

    saveCode(state, { payload }) {
      return {
        ...state,
        code: payload,
      };
    },
  },
};
