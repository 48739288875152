import { message } from 'antd';
import { history } from 'umi';
import { generateLoginUrl } from '@/utils/utils';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';

// key => expired
// 根据 text 节流的 message 方法
const cache = new Map();
const showErrorMessage = text => {
  const expiredTime = cache.get(text);
  if (!expiredTime || expiredTime < +new Date()) {
    cache.set(text, +new Date() + 3000);
    message.error(text);
  }
};

export default request => {
  // 请求拦截
  request.interceptors.request.use(
    config =>
      // config.withCredentials = true // 需要跨域打开此配置
      config,
    error => Promise.reject(error)
  );
  // https://github.com/mzabriskie/axios#interceptors
  request.interceptors.response.use(
    response => {
      if (response.data?.errorcode === 1) {
        message.error(response.data?.errormessage);
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject(response);
      }
      return response;
    },
    /**
     * This is a central point to handle all
     * error messages generated by HTTP
     * requests
     */
    error => {
      if (!error.response) {
        showErrorMessage(error.message || '请求异常!');
        history.push('/exception/500');
        return {};
      }
      switch (error.response.status) {
        case 422: {
          if (isString(error.response.data)) {
            showErrorMessage(error.response.data);
            break;
          }

          const data = error.response.data.errors;
          let content = '';
          if (data && isObject(data)) {
            Object.keys(data).forEach(key => {
              const value = data[key];

              [content] = value;
            });

            showErrorMessage(content);

            break;
          }

          if (error.response.data.errormessage) {
            showErrorMessage(error.response.data.errormessage);
          }

          break;
        }
        case 429: {
          showErrorMessage('你请求过于频繁~！');
          history.push('/exception/429');
          break;
        }
        case 403: {
          // showErrorMessage(error.response.data.message || '您没有此操作权限！');
          history.push('/exception/403');
          break;
        }
        case 404: {
          message.error('请求的接口不存在!');
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject(error?.response);
        }
        case 401: {
          // cookie.remove(userTokenCookie, { domain: userTokenCookieDomain });
          // cookie.remove(userPlatformCookie, { domain: userTokenCookieDomain });
          // setPermissionList([]);
          // 跳到登录页面
          window.location.href = generateLoginUrl(window.location.origin);
          // console.log(generateLoginUrl());
          break;
        }
        case 400: {
          // 兼容之前的代码错误为 err_no 需要进入 then 的判断
          if (error?.response?.data?.errormessage) {
            message.error(error.response.data.errormessage);
            // eslint-disable-next-line prefer-promise-reject-errors
            return Promise.reject(error?.response);
          }
          break;
        }
        case 500:
        case 501:
        case 503:
          if (error?.response?.data?.errorcode === 0) {
            message.error(error.response.data.errormessage);
          }
          showErrorMessage('服务器出了点小问题~！');
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject(error?.response);
        // history.push('/exception/500');
        default:
          break;
      }

      return error.response;
    }
  );
};
