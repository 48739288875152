import { getAppVersionList, checkVersionAdapter } from '@/services/companyApp';

export default {
  namespace: 'CompanyAppManager_subAppList',

  state: {
    data: {},
    is_adapter: false,
  },

  effects: {
    *fetch({ payload }, { call, put }) {
      const {
        data: { data, meta },
      } = yield call(getAppVersionList, payload);

      data.forEach(item => {
        item.device_fireware_support =
          item.device_fireware_support instanceof Object
            ? Object.keys(item.device_fireware_support).map(innerItem => ({
                [innerItem]: item.device_fireware_support[innerItem],
              }))
            : [];

        item.device_main_software_support =
          item.device_main_software_support instanceof Object
            ? Object.keys(item.device_main_software_support).map(innerItem => ({
                [innerItem]: item.device_main_software_support[innerItem],
              }))
            : [];
      });

      yield put({
        type: 'save',
        payload: {
          list: data,
          pagination: {
            total: meta.total,
            pageSize: meta.per_page,
            current: meta.current_page,
          },
        },
      });
    },
    *checkVersionAdapter({ payload }, { call, put }) {
      // const { data } = { payload };
      const { data } = yield call(checkVersionAdapter, payload);
      // console.log(data);
      yield put({
        type: 'setIsAdapter',
        payload: {
          data,
        },
      });
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        data: payload,
      };
    },
    setIsAdapter(state, { payload }) {
      return {
        ...state,
        is_adapter: payload.data.is_adapter,
      };
    },
  },
};
