import { queryDashboard } from '@/services/dashboard';
import moment from 'moment';

export default {
  namespace: 'dashboard',

  state: {
    data: {},
  },

  effects: {
    *fetch({ payload }, { call, put, select }) {
      const old = (yield select(state => state.dashboard.data))[payload.code];

      if (
        old !== undefined &&
        old !== null &&
        moment().diff(old.created_at, 'days') === 0
      ) {
        return;
      }

      const { status, data } = yield call(queryDashboard, payload);
      const code = payload.code || '100000';

      if (status === 200) {
        yield put({
          type: 'save',
          payload: {
            [code]: data.data,
          },
        });
      }
    },
  },

  reducers: {
    save(state, { payload }) {
      const { data } = state;
      return {
        ...state,
        data: { ...data, ...payload },
      };
    },
  },
};
