import {
  saveSingerImgUpload,
  getOSSConfig,
  saveSingersAvatarUpload,
} from '@/services/MusicLibrary/musiclibrary';

export default {
  namespace: 'Singer_Upload',

  state: {
    result: {},
    avatar: {},
  },

  effects: {
    *SingerImgUpload({ payload }, { call, put }) {
      const { status } = yield call(saveSingerImgUpload, payload);
      if (status === 201) {
        yield put({
          type: 'saveResult',
          payload: status,
        });
      }
    },
    *fetchOSSConfig({ payload, callback }, { call, put }) {
      const { status, data } = yield call(getOSSConfig, payload);
      if (status === 200) {
        callback(data);
      }
    },
    *SingersAvatarUpload({ payload, callback }, { call, put }) {
      const { status, data } = yield call(saveSingersAvatarUpload, payload);
      if (status === 201) {
        callback(data);
      }
    },
  },

  reducers: {
    saveResult(state, { payload }) {
      return {
        ...state,
        result: payload,
      };
    },
    saveOSSConfig(state, { payload }) {
      return {
        ...state,
        ossConfig: payload,
      };
    },
    saveAvatar(state, { payload }) {
      return {
        ...state,
        avatar: payload,
      };
    },
  },
};
