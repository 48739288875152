import request from '@/utils/request';

export async function getUpgradePackagesTargetTypes() {
  return request('/upgrade-package-target-types');
}

export async function createUpgradePackages(params) {
  return request(`/upgrade-packages`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function updateUpgradePackages(params) {
  const { id } = params;
  return request(`/upgrade-packages/${id}`, {
    method: 'PUT',
    data: {
      ...params,
    },
  });
}

export async function deleteUpgradePackages(params) {
  const { id } = params;
  return request(`/upgrade-packages/${id}`, {
    method: 'DELETE',
  });
}

export async function getUpgradePackageDescription(params) {
  const { upgrade_package_id } = params;
  return request(`/upgrade-packages/${upgrade_package_id}/description`);
}

export async function getUpgradePackagesVerify(params) {
  return request('/upgrade-packages/verify', {
    params,
  });
}

export async function createUpgradePackagesTargetTypes(params) {
  return request(`/upgrade-package-target-types`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function editUpgradePackagesTargetTypes(params) {
  const { id } = params;
  delete params.id;
  return request(`/upgrade-package-target-types/${id}`, {
    method: 'PUT',
    data: {
      ...params,
    },
  });
}

export async function deleteUpgraadePackagesTargetTypes(params) {
  const { id } = params;
  delete params.id;
  return request(`/upgrade-package-target-types/${id}`, {
    method: 'DELETE',
    data: {
      ...params,
    },
  });
}

/** 升级黑名单场所管理 */
// 黑名单场所列表
export async function getUpgraddeBlackListCompanies(params) {
  return request('/upgrade-blacklist-companies', {
    params,
  });
}
// 添加黑名单
export async function createUpgradeBlackListCompanies(params) {
  return request(`/upgrade-blacklist-companies`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}
// 删除黑名单
export async function deleteUpgradeBlackListCompanies(params) {
  return request(`/upgrade-blacklist-companies`, {
    method: 'DELETE',
    data: {
      ...params,
    },
  });
}
// 获取可添加黑名单场所列表
export async function getCanAddBlackListCompanies(params) {
  return request(`/upgrade-blacklist-companies/can-add-companies`, {
    params,
  });
}

// 获取可添加测试验证场所列表
export async function getVerifyTestCompanies(params) {
  return request(`/verify-test-companies`, {
    params,
  });
}

// 添加测试验证场所
export async function createVerifyTestCompanies(params) {
  return request(`/verify-test-companies`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}
// 删除测试验证场所
export async function deleteVerifyTestCompanies(params) {
  return request(`/verify-test-companies`, {
    method: 'DELETE',
    data: {
      ...params,
    },
  });
}

// 获取可添加黑名单场所列表
export async function getCanAddVerifyTestCompanies(params) {
  return request(`/verify-test-companies/can-add-companies`, {
    params,
  });
}

export async function getUpgradePackagesPilot(params) {
  return request('/upgrade-packages/pilot', {
    params,
  });
}

export async function getUpgradePackagesShelves(params) {
  return request('/upgrade-packages/shelves', {
    params,
  });
}

/** 状态修改 */
export async function upgradePackageStatusToStartVerify({ id }) {
  return request.post(`/upgrade-package-status/${id}/start-verify`);
}

export async function upgradePackageStatusToStopVerify({ id }) {
  return request.post(`/upgrade-package-status/${id}/stop-verify`);
}

export async function upgradePackageStatusToSuccessVerify({ id }) {
  return request.post(`/upgrade-package-status/${id}/success-verify`);
}

export async function upgradePackageStatusToFailVerify({ id }) {
  return request.post(`/upgrade-package-status/${id}/fail-verify`);
}

export async function upgradePackageStatusToStartPilot({ id }) {
  return request.post(`/upgrade-package-status/${id}/start-pilot`);
}

export async function upgradePackageStatusToStopPilot({ id }) {
  return request.post(`/upgrade-package-status/${id}/stop-pilot`);
}

export async function upgradePackageStatusToSuccessPilot({ id }) {
  return request.post(`/upgrade-package-status/${id}/success-pilot`);
}

export async function upgradePackageStatusToFailPilot({ id }) {
  return request.post(`/upgrade-package-status/${id}/fail-pilot`);
}

export async function upgradePackageStatusToStartShelves({ id, ...res }) {
  return request.post(`/upgrade-package-status/${id}/start-shelves`, {
    ...res,
  });
}

export async function upgradePackageStatusToStopShelves({ id, ...res }) {
  return request.post(`/upgrade-package-status/${id}/stop-shelves`, {
    ...res,
  });
}

export async function upgradePackageShelvesStageValidatorPhone() {
  return request.get(`/upgrade-package-shelves-stage-validator-phone`);
}

export async function upgradePackageShelvesStageVerificationCode() {
  return request.post(`/upgrade-package-shelves-stage-verification-code`);
}

export async function getVerifyCompaniesList(params) {
  const { upgrade_package_id } = params;
  params.include = 'company';
  return request(`/upgrade-package/${upgrade_package_id}/verify-jobs`, {
    params,
  });
}

export async function getPilotCompaniesList(params) {
  const { upgrade_package_id } = params;
  params.include = 'company';
  return request(`/upgrade-package/${upgrade_package_id}/pilot-jobs`, {
    params,
  });
}

export async function getUpgradePackageInfo(params) {
  const { upgrade_package_id } = params;
  return request(`/upgrade-packages/${upgrade_package_id}`);
}

export async function getUpgradePackageVerifyStatusProcess(params) {
  const { upgrade_package_id } = params;
  return request(
    `/upgrade-package-status-process/${upgrade_package_id}/verify`
  );
}

export async function getUpgradePackagePilotStatusProcess(params) {
  const { upgrade_package_id } = params;
  return request(`/upgrade-package-status-process/${upgrade_package_id}/pilot`);
}

export async function getUpgradePackageShelvesStatusProcess(params) {
  const { upgrade_package_id } = params;
  return request(
    `/upgrade-package-status-process/${upgrade_package_id}/shelves`
  );
}

export async function createVerifyCompanies(params) {
  const { upgrade_package_id } = params;
  delete params.upgrade_package_id;
  return request(
    `/upgrade-package/${upgrade_package_id}/can-bind-companies/verify`,
    {
      method: 'POST',
      data: {
        ...params,
      },
    }
  );
}

export async function createPilotCompanies(params) {
  const { upgrade_package_id } = params;
  delete params.upgrade_package_id;
  return request(
    `/upgrade-package/${upgrade_package_id}/can-bind-companies/pilot`,
    {
      method: 'POST',
      data: {
        ...params,
      },
    }
  );
}

export async function deleteUpgradePackageJob(params) {
  const { job_id } = params;
  delete params.job_id;
  return request(`/upgrade-package-job/${job_id}`, {
    method: 'DELETE',
    data: {
      ...params,
    },
  });
}

export async function getUpgradePackageVerifyCanBindCompanies(params) {
  const { upgrade_package_id } = params;
  delete params.upgrade_package_id;
  return request(
    `/upgrade-package/${upgrade_package_id}/can-bind-companies/verify`,
    {
      params,
    }
  );
}

export async function getUpgradePackagPilotCanBindCompanies(params) {
  const { upgrade_package_id } = params;
  delete params.upgrade_package_id;
  return request(
    `/upgrade-package/${upgrade_package_id}/can-bind-companies/pilot`,
    {
      params,
    }
  );
}

export async function getJobsStatusLogs(params) {
  const { job_id } = params;
  return request(`/upgrade-package-job/${job_id}/logs`);
}

export async function getDeviceUpgradeLogs(params) {
  const { job_id } = params;
  return request(`/upgrade-package-job/${job_id}/device-update-status-logs`);
}

export async function getStragegyList(params) {
  return request(`/distribution-strategy`, {
    params,
  });
}

export async function createStragegy(params) {
  return request(`/distribution-strategy`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function editStragegy(params) {
  const { distribution_strategy_id } = params;
  delete params.distribution_strategy_id;
  return request(`/distribution-strategy/${distribution_strategy_id}`, {
    method: 'PUT',
    data: {
      ...params,
    },
  });
}

export async function deleteStragegy(params) {
  const { distribution_strategy_id } = params;
  delete params.distribution_strategy_id;
  return request(`/distribution-strategy/${distribution_strategy_id}`, {
    method: 'DELETE',
    data: {
      ...params,
    },
  });
}

export async function getStrategyInfo(params) {
  const { distribution_strategy_id } = params;
  delete params.distribution_strategy_id;
  return request(`/distribution-strategy/${distribution_strategy_id}`, {
    params,
  });
}

export async function getDistributionStragegyCompanies(params) {
  return request(`/distribution-strategy-can-bind-companies`, {
    params,
  });
}

export async function getUpgradePackageStrategyList(params) {
  const { upgrade_package_id } = params;
  delete params.upgrade_package_id;
  return request(`/upgrade-package/${upgrade_package_id}/strategies`, {
    params,
  });
}

export async function createUpgradePackageStrategy(params) {
  const { upgrade_package_id, distribution_strategy_id } = params;
  delete params.upgrade_package_id;
  delete params.distribution_strategy_id;
  return request(
    `/upgrade-package/${upgrade_package_id}/strategy/${distribution_strategy_id}`,
    {
      method: 'POST',
      data: {
        ...params,
      },
    }
  );
}

export async function enableUpgradePackageStrategy(params) {
  const { upgrade_package_strategy_id } = params;
  delete params.upgrade_package_strategy_id;
  return request(
    `/upgrade-package-strategy/${upgrade_package_strategy_id}/enable`,
    {
      method: 'POST',
      data: {
        ...params,
      },
    }
  );
}

export async function disableUpgradePackageStrategy(params) {
  const { upgrade_package_strategy_id } = params;
  delete params.upgrade_package_strategy_id;
  return request(
    `/upgrade-package-strategy/${upgrade_package_strategy_id}/disable`,
    {
      method: 'DELETE',
      data: {
        ...params,
      },
    }
  );
}

export async function enableUpgradePackageStrategyAgain(params) {
  const { upgrade_package_strategy_id } = params;
  delete params.upgrade_package_strategy_id;
  return request(
    `/upgrade-package-strategy/${upgrade_package_strategy_id}/enableAgain`,
    {
      method: 'POST',
      data: {
        ...params,
      },
    }
  );
}

export async function getUpgradePackageStrategyBatchList(params) {
  const { upgrade_package_strategy_id } = params;
  delete params.upgrade_package_strategy_id;
  return request(
    `/upgrade-package-strategy/${upgrade_package_strategy_id}/batches`,
    {
      params,
    }
  );
}

export async function enableUpgradePackageStrategyBatch(params) {
  const { upgrade_package_strategy_batch_id } = params;
  delete params.upgrade_package_strategy_batch_id;
  return request(
    `/upgrade-package-batch-status/${upgrade_package_strategy_batch_id}/enable`,
    {
      method: 'POST',
      data: {
        ...params,
      },
    }
  );
}

export async function disableUpgradePackageStrategyBatch(params) {
  const { upgrade_package_strategy_batch_id } = params;
  delete params.upgrade_package_strategy_batch_id;
  return request(
    `/upgrade-package-batch-status/${upgrade_package_strategy_batch_id}/disable`,
    {
      method: 'DELETE',
      data: {
        ...params,
      },
    }
  );
}

export async function getUpgradePackageBatchJobList(params) {
  const { upgrade_package_strategy_batch_id } = params;
  delete params.upgrade_package_strategy_batch_id;
  params.include = 'company';
  return request(
    `/upgrade-package-batch/${upgrade_package_strategy_batch_id}/jobs`,
    {
      params,
    }
  );
}

export async function getUpgradePackageShelvesJobList(params) {
  const { upgrade_package_id } = params;
  delete params.upgrade_package_id;
  params.include = 'company';
  return request(`/upgrade-package/${upgrade_package_id}/shelves-jobs`, {
    params,
  });
}

export async function deleteUpgradePackageBatchJob(params) {
  const { upgrade_package_strategy_batch_id, job_id } = params;
  delete params.upgrade_package_strategy_batch_id;
  delete params.job_id;
  return request(
    `/upgrade-package-batch/${upgrade_package_strategy_batch_id}/job/${job_id}`,
    {
      method: 'DELETE',
      data: {
        ...params,
      },
    }
  );
}

export async function resetUpgradePackageJob(params) {
  const { job_id } = params;
  delete params.job_id;
  return request(`/upgrade-package-job/${job_id}/reset`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function getShelveSpecificCompanyJobs(params) {
  const { upgrade_package_id } = params;
  delete params.upgrade_package_id;
  params.include = 'company';
  return request(
    `/upgrade-package/${upgrade_package_id}/shelves-specific-company-jobs`,
    {
      params,
    }
  );
}

export async function getShelveSpecificCanBindCompanies(params) {
  const { upgrade_package_id } = params;
  delete params.upgrade_package_id;
  return request(
    `/upgrade-package/${upgrade_package_id}/can-bind-companies/shelves`,
    {
      params,
    }
  );
}

export async function creategShelveSpecificCanBindCompanyJob(params) {
  const { upgrade_package_id } = params;
  delete params.upgrade_package_id;
  return request(
    `/upgrade-package/${upgrade_package_id}/can-bind-companies/shelves`,
    {
      method: 'POST',
      data: {
        ...params,
      },
    }
  );
}

// export async function getManufacturerList() {
//   return request.get('/appMarket/manufacturer/list');
// }

// export async function setManufacturer(params) {
//   return request('/appMarket/manufacturer/detail', {
//     method: 'POST',
//     data: {
//       ...params,
//     },
//   });
// }

// export async function delManufacturer(params) {
//   // console.log(params);
//   // return false;
//   const { id } = params;
//   return request(`/appMarket/manufacturer/remove/${id}`, {
//     method: 'DELETE',
//   });
// }
