import { getUpgradePackageShelvesStatusProcess } from '@/services/remoteUpgrade';

export default {
  namespace: 'RemoteUpgrade_upgradePackageProcess',

  state: {
    statusProcess: {},
  },

  effects: {
    *fetchPackageStatusProcess({ payload }, { call, put }) {
      const { data } = yield call(
        getUpgradePackageShelvesStatusProcess,
        payload
      );

      yield put({
        type: 'saveStatusProcess',
        payload: data,
      });
    },
  },

  reducers: {
    saveStatusProcess(state, { payload }) {
      return {
        ...state,
        statusProcess: payload,
      };
    },
  },
};
