import {
  queryAppVersionInstallLogs,
  queryAppStatusRelateCompanies,
  queryAppInstallCompanies,
} from '@/services/appBase';

export default {
  namespace: 'CompanyAppManager_versionLog',

  state: {
    data: {},
  },

  effects: {
    *fetchAppVersionInstallLogs({ payload }, { call, put }) {
      const {
        data: { data, meta },
      } = yield call(queryAppVersionInstallLogs, payload);

      yield put({
        type: 'save',
        payload: {
          list: data,
          pagination: {
            total: meta.total,
            pageSize: meta.per_page,
            current: meta.current_page,
          },
        },
      });
    },
    *fetchAppStatusChangeRelateCompanies({ payload }, { call, put }) {
      const {
        data: { data, meta },
      } = yield call(queryAppStatusRelateCompanies, payload);

      yield put({
        type: 'save',
        payload: {
          list: data,
          pagination: {
            total: meta.total,
            pageSize: meta.per_page,
            current: meta.current_page,
          },
        },
      });
    },
    // 升级商家列表
    *fetchAppInstallCompanies({ payload }, { call, put }) {
      const {
        data: { data, meta },
      } = yield call(queryAppInstallCompanies, payload);

      yield put({
        type: 'save',
        payload: {
          list: data,
          pagination: {
            total: meta.total,
            pageSize: meta.per_page,
            current: meta.current_page,
          },
        },
      });
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        data: payload,
      };
    },
  },
};
