import request from '@/utils/request';

export function queryRunEnvs(params) {
  return request(`/appMarket/AppRunEnvs`, { method: 'GET', params });
}

export async function createAppRunEnv(params) {
  return request(`/appMarket/AppRunEnvs`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function updateAppRunEnv(params) {
  const { id } = params;
  return request(`/appMarket/AppRunEnvs/${id}`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export function fetchAppRunEnv($params) {
  const { id } = $params;
  return request(`/appMarket/AppRunEnvs/${id}`, { method: 'GET' });
}

export async function deleteAppRunEnv(params) {
  const { id } = params;
  return request(`/appMarket/AppRunEnvs/${id}`, {
    method: 'DELETE',
  });
}
