import * as companyresource from '@/services/ITMarket/companyresource';

export default {
  namespace: 'ITCompanyResource_CompanyResource',
  state: {
    data: {},
  },
  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        data: payload,
      };
    },
  },
  effects: {
    *fetchCompanyResource({ payload }, { call, put }) {
      const { status, data } = yield call(
        companyresource.queryCompanyResource,
        payload
      );
      if (status === 200) {
        yield put({
          type: 'save',
          payload: {
            list: data.data,
            pagination: {
              total: data.total,
              pageSize: data.per_page,
              current: data.current_page,
            },
          },
        });
      }
    },

    *post_ableSceneByCompany({ payload, callback }, { call, put }) {
      const { status } = yield call(
        companyresource.post_ableSceneByCompany,
        payload
      );

      if (status === 200) {
        if (callback) callback();
      }
    },
  },
};
