import request from '@/utils/request';

export async function getTemplateInfo(payload) {
  const { message_code } = payload;
  return request.get(`/messageTemplates/${message_code}`);
}

export async function getTemplates(params) {
  return request('/messageTemplates', { params });
}

export async function createTempplate(params) {
  return request(`/messageTemplates`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function updateTemplate(params) {
  const { id } = params;
  return request(`/messageTemplates/${id}`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function deleteTemplate(params) {
  const { id } = params;
  return request.delete(`/messageTemplates/${id}`);
}

export async function fetchRoles() {
  return request.get('/msgtemplate/getRoles');
}

export async function fetchMessages(params) {
  return request('/messages', { params });
}

export async function setRead(params) {
  const { message_id } = params;
  return request(`/messages/setRead/${message_id}`, {
    method: 'POST',
  });
}

export async function setAllRead() {
  return request(`/messages/setAllRead`, {
    method: 'POST',
  });
}

export async function queryNotices() {
  return request.get('/message/notices');
}

export async function getWsConfig() {
  return request.get('/message/getWsConfig');
}
