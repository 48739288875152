import {
  materialIndex,
  materialShow,
  materialStore,
  materialUpdate,
  materialDestroy,
  materialOnline,
  materialOffline,
  materialUseable,
  materialDisable,
  materialDelete,
  materialNewScenesIndex,
} from '@/services/RSMarket/material';
import { getSceneTypes } from '@/services/RHMarket/material';
import { versionAdapterInfo } from '@/services/companyApp';
import { get } from 'lodash';

export default {
  namespace: 'ValueAddedCenter_RSMarket_materialMgr',

  state: {
    indexData: {
      list: [],
      pagination: {},
    },
    showData: {},
    hardwareSupportedData: {},
    rsboxAdapterInfo: {
      hardwareType: [],
      softwareSupport: [],
    },
    indexNewData: {
      list: [],
      pagination: {},
    },
    sceneTypes: {},
  },

  effects: {
    *index({ payload }, { call, put }) {
      const { status, data } = yield call(materialIndex, payload);

      if (status === 200) {
        yield put({
          type: 'saveIndexData',
          payload: {
            list: data.data,
            pagination: {
              total: data.total,
              pageSize: parseInt(data.per_page),
              current: parseInt(data.current_page),
              // total: data.meta.total,
              // pageSize: parseInt(data.meta.per_page),
              // current: parseInt(data.meta.current_page),
            },
          },
        });
      }
    },

    *indexNewScenes({ payload }, { call, put }) {
      const { status, data } = yield call(materialNewScenesIndex, payload);

      if (status === 200) {
        yield put({
          type: 'saveIndexNewScenesData',
          payload: {
            list: data.data,
            pagination: {
              total: data.total,
              pageSize: parseInt(data.per_page),
              current: parseInt(data.current_page),
              // total: data.meta.total,
              // pageSize: parseInt(data.meta.per_page),
              // current: parseInt(data.meta.current_page),
            },
          },
        });
      }
    },

    *show({ payload, callback }, { call, put }) {
      const { status, data } = yield call(materialShow, payload);

      if (status === 200) {
        yield put({
          type: 'saveShowData',
          payload: data.data,
        });
        if (callback) callback(data.data);
      }
    },

    *store({ payload, callback }, { call, put }) {
      const { status } = yield call(materialStore, payload);

      if (status === 201) {
        if (callback) callback();
      }
    },

    *update({ payload, callback }, { call, put }) {
      const { status, data } = yield call(materialUpdate, payload);

      if (status === 201) {
        if (callback) callback();
      }
    },

    // *destroy({ payload, callback }, { call, put }) {
    //   const { status, data } = yield call(materialDestroy, payload);

    //   if (status === 204) {
    //     if (callback) callback();
    //   }
    // },

    *setOnline({ payload, callback }, { call, put }) {
      const { status } = yield call(materialOnline, payload);

      if (status === 200) {
        if (callback) callback();
      }
    },

    *setOffline({ payload, callback }, { call, put }) {
      const { status } = yield call(materialOffline, payload);

      if (status === 200) {
        if (callback) callback();
      }
    },

    // 启用
    *setUseable({ payload, callback }, { call, put }) {
      const { status } = yield call(materialUseable, payload);

      if (status === 200) {
        if (callback) callback();
      }
    },

    // 禁用
    *setDisable({ payload, callback }, { call, put }) {
      const { status } = yield call(materialDisable, payload);

      if (status === 200) {
        if (callback) callback();
      }
    },

    *rsboxAdapterInfo({ payload = {}, callback }, { call, put }) {
      payload.hardware = 'rsbox';
      const { status, data } = yield call(versionAdapterInfo, payload);

      if (status === 200) {
        const need = {
          hardwareType: get(data, 'device_model_support', []),
          softwareSupport: get(
            data,
            'device_main_software_support.rscreen',
            []
          ),
        };
        yield put({
          type: 'saveRsboxAdapterInfo',
          payload: need,
        });
      }
      if (callback) callback();
    },

    // 删除
    *delete({ payload, callback }, { call }) {
      const { status } = yield call(materialDelete, payload);

      if (status === 204) {
        callback();
      }
    },
    *sceneTypes({ payload }, { call, put }) {
      const { status, data } = yield call(getSceneTypes, payload);

      if (status === 200) {
        yield put({
          type: 'saveSceneTypeDatas',
          payload: data.data,
        });
      }
    },
  },

  reducers: {
    saveIndexData(state, action) {
      return {
        ...state,
        indexData: action.payload,
      };
    },
    saveIndexNewScenesData(state, action) {
      return {
        ...state,
        indexNewData: action.payload,
      };
    },
    saveShowData(state, action) {
      return {
        ...state,
        showData: action.payload,
      };
    },
    saveRsboxAdapterInfo(state, action) {
      return {
        ...state,
        rsboxAdapterInfo: action.payload,
      };
    },
    saveSceneTypeDatas(state, action) {
      return {
        ...state,
        sceneTypes: action.payload,
      };
    },
  },
};
