import {
  queryRoleList,
  queryUserList,
  queryLampList,
  createLamp,
  createAgent,
  createRole,
  deleteRole,
  editRole,
  bindRole,
  editAgent,
  unbindRole,
  allocateLamp,
  allocateAgent,
  editLamp,
  createSecondAgent,
  editSecondAgent,
  queryCompanyRoleList,
  queryRoleType,
} from '@/services/lampControl';

export default {
  namespace: 'lampManage',
  state: {},
  effects: {
    *queryRoleType(_, { call, put }) {
      const { data } = yield call(queryRoleType);
      return data;
    },
    *queryCompanyRoleList({ payload }, { call, put }) {
      const { data } = yield call(queryCompanyRoleList, payload);
      return data;
    },
    *queryLampList({ payload }, { call, put }) {
      const { data } = yield call(queryLampList, payload);
      return data;
    },
    *queryRoleList({ payload }, { call, put }) {
      const { data } = yield call(queryRoleList, payload);
      return data;
    },
    *queryUserList({ payload }, { call, put }) {
      const { data } = yield call(queryUserList, payload);
      return data;
    },
    *createLamp({ payload }, { call, put }) {
      const { data } = yield call(createLamp, payload);
      return data;
    },
    *createAgent({ payload }, { call, put }) {
      const { data } = yield call(createAgent, payload);
      return data;
    },
    *createRole({ payload }, { call, put }) {
      const { data } = yield call(createRole, payload);
      return data;
    },
    *deleteRole({ payload }, { call, put }) {
      const { data } = yield call(deleteRole, payload);
      return data;
    },
    *editRole({ payload }, { call, put }) {
      const { data } = yield call(editRole, payload);
      return data;
    },
    *editLamp({ payload }, { call }) {
      const { data } = yield call(editLamp, payload);
      return data;
    },
    *editAgent({ payload }, { call }) {
      const { data } = yield call(editAgent, payload);
      return data;
    },
    *bindRole({ payload }, { call }) {
      const { data } = yield call(bindRole, payload);
      return data;
    },
    *unbindRole({ payload }, { call }) {
      const { data } = yield call(unbindRole, payload);
      return data;
    },
    *allocateLamp({ payload }, { call }) {
      const { data } = yield call(allocateLamp, payload);
      return data;
    },
    *allocateAgent({ payload }, { call }) {
      const { data } = yield call(allocateAgent, payload);
      return data;
    },
    *createSecondAgent({ payload }, { call }) {
      const { data } = yield call(createSecondAgent, payload);
      return data;
    },
    *editSecondAgent({ payload }, { call }) {
      const { data } = yield call(editSecondAgent, payload);
      return data;
    },
  },
};
