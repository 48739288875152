import { getDeviceActions } from '@/services/api';
import { initCanUseOperation } from '@/core/can-use-operations';

export default {
  namespace: 'devicePermission',

  state: {},

  effects: {
    *fetch({ payload }, { call, put }) {
      const response = yield call(getDeviceActions, payload);
      // 初始化设备操作权限系统
      initCanUseOperation(response.data.data ?? []);
    },
  },
};
