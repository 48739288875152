/**
 * 灯库 工程相关
 */
import {
  editLampEffect,
  deleteLampEffect,
  openLampEffect,
  lampEffectList,
  editLampProjectEffect,
  deleteLampProjectEffect,
  openLampProjectEffect,
  lampProjectEffectList,
  createRoomType,
  editRoomType,
  deleteRoomType,
  roomTypeList,
  editStoreLampEffect,
  auditStoreLampEffect,
  deleteStoreLampEffect,
  storeLampEffectList,
  editStoreProjectEffect,
  auditStoreProjectEffect,
  deleteStoreProjectEffect,
  storeLampProjectList,
  storeLightList,
  uploadProjectCad,
  uploadProjectMov,
  storeAuditLightList,
  storeAuditLampList,
  storeAuditProjectList,
  queryLampEffectListCondition,
  queryLampProjectEffectListCondition,
  auditLampEffect,
  auditProjectEffect,
  lampEffectDownload,
  storeLampEffectDownload,
  storeSaleProjectList,
  storeSaleLampList,
} from '@/services/lampControl';

const whitelist = ['role_type', 'company_id'];

function camelCase(string) {
  if (whitelist.includes(string)) return string;
  return string.replace(/_([a-z])/g, (all, letter) => letter.toUpperCase());
}

function payloadCamelCase(obj) {
  const ret = {};
  Object.entries(obj).forEach(([k, v]) => {
    ret[camelCase(k)] = v;
  });
  return ret;
}

/**
 * 审核页面适配器,将后台返回的数据变为需要的格式
 * @param {*} data
 */
function LampProjectListAdapetr(data) {
  const list = data.data;
  data.data = list
    .map(item => {
      const ret =
        item.project_effect && Object.keys(item.project_effect).length > 0
          ? item.project_effect
          : undefined;
      if (!ret) return;
      if (
        item.storeProjectEffect &&
        Object.keys(item.storeProjectEffect).length > 0 &&
        item?.status?.name === 'UNCONFIR'
      ) {
        // ret.children = [{ ...item.storeProjectEffect, id: Math.random() }]; // 关闭对比
      }
      return { ...ret, ...item };
    })
    .filter(item => item);
  return data;
}

function LampEffectListAdapetr(data) {
  const list = data.data;
  data.data = list
    .map(item => {
      const ret =
        item.lamp_effect && Object.keys(item.lamp_effect).length > 0
          ? item.lamp_effect
          : undefined;
      if (!ret) return;
      if (
        item.storeLampEffect &&
        Object.keys(item.storeLampEffect).length > 0 &&
        item?.status?.name === 'UNCONFIR'
      ) {
        // ret.children = [{ ...item.storeLampEffect, id: Math.random() }]; // 关闭对比
      }
      return { ...ret, ...item };
    })
    .filter(item => item);
  return data;
}

export default {
  namespace: 'lampEffect',
  state: {
    lampManufacturerList: [], // 灯光厂家列表
    lampTypeList: [], // 灯型号
    lampSortList: [], // 灯类型
    lampAisleList: [], // 灯型号
    effectListCondition: {},
    projectEffectListCondition: {},
    roomTypeList: [],
  },
  effects: {
    *queryLampEffectListCondition({ payload }, { call, put }) {
      const { data } = yield call(
        queryLampEffectListCondition,
        payloadCamelCase(payload)
      );
      yield put({
        type: 'save',
        payload: { effectListCondition: data?.data },
      });
    },
    *queryLampProjectEffectListCondition({ payload }, { call, put }) {
      const { data } = yield call(
        queryLampProjectEffectListCondition,
        payloadCamelCase(payload)
      );
      yield put({
        type: 'save',
        payload: { projectEffectListCondition: data?.data },
      });
    },
    *roomTypeList({ payload }, { call, put }) {
      // note
      const { data } = yield call(roomTypeList);
      yield put({
        type: 'save',
        payload: { roomTypeList: data?.data },
      });
    },
    *editLampEffect({ payload }, { call }) {
      const { data } = yield call(editLampEffect, payload);
      return data;
    },
    *deleteLampEffect({ payload }, { call }) {
      const { data } = yield call(deleteLampEffect, payload);
      return data;
    },
    *openLampEffect({ payload }, { call }) {
      const { data } = yield call(openLampEffect, payload);
      return data;
    },
    *lampEffectList({ payload }, { call }) {
      const { data } = yield call(lampEffectList, payloadCamelCase(payload));
      return data;
    },
    *editLampProjectEffect({ payload }, { call }) {
      const { data } = yield call(editLampProjectEffect, payload);
      return data;
    },
    *deleteLampProjectEffect({ payload }, { call }) {
      const { data } = yield call(deleteLampProjectEffect, payload);
      return data;
    },
    *openLampProjectEffect({ payload }, { call }) {
      const { data } = yield call(openLampProjectEffect, payload);
      return data;
    },
    *lampProjectEffectList({ payload }, { call }) {
      const { data } = yield call(
        lampProjectEffectList,
        payloadCamelCase(payload)
      );
      return data;
    },
    *createRoomType({ payload }, { call }) {
      const { data } = yield call(createRoomType, payload);
      return data;
    },
    *editRoomType({ payload }, { call }) {
      const { data } = yield call(editRoomType, payload);
      return data;
    },
    *deleteRoomType({ payload }, { call }) {
      const { data } = yield call(deleteRoomType, payload);
      return data;
    },
    *editStoreLampEffect({ payload }, { call }) {
      const { data } = yield call(editStoreLampEffect, payload);
      return data;
    },
    *auditStoreLampEffect({ payload }, { call }) {
      const { data } = yield call(auditStoreLampEffect, payload);
      return data;
    },
    *deleteStoreLampEffect({ payload }, { call }) {
      const { data } = yield call(deleteStoreLampEffect, payload);
      return data;
    },
    *storeLampEffectList({ payload }, { call }) {
      const { data } = yield call(
        storeLampEffectList,
        payloadCamelCase(payload)
      );
      return data;
    },
    *editStoreProjectEffect({ payload }, { call }) {
      const { data } = yield call(editStoreProjectEffect, payload);
      return data;
    },
    *auditStoreProjectEffect({ payload }, { call }) {
      const { data } = yield call(auditStoreProjectEffect, payload);
      return data;
    },
    *deleteStoreProjectEffect({ payload }, { call }) {
      const { data } = yield call(deleteStoreProjectEffect, payload);
      return data;
    },
    *storeLampProjectList({ payload }, { call }) {
      const { data } = yield call(
        storeLampProjectList,
        payloadCamelCase(payload)
      );
      return data;
    },
    *storeLightList({ payload }, { call }) {
      const { data } = yield call(storeLightList, payloadCamelCase(payload));
      return data;
    },
    *uploadProjectCad({ payload }, { call }) {
      const { data } = yield call(uploadProjectCad, payload);
      return data;
    },
    *uploadProjectMov({ payload }, { call }) {
      const { data } = yield call(uploadProjectMov, payload);
      return data;
    },
    *storeAuditLightList({ payload }, { call }) {
      const { data } = yield call(
        storeAuditLightList,
        payloadCamelCase(payload)
      );
      return data;
    },
    *storeAuditLampList({ payload }, { call }) {
      const { data } = yield call(
        storeAuditLampList,
        payloadCamelCase(payload)
      );
      return LampEffectListAdapetr(data);
    },
    *storeAuditProjectList({ payload }, { call }) {
      const { data } = yield call(
        storeAuditProjectList,
        payloadCamelCase(payload)
      );
      return LampProjectListAdapetr(data);
    },
    *auditLampEffect({ payload }, { call }) {
      const { data } = yield call(auditLampEffect, payload);
      return data;
    },
    *auditProjectEffect({ payload }, { call }) {
      const { data } = yield call(auditProjectEffect, payload);
      return data;
    },
    *lampEffectDownload({ payload }, { call }) {
      const { data } = yield call(lampEffectDownload, payload);
      return data;
    },
    *storeLampEffectDownload({ payload }, { call }) {
      const { data } = yield call(storeLampEffectDownload, payload);
      return data;
    },
    *storeSaleProjectList({ payload }, { call }) {
      const { data } = yield call(
        storeSaleProjectList,
        payloadCamelCase(payload)
      );
      return data;
    },
    *storeSaleLampList({ payload }, { call }) {
      const { data } = yield call(storeSaleLampList, payloadCamelCase(payload));
      return data;
    },
  },
  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};
