/**
 * 灯光厂家和灯信息相关
 */
import {
  queryLampList,
  createLampType,
  editLampType,
  deleteLampType,
  queryLampTypeList,
  createLampAisle,
  editLampAisle,
  deleteLampAisle,
  querylampAisleValueList,
  createLampAisleValue,
  editLampAisleValue,
  deleteLampAisleValue,
  createLampSort,
  editLampSort,
  deleteLampSort,
  createLamp,
  editLamp,
  deleteLamp,
  auditLight,
  visibleLamp,
  lightList,
  queryLampSort,
  queryLampAisleList,
  createLight,
  queryCompanyRoleList,
  editLight,
  queryLightListCondition,
  lightInfoPath,
  lightInfo,
  storeLightInfoPath,
  deleteLight,
} from '@/services/lampControl';

const LIGHT_FACTORY = 'LIGHT_FACTORY';
const LIGHT_AGENT = 'LIGHT_AGENT';

const whitelist = ['role_type', 'company_id'];

function camelCase(string) {
  if (whitelist.includes(string)) return string;
  return string.replace(/_([a-z])/g, (all, letter) => letter.toUpperCase());
}

function payloadCamelCase(obj) {
  const ret = {};
  Object.entries(obj).forEach(([k, v]) => {
    ret[camelCase(k)] = v;
  });
  return ret;
}

export default {
  namespace: 'lampInfo',
  state: {
    lampManufacturerList: [], // 灯光厂家列表
    lampTypeList: [], // 灯型号
    lampSortList: [], // 灯类型
    lampAisleList: [], // 灯通道
    lampAisleValueList: [], // 灯通道值类型
    lightListCondition: {}, // 筛选
  },
  effects: {
    *queryLampList({ payload }, { call, put }) {
      const { data } = yield call(queryCompanyRoleList, {
        ...payload,
        per_page: 1000,
        role_type: LIGHT_FACTORY,
      });
      yield put({
        type: 'save',
        payload: {
          lampManufacturerList: data.data || [],
        },
      });
    },
    *queryLampTypeList({ payload }, { call, put }) {
      const { data } = yield call(queryLampTypeList, payload);
      yield put({
        type: 'save',
        payload: {
          lampTypeList: data.data || [],
        },
      });
    },
    *queryLampSortList({ payload }, { call, put }) {
      const { data } = yield call(queryLampSort, payload);
      yield put({
        type: 'save',
        payload: {
          lampSortList: data || [],
        },
      });
    },
    *queryLampAisleList({ payload }, { call, put }) {
      const { data } = yield call(queryLampAisleList, payload);
      yield put({
        type: 'save',
        payload: {
          lampAisleList: data.data || [],
        },
      });
    },
    *querylampAisleValueList({ payload }, { call, put }) {
      const { data } = yield call(querylampAisleValueList, payload);
      yield put({
        type: 'save',
        payload: {
          lampAisleValueList: data || [],
        },
      });
    },
    *queryLightListCondition({ payload }, { call, put }) {
      const { data } = yield call(
        queryLightListCondition,
        payloadCamelCase(payload)
      );
      yield put({
        type: 'save',
        payload: {
          lightListCondition: data.data || {},
        },
      });
    },
    *lightList({ payload }, { call }) {
      const { data } = yield call(lightList, payloadCamelCase(payload));
      return data;
    },
    *createLampType({ payload }, { call }) {
      const { data } = yield call(createLampType, payload);
      return data;
    },
    *editLampType({ payload }, { call }) {
      const { data } = yield call(editLampType, payload);
      return data;
    },
    *deleteLampType({ payload }, { call }) {
      const { data } = yield call(deleteLampType, payload);
      return data;
    },
    *createLampAisle({ payload }, { call }) {
      const { data } = yield call(createLampAisle, payload);
      return data;
    },
    *editLampAisle({ payload }, { call }) {
      const { data } = yield call(editLampAisle, payload);
      return data;
    },
    *deleteLampAisle({ payload }, { call }) {
      const { data } = yield call(deleteLampAisle, payload);
      return data;
    },
    *createLampAisleValue({ payload }, { call }) {
      const { data } = yield call(createLampAisleValue, payload);
      return data;
    },
    *editLampAisleValue({ payload }, { call }) {
      const { data } = yield call(editLampAisleValue, payload);
      return data;
    },
    *deleteLampAisleValue({ payload }, { call }) {
      const { data } = yield call(deleteLampAisleValue, payload);
      return data;
    },
    *createLampSort({ payload }, { call }) {
      const { data } = yield call(createLampSort, payload);
      return data;
    },
    *editLampSort({ payload }, { call }) {
      const { data } = yield call(editLampSort, payload);
      return data;
    },
    *deleteLampSort({ payload }, { call }) {
      const { data } = yield call(deleteLampSort, payload);
      return data;
    },
    *createLamp({ payload }, { call }) {
      const { data } = yield call(createLamp, payload);
      return data;
    },
    *editLamp({ payload }, { call }) {
      const { data } = yield call(editLamp, payload);
      return data;
    },
    *deleteLight({ payload }, { call }) {
      const { data } = yield call(deleteLight, payload);
      return data;
    },
    *auditLight({ payload }, { call }) {
      const { data } = yield call(auditLight, payload);
      return data;
    },
    // *visibleLamp({ payload }, { call }) {
    //   const { data } = yield call(visibleLamp, payload);
    //   return data;
    // },
    *createLight({ payload }, { call }) {
      const { data } = yield call(createLight, payload);
      return data;
    },
    *editLight({ payload }, { call }) {
      const { data } = yield call(editLight, payload);
      return data;
    },
    *lightInfoPath({ payload }, { call }) {
      const { data } = yield call(lightInfoPath, payload);
      return data;
    },
    *storeLightInfoPath({ payload }, { call }) {
      const { data } = yield call(storeLightInfoPath, payload);
      return data;
    },
    *lightInfo({ payload }, { call }) {
      const { data } = yield call(lightInfo, payload);
      return data;
    },
  },
  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};
