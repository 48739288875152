import request from '@/utils/request';

/** 灯光厂家列表 */
export function queryIntelligentControlList(params) {
  return request(`/company-service/intelligent-control-vendors`, {
    method: 'GET',
    params,
  });
}

export function createIntelligentControl(data) {
  return request(`/company-service/intelligent-control-vendors`, {
    method: 'POST',
    data,
  });
}

export function editIntelligentControl(data) {
  return request(`/company-service/intelligent-control-vendors/${data.id}`, {
    method: 'PUT',
    data,
  });
}
