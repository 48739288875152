import { queryNetCompany, queryCompanyStb } from '@/services/company';

export default {
  namespace: 'CompanyAppManager_companyStb',

  state: {
    data: [],
    appInfo: {},
  },

  effects: {
    *fetchNet({ payload }, { call, put }) {
      const {
        data: { data, meta },
      } = yield call(queryNetCompany, payload);

      yield put({
        type: 'save',
        payload: {
          list: data,
          pagination: {
            total: meta.total,
            pageSize: meta.per_page,
            current: meta.current_page,
          },
        },
      });
    },
    *fetchComapnyStbList({ payload }, { call, put }) {
      const {
        data: { data, meta },
      } = yield call(queryCompanyStb, payload);

      yield put({
        type: 'save',
        payload: {
          list: data,
          pagination: {
            total: meta.total,
            pageSize: meta.per_page,
            current: meta.current_page,
          },
        },
      });
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        data: payload,
      };
    },
    saveAppInfo(state, { payload }) {
      return {
        ...state,
        appInfo: payload,
      };
    },
  },
};
