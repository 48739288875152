import {
  allAgentMenuList,
  agentMenuList,
  agentMenuCreate,
  agentMenuEdit,
  agentMenuDelete,
  agentAllocateMenu,
} from '@/services/menu';

export default {
  namespace: 'PlatformManager_agentMenu',

  state: {
    data: {},
    menuData: {},
    userInfo: {},
    result: {},
  },

  effects: {
    *fetch({ payload }, { call, put }) {
      const {
        data: { data },
      } = yield call(allAgentMenuList, payload);

      yield put({
        type: 'save',
        payload: {
          list: data,
        },
      });
    },
    // *getAgentMenu({ payload }, { call, put }) {
    //   const {
    //     data: { data: menuData },
    //   } = yield call(agentMenuList, payload);

    //   yield put({
    //     type: 'saveAgentMenu',
    //     payload: {
    //       list: menuData,
    //     },
    //   });
    // },
    *create({ payload }, { call, put }) {
      const { data } = yield call(agentMenuCreate, payload);
      yield put({
        type: 'saveResult',
        payload: data,
      });
    },
    *edit({ payload }, { call, put }) {
      const { data } = yield call(agentMenuEdit, payload);
      yield put({
        type: 'saveResult',
        payload: data,
      });
    },
    *delete({ payload }, { call, put }) {
      const { data } = yield call(agentMenuDelete, payload);
      yield put({
        type: 'saveResult',
        payload: data.result,
      });
    },
    *saveAgent({ payload }, { call, put }) {
      const { data } = yield call(agentAllocateMenu, payload);
      return data;
    },
  },
  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        data: payload,
      };
    },
    saveAgentMenu(state, { payload }) {
      return {
        ...state,
        menuData: payload,
      };
    },
    saveResult(state, { payload }) {
      return {
        ...state,
        result: payload,
      };
    },
  },
};
