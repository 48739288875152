import { getUpgradePackagesTargetTypes } from '@/services/remoteUpgrade';
import { upgradePackageTreeChange } from '../utils';

export default {
  namespace: 'RemoteUpgrade_targetTypes',

  state: {
    data: {},
  },

  effects: {
    *fetch({ payload }, { call, put, select }) {
      const oldData = yield select(
        state => state.RemoteUpgrade_targetTypes.data
      );
      if (oldData.length > 0) {
        return;
      }

      const { status, data } = yield call(
        getUpgradePackagesTargetTypes,
        payload
      );

      if (status === 200) {
        yield put({
          type: 'save',
          payload: data,
        });
      }
    },
  },

  reducers: {
    save(state, { payload }) {
      const changeData = upgradePackageTreeChange(payload);

      return {
        ...state,
        data: {
          ...changeData,
        },
      };
    },
  },
};
