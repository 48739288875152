import {
  agentIndexList,
  agentIndex,
  agentLogIndex,
  getAuthTypeList,
  getNewAuthTypeList,
  addAuthorization,
  agentLogIndexList,
  authLogCount,
  updateAuthorization,
  softDogList,
  unbindSoftDogData,
  delSoftDogData,
  editSoftDogData,
  BoundAgentData,
  companyIndexList,
  companyListIndex,
  companyLogIndexList,
  companyExperimentLogIndexList,
  companyLogIndex,
  queryPrinciple,
  YTAgentIndexList,
  bindAgentData,
  YTOrderIndexList,
  indexSynchronizationOrder,
  exportAuthLogList,
  exportAuthList,
  exportCompanyAuthList,
  exportCompanyAuthLogList,
  AgentBindInfoList,
  exportAgentInfoList,
  bindInfoCountIndex,
  SBAgentIndexList,
  bindSBAgentData,
} from '@/services/BusinessAuthorization/agentauthorization';
import { get } from 'lodash';

export default {
  namespace: 'ValueAddedCenter_BusinessAuthorization_agentauthorization',

  state: {
    AgentDataList: { list: [] },
    AgentLogDataList: { list: {} },
    authLogCountDataList: { list: [] },
    bindInfoCountDataList: { list: [] },
    authType: { list: [] },
    newAuthType: { list: [] },
    exportData: { list: [] },
    PrincipleData: { list: [] },
    companyAuthLogDataList: { list: [] },
    indexCompanyData: {
      list: [],
      pagination: {},
    },
    indexCompanyLogData: {
      list: [],
      pagination: {},
    },
    indexExperimentCompanyLogData: {
      list: [],
      pagination: {},
    },
    indexData: {
      list: [],
      pagination: {},
    },
    indexAgentLogData: {
      list: [],
      pagination: {},
    },
    softDogData: {
      list: [],
      pagination: {},
    },
    YTAgentData: {
      list: [],
      pagination: {},
    },
    YTOrderData: {
      list: [],
      pagination: {},
    },
    AgentBindInfoData: {
      list: [],
      pagination: {},
    },

    SBAgentData: {
      list: [],
      pagination: {},
    },
  },

  effects: {
    *index({ payload }, { call, put }) {
      const { status, data } = yield call(agentIndexList, payload);

      if (status === 200) {
        yield put({
          type: 'saveIndexData',
          payload: {
            list: data.data,
            pagination: {
              total: data.meta.total,
              pageSize: parseInt(data.meta.per_page),
              current: parseInt(data.meta.current_page),
            },
          },
        });
      }
    },
    // 根据条件获取代理商信息
    *getAgentData({ payload }, { call, put }) {
      const { status, data } = yield call(agentIndex, payload);

      if (status === 200) {
        yield put({
          type: 'agentData',
          payload: {
            list: data.data,
          },
        });
      }
    },
    // 代理商授权根据条件获取统计区域信息
    *indexAuthLog({ payload }, { call, put }) {
      const { status, data } = yield call(agentLogIndex, payload);

      if (status === 200) {
        yield put({
          type: 'agentLogData',
          payload: {
            list: data,
          },
        });
      }
    },
    // 获取授权类型信息
    *getAuthType({ payload }, { call, put }) {
      const { status, data } = yield call(getAuthTypeList, payload);

      if (status === 200) {
        yield put({
          type: 'authTypeList',
          payload: {
            list: data,
          },
        });
      }
    },
    // 获取授权类型信息
    *getNewAuthType({ payload }, { call, put }) {
      const { status, data } = yield call(getNewAuthTypeList, payload);

      if (status === 200) {
        yield put({
          type: 'newAuthTypeList',
          payload: {
            list: data,
          },
        });
      }
    },
    // 新增代理商授权库存
    *addAgentAuthorization({ payload, callback }, { call, put }) {
      const { status, data } = yield call(addAuthorization, payload);

      if (status === 200) {
        if (callback) callback(data);
      }
    },
    // 获取代理商授权记录
    *indexAgentLog({ payload }, { call, put }) {
      const { status, data } = yield call(agentLogIndexList, payload);

      if (status === 200) {
        yield put({
          type: 'saveIndexAgentLogData',
          payload: {
            list: data.data,
            pagination: {
              total: data.meta.total,
              pageSize: parseInt(data.meta.per_page),
              current: parseInt(data.meta.current_page),
            },
          },
        });
      }
    },
    // 代理商授权记录根据条件获取统计区域信息
    *indexAgentAuthLog({ payload }, { call, put }) {
      const { status, data } = yield call(authLogCount, payload);

      if (status === 200) {
        yield put({
          type: 'authLogCountData',
          payload: {
            list: data,
          },
        });
      }
    },
    // 修改代理商授权记录页面数据
    *updateAuthorizationData({ payload, callback }, { call, put }) {
      const { status, data } = yield call(updateAuthorization, payload);

      if (status === 200) {
        if (callback) callback(data);
      }
    },
    // 获取加密狗数据
    *indexSoftDog({ payload }, { call, put }) {
      const { status, data } = yield call(softDogList, payload);

      if (status === 200) {
        yield put({
          type: 'saveSoftDogData',
          payload: {
            list: data.data,
            pagination: {
              total: data.meta.total,
              pageSize: parseInt(data.meta.per_page),
              current: parseInt(data.meta.current_page),
            },
          },
        });
      }
    },
    // 解绑加密狗场所
    *unbindSoftDog({ payload, callback }, { call, put }) {
      const { status, data } = yield call(unbindSoftDogData, payload);

      if (status === 200) {
        if (callback) callback(data);
      }
    },
    // 删除加密狗信息
    *delSoftDog({ payload, callback }, { call, put }) {
      const { status, data } = yield call(delSoftDogData, payload);

      if (status === 200) {
        if (callback) callback(data);
      }
    },
    // 编辑保存加密狗信息
    *editSoftDog({ payload, callback }, { call, put }) {
      const { status, data } = yield call(editSoftDogData, payload);

      if (status === 200) {
        if (callback) callback(data);
      }
    },
    // 批量保存加密狗代理商信息
    *BoundAgent({ payload, callback }, { call, put }) {
      const { status, data } = yield call(BoundAgentData, payload);

      if (status === 200) {
        if (callback) callback(data);
      }
    },
    // 获取场所授权信息
    *indexCompany({ payload, callback }, { call, put }) {
      const { status, data } = yield call(companyIndexList, payload);

      if (status === 200) {
        yield put({
          type: 'saveCompanyData',
          payload: {
            list: data.data,
            pagination: {
              total: data.meta.total,
              pageSize: parseInt(data.meta.per_page),
              current: parseInt(data.meta.current_page),
            },
          },
        });
      }

      if (callback) callback();
    },
    // 场所授权根据条件获取统计区域信息
    *indexCompanyAuthLog({ payload }, { call, put }) {
      const { status, data } = yield call(companyListIndex, payload);

      if (status === 200) {
        yield put({
          type: 'companyAuthLogData',
          payload: {
            list: data,
          },
        });
      }
    },
    // 获取场所授权记录信息
    *indexCompanyLog({ payload }, { call, put }) {
      const { status, data } = yield call(companyLogIndexList, payload);

      if (status === 200) {
        yield put({
          type: 'saveCompanyLogData',
          payload: {
            list: data.data,
            pagination: {
              total: data.meta.total,
              pageSize: parseInt(data.meta.per_page),
              current: parseInt(data.meta.current_page),
            },
          },
        });
      }
    },
    // 试点授权记录
    *indexExperimentCompanyLog({ payload }, { call, put }) {
      const { status, data } = yield call(
        companyExperimentLogIndexList,
        payload
      );

      if (status === 200) {
        yield put({
          type: 'saveFourkCompanyLogData',
          payload: {
            list: data.data,
            pagination: {
              total: data.meta.total,
              pageSize: parseInt(data.meta.per_page),
              current: parseInt(data.meta.current_page),
            },
          },
        });
      }
    },
    // 场所授权记录根据条件获取统计区域信息
    *indexCompanyLogStatistics({ payload }, { call, put }) {
      const { status, data } = yield call(companyLogIndex, payload);

      if (status === 200) {
        yield put({
          type: 'authLogCountData',
          payload: {
            list: data,
          },
        });
      }
    },
    // 获取所有省份
    *fetchPrinciple({ payload }, { call, put }) {
      const { data } = yield call(queryPrinciple, payload);
      yield put({
        type: 'savePrinciple',
        payload: {
          list: data,
        },
      });
    },
    // 获取易拓代理商绑定记录列表
    *indexYTAgent({ payload }, { call, put }) {
      const { status, data } = yield call(YTAgentIndexList, payload);

      if (status === 200) {
        yield put({
          type: 'saveYTAgentData',
          payload: {
            list: data.data,
            pagination: {
              total: data.meta.total,
              pageSize: parseInt(data.meta.per_page),
              current: parseInt(data.meta.current_page),
            },
          },
        });
      }
    },
    // 保存易拓关联视易代理商
    *bindAgent({ payload, callback }, { call, put }) {
      const { status, data } = yield call(bindAgentData, payload);

      if (status === 200) {
        if (callback) callback(data);
      }
    },
    // 获取易拓订单记录列表
    *indexYTOrder({ payload }, { call, put }) {
      const { status, data } = yield call(YTOrderIndexList, payload);

      if (status === 200) {
        yield put({
          type: 'saveYTOrderData',
          payload: {
            list: data.data,
            pagination: {
              total: data.meta.total,
              pageSize: parseInt(data.meta.per_page),
              current: parseInt(data.meta.current_page),
            },
          },
        });
      }
    },
    // 同步易拓订单
    *synchronizationOrder({ payload, callback }, { call, put }) {
      const { status, data } = yield call(indexSynchronizationOrder, payload);

      if (status === 200) {
        if (callback) callback(data);
      }
    },
    // 代理商授权页面导出
    *exportAuth({ payload, callback }, { call, put }) {
      const { status, data } = yield call(exportAuthList, payload);

      if (status === 200) {
        if (callback) callback(data);
      }
    },
    // 代理商授权记录页面导出
    *exportAuthLog({ payload, callback }, { call, put }) {
      const { status, data } = yield call(exportAuthLogList, payload);

      if (status === 200) {
        if (callback) callback(data);
      }
    },
    // 场所授权页面导出
    *exportCompanyAuth({ payload, callback }, { call, put }) {
      const { status, data } = yield call(exportCompanyAuthList, payload);

      if (status === 200) {
        if (callback) callback(data);
      }
    },
    // 场所授权记录页面导出
    *exportCompanyLogAuth({ payload, callback }, { call, put }) {
      const { status, data } = yield call(exportCompanyAuthLogList, payload);

      if (status === 200) {
        if (callback) callback(data);
      }
    },
    // 获取代理商信息列表
    *indexAgentBindInfo({ payload }, { call, put }) {
      const { status, data } = yield call(AgentBindInfoList, payload);

      if (status === 200) {
        yield put({
          type: 'saveAgentBindInfoData',
          payload: {
            list: data.data,
            pagination: {
              total: data.meta.total,
              pageSize: parseInt(data.meta.per_page),
              current: parseInt(data.meta.current_page),
            },
          },
        });
      }
    },
    // 代理商信息页面导出
    *exportAgentInfo({ payload, callback }, { call, put }) {
      const { status, data } = yield call(exportAgentInfoList, payload);

      if (status === 200) {
        if (callback) callback(data);
      }
    },
    // 代理商信息页面获取统计区域信息
    *indexBindInfoCount({ payload }, { call, put }) {
      const { status, data } = yield call(bindInfoCountIndex, payload);

      if (status === 200) {
        yield put({
          type: 'bindInfoCountData',
          payload: {
            list: data,
          },
        });
      }
    },
    // 获取注册系统代理商绑定记录列表
    *indexSBAgent({ payload }, { call, put }) {
      const { status, data } = yield call(SBAgentIndexList, payload);

      if (status === 200) {
        yield put({
          type: 'saveSBAgentData',
          payload: {
            list: data.data.data,
            pagination: {
              total: data.data.total,
              pageSize: parseInt(data.data.per_page),
              current: parseInt(data.data.current_page),
            },
          },
        });
      }
    },
    // 保存注册系统关联视易代理商
    *bindSBAgent({ payload, callback }, { call, put }) {
      const { status, data } = yield call(bindSBAgentData, payload);

      if (status === 200) {
        if (callback) callback(data);
      }
    },
  },

  reducers: {
    savePrinciple(state, action) {
      return {
        ...state,
        PrincipleData: action.payload,
      };
    },
    saveSoftDogData(state, action) {
      return {
        ...state,
        softDogData: action.payload,
      };
    },
    saveIndexData(state, action) {
      return {
        ...state,
        indexData: action.payload,
      };
    },
    saveCompanyData(state, action) {
      return {
        ...state,
        indexCompanyData: action.payload,
      };
    },
    saveCompanyLogData(state, action) {
      return {
        ...state,
        indexCompanyLogData: action.payload,
      };
    },
    saveFourkCompanyLogData(state, action) {
      return {
        ...state,
        indexExperimentCompanyLogData: action.payload,
      };
    },
    saveIndexAgentLogData(state, action) {
      return {
        ...state,
        indexAgentLogData: action.payload,
      };
    },
    agentData(state, action) {
      return {
        ...state,
        AgentDataList: action.payload,
      };
    },
    agentLogData(state, action) {
      return {
        ...state,
        AgentLogDataList: action.payload,
      };
    },
    authTypeList(state, action) {
      return {
        ...state,
        authType: action.payload,
      };
    },
    newAuthTypeList(state, action) {
      return {
        ...state,
        newAuthType: action.payload,
      };
    },
    authLogCountData(state, action) {
      return {
        ...state,
        authLogCountDataList: action.payload,
      };
    },
    saveYTAgentData(state, action) {
      return {
        ...state,
        YTAgentData: action.payload,
      };
    },
    saveYTOrderData(state, action) {
      return {
        ...state,
        YTOrderData: action.payload,
      };
    },
    companyAuthLogData(state, action) {
      return {
        ...state,
        companyAuthLogDataList: action.payload,
      };
    },
    exportListData(state, action) {
      return {
        ...state,
        exportData: action.payload,
      };
    },
    saveAgentBindInfoData(state, action) {
      return {
        ...state,
        AgentBindInfoData: action.payload,
      };
    },
    bindInfoCountData(state, action) {
      return {
        ...state,
        bindInfoCountDataList: action.payload,
      };
    },
    saveSBAgentData(state, action) {
      return {
        ...state,
        SBAgentData: action.payload,
      };
    },
  },
};
