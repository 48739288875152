import request from '@/utils/request';

// 菜单列表
export async function allMenuList() {
  return request(`/menu`);
}

export async function userMenuList() {
  return request(`/menu/getUserMenu`);
}

export async function menuCreate(params) {
  return request(`/menu/create`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function menuEdit(params) {
  const { id } = params;
  delete params.id;
  return request(`/menu/${id}/edit`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function menuDelete(params) {
  const { id } = params;
  delete params.id;
  return request(`/menu/${id}/delete`);
}

/** 代理商平台菜单 */

// 菜单列表
export async function allAgentMenuList() {
  return request(`/agent/menu`);
}

export async function getAllMenuTemplates() {
  return request('/agent/getAllMenuTemplates');
}

// 二级代理商菜单列表
export async function secondAgentMenuList() {
  return request(`/agent/second-agent-menu`);
}

export async function agentCompanyMenuList() {
  return request(`/agent-company-permission-list`);
}

export async function agentMenuCreate(params) {
  return request(`/agent/menu/create`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function agentMenuEdit(params) {
  const { id } = params;
  delete params.id;
  return request(`/agent/menu/${id}/edit`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function agentMenuDelete(params) {
  const { id } = params;
  delete params.id;
  return request(`/agent/menu/${id}/delete`);
}

export async function agentAllocateMenu(data) {
  return request('/agent/allocate-menu', {
    method: 'POST',
    data,
  });
}

export async function agentBatchAllocateMenu(data) {
  return request('/agent/batch-allocate-menu', {
    method: 'POST',
    data,
  });
}

/** 商家平台菜单 */

// 菜单列表
export async function allCompanyMenuList() {
  return request(`/company-service/menus`);
}

export async function companyMenuCreate(params) {
  return request(`/company-service/menus`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function companyMenuEdit({ id, ...params }) {
  return request(`/company-service/menus/${id}`, {
    method: 'PUT',
    data: {
      ...params,
    },
  });
}

export async function companyMenuDelete({ id }) {
  return request(`/company-service/menus/${id}`, {
    method: 'DELETE',
  });
}

/** 合作商平台菜单 */

// 菜单列表
export async function allPartnerMenuList() {
  return request(`/partner/getMenuList`);
}

export async function partnerMenuCreate(params) {
  return request(`/partner/createMenu`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function partnerMenuEdit({ id, ...params }) {
  return request(`/partner/updateMenu/${id}`, {
    method: 'PUT',
    data: {
      ...params,
    },
  });
}

export async function partnerMenuDelete({ id }) {
  return request(`/partner/deleteMenu/${id}`, {
    method: 'DELETE',
  });
}
