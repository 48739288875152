import { getAppBaseDetail } from '@/services/companyApp';

export default {
  namespace: 'CompanyAppManager_baseAppDetail',

  state: {
    data: {},
    regions: {},
  },

  effects: {
    *fetch({ payload: id, callback }, { call, put }) {
      const { data } = yield call(getAppBaseDetail, { appBaseId: id });

      yield put({
        type: 'save',
        payload: {
          [id]: data,
        },
      });
      callback(data);
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        data: payload,
      };
    },
  },
};
