import { getAdapterCheckboxList } from '@/services/companyApp';

export default {
  namespace: 'CompanyAppManager_adapterCheckboxList',

  state: {
    data: [],
  },

  effects: {
    *fetch({ payload }, { call, put }) {
      const { data } = yield call(getAdapterCheckboxList, payload);
      // const {
      //   data: { data },
      // } = yield call(getAdapterCheckboxList, payload);
      yield put({
        type: 'save',
        payload: data.data,
      });
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        data: payload,
      };
    },
  },
};
