import {
  queryStereoList,
  createStereo,
  editStereo,
} from '@/services/stereoControl';

export default {
  namespace: 'StereoManage',
  state: {},
  effects: {
    *queryStereoList({ payload }, { call, put }) {
      const { data } = yield call(queryStereoList, payload);
      return data;
    },
    *createStereo({ payload }, { call, put }) {
      const { data } = yield call(createStereo, payload);
      return data;
    },
    *editStereo({ payload }, { call, put }) {
      const { data } = yield call(editStereo, payload);
      return data;
    },
  },
};
