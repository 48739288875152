import { queryCompanyDeviceOverview } from '@/services/company';

export default {
  namespace: 'CompanyList_deviceOverview',

  state: {
    data: {},
  },

  effects: {
    *fetch({ payload }, { call, put }) {
      const { status, data } = yield call(queryCompanyDeviceOverview, payload);

      if (status === 200) {
        yield put({
          type: 'save',
          payload: {
            ...data.data,
          },
        });
      }
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        data: payload,
      };
    },
  },
};
