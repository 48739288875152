import * as companyAppService from '@/services/companyApp';

export default {
  namespace: 'CompanyAppManager_VersionPilot',
  state: {
    data: {},
    initTranceFormData: {
      use_type: null,
      targetKeys: [],
      tranceData: [],
      OrderStatusResult: {},
      OrderStatusListResult: {},
      OrderProgressResult: {},
    },
  },

  effects: {
    // 工单进度弹出框数据初始化
    *getOrderProgress({ payload }, { call, put }) {
      const { data } = yield call(companyAppService.getOrderProgress, {
        payload,
      });
      yield put({
        type: 'getOrderProgressResult',
        payload: data,
      });
      // console.log('--datadata---');
      // console.log(data);
      // console.log('+++datadatadata+++');
    },

    // OrderStatus弹出框数据初始化
    *OrderStatusList({ payload, callback }, { call, put }) {
      // console.log('---OrderStatusList--');
      // console.log(payload);
      // console.log('+++OrderStatusList+++');
      const { data } = yield call(companyAppService.OrderStatusList, {
        payload,
      });
      yield put({
        type: 'OrderStatusListResult',
        payload: data,
      });
      callback(data);
      // console.log('--datadata---');
      // console.log(data);
      // console.log('+++datadatadata+++');
    },

    // 保存OrderStatus
    *saveOrderStatus({ payload }, { call, put }) {
      console.log('--qqqq---');
      console.log(payload);
      console.log('+++qqqq+++');
      const { data } = yield call(companyAppService.saveOrderStatus, {
        payload,
      });
      yield put({
        type: 'OrderStatusResult',
        payload: data,
      });
    },

    *searchAppTransData({ payload }, { call, put }) {
      const { data } = yield call(companyAppService.searchAppTransData, {
        payload,
      });
      yield put({
        type: 'saveTranceData',
        payload: { data },
      });
    },
    *transeformInit({ payload }, { call, put }) {
      // console.log('-----');
      // console.log(payload);
      // console.log('++++++');
      const { data } = yield call(companyAppService.getAppPilotCompany, {
        payload,
      });

      yield put({
        type: 'saveTranceData',
        payload: { data },
      });
    },
    *setPilotCompany({ payload, callback }, { call }) {
      const { data } = yield call(companyAppService.setPilotCompany, {
        payload,
      });
      callback(data);
    },
    // 保存应用审核商家配置
    *setVersionPilotAdapter({ payload, callback }, { call }) {
      console.log('1111JS->', payload);
      const { data, status } = yield call(
        companyAppService.setVersionPilotAdapter,
        {
          payload,
        }
      );
      callback(data, status);
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        data: payload,
      };
    },
    saveTranceData(state, { payload }) {
      return {
        ...state,
        initTranceFormData: payload,
      };
    },
    OrderStatusResult(state, { payload }) {
      return {
        ...state,
        OrderStatusResult: payload,
      };
    },
    OrderStatusListResult(state, { payload }) {
      return {
        ...state,
        OrderStatusListResult: payload,
      };
    },
    getOrderProgressResult(state, { payload }) {
      return {
        ...state,
        OrderProgressResult: payload,
      };
    },
  },
};
