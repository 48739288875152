import { agentIndex, diskAbnormalList } from '../service';

export default {
  namespace: 'cloudData_diskAbnormal',

  state: {
    indexData: {
      list: [],
      pagination: {},
    },
    AgentDataList: { list: [] },
  },

  effects: {
    *index({ payload }, { call, put }) {
      const { status, data } = yield call(diskAbnormalList, payload);

      if (status === 200) {
        yield put({
          type: 'saveIndexData',
          payload: {
            list: data.data,
            pagination: {
              total: data.meta.total,
              pageSize: parseInt(data.meta.per_page),
              current: parseInt(data.meta.current_page),
            },
          },
        });
      }
    },
    // 根据条件获取代理商信息
    *getAgentData({ payload }, { call, put }) {
      const { status, data } = yield call(agentIndex, payload);

      if (status === 200) {
        yield put({
          type: 'agentData',
          payload: {
            list: data.data,
          },
        });
      }
    },
  },

  reducers: {
    saveIndexData(state, action) {
      return {
        ...state,
        indexData: action.payload,
      };
    },
    agentData(state, action) {
      return {
        ...state,
        AgentDataList: action.payload,
      };
    },
  },
};
