import moment from 'moment/moment';
import request from '@/utils/request';

export async function queryKmCompanies(params) {
  return request('/km/companies', {
    params,
  });
}

export async function queryBaobeiCompanies(params) {
  return request('/baobei/companies', {
    params,
  });
}

export async function querySaleAuthorizationCompanies(params) {
  return request('/sale-authorization/companies', {
    params,
  });
}

export async function querySaleManagementAuthorizationCompanies(params) {
  return request('/sale-authorization/management-companies', {
    params,
  });
}

// 趋势分析
export async function queryDataAnalysis(params) {
  return request('/data/Analysis', {
    params,
  });
}

// 查询所有省份
export async function queryPrinciple(params) {
  return request('/analyze/get-principle', {
    params,
  });
}

// 导出
export async function exportFile(params) {
  return request('/analyze/export', {
    params,
  });
}

// 导出
export async function exportDeptFile(params) {
  return request('/analyze/exportDept', {
    params,
  });
}

// 获取管理系统页面使用情况数据
export async function getPageData(params) {
  return request('/analyze/get-page-data', {
    params,
  });
}

// 获取管理系统子系统
export async function getSubSystem(params) {
  return request('/analyze/get-sub-system', {
    params,
  });
}

// 获取管理系统岗位使用情况数据
export async function getDeptData(params) {
  return request('/analyze/get-dept-data', {
    params,
  });
}

export async function getManagementUrl(params) {
  return request('/analyze/get-management-url', {
    params,
  });
}

export async function getDeptUrl(params) {
  return request('/analyze/get-dept-url', {
    params,
  });
}
