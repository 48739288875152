import {
  getUpgradePackageBatchJobList,
  deleteUpgradePackageBatchJob,
} from '@/services/remoteUpgrade';

export default {
  namespace: 'RemoteUpgrade_upgradePackageBatchJobs',

  state: {
    jobs: [],
    response: '',
  },

  effects: {
    *fetchUpgradePackageJobList({ payload }, { call, put }) {
      const { status, data } = yield call(
        getUpgradePackageBatchJobList,
        payload
      );

      if (status === 200) {
        yield put({
          type: 'saveList',
          payload: {
            list: data.data,
            pagination: {
              total: data.meta.total,
              pageSize: parseInt(data.meta.per_page),
              current: data.meta.current_page,
            },
          },
        });
      }
    },
    *deleteUpgradePackageBatchJob({ payload }, { call, put }) {
      const { status } = yield call(deleteUpgradePackageBatchJob, payload);
      yield put({
        type: 'saveResponse',
        payload: status,
      });
    },
  },

  reducers: {
    saveList(state, { payload }) {
      return {
        ...state,
        jobs: payload,
      };
    },
    saveResponse(state, { payload }) {
      return {
        ...state,
        response: payload,
      };
    },
  },
};
