import { queryDevices, queryExceptions } from '@/services/search';

export default {
  namespace: 'search',

  state: {
    devices: {},
    exceptions: {},
  },

  effects: {
    *fetchDevices({ payload }, { call, put }) {
      const { status, data } = yield call(queryDevices, payload);

      if (status === 200) {
        yield put({
          type: 'saveDevices',
          payload: {
            list: data.data,
            pagination: {
              total: data.meta.total,
              pageSize: data.meta.per_page,
              current: data.meta.current_page,
            },
          },
        });
      }
    },

    *fetchExceptions({ payload }, { call, put }) {
      const { status, data } = yield call(queryExceptions, payload);

      if (status === 200) {
        yield put({
          type: 'saveExceptions',
          payload: {
            list: data.data,
            pagination: {
              total: data.meta.total,
              pageSize: data.meta.per_page,
              current: data.meta.current_page,
            },
          },
        });
      }
    },
  },

  reducers: {
    saveDevices(state, { payload }) {
      return {
        ...state,
        devices: payload,
      };
    },

    saveExceptions(state, { payload }) {
      return {
        ...state,
        exceptions: payload,
      };
    },
  },
};
