import request, { businessRequest } from '@/utils/request';

// 获取代理商授权列表
export async function agentIndexList(params) {
  return request('/business/agent/auth-list', { params });
}

// 获取代理商信息
export async function agentIndex(params) {
  return request('/business/agent/list', { params });
}

// 获取代理商统计区域信息
export async function agentLogIndex(params) {
  return request('/business/agent/auth-count', { params });
}

// 获取授权类型信息
export async function getAuthTypeList(params) {
  return request('/business/auth-type', { method: 'GET' });
}
// 获取新的授权类型信息
export async function getNewAuthTypeList(params) {
  return request('/business/new-auth-type', { method: 'GET' });
}

// 添加代理商授权库存
export async function addAuthorization(params) {
  return request('/business/agent/add-points', {
    method: 'POST',
    data: params,
  });
}
// 获取代理商授权记录列表
export async function agentLogIndexList(params) {
  return request('/business/agent/auth-log-list', { params });
}

// 获取代理商统计区域信息
export async function authLogCount(params) {
  return request('/business/agent/auth-log-count', { params });
}
// 修改代理商授权记录页面
export async function updateAuthorization(params) {
  return request('/business/agent/edit-auth-log', {
    method: 'POST',
    data: params,
  });
}
// 获取加密狗列表
export async function softDogList(params) {
  return request('/business/dog/bind-encryption-dog-list', { params });
}
// 解绑加密狗场所
export async function unbindSoftDogData(params) {
  return request('/business/agent/unbind-encryption-dog', {
    method: 'POST',
    data: params,
  });
}
// 删除加密狗信息
export async function delSoftDogData(params) {
  return request('/business/dog/delete-encryption-dog', {
    method: 'POST',
    data: params,
  });
}
// 编辑保存加密狗信息
export async function editSoftDogData(params) {
  return request('/business/dog/edit-encryption-dog', {
    method: 'POST',
    data: params,
  });
}
// 批量保存加密狗代理商信息
export async function BoundAgentData(params) {
  return request('/business/agent/distribution-encryption-dog', {
    method: 'POST',
    data: params,
  });
}
// 获取场所授权列表
export async function companyIndexList(params) {
  return request('/business/company/auth-list', { params });
}
// 获取场所列表统计区域信息
export async function companyListIndex(params) {
  return request('/business/company/auth-list-count', { params });
  // return request('/business/agent/auth-list-export', { params });
}
// 获取场所授权记录列表
export async function companyLogIndexList(params) {
  return request('/business/company/auth-log-list', { params });
}
// 4k试点授权
export async function companyExperimentLogIndexList(params) {
  return request('/business/company/experiment-auth-log-list', { params });
}
// 获取场所记录统计区域信息
export async function companyLogIndex(params) {
  return request('/business/company/auth-log-count', { params });
}
// 查询所有省份
export async function queryPrinciple(params) {
  return request('/analyze/get-principle', {
    params,
  });
}
// 获取易拓代理商绑定记录列表
export async function YTAgentIndexList(params) {
  return request('/business/agent-map-list', { params });
}
// 保存易拓关联视易代理商
export async function bindAgentData(params) {
  return request('/business/bind-agent', {
    method: 'POST',
    data: params,
  });
}
// 获取易拓订单记录列表
export async function YTOrderIndexList(params) {
  return request('/business/yt-order-list', { params });
}
// 同步易拓订单
export async function indexSynchronizationOrder(params) {
  return request('/business/agent/sync-order', {
    method: 'POST',
    data: params,
  });
}
// 代理商授权页面导出
export async function exportAuthList(params) {
  // return businessRequest('/agent/auth-list-export', { params });
  return request('/business/agent/auth-list-export', { params });
}
// 代理商授权记录页面导出
export async function exportAuthLogList(params) {
  return request('/business/agent/auth-log-list-export', { params });
}
// 场所授权页面导出
export async function exportCompanyAuthList(params) {
  return request('/business/company/auth-list-export', { params });
}
// 场所授权记录页面导出
export async function exportCompanyAuthLogList(params) {
  return request('/business/company/auth-log-list-export', { params });
}
// 获取代理商信息列表
export async function AgentBindInfoList(params) {
  return request('/business/agent/bind-info', { params });
}
// 代理商信息页面导出
export async function exportAgentInfoList(params) {
  return request('/business/agent/bind-info-export', { params });
}
// 代理商信息统计区域信息
export async function bindInfoCountIndex(params) {
  return request('/business/agent/bind-info-count', { params });
}

// 获取注册系统代理商绑定记录列表
export async function SBAgentIndexList(params) {
  return request('/business-service/api/register-agent/list', { params });
}

// 保存注册系统关联视易代理商
export async function bindSBAgentData(params) {
  return request('/business-service/api/register-agent/bind', {
    method: 'POST',
    data: params,
  });
}
