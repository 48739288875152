import request from '@/utils/request';
import { stringify } from 'qs';

export async function queryAppVersionInfo(params) {
  const { appVersionId, baseAppId } = params;
  return request(`/appMarket/${baseAppId}/getVersionInfo/${appVersionId}`);
}

export async function queryAppVersionInstallLogs(params) {
  const { appVersionId, baseAppId } = params;
  delete params.appVersionId;
  delete params.baseAppId;
  return request(
    `/appMarket/${baseAppId}/versionInstallLogs/${appVersionId}?${stringify(
      params
    )}`
  );
}

export async function queryAppStatusRelateCompanies(params) {
  const { logId, baseAppId, appVersionId } = params;
  delete params.baseAppId;
  delete params.appVersionId;
  delete params.logId;
  return request(
    `/appMarket/${baseAppId}/versionInstallLogs/${appVersionId}/log/${logId}/companies`,
    {
      params,
    }
  );
}

export async function queryAppInstallCompanies(params) {
  const { versionId } = params;
  console.log('11versionId22->', versionId);
  return request(`appMarket/version/${versionId}/installed/companies`, {
    params,
  });
}

export async function getCanBindLocalReleaseCompanies(params) {
  const { appVersionId } = params;
  delete params.appVersionId;
  return request(`/app/LocalReleaseCompany/${appVersionId}`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function getLocalReleaseCompanies(params) {
  const { appVersionId } = params;
  delete params.appVersionId;
  params.include = 'company';
  return request(`/app/publishedLocalReleaseCompany/${appVersionId}`, {
    params,
  });
}

export async function createLocalRelease(params) {
  const { appVersionId, companyQuery } = params;
  delete params.appVersionId;
  delete params.companyQuery;
  return request(`/appMarket/appVersion/${appVersionId}/statusLocalRelease`, {
    method: 'POST',
    data: {
      ...companyQuery,
      ...params,
    },
  });
}

export async function deleteLocalRelease(params) {
  const { appVersionId } = params;
  delete params.appVersionId;
  return request(`/appMarket/appVersion/${appVersionId}/deleteLocalRelease`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function appVersionStageValidatorPhone() {
  return request.get(`/app-version-stage-validator-phone`);
}

export async function appVersionStageVerificationCode() {
  return request.post(`/app-version-stage-verification-code`);
}
