import {
  queryChainBrands,
  createChainBrands,
  editChainBrands,
  queryChainBrandStoreForId,
} from '@/services/chainBrand';

export default {
  namespace: 'ChainBrandManage',
  state: {},
  effects: {
    *queryChainBrandForId({ payload, callback }, { call, put }) {
      const { data } = yield call(queryChainBrandStoreForId, payload);

      if (callback) {
        callback(data);
      }

      return data;
    },

    *queryChainBrands({ payload, callback }, { call, put }) {
      const { data } = yield call(queryChainBrands, payload);

      if (callback) {
        callback(data);
      }

      return data;
    },
    *createChainBrands({ payload, callback }, { call, put }) {
      const { data } = yield call(createChainBrands, payload);

      if (callback) {
        callback(data);
      }
      return data;
    },
    *editChainBrands({ payload }, { call, put }) {
      const { data } = yield call(editChainBrands, payload);
      return data;
    },
  },
};
