import {
  getUpgraddeBlackListCompanies,
  createUpgradeBlackListCompanies,
  deleteUpgradeBlackListCompanies,
  getCanAddBlackListCompanies,
} from '@/services/remoteUpgrade';

export default {
  namespace: 'UpgradeBlacklistCompanies_upgradeBlacklistCompanies',

  state: {
    blackList: [],
    canAddList: [],
    response: '',
  },

  effects: {
    *fetchBlackList({ payload }, { call, put }) {
      const { status, data } = yield call(
        getUpgraddeBlackListCompanies,
        payload
      );

      if (status === 200) {
        yield put({
          type: 'saveBlackList',
          payload: {
            list: data.data,
            pagination: {
              total: data.meta.total,
              pageSize: parseInt(data.meta.per_page),
              current: data.meta.current_page,
            },
          },
        });
      }
    },
    *create({ payload }, { call, put }) {
      const { status } = yield call(createUpgradeBlackListCompanies, payload);
      yield put({
        type: 'saveResponse',
        payload: status,
      });
    },
    *delete({ payload }, { call, put }) {
      const { status } = yield call(deleteUpgradeBlackListCompanies, payload);
      yield put({
        type: 'saveResponse',
        payload: status,
      });
    },
    *fetchCanAddList({ payload }, { call, put }) {
      const { status, data } = yield call(getCanAddBlackListCompanies, payload);

      if (status === 200) {
        yield put({
          type: 'saveCanAddList',
          payload: {
            list: data.data,
            pagination: {
              total: data.meta.total,
              pageSize: data.meta.per_page,
              current: data.meta.current_page,
            },
          },
        });
      }
    },
  },

  reducers: {
    saveBlackList(state, action) {
      return {
        ...state,
        blackList: action.payload,
      };
    },
    saveCanAddList(state, action) {
      return {
        ...state,
        canAddList: action.payload,
      };
    },
    saveResponse(state, { payload }) {
      return {
        ...state,
        response: payload,
      };
    },
  },
};
