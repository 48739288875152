import {
  get_deleteServerSongTasks,
  post_deleteServerSongTasks,
  get_deleteServerSongTasks_fengyun_id,
} from '@/services/company';

export default {
  namespace: 'Devices_devicesRepeatedSongsLog',

  state: {
    data: {},
    fengyunData: {},
  },

  effects: {
    *fetch({ payload }, { call, put }) {
      const { status, data } = yield call(get_deleteServerSongTasks, payload);

      if (status === 200) {
        yield put({
          type: 'save',
          payload: {
            list: data.data.items,
            pagination: {
              total: data.data.meta.total,
              pageSize: data.data.meta.per_page,
              current: data.data.meta.current_page,
            },
          },
        });
      }
    },

    *addTask({ payload }, { call }) {
      try {
        const res = yield call(post_deleteServerSongTasks, payload);
        return res;
      } catch (e) {
        return Promise.reject(e);
      }
    },

    *fengyunDetailUrl({ payload }, { call, put }) {
      const { status, data } = yield call(
        get_deleteServerSongTasks_fengyun_id,
        payload
      );

      if (status === 200) {
        yield put({
          type: 'saveFengyun',
          payload: data,
        });
      }
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        data: payload,
      };
    },

    saveFengyun(state, { payload }) {
      return {
        ...state,
        fengyunData: payload,
      };
    },
  },
};
