import {
  startuse,
  stoptuse,
  delete_activity,
  getCompanyAdvert,
  getpadAdverts,
  getCompanyAdvertDetail,
  setCompanyAdvert,
  addpadAdverts,
  setpadAdverts,
  updatepadAdverts,
  getOssConfigs,
} from '@/services/resourceCenter/advert';
import { asyncGetClient } from '@/utils/oss';

export default {
  namespace: 'ValueAddedCenter_padAdvertSetting',

  state: {
    current: {},
  },
  effects: {
    *enable({ payload, callback }, { call, put }) {
      const { data } = yield call(startuse, payload);
      return data;
    },
    // *disable({ payload, callback }, { call, put }) {
    //   const { data } = yield call(stoptuse, payload);
    //   return data;
    // },
    *dele({ payload, callback }, { call, put }) {
      const { data } = yield call(delete_activity, payload);
      return data;
    },
    *get({ payload }, { call, put }) {
      const { status, data } = yield call(getpadAdverts, payload);
      if (status === 200) {
        yield put({
          type: 'saveData',
          payload: {
            list: data.data,
            pagination: {
              total: parseInt(data.total),
              pageSize: parseInt(data.per_page),
              current: parseInt(data.current_page),
            },
          },
        });
      }
    },
    *getCompanyAdvert({ payload }, { call, put }) {
      const { status, data } = yield call(getCompanyAdvert, payload);
      if (status === 200) {
        yield put({
          type: 'saveCurrent',
          payload: data,
        });
      }
      return data;
    },
    *addpad({ payload, callback }, { call, put }) {
      const { data } = yield call(addpadAdverts, payload);
      return data;
    },
    *setCompanyAdvert({ payload, callback }, { call, put }) {
      const { data } = yield call(setCompanyAdvert, payload);
      return data;
    },

    // *setpad({ payload, callback }, { call, put }) {
    //   const { data } = yield call(setpadAdverts, payload);
    //   return data;
    // },
    *update({ payload, callback }, { call, put }) {
      const { data } = yield call(updatepadAdverts, payload);
      return data;
    },
    *fetchOssConfig() {
      return yield (async () => {
        let ossConfigs = {};

        try {
          ossConfigs = await asyncGetClient();
        } catch (error) {
          console.error(error);
          return;
        }
        return ossConfigs;
      })();
    },
  },

  reducers: {
    saveData(state, action) {
      return {
        ...state,
        indexData: action.payload,
      };
    },
    saveCurrent(state, action) {
      return {
        ...state,
        current: action.payload,
      };
    },
    save(state, { payload }) {
      return {
        ...state,
        data: payload,
      };
    },
  },
};
