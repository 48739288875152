import * as companyresource from '@/services/companyresource';

export default {
  namespace: 'CompanyResource_ScribePrice',
  state: {
    ScribePrice: [],
  },
  reducers: {
    saveScribePriceData(state, action) {
      return {
        ...state,
        ScribePrice: action.payload.data,
      };
    },
  },
  effects: {
    // 获取订阅包年/包月价格
    *subScribePrice({ payload }, { call, put }) {
      const { status, data } = yield call(
        companyresource.queryScribePriceData,
        payload
      );

      if (status === 200) {
        yield put({
          type: 'saveScribePriceData',
          payload: {
            data,
          },
        });
      }
    },

    // 年订阅确认
    *storeYearSubscriptionOk({ payload, callback }, { call, put }) {
      const { status } = yield call(
        companyresource.YearSubscriptionOk,
        payload
      );

      if (status === 201) {
        if (callback) callback();
      }
    },

    // 月订阅确认
    *storeMonthSubscriptionOk({ payload, callback }, { call, put }) {
      const { status } = yield call(
        companyresource.MonthSubscriptionOk,
        payload
      );

      if (status === 201) {
        if (callback) callback();
      }
    },
  },
};
