import { message } from 'antd';
import OSS from 'ali-oss';
import moment from 'moment';
import { showOSSConfig } from '@/services/api';

// 1 min 内不重复获取STS配置
export const asyncGetClient = (() => {
  let uploadConfig = null;
  let prevTime = +new Date();
  return async (getOssConfigApi = showOSSConfig) => {
    if (uploadConfig && +new Date() - prevTime < 60 * 1000) return uploadConfig;
    prevTime = +new Date();
    const { data, status } = await getOssConfigApi();
    if (status === 200) {
      uploadConfig = {
        domain: data.data.domain,
        client: new OSS({
          ...data.data,
          refreshSTSToken: async () => {
            const { data: refreshToken = {} } = await getOssConfigApi();
            return {
              ...refreshToken.data,
            };
          },
        }),
      };
      return uploadConfig;
    }
    return {};
  };
})();

// 定义上传文件的路径URL
const uploadPath = (path, file) =>
  // 上传文件的路径，使用日期命名文件目录
  `${moment().format('YYYYMMDD')}/${file.name.split('.')[0]}-${
    file.uid
  }.${file.type.split('/')[1] || file.name.split('.')[1]}`;

export const uploadToOss = async (_, path, file, onchange) => {
  const { client, domain } = await asyncGetClient();
  if (!client) return message.error('获取OSS配置失败! 请稍后再试');
  const url = uploadPath(path, file);
  return new Promise((resolve, reject) => {
    client
      .multipartUpload(url, file, {
        progress: percent => {
          if (typeof onchange === 'function') onchange(percent);
        },
      })
      .then(data => {
        data.domain = domain;
        resolve(data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

// 新的文件上传oss后获取到oss的文件url
export const newUploadToOss = async (path, file, onchange) => {
  const { client, domain } = await asyncGetClient();
  if (!client) return message.error('获取OSS配置失败! 请稍后再试');

  const fileName = file.name;
  const suffix = fileName.substring(fileName.lastIndexOf('.'));
  const storeAs = `${path}/${moment().format(
    'YYYYMMDD'
  )}/${new Date().getTime()}${suffix}`;

  return new Promise((resolve, reject) => {
    client
      .multipartUpload(storeAs, file, {
        progress: percent => {
          if (typeof onchange === 'function') onchange(percent);
        },
      })
      .then(data => {
        const urlData = new URL(data.res.requestUrls);
        const url = domain + urlData.pathname;
        resolve(url);
      })
      .catch(error => {
        reject(error);
      });
  });
};
