import { parse } from 'query-string';
import { saveAs } from 'file-saver';
import { isObject, isFunction } from 'util';
import { values } from 'lodash';
import moment from 'moment';

/* eslint no-useless-escape:0 import/prefer-default-export:0 */
const reg = /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;

export function isUrl(path) {
  return reg.test(path);
}

export function isPromise(val) {
  return isObject(val) && isFunction(val.then) && isFunction(val.catch);
}

export function getPageQuery() {
  return parse(window.location.href.split('?')[1]);
}

export function firstNumberInSumPercent(a, b) {
  let total = a + b;
  if (isObject(b)) {
    total = values(b).reduce((sum, newItem) => sum + newItem, 0);
  }
  if (total === 0) {
    return '0%';
  }
  const percent = ((a / total) * 100).toFixed(2);

  return `${percent}%`;
}

export function generateLoginUrl(url = window.location.href) {
  return `${process.env.LOGIN_URL}?redirect_url=${encodeURIComponent(url)}`;
}

export function coverToHttps(src) {
  return typeof src === 'string' ? src.replace(/^http?:/, 'https:') : src;
}

export function urlTest(str) {
  return /^https?:\/\//.test(str);
}

export function imageTest(src) {
  return /^https?:\/\//.test(src) && /\.(jpeg|png|jpg)$/.test(src);
}

/** 判断是否是cdn */
export function cdnTest(str) {
  if (str) return str.includes('cdn');
}

/** 转换为 https */
export function convertToHttps(str) {
  return typeof str === 'string' ? str.replace(/^http:\/\//, 'https://') : '';
}

/**
 * 智控中心下载图片,处理工具段上传资源跨域问题
 * @param {*} src
 * @param {*} name
 */
export function downloadImage(src, name) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    // 解决跨域 canvas 污染问题
    image.setAttribute('crossOrigin', 'anonymous');
    image.src = convertToHttps(src);
    image.onload = function() {
      try {
        const canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;
        const context = canvas.getContext('2d');
        context.drawImage(image, 0, 0, image.width, image.height);
        const url = canvas.toDataURL('image/png');
        const a = document.createElement('a');
        const event = new MouseEvent('click');
        a.download =
          name ||
          src
            .split('/')
            .pop()
            .split('.')
            .shift();
        a.href = url;
        a.dispatchEvent(event);
        console.log(url);
        resolve();
      } catch (error) {
        reject(error);
      }
    };
  });
}

function createObjectURL(object) {
  return window.URL
    ? window.URL.createObjectURL(object)
    : window.webkitURL.createObjectURL(object);
}

export function downloadUrlFile(src, name) {
  saveAs(
    src,
    name ||
      src
        .split('/')
        .pop()
        .split('.')
        .shift()
  );
}

export function downloadUrl(src) {
  downloadUrlFile(convertToHttps(decodeURI(src)));
}

export const validatorPhone = (rule, value, callback) => {
  const phone = value && value.replace(/\s/g, '');

  if (phone === '' || phone === 'undefined' || phone == null) {
    callback();
  } else if (phone.length !== 11) {
    callback('请输入11位手机号');
  } else if (!/^1[3456789]\d{9}$/.test(phone)) {
    callback('请输入正确的手机号');
  } else {
    return true;
  }
};

// 表单时间搜索转换
export const timeSearchTransformer = (time, position) => {
  if (position === 'start')
    return time && time?.length !== 0
      ? moment(time[0]).format('YYYY-MM-DD HH:mm:ss')
      : undefined;
  if (position === 'end')
    return time && time?.length !== 0
      ? moment(time[1]).format('YYYY-MM-DD HH:mm:ss')
      : undefined;
};
// 10.1.1.b8, 10.1.1
export const compareStbVersionIsLessThan = (currentVersion, targetVersion) => {
  const currentVersions = currentVersion.split('.');
  const targetVersions = targetVersion.split('.');

  if (currentVersion.length < 3) {
    return true;
  }

  // 大于，直接返回 false
  if (
    parseInt(currentVersions[0].replace('v', '')) > parseInt(targetVersions[0])
  ) {
    return false;
  }
  if (
    parseInt(currentVersions[0].replace('v', '')) < parseInt(targetVersions[0])
  ) {
    return true;
  }

  // 大于，直接返回 false
  if (parseInt(currentVersions[1]) > parseInt(targetVersions[1])) {
    return false;
  }
  if (parseInt(currentVersions[1]) < parseInt(targetVersions[1])) {
    return true;
  }

  // 大于等于，直接 false
  if (parseInt(currentVersions[2]) > parseInt(targetVersions[2])) {
    return false;
  }
  if (parseInt(currentVersions[2]) < parseInt(targetVersions[2])) {
    return true;
  }

  return false;
};

// VJ素材code转码
export const generateVJCode = str => {
  const num = Number(str.substr(2));
  let str16 = num.toString(16);
  if (str16.length % 2 === 1) str16 = `${str16.slice(0, 1)}0${str16.slice(1)}`;

  while (str16.length !== 6) {
    str16 = `0${str16}`;
  }

  const arr16 = str16.match(/.{2}/g);
  const arr10 = arr16.map(item => parseInt(Number(`0x${item}`), 10));
  const result = arr10.join(' ');

  return result;
};

// 使用字符串路径获取对象的值
export function getObjValueByChainStr(obj, chain) {
  const chainSplit = chain.split('.');
  let result = obj;
  for (let i = 0; i < chainSplit.length; i++) {
    if (!result[chainSplit[i]]) {
      return false;
    }
    result = result[chainSplit[i]];
  }
  return result;
}

/* 判断版本号10.5.6是否介于minimum和maximum之间
 * 当 maximum 不传时代表无限大
 * minimum <= current <= maximum */
function versionIsBetween(current, minimum, maximum = null) {
  const pattern = /^(\d+)\.(\d+)\.(\d+)$/;
  const [, currentMajor, currentMinor, currentPatch] =
    pattern.exec(current) || [];
  const [, minimumMajor, minimumMinor, minimumPatch] =
    pattern.exec(minimum) || [];
  const [, maximumMajor, maximumMinor, maximumPatch] =
    pattern.exec(maximum) || [];
  if (!currentMajor || !minimumMajor || (maximum !== null && !maximumMajor)) {
    return false;
  }

  const compare = (a, b) =>
    // eslint-disable-next-line no-nested-ternary
    a[0] !== b[0] ? a[0] - b[0] : a[1] !== b[1] ? a[1] - b[1] : a[2] - b[2];
  const curr = [currentMajor, currentMinor, currentPatch].map(Number);
  const min = [minimumMajor, minimumMinor, minimumPatch].map(Number);
  const max = [
    maximumMajor || Number.POSITIVE_INFINITY,
    maximumMinor || Number.POSITIVE_INFINITY,
    maximumPatch || Number.POSITIVE_INFINITY,
  ].map(Number);
  return compare(min, curr) <= 0 && compare(curr, max) <= 0;
}

// 冒号格式mac地址
export const addColonsToMacAddress = macAddress =>
  macAddress ? macAddress.match(/.{1,2}/g).join(':') : '';
