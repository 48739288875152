import {
  allMenuList,
  userMenuList,
  menuCreate,
  menuEdit,
  menuDelete,
} from '@/services/menu';

export default {
  namespace: 'SystemManager_menu',

  state: {
    data: {},
    menuData: {},
    userInfo: {},
    result: {},
  },

  effects: {
    *fetch({ payload }, { call, put }) {
      const {
        data: { data },
      } = yield call(allMenuList, payload);

      yield put({
        type: 'save',
        payload: {
          list: data,
        },
      });
    },
    *getUserMenu({ payload }, { call, put }) {
      const {
        data: { data: menuData },
      } = yield call(userMenuList, payload);

      yield put({
        type: 'saveUserMenu',
        payload: {
          list: menuData,
        },
      });
    },
    *create({ payload }, { call, put }) {
      const { data } = yield call(menuCreate, payload);
      yield put({
        type: 'saveResult',
        payload: data,
      });
    },
    *edit({ payload }, { call, put }) {
      const { data } = yield call(menuEdit, payload);
      yield put({
        type: 'saveResult',
        payload: data,
      });
    },
    *delete({ payload }, { call, put }) {
      const { data } = yield call(menuDelete, payload);
      yield put({
        type: 'saveResult',
        payload: data.result,
      });
    },
  },
  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        data: payload,
      };
    },
    saveUserMenu(state, { payload }) {
      return {
        ...state,
        menuData: payload,
      };
    },
    saveResult(state, { payload }) {
      return {
        ...state,
        result: payload,
      };
    },
  },
};
