import * as companyresource from '@/services/companyresource';

export default {
  namespace: 'CompanyResource_ResourceBuy',
  state: {
    data: {},
    ScribePrice: {},
    BuyResourceData: {},
  },
  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        data: payload,
      };
    },
    saveScribePriceData(state, action) {
      return {
        ...state,
        ScribePrice: action.payload.data,
      };
    },
    saveBuyResourceData(state, action) {
      return {
        ...state,
        BuyResourceData: action.payload.data,
      };
    },
  },
  effects: {
    // 根据盒子MAC获取盒子对应素材
    *fetchResourceToMac({ payload }, { call, put }) {
      const { status, data } = yield call(
        companyresource.queryResourceToMac,
        payload
      );
      if (status === 200) {
        yield put({
          type: 'save',
          payload: {
            list: data.data,
            pagination: {
              total: data.total,
              pageSize: data.per_page,
              current: data.current_page,
            },
          },
        });
      }
    },

    // 单个素材结算
    *storeSettleAccount({ payload, callback }, { call, put }) {
      const { status } = yield call(
        companyresource.SettleAccountResourceBuy,
        payload
      );

      if (status === 201) {
        if (callback) callback();
      }
    },

    // 素材批量结算
    *storeSettleAccountList({ payload, callback }, { call, put }) {
      const { status } = yield call(
        companyresource.SettleAccountResourceListBuy,
        payload
      );

      if (status === 201) {
        if (callback) callback();
      }
    },

    // 获取订阅包年/包月价格
    *buySubscribePrice({ payload }, { call, put }) {
      const { status, data } = yield call(
        companyresource.queryScribePriceData,
        payload
      );

      if (status === 200) {
        yield put({
          type: 'saveScribePriceData',
          payload: {
            data,
          },
        });
      }
    },

    // 根据code获取素材信息
    *getBuyResourceData({ payload }, { call, put }) {
      const { status, data } = yield call(
        companyresource.queryBuyResourceData,
        payload
      );

      if (status === 200) {
        yield put({
          type: 'saveBuyResourceData',
          payload: {
            data,
          },
        });
      }
    },
  },
};
