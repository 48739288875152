import {
  orderList,
  accountList,
  consumeList,
  balanceChangedList,
  AccountListBind,
} from '@/services/RSMarket/backendAgent';

export default {
  namespace: 'ValueAddedCenter_RSMarket_backendAgent',

  state: {
    orderListIndexData: {
      list: [],
      pagination: {},
    },
    accountListIndexData: {
      list: [],
      pagination: {},
    },
    consumeListIndexData: {
      list: [],
      pagination: {},
    },
    balanceChangedListIndexData: {
      list: [],
      pagination: {},
    },
  },

  effects: {
    *orderListIndex({ payload }, { call, put }) {
      const { status, data } = yield call(orderList, payload);

      if (status === 200) {
        yield put({
          type: 'orderListIndexData',
          payload: {
            list: data.data,
            pagination: {
              total: data.total,
              pageSize: data.per_page,
              current: parseInt(data.current_page),
            },
          },
        });
      }
    },

    *accountListIndex({ payload }, { call, put }) {
      const { status, data } = yield call(accountList, payload);

      if (status === 200) {
        yield put({
          type: 'accountListIndexData',
          payload: {
            list: data.data,
            pagination: {
              total: data.total,
              pageSize: data.per_page,
              current: parseInt(data.current_page),
            },
          },
        });
      }
    },

    *consumeListIndex({ payload }, { call, put }) {
      const { status, data } = yield call(consumeList, payload);

      if (status === 200) {
        yield put({
          type: 'consumeListIndexData',
          payload: {
            list: data.data,
            pagination: {
              total: data.total,
              pageSize: data.per_page,
              current: parseInt(data.current_page),
            },
          },
        });
      }
    },

    *balanceChangedListIndex({ payload }, { call, put }) {
      const { status, data } = yield call(balanceChangedList, payload);

      if (status === 200) {
        yield put({
          type: 'balanceChangedListIndexData',
          payload: {
            list: data.data,
            pagination: {
              total: data.total,
              pageSize: data.per_page,
              current: parseInt(data.current_page),
            },
          },
        });
      }
    },

    *bind({ payload, callback }, { call, put }) {
      const { status } = yield call(AccountListBind, payload);

      if (status === 201) {
        if (callback) callback();
      }
    },
  },

  reducers: {
    orderListIndexData(state, action) {
      return {
        ...state,
        orderListIndexData: action.payload,
      };
    },

    accountListIndexData(state, action) {
      return {
        ...state,
        accountListIndexData: action.payload,
      };
    },

    consumeListIndexData(state, action) {
      return {
        ...state,
        consumeListIndexData: action.payload,
      };
    },

    balanceChangedListIndexData(state, action) {
      return {
        ...state,
        balanceChangedListIndexData: action.payload,
      };
    },
  },
};
