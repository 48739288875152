import {
  getClassification,
  deleteClassification,
  saveClassification,
  getClassificationOne,
} from '@/services/MusicLibrary/remoteResource';

export default {
  namespace: 'Classification',

  state: {
    data: {},
    visible: false,
    current: [],
    sortedInfo: '',
    result: {},
    data_one: {},
  },

  effects: {
    *fetchClassification({ payload }, { call, put }) {
      const { status, data } = yield call(getClassification, payload);

      if (status === 200) {
        yield put({
          type: 'save',
          payload: {
            list: data.data,
            pagination: {
              total: data.meta.total,
              pageSize: data.meta.per_page,
              current: data.meta.current_page,
            },
          },
        });
      }
    },
    *delClassification({ payload }, { call, put }) {
      const { status, data } = yield call(deleteClassification, payload);
      if (status === 200) {
        yield put({
          type: 'saveResult',
          payload: data,
        });
      }
    },
    *fetchOne({ payload }, { call, put }) {
      const { status, data } = yield call(getClassificationOne, payload);
      if (status === 200) {
        yield put({
          type: 'saveOne',
          payload: data,
        });
      }
    },
    *editClassification({ payload }, { call, put }) {
      const { status, data } = yield call(saveClassification, payload);
      if (status === 200) {
        yield put({
          type: 'saveResult',
          payload: data,
        });
      }
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        data: payload,
      };
    },
    saveResult(state, { payload }) {
      return {
        ...state,
        result: payload,
      };
    },
    saveOne(state, { payload }) {
      return {
        ...state,
        data_one: payload,
      };
    },
  },
};
