import {
  getPageData,
  queryPrinciple,
  exportFile,
  getSubSystem,
  getDeptData,
  exportDeptFile,
  getManagementUrl,
  getDeptUrl,
  getPageDataNew,
  getDeptDataNew,
} from '@/services/dataCenter';
import { fetchManagementTypesData } from '@/services/piracy';

export default {
  namespace: 'FeaturesUseAnalyze',

  state: {
    data: {},
    dept_data: {},
    principle_regions: [],
    result: {},
    exportResult: {},
    managementSystemTypes: [],
    subSystemTypes: [],
    management_url: [],
    dept_url: [],
  },

  effects: {
    fetchPageData: [
      function*({ payload }, { call, put }) {
        const { status, data } = yield call(getPageData, payload);

        if (status === 200) {
          yield put({
            type: 'save',
            payload: {
              list: data.data.data,
              pagination: {
                total: data.data.total,
                pageSize: data.data.perPage,
                current: data.data.currentPage,
              },
            },
          });
        }
      },
      { type: 'takeLatest' },
    ],
    *fetchPrinciple({ payload }, { call, put }) {
      const { data } = yield call(queryPrinciple, payload);
      yield put({
        type: 'savePrinciple',
        payload: data,
      });
    },
    *export({ payload }, { call, put }) {
      const { data } = yield call(exportFile, payload);
      yield put({
        type: 'saveExport',
        payload: data,
      });
    },
    *exportDept({ payload }, { call, put }) {
      const { data } = yield call(exportDeptFile, payload);
      yield put({
        type: 'saveExport',
        payload: data,
      });
    },
    *fetchManagementType({ payload }, { call, put }) {
      const { data } = yield call(fetchManagementTypesData, payload);
      yield put({
        type: 'saveManagementType',
        payload: data,
      });
    },
    *fetchSubSystemTypes({ payload }, { call, put }) {
      const { data } = yield call(getSubSystem, payload);
      yield put({
        type: 'saveSubSystemType',
        payload: data,
      });
    },
    *fetchDeptData({ payload }, { call, put }) {
      const { data } = yield call(getDeptData, payload);
      yield put({
        type: 'saveDept',
        payload: {
          list: data.data.data,
          pagination: {
            total: data.data.total,
            pageSize: data.data.perPage,
            current: data.data.currentPage,
          },
        },
      });
    },
    *fetchManagementUrl({ payload }, { call, put }) {
      const { data } = yield call(getManagementUrl, payload);
      yield put({
        type: 'saveManagementUrl',
        payload: data,
      });
    },
    *fetchDeptUrl({ payload }, { call, put }) {
      const { data } = yield call(getDeptUrl, payload);
      yield put({
        type: 'saveDeptUrl',
        payload: data,
      });
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        data: payload,
      };
    },
    saveDept(state, { payload }) {
      return {
        ...state,
        dept_data: payload,
      };
    },
    savePrinciple(state, { payload }) {
      return {
        ...state,
        principle_regions: payload,
      };
    },
    saveExport(state, { payload }) {
      return {
        ...state,
        exportResult: payload,
      };
    },
    saveManagementType(state, { payload }) {
      return {
        ...state,
        managementSystemTypes: payload,
      };
    },
    saveSubSystemType(state, { payload }) {
      return {
        ...state,
        subSystemTypes: payload,
      };
    },
    saveManagementUrl(state, { payload }) {
      return {
        ...state,
        management_url: payload,
      };
    },
    saveDeptUrl(state, { payload }) {
      return {
        ...state,
        dept_url: payload,
      };
    },
  },
};
