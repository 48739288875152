import {
  getDeviceAbnormalLogList,
  getDeviceDetailInfo,
} from '@/services/devices';

export default {
  namespace: 'Devices_abnormallog',

  state: {
    result: [],
  },

  effects: {
    *fetchList({ payload }, { call, put }) {
      const { status, data } = yield call(getDeviceAbnormalLogList, payload);
      if (status === 200) {
        yield put({
          type: 'queryList',
          payload: {
            list: data.result.data,
            pagination: {
              total: data.result.meta.total,
              pageSize: data.result.meta.per_page,
              current: data.result.meta.current_page,
            },
          },
        });
      }
    },
    *fetchDeviceInfo({ payload }, { call, put }) {
      const { data } = yield call(getDeviceDetailInfo, payload);
      yield put({
        type: 'queryInfo',
        payload: data,
      });
    },
  },

  reducers: {
    queryList(state, action) {
      return {
        ...state,
        result: action.payload,
      };
    },
    queryInfo(state, action) {
      return {
        ...state,
        info: action.payload,
      };
    },
  },
};
