import { queryPlatformList } from '@/services/manage/platform';

export default {
  namespace: 'platform',

  state: {},

  effects: {
    *queryPlatformList({ payload }, { call }) {
      const { data } = yield call(queryPlatformList, payload);
      return data;
    },
  },
};
