import {
  getSongsList,
  getStatisticsData,
  songExport,
  uploadSongFile,
  uploadSongFileDetail,
  getOSSConfig,
  uploadFourkSongFileMap,
  uploadFourkSongFileMapDetail,
} from '@/services/MusicLibrary/musiclibrary';

export default {
  namespace: 'Song_Info',

  state: {
    data: {
      list: [],
      pagination: {},
    },
    visible: false,
    current: [],
    sortedInfo: '',
    result: {},
    data_one: {},
  },

  effects: {
    *fetchSongsList({ payload }, { call, put }) {
      const { status, data } = yield call(getSongsList, payload);

      if (status === 200) {
        yield put({
          type: 'save',
          payload: {
            list: data.data,
            pagination: {
              total: data.meta.total,
              pageSize: data.meta.per_page,
              current: data.meta.current_page,
            },
          },
        });
      }
    },

    *fetchStatisticsData({ payload }, { call, put }) {
      const { status, data } = yield call(getStatisticsData, payload);

      if (status === 200) {
        yield put({
          type: 'saveResult',
          payload: data,
        });
      }
    },

    *songExport({ payload, callback }, { call }) {
      const { status } = yield call(songExport, payload);
      if (status === 201) {
        callback();
      }
    },

    *uploadSongFile({ payload, callback, errorCallback }, { call, put }) {
      const { status, data } = yield call(uploadSongFile, payload);
      if (status === 200) {
        callback(data);
      }
      if (status === 400) {
        errorCallback(data);
      }
    },

    *uploadSongFileDetail({ payload, callback }, { call, put }) {
      const { status, data } = yield call(uploadSongFileDetail, payload);
      if (status === 201) {
        callback(data);
      }
    },

    *uploadFourkSongFileMap(
      { payload, callback, errorCallback },
      { call, put }
    ) {
      const { status, data } = yield call(uploadFourkSongFileMap, payload);
      if (status === 200) {
        callback(data);
      }
      if (status === 400) {
        errorCallback(data);
      }
    },

    *uploadFourkSongFileMapDetail({ payload, callback }, { call, put }) {
      const { status, data } = yield call(
        uploadFourkSongFileMapDetail,
        payload
      );
      if (status === 201) {
        callback(data);
      }
    },

    *fetchOSSConfig({ payload, callback }, { call, put }) {
      const { status, data } = yield call(getOSSConfig, payload);
      if (status === 200) {
        callback(data);
      }
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        data: payload,
      };
    },

    saveResult(state, { payload }) {
      return {
        ...state,
        result: payload,
      };
    },
  },
};
