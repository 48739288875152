import {
  querySkins,
  startShelves,
  stopShelves,
  saveSkins,
  showSkins,
  updateSkin,
  companyPushSkin,
  companyCurrentUseSkin,
  companySkinPushedLogs,
  companySkinPushedChangeStatus,
} from '@/services/nightclubSkin';

export default {
  namespace: 'ValueAddedCenter_nightclubSkin',

  state: {
    data: {},
    show: {},
    companyCurrentUseSkin: {},
    companySkinPushedLogsData: {},
    companySkinPushedChangeStatusData: {},
  },

  effects: {
    *fetch({ payload }, { call, put }) {
      const { status, data } = yield call(querySkins, payload);
      if (status === 200) {
        yield put({
          type: 'save',
          payload: {
            list: data.data,
            pagination: {
              total: data.meta.total,
              pageSize: data.meta.per_page,
              current: data.meta.current_page,
            },
          },
        });
      }
    },

    *companySkinPushedLogs({ payload }, { call, put }) {
      const { status, data } = yield call(companySkinPushedLogs, payload);
      if (status === 200) {
        yield put({
          type: 'saveCompanyPushedLogs',
          payload: {
            list: data.data,
            pagination: {
              total: data.meta.total,
              pageSize: data.meta.per_page,
              current: data.meta.current_page,
            },
          },
        });
      }
    },

    *startShelves({ payload, callback }, { call, put }) {
      const { status, data } = yield call(startShelves, payload);
      if (status === 201 && callback) {
        callback();
      }
    },

    *stopShelves({ payload, callback }, { call, put }) {
      const { status, data } = yield call(stopShelves, payload);
      if (status === 201 && callback) {
        callback();
      }
    },

    *saveSkins({ payload, callback }, { call, put }) {
      const { status, data } = yield call(saveSkins, payload);
      if (status === 201 && callback) {
        callback();
      }
    },

    *updateSkin({ payload, callback }, { call, put }) {
      const { status, data } = yield call(updateSkin, payload);
      if (status === 200 && callback) {
        callback();
      }
    },

    *show({ payload, callback }, { call, put }) {
      const { status, data } = yield call(showSkins, payload);
      if (status === 200) {
        yield put({
          type: 'saveShow',
          payload: {
            ...data,
          },
        });

        callback(data);
      }
    },

    *companyPushSkin({ payload, callback }, { call, put }) {
      const { status } = yield call(companyPushSkin, payload);
      if (status === 201 && callback) {
        callback();
      }
    },

    *companyCurrentUseSkin({ payload }, { call, put }) {
      const { status, data } = yield call(companyCurrentUseSkin, payload);
      if (status === 200) {
        console.log('in model', { data });
        yield put({
          type: 'saveCompanyCurrentUseSkin',
          payload: data,
        });
      }
    },

    *companySkinPushedChangeStatusData({ payload }, { call, put }) {
      const { status, data } = yield call(
        companySkinPushedChangeStatus,
        payload
      );
      if (status === 200) {
        yield put({
          type: 'saveCompanySkinPushedChangeStatusData',
          payload: {
            list: data.data,
            pagination: {
              total: data.meta.total,
              pageSize: data.meta.per_page,
              current: data.meta.current_page,
            },
          },
        });
      }
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        data: payload,
      };
    },

    saveShow(state, { payload }) {
      return {
        ...state,
        show: payload,
      };
    },

    saveCompanyCurrentUseSkin(state, { payload }) {
      return {
        ...state,
        companyCurrentUseSkin: payload,
      };
    },

    saveCompanyPushedLogs(state, { payload }) {
      return {
        ...state,
        companySkinPushedLogsData: payload,
      };
    },

    saveCompanySkinPushedChangeStatusData(state, { payload }) {
      return {
        ...state,
        companySkinPushedChangeStatusData: payload,
      };
    },
  },
};
