import {
  fetchConfigurations,
  createConfigurations,
  updateConfigurations,
  deleteConfigurations,
} from '@/services/configuration';

export default {
  namespace: 'SystemManager_configuration',

  state: {
    data: {},
  },

  effects: {
    *fetch({ payload }, { call, put }) {
      const { data } = yield call(fetchConfigurations, payload);

      yield put({
        type: 'save',
        payload: {
          list: data.data,
          pagination: {
            total: data.meta.total,
            pageSize: data.meta.per_page,
            current: data.meta.current_page,
          },
        },
      });
    },

    *create({ payload, callback }, { call }) {
      const { status } = yield call(createConfigurations, payload);

      if (status === 201) {
        callback();
      }
    },

    *update({ payload, callback }, { call }) {
      const { status } = yield call(updateConfigurations, payload);

      if (status === 200) {
        callback();
      }
    },

    *delete({ payload, callback }, { call }) {
      const { status } = yield call(deleteConfigurations, payload);

      if (status === 204) {
        callback();
      }
    },
  },
  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        data: payload,
      };
    },
  },
};
