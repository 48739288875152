export default {
  'menu.home': '首页',
  'menu.more-blocks': '更多区块',
  'menu.403': '403',
  'menu.404': '404',
  'menu.429': '429',
  'menu.500': '500',
  'menu.empty': '空',
  /*
  |--------------------------------------------------------------------------
  | 管理中心
  |--------------------------------------------------------------------------
  */
  'menu.manage-center': '管理',
  'menu.manage-center.system-manager': '系统管理',
  'menu.manage-center.system-manager.user': '用户管理',
  'menu.manage-center.system-manager.user-data-filter': '数据权限配置',
  'menu.manage-center.system-manager.user-data-filter.region':
    '数据权限配置-区域',
  'menu.manage-center.system-manager.user-data-filter.company':
    '数据权限配置-场所',
  'menu.manage-center.system-manager.role': '角色管理',
  'menu.manage-center.system-manager.menu': '菜单管理',
  'menu.manage-center.system-manager.configuration': '参数配置',

  'menu.manage-center.operation-maintenance-center': '运维管理',
  'menu.manage-center.operation-maintenance-center.test-companies':
    '测试商家管理',
  'menu.manage-center.operation-maintenance-center.verify-test-companies':
    '测试商家管理',
  'menu.manage-center.operation-maintenance-center.distribution-strategy':
    '策略模版管理',
  'menu.manage-center.operation-maintenance-center.distribution-strategy-create':
    '策略模版创建',
  'menu.manage-center.operation-maintenance-center.distribution-strategy-edit':
    '策略模版编辑',
  'menu.manage-center.account': '个人管理',
  'menu.manage-center.account.center': '个人中心',
  'menu.manage-center.account.settings': '个人设置',
  'menu.manage-center.account.settings.base': '基本设置',
  'menu.manage-center.account.settings.security': '安全设置',

  'menu.account.logout': '退出登录',
  /*
  |--------------------------------------------------------------------------
  | 数据中心
  |--------------------------------------------------------------------------
  */
  'menu.data-center': '数据',
  'menu.data-center.dashboard': '数据概览',
  'menu.data-center.dashboard.ktv-system': '点歌系统',
  'menu.data-center.dashboard.management-system': '管理系统',

  'menu.data-center.data-cleansing': '场所分析',
  'menu.data-center.data-cleansing.data-analysis': '趋势分析',
  'menu.data-center.data-cleansing.data-obk-venn': '点播报备K米韦恩图',

  'menu.data-center.company-analyze': '原始数据源',
  'menu.data-center.company-analyze.km-companies': 'K 米场所',
  'menu.data-center.company-analyze.baobei-companies': '报备场所',
  'menu.data-center.company-analyze.sale-authorization-companies':
    '点歌授权场所',
  'menu.data-center.company-analyze.sale-authorization-management-companies':
    '管理授权场所',
  'menu.data-center.device-analyze': '设备分析',
  'menu.data-center.features-use-analyze': '功能使用分析',

  'menu.account.trigger': '触发报错',

  'menu.table.list': '表格列表',

  /*
  |--------------------------------------------------------------------------
  | 运维中心
  |--------------------------------------------------------------------------
  */
  'menu.operation-maintenance-center': '运维',
  'menu.operation-maintenance-center.company-list': '场所列表',
  'menu.operation-maintenance-center.company-list.net-companies': '联网场所',
  'menu.operation-maintenance-center.company-list.not-net-companies':
    '非联网场所',

  'menu.operation-maintenance-center.company': '场所',
  'menu.operation-maintenance-center.company.detail': '场所详情',
  'menu.operation-maintenance-center.company.detail.info': '场所详情',
  'menu.operation-maintenance-center.company.detail.company-boxes': '包厢列表',
  'menu.operation-maintenance-center.company.detail.company-devices-overview':
    '场所概览',
  'menu.operation-maintenance-center.company.devices': '场所设备',
  'menu.operation-maintenance-center.company.devices.stb': '场所机顶盒',
  'menu.operation-maintenance-center.company.devices.fengyun': '场所锋云',
  'menu.operation-maintenance-center.company.devices.gateway': '场所网关',
  'menu.operation-maintenance-center.company.devices.management':
    '场所管理系统',
  'menu.operation-maintenance-center.company.devices.mpj': '门牌机',
  'menu.operation-maintenance-center.company.devices.ddj': '自助点单机',
  'menu.operation-maintenance-center.company.devices.zk': '智控',
  'menu.operation-maintenance-center.company.devices.camera': '摄像头',
  'menu.operation-maintenance-center.company.devices.mic': '小嗨麦克风',
  'menu.operation-maintenance-center.company.devices.yzcy': '远程资源',
  'menu.operation-maintenance-center.company.devices.voice-box': '语音盒',
  'menu.operation-maintenance-center.company.devices.rsbox': '全景服务器',
  'menu.operation-maintenance-center.company.devices.management-peripherals':
    '管理系统外设',
  'menu.operation-maintenance-center.company.detail.company-links':
    '场所拓扑图',
  'menu.operation-maintenance-center.company.detail.company-boxlinks':
    '场所包厢系统运行情况',
  'menu.operation-maintenance-center.company.detail.sub-store-list': '分店列表',
  'menu.operation-maintenance-center.company.fengyun': '锋云',
  'menu.operation-maintenance-center.company.fengyun.files': '文件',
  'menu.operation-maintenance-center.company.gateway': '网关',
  'menu.operation-maintenance-center.company.gateway.files': '网关文件',
  'menu.operation-maintenance-center.company.management': '管理系统',
  'menu.operation-maintenance-center.company.management.peripherals':
    '管理系统外设',
  'menu.operation-maintenance-center.company.product': '商家产品',
  'menu.operation-maintenance-center.company.changelog': '产品变更记录',
  'menu.operation-maintenance-center.company.abnormal': '异常信息记录',

  'menu.operation-maintenance-center.search': '设备搜索',
  'menu.operation-maintenance-center.search.devices': '设备列表',
  'menu.operation-maintenance-center.search.device-exceptions': '设备异常日志',

  'menu.operation-maintenance-center.company-app-manager': '应用市场',
  'menu.operation-maintenance-center.company-app-manager.app-base-list':
    '应用列表',
  'menu.operation-maintenance-center.company-app-manager.app-base-detail':
    '应用参数详情',
  'menu.operation-maintenance-center.company-app-manager.app-net-companies':
    '场所应用',
  'menu.operation-maintenance-center.company-app-manager.stb-sdk-permissions':
    '机顶盒SDK权限管理',
  'menu.operation-maintenance-center.company-app-manager.app-review':
    '应用审核',
  'menu.operation-maintenance-center.company-base-app': '基础应用',
  'menu.operation-maintenance-center.company-base-app.version-list': '版本列表',
  'menu.operation-maintenance-center.company-base-app.version-detail':
    '配置版本',
  'menu.operation-maintenance-center.company-base-app.version-create':
    '创建版本',
  'menu.operation-maintenance-center.company-app-manager.app-stb-adapter':
    '适配采集',
  'menu.operation-maintenance-center.app-stb-adapter': '机顶盒信息',
  'menu.operation-maintenance-center.app-stb-adapter.firmware': '固件信息',
  'menu.operation-maintenance-center.app-stb-adapter.main-app-group':
    '主应用信息',
  'menu.operation-maintenance-center.company-app-manager.manufacturer':
    '厂商信息',
  'menu.operation-maintenance-center.app-stb-adapter.hardware-model':
    '硬件信息',

  'menu.operation-maintenance-center.base-app': '应用',
  'menu.operation-maintenance-center.base-app.version': '应用版本',
  'menu.operation-maintenance-center.base-app.version.log': '发布日志',
  'menu.operation-maintenance-center.base-app.version.log-companies':
    '发布状态场所',
  'menu.operation-maintenance-center.base-app.version.local-release':
    '区域发布',

  'menu.operation-maintenance-center.companyApp': '场所应用',
  'menu.operation-maintenance-center.companyApp.appDetail': '应用详情',
  'menu.operation-maintenance-center.companyApp.stb-device-app': '机顶盒应用',
  'menu.operation-maintenance-center.companyApp.stb-device-app-log':
    '机顶盒应用日志',
  'menu.operation-maintenance-center.companyApp.appDetail.company-app-overview':
    '场所概览',
  'menu.operation-maintenance-center.companyApp.appDetail.app-boxes':
    '应用机顶盒列表',
  'menu.operation-maintenance-center.companyApp.appDetail.app-install-log':
    '应用安装日志',
  'menu.operation-maintenance-center.company-app-manager.app-base-create':
    '创建应用',
  'menu.operation-maintenance-center.remote-upgrade': '远程升级',
  'menu.operation-maintenance-center.remote-upgrade.verify-packages':
    '升级包验证列表',
  'menu.operation-maintenance-center.remote-upgrade.verify-companies':
    '验证场所管理',
  'menu.operation-maintenance-center.remote-upgrade.upgrade-package-create':
    '创建升级包',
  'menu.operation-maintenance-center.remote-upgrade.upgrade-package-create.upgrade-package-base-info':
    '升级包基本信息',
  'menu.operation-maintenance-center.remote-upgrade.upgrade-package-create.upgrade-package-env-dependent-config':
    '升级包依赖配置',
  'menu.operation-maintenance-center.remote-upgrade.upgrade-package-create.upgrade-package-result':
    '升级包配置结果',
  'menu.operation-maintenance-center.remote-upgrade.upgrade-package-edit':
    '编辑升级包',
  'menu.operation-maintenance-center.remote-upgrade.upgrade-package-match-rules':
    '升级包适用条件规则',
  'menu.operation-maintenance-center.remote-upgrade.pilot-companies':
    '试点场所管理',
  'menu.operation-maintenance-center.remote-upgrade.grounding-strategies':
    '上架策略管理',
  'menu.operation-maintenance-center.remote-upgrade.grounding-strategies.grounding-strategies-list':
    '升级包上架策略列表',
  'menu.operation-maintenance-center.remote-upgrade.grounding-strategies.grounding-strategies-create':
    '升级包上架策略创建',
  'menu.operation-maintenance-center.remote-upgrade.grounding-strategies.grounding-strategies-specific':
    '指定商家升级',
  'menu.operation-maintenance-center.remote-upgrade.grounding-strategies.shelves-jobs':
    '任务列表',
  'menu.operation-maintenance-center.remote-upgrade.pilot-packages':
    '升级包试点列表',
  'menu.operation-maintenance-center.remote-upgrade.shelves-packages':
    '升级包上架列表',
  'menu.operation-maintenance-center.remote-upgrade.upgrade-packages-target-types':
    '升级包目标类型管理',
  'menu.operation-maintenance-center.remote-upgrade.upgrade-blacklist-companies':
    '升级黑名单场所管理',

  'menu.operation-maintenance-center.maintenance-system': '维保系统',
  'menu.operation-maintenance-center.company-app-manager.app-review-detail-en':
    '工单详情',
  'menu.operation-maintenance-center.company-app-manager.hardware-application':
    '硬件平台应用',

  /*
  |--------------------------------------------------------------------------
  | 监控中心
  |--------------------------------------------------------------------------
  */
  'menu.monitor-center': '监控',
  'menu.monitor-center.bugsell-monitor': '窜货监控',
  'menu.monitor-center.company-devices-monitor': '场所设备监控',
  'menu.monitor-center.early-warning-center': '预警中心',

  /*
  |--------------------------------------------------------------------------
  | 支持中心
  |--------------------------------------------------------------------------
  */
  'menu.support-center': '支持',

  /*
  |--------------------------------------------------------------------------
  | 增值中心
  |--------------------------------------------------------------------------
  */
  'menu.value-added-center': '增值',
  'menu.value-added-center.skin-market': '皮肤商城',
  'menu.value-added-center.intelligent-control-market': '智控商城',
  'menu.value-added-center.nightclub-skin': '商务皮肤商城',
  'menu.value-added-center.nightclub-skin.list': '商务皮肤列表',
  'menu.value-added-center.nightclub-skin.create': '夜总会皮肤创建',
  'menu.value-added-center.nightclub-skin.edit': '夜总会皮肤编辑',
  'menu.value-added-center.nightclub-skin.companies': '场所列表',
  'menu.value-added-center.nightclub-skin.company-skin': '场所皮肤推送管理',

  'menu.value-added-center.rsMarket': '环幕商城',
  'menu.value-added-center.rsMarket.materialmanag': '素材管理',
  'menu.value-added-center.rsMarket.rs-resourcesongtype': '歌曲类型配置',
  'menu.value-added-center.rsMarket.rs-resourcesong': '歌曲配置',
  'menu.value-added-center.rsMarket.company_rsbox': '场所环幕盒子',
  'menu.value-added-center.rsMarket.resource_buy': '素材购买',
  'menu.value-added-center.rsMarket.resource_usable': '可用素材',
  'menu.value-added-center.rsMarket.resource_buy_tasks': '素材购买记录',
  'menu.value-added-center.rsMarket.resource_push_tasks': '素材推送记录',
  'menu.value-added-center.rsMarket.resource_subscriber': '素材订阅',
  'menu.value-added-center.rsMarket.resource_transfer': '素材转移',
  'menu.value-added-center.rsMarket.agent_balance': '选择代理商',

  /*
  |--------------------------------------------------------------------------
  | 商务中心
  |--------------------------------------------------------------------------
  */
  'menu.business-center': '商务',
  'menu.business-center.authorize-manage': '授权管控',
  'menu.business-center.authorize-manage.device-authorization-list': '产品授权',
  /*
  |--------------------------------------------------------------------------
  | 连锁中心
  |--------------------------------------------------------------------------
  */
  'menu.chain-center': '连锁',
  /*
  |--------------------------------------------------------------------------
  | 资源中心（曲库）
  |--------------------------------------------------------------------------
  */
  'menu.resource-center': '资源',
};
