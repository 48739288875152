import {
  queryCompanyInfo,
  queryCompanyMainDeviceInfo,
  editCompanyBaseInfo,
  queryAuthorizationInfo,
  managementSqlInjection,
  smsverify,
} from '@/services/company';

export default {
  namespace: 'companyInfo',

  state: {
    companies: {},
    mainDeviceInfo: {},
    result: {},
    authorizationInfo: {},
    code: '',
  },

  effects: {
    *fetch({ payload: companyId }, { call, put }) {
      const { status, data } = yield call(queryCompanyInfo, companyId);

      if (status === 200) {
        yield put({
          type: 'save',
          payload: {
            [companyId]: data.data,
          },
        });
      }
    },

    *fetchCompanyMainDeviceInfo({ payload: companyId }, { call, put }) {
      const { status, data } = yield call(
        queryCompanyMainDeviceInfo,
        companyId
      );

      if (status === 200) {
        yield put({
          type: 'saveMainDeviceInfo',
          payload: data.data,
        });
      }
    },

    *editCompanyBaseInfo({ payload }, { call, put }) {
      const { data } = yield call(editCompanyBaseInfo, payload);

      yield put({
        type: 'saveResult',
        payload: data,
      });
    },

    *fetchCompanyAuthorizationInfo({ payload: companyId }, { call, put }) {
      const { status, data } = yield call(
        queryAuthorizationInfo,
        companyId
      );
      if (status === 200) {
        yield put({
          type: 'saveAuthorizationInfo',
          payload: data,
        });
      }
    },

    *saveManagementSqlInjection({ payload }, { call, put }) {
      const { data } = yield call(managementSqlInjection, payload);

      yield put({
        type: 'saveResult',
        payload: data,
      });
    },

    *sendSmsverify({ payload }, { call, put }) {
      const { data } = yield call(smsverify, payload);

      yield put({
        type: 'saveCode',
        payload: data,
      });
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        companies: {
          ...state.companies,
          ...payload,
        },
      };
    },

    saveMainDeviceInfo(state, { payload }) {
      return {
        ...state,
        mainDeviceInfo: payload,
      };
    },

    saveResult(state, { payload }) {
      return {
        ...state,
        result: payload,
      };
    },

    saveAuthorizationInfo(state, { payload }) {
      return {
        ...state,
        authorizationInfo: payload,
      };
    },

    saveCode(state, { payload }) {
      return {
        ...state,
        code: payload,
      };
    },
  },
};
