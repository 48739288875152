// @ts-nocheck
import { Component } from 'react';
import { ApplyPluginsType } from 'umi';
import dva from 'dva';
// @ts-ignore
import createLoading from '/app/my-app/node_modules/dva-loading/dist/index.esm.js';
import { plugin, history } from '../core/umiExports';
import ModelCompanyInfo0 from '/app/my-app/src/models/companyInfo.js';
import ModelDevicePermission1 from '/app/my-app/src/models/devicePermission.js';
import ModelGlobal2 from '/app/my-app/src/models/global.js';
import ModelList3 from '/app/my-app/src/models/list.js';
import ModelLogin4 from '/app/my-app/src/models/login.js';
import ModelMenu5 from '/app/my-app/src/models/menu.js';
import ModelProject6 from '/app/my-app/src/models/project.js';
import ModelPunishCommon7 from '/app/my-app/src/models/punishCommon.js';
import ModelSetting8 from '/app/my-app/src/models/setting.js';
import ModelUser9 from '/app/my-app/src/models/user.js';
import ModelWorkparams10 from '/app/my-app/src/models/workparams.js';
import ModelGeographic11 from '/app/my-app/src/pages/Account/Settings/models/geographic.js';
import ModelChainBrandManage12 from '/app/my-app/src/pages/BusinessCenter/ChainCenter/models/chainBrandManage.js';
import ModelChainBrandStore13 from '/app/my-app/src/pages/BusinessCenter/ChainCenter/models/chainBrandStore.js';
import ModelChainBrandStoreCompanies14 from '/app/my-app/src/pages/BusinessCenter/ChainCenter/models/chainBrandStoreCompanies.js';
import ModelChainBrandStoreIndustryCompanies15 from '/app/my-app/src/pages/BusinessCenter/ChainCenter/models/chainBrandStoreIndustryCompanies.js';
import ModelAgentManage16 from '/app/my-app/src/pages/BusinessCenter/models/agentManage.js';
import ModelCompany17 from '/app/my-app/src/pages/BusinessCenter/models/company.js';
import ModelDeviceAuthorization18 from '/app/my-app/src/pages/BusinessCenter/models/deviceAuthorization.js';
import ModelIntelligentControlManage19 from '/app/my-app/src/pages/BusinessCenter/models/IntelligentControlManage.js';
import ModelLampManage20 from '/app/my-app/src/pages/BusinessCenter/models/lampManage.js';
import ModelMenu21 from '/app/my-app/src/pages/BusinessCenter/models/menu.js';
import ModelRole22 from '/app/my-app/src/pages/BusinessCenter/models/role.js';
import ModelRoleTemplate23 from '/app/my-app/src/pages/BusinessCenter/models/roleTemplate.js';
import ModelStereoManage24 from '/app/my-app/src/pages/BusinessCenter/models/stereoManage.js';
import ModelVodManage25 from '/app/my-app/src/pages/BusinessCenter/models/vodManage.js';
import ModelBaobeiCompanies26 from '/app/my-app/src/pages/DataCenter/CompanyAnalyze/models/baobeiCompanies.js';
import ModelKmCompanies27 from '/app/my-app/src/pages/DataCenter/CompanyAnalyze/models/kmCompanies.js';
import ModelSaleAutohrizationCompanies28 from '/app/my-app/src/pages/DataCenter/CompanyAnalyze/models/saleAutohrizationCompanies.js';
import ModelSaleAutohrizationManagementCompanies29 from '/app/my-app/src/pages/DataCenter/CompanyAnalyze/models/saleAutohrizationManagementCompanies.js';
import ModelDataAnalysis30 from '/app/my-app/src/pages/DataCenter/DataCleansing/models/dataAnalysis.js';
import ModelAnalysis31 from '/app/my-app/src/pages/DataCenter/FeaturesUseAnalyze/models/analysis.js';
import ModelDataOverview32 from '/app/my-app/src/pages/DataCenter/SystemOverview/models/DataOverview.js';
import ModelInformationManage33 from '/app/my-app/src/pages/ManageCenter/InformationManager/models/informationManage.js';
import ModelMessage34 from '/app/my-app/src/pages/ManageCenter/MessageManager/models/message.js';
import ModelMessageCenter35 from '/app/my-app/src/pages/ManageCenter/MessageManager/models/messageCenter.js';
import ModelAgentMenu36 from '/app/my-app/src/pages/ManageCenter/PlatformManager/models/agentMenu.js';
import ModelCompanyMenu37 from '/app/my-app/src/pages/ManageCenter/PlatformManager/models/companyMenu.js';
import ModelPartnerMenu38 from '/app/my-app/src/pages/ManageCenter/PlatformManager/models/partnerMenu.js';
import ModelPlatform39 from '/app/my-app/src/pages/ManageCenter/PlatformManager/models/platform.js';
import ModelRoleTemplate40 from '/app/my-app/src/pages/ManageCenter/PlatformManager/models/roleTemplate.js';
import ModelConfiguration41 from '/app/my-app/src/pages/ManageCenter/SystemManager/models/configuration.js';
import ModelLampRole42 from '/app/my-app/src/pages/ManageCenter/SystemManager/models/lampRole.js';
import ModelMenu43 from '/app/my-app/src/pages/ManageCenter/SystemManager/models/menu.js';
import ModelRole44 from '/app/my-app/src/pages/ManageCenter/SystemManager/models/role.js';
import ModelUser45 from '/app/my-app/src/pages/ManageCenter/SystemManager/models/user.js';
import ModelCompany46 from '/app/my-app/src/pages/MonitorCenter/Piracy/models/company.js';
import ModelPiracy47 from '/app/my-app/src/pages/MonitorCenter/Piracy/models/piracy.js';
import ModelSchemes48 from '/app/my-app/src/pages/MonitorCenter/Piracy/models/schemes.js';
import ModelCompany49 from '/app/my-app/src/pages/MonitorCenter/Punish/models/company.js';
import ModelSchemes50 from '/app/my-app/src/pages/MonitorCenter/Punish/models/schemes.js';
import ModelDiskAbnormalModels51 from '/app/my-app/src/pages/OperationMaintenanceCenter/CloudData/diskAbnormalInfo/models/diskAbnormalModels.js';
import ModelAppStbAdapter52 from '/app/my-app/src/pages/OperationMaintenanceCenter/CompanyAppManager/AppStbAdapter/models/appStbAdapter.js';
import ModelManufacturer53 from '/app/my-app/src/pages/OperationMaintenanceCenter/CompanyAppManager/AppStbAdapter/models/manufacturer.js';
import ModelAdapterCheckboxList54 from '/app/my-app/src/pages/OperationMaintenanceCenter/CompanyAppManager/models/AdapterCheckboxList.js';
import ModelAppBaseDetail55 from '/app/my-app/src/pages/OperationMaintenanceCenter/CompanyAppManager/models/AppBaseDetail.js';
import ModelAppconfig56 from '/app/my-app/src/pages/OperationMaintenanceCenter/CompanyAppManager/models/appconfig.js';
import ModelAppInstall57 from '/app/my-app/src/pages/OperationMaintenanceCenter/CompanyAppManager/models/appInstall.js';
import ModelAppInstallLog58 from '/app/my-app/src/pages/OperationMaintenanceCenter/CompanyAppManager/models/appInstallLog.js';
import ModelAppManufacturer59 from '/app/my-app/src/pages/OperationMaintenanceCenter/CompanyAppManager/models/appManufacturer.js';
import ModelAppReview60 from '/app/my-app/src/pages/OperationMaintenanceCenter/CompanyAppManager/models/appReview.js';
import ModelAppRunEnv61 from '/app/my-app/src/pages/OperationMaintenanceCenter/CompanyAppManager/models/appRunEnv.js';
import ModelAppVersionStageValidate62 from '/app/my-app/src/pages/OperationMaintenanceCenter/CompanyAppManager/models/appVersionStageValidate.js';
import ModelBaseAppDetail63 from '/app/my-app/src/pages/OperationMaintenanceCenter/CompanyAppManager/models/baseAppDetail.js';
import ModelCompanyAppList64 from '/app/my-app/src/pages/OperationMaintenanceCenter/CompanyAppManager/models/CompanyAppList.js';
import ModelCompanyOverview65 from '/app/my-app/src/pages/OperationMaintenanceCenter/CompanyAppManager/models/companyOverview.js';
import ModelCompanyStb66 from '/app/my-app/src/pages/OperationMaintenanceCenter/CompanyAppManager/models/companyStb.js';
import ModelLocalRelease67 from '/app/my-app/src/pages/OperationMaintenanceCenter/CompanyAppManager/models/localRelease.js';
import ModelStbDevice68 from '/app/my-app/src/pages/OperationMaintenanceCenter/CompanyAppManager/models/stbDevice.js';
import ModelStbSdkPermission69 from '/app/my-app/src/pages/OperationMaintenanceCenter/CompanyAppManager/models/stbSdkPermission.js';
import ModelSubAppDetail70 from '/app/my-app/src/pages/OperationMaintenanceCenter/CompanyAppManager/models/subAppDetail.js';
import ModelSubAppList71 from '/app/my-app/src/pages/OperationMaintenanceCenter/CompanyAppManager/models/subAppList.js';
import ModelVersionInfo72 from '/app/my-app/src/pages/OperationMaintenanceCenter/CompanyAppManager/models/versionInfo.js';
import ModelVersionLog73 from '/app/my-app/src/pages/OperationMaintenanceCenter/CompanyAppManager/models/versionLog.js';
import ModelVersionPilot74 from '/app/my-app/src/pages/OperationMaintenanceCenter/CompanyAppManager/models/VersionPilot.js';
import ModelCamera75 from '/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/CompanyDevicesList/models/camera.js';
import ModelDdj76 from '/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/CompanyDevicesList/models/ddj.js';
import ModelFengyun77 from '/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/CompanyDevicesList/models/fengyun.js';
import ModelGateway78 from '/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/CompanyDevicesList/models/gateway.js';
import ModelManagement79 from '/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/CompanyDevicesList/models/management.js';
import ModelManagementPeripheral80 from '/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/CompanyDevicesList/models/managementPeripheral.js';
import ModelMic81 from '/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/CompanyDevicesList/models/mic.js';
import ModelMpj82 from '/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/CompanyDevicesList/models/mpj.js';
import ModelPad83 from '/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/CompanyDevicesList/models/pad.js';
import ModelRsbox84 from '/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/CompanyDevicesList/models/rsbox.js';
import ModelStb85 from '/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/CompanyDevicesList/models/stb.js';
import ModelVoiceBox86 from '/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/CompanyDevicesList/models/voiceBox.js';
import ModelYczy87 from '/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/CompanyDevicesList/models/yczy.js';
import ModelZk88 from '/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/CompanyDevicesList/models/zk.js';
import ModelCompany89 from '/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/models/company.js';
import ModelCompanyBoxes90 from '/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/models/companyBoxes.js';
import ModelDeviceOverview91 from '/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/models/deviceOverview.js';
import ModelHardwarelink92 from '/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/models/hardwarelink.js';
import ModelAbnormallog93 from '/app/my-app/src/pages/OperationMaintenanceCenter/Devices/models/abnormallog.js';
import ModelChangelog94 from '/app/my-app/src/pages/OperationMaintenanceCenter/Devices/models/changelog.js';
import ModelFengyun95 from '/app/my-app/src/pages/OperationMaintenanceCenter/Devices/models/fengyun.js';
import ModelFengyunConfiguration96 from '/app/my-app/src/pages/OperationMaintenanceCenter/Devices/models/fengyunConfiguration.js';
import ModelFengyunFiles97 from '/app/my-app/src/pages/OperationMaintenanceCenter/Devices/models/fengyunFiles.js';
import ModelFengyunRepeatSongs98 from '/app/my-app/src/pages/OperationMaintenanceCenter/Devices/models/fengyunRepeatSongs.js';
import ModelGateway99 from '/app/my-app/src/pages/OperationMaintenanceCenter/Devices/models/gateway.js';
import ModelGatewayFiles100 from '/app/my-app/src/pages/OperationMaintenanceCenter/Devices/models/gatewayFiles.js';
import ModelManagementPeripheralLinks101 from '/app/my-app/src/pages/OperationMaintenanceCenter/Devices/models/managementPeripheralLinks.js';
import ModelStbLog102 from '/app/my-app/src/pages/OperationMaintenanceCenter/Devices/models/stbLog.js';
import ModelStbLogList103 from '/app/my-app/src/pages/OperationMaintenanceCenter/Devices/models/stbLogList.js';
import ModelDeviceUpgradeStatus104 from '/app/my-app/src/pages/OperationMaintenanceCenter/RemoteUpgrade/models/deviceUpgradeStatus.js';
import ModelDistributionStrategy105 from '/app/my-app/src/pages/OperationMaintenanceCenter/RemoteUpgrade/models/distributionStrategy.js';
import ModelDistributionStrategyCompanies106 from '/app/my-app/src/pages/OperationMaintenanceCenter/RemoteUpgrade/models/distributionStrategyCompanies.js';
import ModelDistributionStrategyValidate107 from '/app/my-app/src/pages/OperationMaintenanceCenter/RemoteUpgrade/models/distributionStrategyValidate.js';
import ModelJobStatus108 from '/app/my-app/src/pages/OperationMaintenanceCenter/RemoteUpgrade/models/JobStatus.js';
import ModelPilotComopanies109 from '/app/my-app/src/pages/OperationMaintenanceCenter/RemoteUpgrade/models/pilotComopanies.js';
import ModelPilotPackages110 from '/app/my-app/src/pages/OperationMaintenanceCenter/RemoteUpgrade/models/pilotPackages.js';
import ModelShelvesPackages111 from '/app/my-app/src/pages/OperationMaintenanceCenter/RemoteUpgrade/models/shelvesPackages.js';
import ModelShelvesStageValidate112 from '/app/my-app/src/pages/OperationMaintenanceCenter/RemoteUpgrade/models/shelvesStageValidate.js';
import ModelSpecificCompany113 from '/app/my-app/src/pages/OperationMaintenanceCenter/RemoteUpgrade/models/specificCompany.js';
import ModelTargetTypes114 from '/app/my-app/src/pages/OperationMaintenanceCenter/RemoteUpgrade/models/targetTypes.js';
import ModelUpdatePackageStatus115 from '/app/my-app/src/pages/OperationMaintenanceCenter/RemoteUpgrade/models/updatePackageStatus.js';
import ModelUpgradeBlacklistCompanies116 from '/app/my-app/src/pages/OperationMaintenanceCenter/RemoteUpgrade/models/upgradeBlacklistCompanies.js';
import ModelUpgradePackageBatchJobs117 from '/app/my-app/src/pages/OperationMaintenanceCenter/RemoteUpgrade/models/upgradePackageBatchJobs.js';
import ModelUpgradePackageJobs118 from '/app/my-app/src/pages/OperationMaintenanceCenter/RemoteUpgrade/models/upgradePackageJobs.js';
import ModelUpgradePackageProcess119 from '/app/my-app/src/pages/OperationMaintenanceCenter/RemoteUpgrade/models/upgradePackageProcess.js';
import ModelUpgradePackages120 from '/app/my-app/src/pages/OperationMaintenanceCenter/RemoteUpgrade/models/upgradePackages.js';
import ModelUpgradePackagesMgr121 from '/app/my-app/src/pages/OperationMaintenanceCenter/RemoteUpgrade/models/upgradePackagesMgr.js';
import ModelUpgradePackagesTargetTypes122 from '/app/my-app/src/pages/OperationMaintenanceCenter/RemoteUpgrade/models/upgradePackagesTargetTypes.js';
import ModelUpgradePackageStrategy123 from '/app/my-app/src/pages/OperationMaintenanceCenter/RemoteUpgrade/models/upgradePackageStrategy.js';
import ModelUpgradePackageStrategyBatch124 from '/app/my-app/src/pages/OperationMaintenanceCenter/RemoteUpgrade/models/upgradePackageStrategyBatch.js';
import ModelVerifyCompanies125 from '/app/my-app/src/pages/OperationMaintenanceCenter/RemoteUpgrade/models/verifyCompanies.js';
import ModelVerifyPackages126 from '/app/my-app/src/pages/OperationMaintenanceCenter/RemoteUpgrade/models/verifyPackages.js';
import ModelVerifyTestCompanies127 from '/app/my-app/src/pages/OperationMaintenanceCenter/RemoteUpgrade/models/verifyTestCompanies.js';
import ModelSearch128 from '/app/my-app/src/pages/OperationMaintenanceCenter/Search/models/search.js';
import ModelSongCommon129 from '/app/my-app/src/pages/ResourceCenter/Action/models/songCommon.js';
import ModelSingerinfo130 from '/app/my-app/src/pages/ResourceCenter/MusicLibrary/models/singerinfo.js';
import ModelSingerupload131 from '/app/my-app/src/pages/ResourceCenter/MusicLibrary/models/singerupload.js';
import ModelSongfile132 from '/app/my-app/src/pages/ResourceCenter/MusicLibrary/models/songfile.js';
import ModelSonginfo133 from '/app/my-app/src/pages/ResourceCenter/MusicLibrary/models/songinfo.js';
import ModelSongIntonationFile134 from '/app/my-app/src/pages/ResourceCenter/MusicLibrary/models/songIntonationFile.js';
import ModelAdvert135 from '/app/my-app/src/pages/ResourceCenter/PadAdvert/models/advert.js';
import ModelClassification136 from '/app/my-app/src/pages/ResourceCenter/RemoteResource/models/classification.js';
import ModelRecommendSong137 from '/app/my-app/src/pages/ResourceCenter/RemoteResource/models/recommendSong.js';
import ModelSong138 from '/app/my-app/src/pages/ResourceCenter/RemoteResource/models/song.js';
import ModelVipSong139 from '/app/my-app/src/pages/ResourceCenter/RemoteResource/models/vipSong.js';
import ModelAgentauthorization140 from '/app/my-app/src/pages/ValueAddedCenter/BusinessAuthorization/models/agentauthorization.js';
import ModelPadAdvertSetting141 from '/app/my-app/src/pages/ValueAddedCenter/Companies/models/padAdvertSetting.js';
import ModelShopFMbox142 from '/app/my-app/src/pages/ValueAddedCenter/FMMarket/ShopList/models/ShopFMbox.js';
import ModelLampEffect143 from '/app/my-app/src/pages/ValueAddedCenter/IntelligentControlCenter/models/lampEffect.js';
import ModelLampInfo144 from '/app/my-app/src/pages/ValueAddedCenter/IntelligentControlCenter/models/lampInfo.js';
import ModelLampMarket145 from '/app/my-app/src/pages/ValueAddedCenter/IntelligentControlCenter/models/lampMarket.js';
import ModelCompanylist146 from '/app/my-app/src/pages/ValueAddedCenter/ITMarket/CompanyResource/models/companylist.js';
import ModelCompanyresourceusable147 from '/app/my-app/src/pages/ValueAddedCenter/ITMarket/CompanyResource/models/companyresourceusable.js';
import ModelCompanyrsbox148 from '/app/my-app/src/pages/ValueAddedCenter/ITMarket/CompanyResource/models/companyrsbox.js';
import ModelResourcebuy149 from '/app/my-app/src/pages/ValueAddedCenter/ITMarket/CompanyResource/models/resourcebuy.js';
import ModelResourcebuytasks150 from '/app/my-app/src/pages/ValueAddedCenter/ITMarket/CompanyResource/models/resourcebuytasks.js';
import ModelResourceusable151 from '/app/my-app/src/pages/ValueAddedCenter/ITMarket/CompanyResource/models/resourceusable.js';
import ModelMaterial152 from '/app/my-app/src/pages/ValueAddedCenter/ITMarket/models/material.js';
import ModelCompany153 from '/app/my-app/src/pages/ValueAddedCenter/models/company.js';
import ModelNightclubSkin154 from '/app/my-app/src/pages/ValueAddedCenter/models/nightclubSkin.js';
import ModelVodRoomDateChannelLogs155 from '/app/my-app/src/pages/ValueAddedCenter/models/vodRoomDateChannelLogs.js';
import ModelVodRoomDateChannels156 from '/app/my-app/src/pages/ValueAddedCenter/models/vodRoomDateChannels.js';
import ModelCompanyresourceusable157 from '/app/my-app/src/pages/ValueAddedCenter/RHMarket/CompanyResource/models/companyresourceusable.js';
import ModelResourcebuytasks158 from '/app/my-app/src/pages/ValueAddedCenter/RHMarket/CompanyResource/models/resourcebuytasks.js';
import ModelMaterial159 from '/app/my-app/src/pages/ValueAddedCenter/RHMarket/models/material.js';
import ModelAgentbalance160 from '/app/my-app/src/pages/ValueAddedCenter/RSMarket/CompanyResource/models/agentbalance.js';
import ModelAgentChangeList161 from '/app/my-app/src/pages/ValueAddedCenter/RSMarket/CompanyResource/models/agentChangeList.js';
import ModelAgentConsumptionDetail162 from '/app/my-app/src/pages/ValueAddedCenter/RSMarket/CompanyResource/models/agentConsumptionDetail.js';
import ModelAgentPayDetail163 from '/app/my-app/src/pages/ValueAddedCenter/RSMarket/CompanyResource/models/agentPayDetail.js';
import ModelCompanylist164 from '/app/my-app/src/pages/ValueAddedCenter/RSMarket/CompanyResource/models/companylist.js';
import ModelCompanyresourceusable165 from '/app/my-app/src/pages/ValueAddedCenter/RSMarket/CompanyResource/models/companyresourceusable.js';
import ModelCompanyrsbox166 from '/app/my-app/src/pages/ValueAddedCenter/RSMarket/CompanyResource/models/companyrsbox.js';
import ModelCrsresourcesong167 from '/app/my-app/src/pages/ValueAddedCenter/RSMarket/CompanyResource/models/crsresourcesong.js';
import ModelCrsresourcesongtype168 from '/app/my-app/src/pages/ValueAddedCenter/RSMarket/CompanyResource/models/crsresourcesongtype.js';
import ModelCrsresourcetailor169 from '/app/my-app/src/pages/ValueAddedCenter/RSMarket/CompanyResource/models/crsresourcetailor.js';
import ModelResourcebuy170 from '/app/my-app/src/pages/ValueAddedCenter/RSMarket/CompanyResource/models/resourcebuy.js';
import ModelResourcebuytasks171 from '/app/my-app/src/pages/ValueAddedCenter/RSMarket/CompanyResource/models/resourcebuytasks.js';
import ModelResourcepushtasks172 from '/app/my-app/src/pages/ValueAddedCenter/RSMarket/CompanyResource/models/resourcepushtasks.js';
import ModelResourceusable173 from '/app/my-app/src/pages/ValueAddedCenter/RSMarket/CompanyResource/models/resourceusable.js';
import ModelMaterial174 from '/app/my-app/src/pages/ValueAddedCenter/RSMarket/MaterialMgr/models/material.js';
import ModelMaterialHigh175 from '/app/my-app/src/pages/ValueAddedCenter/RSMarket/MaterialMgr/models/materialHigh.js';
import ModelMaterialIteract176 from '/app/my-app/src/pages/ValueAddedCenter/RSMarket/MaterialMgr/models/materialIteract.js';
import ModelCompanyScribePrice177 from '/app/my-app/src/pages/ValueAddedCenter/RSMarket/models/companyScribePrice.js';
import ModelDefaultSongs178 from '/app/my-app/src/pages/ValueAddedCenter/RSMarket/models/defaultSongs.js';
import ModelDefaultSongTypes179 from '/app/my-app/src/pages/ValueAddedCenter/RSMarket/models/defaultSongTypes.js';
import ModelDefaultTailors180 from '/app/my-app/src/pages/ValueAddedCenter/RSMarket/models/defaultTailors.js';
import ModelMaterial181 from '/app/my-app/src/pages/ValueAddedCenter/RSMarket/models/material.js';
import ModelModel182 from '/app/my-app/src/pages/DataCenter/Dashboard/model.js';
import ModelModel183 from '/app/my-app/src/pages/ValueAddedCenter/RSMarket/BackendAgent/model.js';

let app:any = null;

export function _onCreate(options = {}) {
  const runtimeDva = plugin.applyPlugins({
    key: 'dva',
    type: ApplyPluginsType.modify,
    initialValue: {},
  });
  app = dva({
    history,
    
    ...(runtimeDva.config || {}),
    // @ts-ignore
    ...(typeof window !== 'undefined' && window.g_useSSR ? { initialState: window.g_initialProps } : {}),
    ...(options || {}),
  });
  
  app.use(createLoading());
  (runtimeDva.plugins || []).forEach((plugin:any) => {
    app.use(plugin);
  });
  app.model({ namespace: 'companyInfo', ...ModelCompanyInfo0 });
app.model({ namespace: 'devicePermission', ...ModelDevicePermission1 });
app.model({ namespace: 'global', ...ModelGlobal2 });
app.model({ namespace: 'list', ...ModelList3 });
app.model({ namespace: 'login', ...ModelLogin4 });
app.model({ namespace: 'menu', ...ModelMenu5 });
app.model({ namespace: 'project', ...ModelProject6 });
app.model({ namespace: 'punishCommon', ...ModelPunishCommon7 });
app.model({ namespace: 'setting', ...ModelSetting8 });
app.model({ namespace: 'user', ...ModelUser9 });
app.model({ namespace: 'workparams', ...ModelWorkparams10 });
app.model({ namespace: 'geographic', ...ModelGeographic11 });
app.model({ namespace: 'chainBrandManage', ...ModelChainBrandManage12 });
app.model({ namespace: 'chainBrandStore', ...ModelChainBrandStore13 });
app.model({ namespace: 'chainBrandStoreCompanies', ...ModelChainBrandStoreCompanies14 });
app.model({ namespace: 'chainBrandStoreIndustryCompanies', ...ModelChainBrandStoreIndustryCompanies15 });
app.model({ namespace: 'agentManage', ...ModelAgentManage16 });
app.model({ namespace: 'company', ...ModelCompany17 });
app.model({ namespace: 'deviceAuthorization', ...ModelDeviceAuthorization18 });
app.model({ namespace: 'IntelligentControlManage', ...ModelIntelligentControlManage19 });
app.model({ namespace: 'lampManage', ...ModelLampManage20 });
app.model({ namespace: 'menu', ...ModelMenu21 });
app.model({ namespace: 'role', ...ModelRole22 });
app.model({ namespace: 'roleTemplate', ...ModelRoleTemplate23 });
app.model({ namespace: 'stereoManage', ...ModelStereoManage24 });
app.model({ namespace: 'vodManage', ...ModelVodManage25 });
app.model({ namespace: 'baobeiCompanies', ...ModelBaobeiCompanies26 });
app.model({ namespace: 'kmCompanies', ...ModelKmCompanies27 });
app.model({ namespace: 'saleAutohrizationCompanies', ...ModelSaleAutohrizationCompanies28 });
app.model({ namespace: 'saleAutohrizationManagementCompanies', ...ModelSaleAutohrizationManagementCompanies29 });
app.model({ namespace: 'dataAnalysis', ...ModelDataAnalysis30 });
app.model({ namespace: 'analysis', ...ModelAnalysis31 });
app.model({ namespace: 'DataOverview', ...ModelDataOverview32 });
app.model({ namespace: 'informationManage', ...ModelInformationManage33 });
app.model({ namespace: 'message', ...ModelMessage34 });
app.model({ namespace: 'messageCenter', ...ModelMessageCenter35 });
app.model({ namespace: 'agentMenu', ...ModelAgentMenu36 });
app.model({ namespace: 'companyMenu', ...ModelCompanyMenu37 });
app.model({ namespace: 'partnerMenu', ...ModelPartnerMenu38 });
app.model({ namespace: 'platform', ...ModelPlatform39 });
app.model({ namespace: 'roleTemplate', ...ModelRoleTemplate40 });
app.model({ namespace: 'configuration', ...ModelConfiguration41 });
app.model({ namespace: 'lampRole', ...ModelLampRole42 });
app.model({ namespace: 'menu', ...ModelMenu43 });
app.model({ namespace: 'role', ...ModelRole44 });
app.model({ namespace: 'user', ...ModelUser45 });
app.model({ namespace: 'company', ...ModelCompany46 });
app.model({ namespace: 'piracy', ...ModelPiracy47 });
app.model({ namespace: 'schemes', ...ModelSchemes48 });
app.model({ namespace: 'company', ...ModelCompany49 });
app.model({ namespace: 'schemes', ...ModelSchemes50 });
app.model({ namespace: 'diskAbnormalModels', ...ModelDiskAbnormalModels51 });
app.model({ namespace: 'appStbAdapter', ...ModelAppStbAdapter52 });
app.model({ namespace: 'manufacturer', ...ModelManufacturer53 });
app.model({ namespace: 'AdapterCheckboxList', ...ModelAdapterCheckboxList54 });
app.model({ namespace: 'AppBaseDetail', ...ModelAppBaseDetail55 });
app.model({ namespace: 'appconfig', ...ModelAppconfig56 });
app.model({ namespace: 'appInstall', ...ModelAppInstall57 });
app.model({ namespace: 'appInstallLog', ...ModelAppInstallLog58 });
app.model({ namespace: 'appManufacturer', ...ModelAppManufacturer59 });
app.model({ namespace: 'appReview', ...ModelAppReview60 });
app.model({ namespace: 'appRunEnv', ...ModelAppRunEnv61 });
app.model({ namespace: 'appVersionStageValidate', ...ModelAppVersionStageValidate62 });
app.model({ namespace: 'baseAppDetail', ...ModelBaseAppDetail63 });
app.model({ namespace: 'CompanyAppList', ...ModelCompanyAppList64 });
app.model({ namespace: 'companyOverview', ...ModelCompanyOverview65 });
app.model({ namespace: 'companyStb', ...ModelCompanyStb66 });
app.model({ namespace: 'localRelease', ...ModelLocalRelease67 });
app.model({ namespace: 'stbDevice', ...ModelStbDevice68 });
app.model({ namespace: 'stbSdkPermission', ...ModelStbSdkPermission69 });
app.model({ namespace: 'subAppDetail', ...ModelSubAppDetail70 });
app.model({ namespace: 'subAppList', ...ModelSubAppList71 });
app.model({ namespace: 'versionInfo', ...ModelVersionInfo72 });
app.model({ namespace: 'versionLog', ...ModelVersionLog73 });
app.model({ namespace: 'VersionPilot', ...ModelVersionPilot74 });
app.model({ namespace: 'camera', ...ModelCamera75 });
app.model({ namespace: 'ddj', ...ModelDdj76 });
app.model({ namespace: 'fengyun', ...ModelFengyun77 });
app.model({ namespace: 'gateway', ...ModelGateway78 });
app.model({ namespace: 'management', ...ModelManagement79 });
app.model({ namespace: 'managementPeripheral', ...ModelManagementPeripheral80 });
app.model({ namespace: 'mic', ...ModelMic81 });
app.model({ namespace: 'mpj', ...ModelMpj82 });
app.model({ namespace: 'pad', ...ModelPad83 });
app.model({ namespace: 'rsbox', ...ModelRsbox84 });
app.model({ namespace: 'stb', ...ModelStb85 });
app.model({ namespace: 'voiceBox', ...ModelVoiceBox86 });
app.model({ namespace: 'yczy', ...ModelYczy87 });
app.model({ namespace: 'zk', ...ModelZk88 });
app.model({ namespace: 'company', ...ModelCompany89 });
app.model({ namespace: 'companyBoxes', ...ModelCompanyBoxes90 });
app.model({ namespace: 'deviceOverview', ...ModelDeviceOverview91 });
app.model({ namespace: 'hardwarelink', ...ModelHardwarelink92 });
app.model({ namespace: 'abnormallog', ...ModelAbnormallog93 });
app.model({ namespace: 'changelog', ...ModelChangelog94 });
app.model({ namespace: 'fengyun', ...ModelFengyun95 });
app.model({ namespace: 'fengyunConfiguration', ...ModelFengyunConfiguration96 });
app.model({ namespace: 'fengyunFiles', ...ModelFengyunFiles97 });
app.model({ namespace: 'fengyunRepeatSongs', ...ModelFengyunRepeatSongs98 });
app.model({ namespace: 'gateway', ...ModelGateway99 });
app.model({ namespace: 'gatewayFiles', ...ModelGatewayFiles100 });
app.model({ namespace: 'managementPeripheralLinks', ...ModelManagementPeripheralLinks101 });
app.model({ namespace: 'stbLog', ...ModelStbLog102 });
app.model({ namespace: 'stbLogList', ...ModelStbLogList103 });
app.model({ namespace: 'deviceUpgradeStatus', ...ModelDeviceUpgradeStatus104 });
app.model({ namespace: 'distributionStrategy', ...ModelDistributionStrategy105 });
app.model({ namespace: 'distributionStrategyCompanies', ...ModelDistributionStrategyCompanies106 });
app.model({ namespace: 'distributionStrategyValidate', ...ModelDistributionStrategyValidate107 });
app.model({ namespace: 'JobStatus', ...ModelJobStatus108 });
app.model({ namespace: 'pilotComopanies', ...ModelPilotComopanies109 });
app.model({ namespace: 'pilotPackages', ...ModelPilotPackages110 });
app.model({ namespace: 'shelvesPackages', ...ModelShelvesPackages111 });
app.model({ namespace: 'shelvesStageValidate', ...ModelShelvesStageValidate112 });
app.model({ namespace: 'specificCompany', ...ModelSpecificCompany113 });
app.model({ namespace: 'targetTypes', ...ModelTargetTypes114 });
app.model({ namespace: 'updatePackageStatus', ...ModelUpdatePackageStatus115 });
app.model({ namespace: 'upgradeBlacklistCompanies', ...ModelUpgradeBlacklistCompanies116 });
app.model({ namespace: 'upgradePackageBatchJobs', ...ModelUpgradePackageBatchJobs117 });
app.model({ namespace: 'upgradePackageJobs', ...ModelUpgradePackageJobs118 });
app.model({ namespace: 'upgradePackageProcess', ...ModelUpgradePackageProcess119 });
app.model({ namespace: 'upgradePackages', ...ModelUpgradePackages120 });
app.model({ namespace: 'upgradePackagesMgr', ...ModelUpgradePackagesMgr121 });
app.model({ namespace: 'upgradePackagesTargetTypes', ...ModelUpgradePackagesTargetTypes122 });
app.model({ namespace: 'upgradePackageStrategy', ...ModelUpgradePackageStrategy123 });
app.model({ namespace: 'upgradePackageStrategyBatch', ...ModelUpgradePackageStrategyBatch124 });
app.model({ namespace: 'verifyCompanies', ...ModelVerifyCompanies125 });
app.model({ namespace: 'verifyPackages', ...ModelVerifyPackages126 });
app.model({ namespace: 'verifyTestCompanies', ...ModelVerifyTestCompanies127 });
app.model({ namespace: 'search', ...ModelSearch128 });
app.model({ namespace: 'songCommon', ...ModelSongCommon129 });
app.model({ namespace: 'singerinfo', ...ModelSingerinfo130 });
app.model({ namespace: 'singerupload', ...ModelSingerupload131 });
app.model({ namespace: 'songfile', ...ModelSongfile132 });
app.model({ namespace: 'songinfo', ...ModelSonginfo133 });
app.model({ namespace: 'songIntonationFile', ...ModelSongIntonationFile134 });
app.model({ namespace: 'advert', ...ModelAdvert135 });
app.model({ namespace: 'classification', ...ModelClassification136 });
app.model({ namespace: 'recommendSong', ...ModelRecommendSong137 });
app.model({ namespace: 'song', ...ModelSong138 });
app.model({ namespace: 'vipSong', ...ModelVipSong139 });
app.model({ namespace: 'agentauthorization', ...ModelAgentauthorization140 });
app.model({ namespace: 'padAdvertSetting', ...ModelPadAdvertSetting141 });
app.model({ namespace: 'ShopFMbox', ...ModelShopFMbox142 });
app.model({ namespace: 'lampEffect', ...ModelLampEffect143 });
app.model({ namespace: 'lampInfo', ...ModelLampInfo144 });
app.model({ namespace: 'lampMarket', ...ModelLampMarket145 });
app.model({ namespace: 'companylist', ...ModelCompanylist146 });
app.model({ namespace: 'companyresourceusable', ...ModelCompanyresourceusable147 });
app.model({ namespace: 'companyrsbox', ...ModelCompanyrsbox148 });
app.model({ namespace: 'resourcebuy', ...ModelResourcebuy149 });
app.model({ namespace: 'resourcebuytasks', ...ModelResourcebuytasks150 });
app.model({ namespace: 'resourceusable', ...ModelResourceusable151 });
app.model({ namespace: 'material', ...ModelMaterial152 });
app.model({ namespace: 'company', ...ModelCompany153 });
app.model({ namespace: 'nightclubSkin', ...ModelNightclubSkin154 });
app.model({ namespace: 'vodRoomDateChannelLogs', ...ModelVodRoomDateChannelLogs155 });
app.model({ namespace: 'vodRoomDateChannels', ...ModelVodRoomDateChannels156 });
app.model({ namespace: 'companyresourceusable', ...ModelCompanyresourceusable157 });
app.model({ namespace: 'resourcebuytasks', ...ModelResourcebuytasks158 });
app.model({ namespace: 'material', ...ModelMaterial159 });
app.model({ namespace: 'agentbalance', ...ModelAgentbalance160 });
app.model({ namespace: 'agentChangeList', ...ModelAgentChangeList161 });
app.model({ namespace: 'agentConsumptionDetail', ...ModelAgentConsumptionDetail162 });
app.model({ namespace: 'agentPayDetail', ...ModelAgentPayDetail163 });
app.model({ namespace: 'companylist', ...ModelCompanylist164 });
app.model({ namespace: 'companyresourceusable', ...ModelCompanyresourceusable165 });
app.model({ namespace: 'companyrsbox', ...ModelCompanyrsbox166 });
app.model({ namespace: 'crsresourcesong', ...ModelCrsresourcesong167 });
app.model({ namespace: 'crsresourcesongtype', ...ModelCrsresourcesongtype168 });
app.model({ namespace: 'crsresourcetailor', ...ModelCrsresourcetailor169 });
app.model({ namespace: 'resourcebuy', ...ModelResourcebuy170 });
app.model({ namespace: 'resourcebuytasks', ...ModelResourcebuytasks171 });
app.model({ namespace: 'resourcepushtasks', ...ModelResourcepushtasks172 });
app.model({ namespace: 'resourceusable', ...ModelResourceusable173 });
app.model({ namespace: 'material', ...ModelMaterial174 });
app.model({ namespace: 'materialHigh', ...ModelMaterialHigh175 });
app.model({ namespace: 'materialIteract', ...ModelMaterialIteract176 });
app.model({ namespace: 'companyScribePrice', ...ModelCompanyScribePrice177 });
app.model({ namespace: 'defaultSongs', ...ModelDefaultSongs178 });
app.model({ namespace: 'defaultSongTypes', ...ModelDefaultSongTypes179 });
app.model({ namespace: 'defaultTailors', ...ModelDefaultTailors180 });
app.model({ namespace: 'material', ...ModelMaterial181 });
app.model({ namespace: 'model', ...ModelModel182 });
app.model({ namespace: 'model', ...ModelModel183 });
  return app;
}

export function getApp() {
  return app;
}

/**
 * whether browser env
 * 
 * @returns boolean
 */
function isBrowser(): boolean {
  return typeof window !== 'undefined' &&
  typeof window.document !== 'undefined' &&
  typeof window.document.createElement !== 'undefined'
}

export class _DvaContainer extends Component {
  constructor(props: any) {
    super(props);
    // run only in client, avoid override server _onCreate()
    if (isBrowser()) {
      _onCreate()
    }
  }

  componentWillUnmount() {
    let app = getApp();
    app._models.forEach((model:any) => {
      app.unmodel(model.namespace);
    });
    app._models = [];
    try {
      // 释放 app，for gc
      // immer 场景 app 是 read-only 的，这里 try catch 一下
      app = null;
    } catch(e) {
      console.error(e);
    }
  }

  render() {
    let app = getApp();
    app.router(() => this.props.children);
    return app.start()();
  }
}
