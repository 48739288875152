import request from '@/utils/request';
import { stringify } from 'qs';

export async function queryCompanyInfo(companyId) {
  const params = {
    // include: 'agent,agent.oneLevelAgent,extraInformation,agent_info,secondAgents',
    include: 'agent_info,secondAgents,extraInformation',
  };
  return request(`/company/${companyId}`, {
    params,
  });
}

export async function queryCompanyMainDeviceInfo(companyId) {
  return request(`/company/${companyId}/mainDeviceInfo`);
}

export async function editCompanyBaseInfo(params) {
  const { companyId } = params;
  delete params.companyId;
  return request.post(`/company/${companyId}/editCompanyBaseInfo`, {
    ...params,
  });
}

export async function update(params) {
  const { companyId } = params;
  delete params.companyId;
  return request.put(`/company/${companyId}`, {
    ...params,
  });
}

export async function queryCompanyDeviceOverview(companyId) {
  return request(`/company/${companyId}/deviceOverview`);
}

export async function queryNetCompany(params) {
  return request(`/net-companies`, {
    params,
  });
}

export async function queryNotNetCompany(params) {
  return request(`/not-net-companies`, {
    params,
  });
}

export async function querySubCompany(params) {
  const { companyId } = params;
  delete params.companyId;
  return request(`/company/${companyId}/sub-companies`, {
    params,
  });
}

export async function queryCompanyStbHardTypes() {
  return request(`/companies-stb-hard-models`, {});
}

export async function queryCompanyBoxes(params) {
  const { companyId } = params;
  delete params.companyId;
  return request(`/company/${companyId}/boxes`, {
    params,
  });
}

export async function queryCompanyYczy(params) {
  const { companyId } = params;
  params.include = '';
  delete params.companyId;
  return request(`/company/${companyId}/yczy`, {
    params,
  });
}

export async function queryCompanyStb(params) {
  const { companyId } = params;
  params.include = ',parentStb,stbDeviceReportConfig';
  delete params.companyId;
  return request(`/company/${companyId}/stb`, {
    params,
  });
}

export async function queryCompanyMpj(params) {
  const { companyId } = params;
  params.include = ',parentStb';
  delete params.companyId;
  return request(`/company/${companyId}/mpj`, {
    params,
  });
}

export async function queryCompanyDdj(params) {
  const { companyId } = params;
  params.include = ',parentStb';
  delete params.companyId;
  return request(`/company/${companyId}/ddj`, {
    params,
  });
}

export async function queryCompanyZk(params) {
  const { companyId } = params;
  params.include = ',box';
  delete params.companyId;
  return request(`/company/${companyId}/zk`, {
    params,
  });
}

export async function queryCompanyCamera(params) {
  const { companyId } = params;
  params.include = ',box';
  delete params.companyId;
  return request(`/company/${companyId}/camera`, {
    params,
  });
}

export async function queryCompanyVoiceBox(params) {
  const { companyId } = params;
  params.include = ',box';
  delete params.companyId;
  return request(`/company/${companyId}/voice-box`, {
    params,
  });
}

export async function queryCompanyPad(params) {
  const { companyId } = params;
  params.include = ',box';
  delete params.companyId;
  return request(`/company/${companyId}/pad`, {
    params,
  });
}

export async function queryCompanyRsbox(params) {
  const { companyId } = params;
  params.include = ',parentStb';
  delete params.companyId;
  return request(`/company/${companyId}/rsbox`, {
    params,
  });
}

export async function queryCompanyMic(params) {
  const { companyId } = params;
  params.include = ',box';
  delete params.companyId;
  return request(`/company/${companyId}/mic`, {
    params,
  });
}

export async function queryCompanyFengyun(params) {
  const { companyId } = params;
  params.include = '';
  delete params.companyId;
  return request(`/company/${companyId}/fengyun`, {
    params,
  });
}

export async function queryCompanyGateway(params) {
  const { companyId } = params;
  params.include = '';
  delete params.companyId;
  return request(`/company/${companyId}/gateway`, {
    params,
  });
}

export async function queryCompanyManagement(params) {
  const { companyId } = params;
  params.include = '';
  delete params.companyId;
  return request(`/company/${companyId}/management`, {
    params,
  });
}

export async function queryCompanyManagementPeripheral(params) {
  const { companyId } = params;
  params.include = '';
  delete params.companyId;
  return request(`/company/${companyId}/managementPeripheral`, {
    params,
  });
}

export async function queryCompanyFengyunFiles(params) {
  const { companyId, fengyunId } = params;
  delete params.companyId;
  delete params.fengyunId;
  return request(`/company/${companyId}/fengyun/${fengyunId}/files`, {
    params,
  });
}

export async function postCompanyFengyunFile(params) {
  const { companyId, fengyunId } = params;
  delete params.companyId;
  delete params.fengyunId;
  return request.post(`/company/${companyId}/fengyun/${fengyunId}/files`, {
    ...params,
  });
}

export async function queryCompanyFengyunConfiguration(params) {
  const { companyId, fengyunId } = params;
  delete params.companyId;
  delete params.fengyunId;
  const deviceType = 'FY';
  return request(
    `/company/${companyId}/device_type/${deviceType}/device_id/${fengyunId}/configuration`,
    {
      params,
    }
  );
}

export async function postCompanyFengyunConfiguration(params) {
  return request.post(`/companyDeviceConfiguration`, {
    ...params,
  });
}

export async function putCompanyFengyunConfiguration(params) {
  return request.put(`/companyDeviceConfiguration/${params.id}`, {
    ...params,
  });
}

export async function pushCompanyFengyunConfiguration({
  companyId,
  fengyunId,
}) {
  return request.post(
    `/company/${companyId}/fengyun_id/${fengyunId}/pushConfiguration`
  );
}

export async function pullCompanyFengyunConfiguration({
  companyId,
  fengyunId,
}) {
  return request.post(
    `/company/${companyId}/fengyun_id/${fengyunId}/pullConfiguration`
  );
}

export async function queryCompanyFengyunDetail(params) {
  const { companyId, fengyunId } = params;
  delete params.companyId;
  delete params.fengyunId;
  return request(`/company/${companyId}/fengyun/${fengyunId}`, {
    params,
  });
}

export async function queryCompanyGatewayFiles(params) {
  const { companyId, gatewayId } = params;
  delete params.companyId;
  delete params.gatewayId;
  return request(`/company/${companyId}/gateway/${gatewayId}/files`, {
    params,
  });
}

export async function postCompanyGatewayFile(params) {
  const { companyId, gatewayId } = params;
  delete params.companyId;
  delete params.gatewayId;
  return request.post(`/company/${companyId}/gateway/${gatewayId}/files`, {
    ...params,
  });
}

export async function queryCompanyGatewayDetail(params) {
  const { companyId, gatewayId } = params;
  delete params.companyId;
  delete params.gatewayId;
  return request(`/company/${companyId}/gateway/${gatewayId}`, {
    params,
  });
}

export async function getCompanyDeviceLinks(params) {
  return request(`/devices/getCompanyDeviceLinks?${stringify(params)}`);
}

export async function getCompanyBoxDeviceLinks(params) {
  return request(`/devices/getCompanyBoxDeviceLinks?${stringify(params)}`);
}

export async function getManagementPeripheralLinks(params) {
  return request(`/devices/getManagementPeripheralLinks?${stringify(params)}`);
}

export async function queryCompanyOverView(params) {
  const { companyId } = params;
  return request(`/appMarket/getCompanyOverView/${companyId}`);
}

export async function queryCompanyAppList(params) {
  const { companyId } = params;
  delete params.companyId;
  return request(`/appMarket/getCompanyAppList/${companyId}`, {
    params,
  });
}

export async function setAppInstallType(params) {
  const { companyId } = params;
  delete params.companyId;
  return request.post(`/appMarket/setAppInstallType/${companyId}`, {
    ...params,
  });
}

export async function updateCompanyAppConfig(params) {
  const { companyId } = params;
  return request.post(`/appMarket/${companyId}/updateCompanyAppConfig`);
}

export async function changeCompanyAgent(data) {
  console.log('==>', data);
  return request(`/change-company-agent`, { method: 'POST', data });
}

export async function agentCompanyRelationList(params) {
  return request(`/agent-company-relation-list`, { method: 'GET', params });
}

export async function agentCompanyList(params) {
  return request(`/agent-company-list`, { method: 'GET', params });
}

export async function queryAuthorizationInfo(companyId) {
  return request(`/company/${companyId}/authorizationInfo`);
}

export async function managementSqlInjection(data) {
  return request(`/punish/managementSqlInjection`, { method: 'POST', data });
}

export async function smsverify(data) {
  return request(`/punish/smsverify`, { method: 'POST', data });
}

export async function changeSongType(data) {
  return request(`/company/change-song-type`, { method: 'POST', data });
}

export async function changeCloudSongPass(data) {
  return request(`/company/change-cloud-song-pass`, { method: 'POST', data });
}

// 同步机顶盒日志
export async function syncLogs(params) {
  return request('/device-job/log/job', {
    method: 'POST',
    data: params,
  });
}

export async function queryIndustryCompany(params) {
  return request(`/company-service/industry-companies/list`, {
    params,
  });
}

export async function queryShiyiCompany(params) {
  return request(`/company-service/companies`, {
    params,
  });
}

// 获取删除歌曲任务列表
export async function get_deleteServerSongTasks(params) {
  return request(`/music-library/api/delete-server-song-tasks`, {
    params,
  });
}

// 创建删除任务
export async function post_deleteServerSongTasks(data) {
  return request('/music-library/api/delete-server-song-tasks', {
    method: 'POST',
    data,
  });
}

// 推送删除任务
export async function post_pushDeleteTask(data) {
  return request(
    `/music-library/api/delete-server-song-tasks/${data.id}/push-delete-task`,
    {
      method: 'POST',
      data,
    }
  );
}

// 选择需删除歌曲
export async function post_selectDeleteSong(data) {
  return request(
    `/music-library/api/delete-server-song-tasks/${data.id}/select-delete-song`,
    {
      method: 'POST',
      data,
    }
  );
}

// 获取服务器删除的歌曲
export async function get_serverDeleteSongs(params) {
  return request(
    `/music-library/api/delete-server-song-tasks/${
      params.id
    }/server-delete-songs`,
    {
      params,
    }
  );
}

// 获取任务详情
export async function get_deleteServerSongTasks_id(params) {
  return request(`/music-library/api/delete-server-song-tasks/${params.id}`, {
    params,
  });
}

// 获取锋云协议
export async function get_deleteServerSongTasks_fengyun_id(params) {
  return request(
    `/music-library/api/delete-server-song-tasks/fengyun/${params.id}`,
    {
      params,
    }
  );
}
