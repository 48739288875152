import request from '@/utils/request';

export async function querySkins(params) {
  return request('/nightclub/skins', { params });
}

export async function startShelves(params) {
  const { id } = params;
  return request.post(`/nightclub/skins/${id}/start-shelves`);
}

export async function stopShelves(params) {
  const { id } = params;
  return request.post(`/nightclub/skins/${id}/stop-shelves`);
}

export async function saveSkins(params) {
  return request.post(`/nightclub/skins`, {
    ...params,
  });
}

export async function showSkins(params) {
  const { id } = params;
  return request.get(`/nightclub/skins/${id}`);
}

export async function updateSkin(params) {
  const { id } = params;
  return request.put(`/nightclub/skins/${id}`, params);
}

export async function companyPushSkin(params) {
  const { companyId, skinId } = params;
  return request.post(
    `/nightclub/company-skins/${companyId}/push/${skinId}`,
    params
  );
}

export async function companyCurrentUseSkin(params) {
  const { companyId } = params;
  return request(`/nightclub/company-skins/${companyId}/current-use`, {
    params,
  });
}

export async function companySkinPushedLogs(params) {
  const { companyId } = params;
  return request(`/nightclub/company-skins/${companyId}/pushed-logs`, {
    params,
  });
}

export async function companySkinPushedChangeStatus(params) {
  const { companyId, taskId } = params;
  return request(
    `/nightclub/company-skins/${companyId}/push/${taskId}/change-status`,
    {
      params,
    }
  );
}
