import {
  queryRoleList,
  queryUserList,
  queryAgentList,
  createLamp,
  createAgent,
  createRole,
  deleteRole,
  editRole,
  bindRole,
  editAgent,
  unbindRole,
  allocateLamp,
  allocateAgent,
  editLamp,
  createSecondAgent,
  queryCompanyRoleList,
  queryRoleType,
  editSecondAgent,
  editAgentState,
} from '@/services/lampControl';
import { agentCompanyList } from '@/services/company';
import {
  secondAgentList,
  changeBatchCompanyAgent,
  auditSecondAgent,
  batchBindEncryptionDog,
} from '@/services/agent';

export default {
  namespace: 'ManagerCenter_informationManage',
  state: {
    data: {},
  },
  effects: {
    *queryCompanyRoleList({ payload }, { call, put }) {
      const { data } = yield call(queryCompanyRoleList, payload);
      return data;
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        data: payload,
      };
    },
  },
};
