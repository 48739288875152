import {
  queryUserList,
  queryUserInfo,
  userCreate,
  userEdit,
  userDelete,
  changeGlobalMenu,
  changeGlobalData,
  changeGlobalUser,
  saveUserRoles,
  saveUserDataFilter,
  queryUserCompanyDataFilter,
  queryUserCompanyNotDataFilter,
  saveUserRegionDataFilter,
  addUserCompanyDataFilter,
  removeUserCompanyDataFilter,
  bindAgentData,
  saveUserMenus,
  changeAgentSuper,
  bindAgentIdData,
} from '@/services/user';
import { queryNetCompany } from '@/services/company';
import { roleCreate, roleDelete, roleEdit } from '@/services/role';
import { openLightData, closeLightData } from '@/services/lampControl';
import { queryPlatformList } from '@/services/manage/platform';

export default {
  namespace: 'SystemManager_user',

  state: {
    data: {},
    result: {},
    userInfo: {},
    userCompanyDataFilter: {},
    userCompanyNotDataFilter: {},
    platformList: [],
    AgentIdData: {},
  },

  effects: {
    *fetch({ payload }, { call, put }) {
      const {
        data: { data, meta },
      } = yield call(queryUserList, payload);

      yield put({
        type: 'save',
        payload: {
          list: data,
          pagination: {
            total: meta.total,
            pageSize: meta.per_page,
            current: meta.current_page,
          },
        },
      });
    },
    *create({ payload, callback }, { call, put }) {
      const { status, data } = yield call(userCreate, payload);
      yield put({
        type: 'saveResult',
        payload: data,
      });
      if (!/20\d/.test(status)) {
        return false;
      }
      return data;
    },

    *edit({ payload, callback }, { call, put }) {
      const { status, data } = yield call(userEdit, payload);
      yield put({
        type: 'saveResult',
        payload: data,
      });
      if (!/20\d/.test(status)) {
        return false;
      }
      return data;
    },

    *delete({ payload, callback }, { call, put }) {
      const { status, data } = yield call(userDelete, payload);
      yield put({
        type: 'saveResult',
        payload: data,
      });
      return data;
    },

    *changeGlobalMenu({ payload, callback }, { call, put }) {
      const { status, data } = yield call(changeGlobalMenu, payload);

      if (!status) {
        return;
      }

      yield put({
        type: 'saveResult',
        payload: data,
      });
    },

    *changeGlobalData({ payload, callback }, { call, put }) {
      const { status, data } = yield call(changeGlobalData, payload);

      if (!status) {
        return;
      }

      yield put({
        type: 'saveResult',
        payload: data,
      });
    },

    *changeGlobalUser({ payload, callback }, { call, put }) {
      const { status, data } = yield call(changeGlobalUser, payload);

      if (!status) {
        return;
      }

      yield put({
        type: 'saveResult',
        payload: data,
      });
    },

    *changeGlobalLamp({ payload, callback }, { call, put }) {
      const { action } = payload;
      const { status, data } =
        action === 'open'
          ? yield call(openLightData, payload)
          : yield call(closeLightData, payload);

      if (!status) {
        return;
      }

      yield put({
        type: 'saveResult',
        payload: data,
      });
    },

    *changeAgentSuper({ payload, callback }, { call, put }) {
      const { action, id } = payload;
      const OPEN = 1;
      const CLOSE = 0;
      const { status, data } =
        action === 'open'
          ? yield call(changeAgentSuper, { user_id: id, is_super: OPEN })
          : yield call(changeAgentSuper, { user_id: id, is_super: CLOSE });

      if (!status) {
        return;
      }

      yield put({
        type: 'saveResult',
        payload: data,
      });
    },

    *getUserInfo({ payload }, { call, put }) {
      const {
        data: { data },
      } = yield call(queryUserInfo, payload);
      yield put({
        type: 'saveUserInfo',
        payload: data,
      });
    },

    *saveUserRoles({ payload }, { call, put }) {
      const { data } = yield call(saveUserRoles, payload);
      yield put({
        type: 'saveResult',
        payload: data,
      });
    },

    *saveUserMenus({ payload }, { call, put }) {
      const { data } = yield call(saveUserMenus, payload);
      yield put({
        type: 'saveResult',
        payload: data,
      });
    },

    *saveUserRegionDataFilter({ payload }, { call, put }) {
      const { data } = yield call(saveUserRegionDataFilter, payload);
      yield put({
        type: 'saveResult',
        payload: data,
      });
    },

    *addUserCompanyDataFilter({ payload }, { call, put }) {
      const { data } = yield call(addUserCompanyDataFilter, payload);
      yield put({
        type: 'saveResult',
        payload: data,
      });
    },

    *removeUserCompanyDataFilter({ payload }, { call, put }) {
      const { data } = yield call(removeUserCompanyDataFilter, payload);
      yield put({
        type: 'saveResult',
        payload: data,
      });
    },

    *fetchUserCompanyDataFilter({ payload }, { call, put }) {
      const {
        data: { data, meta },
      } = yield call(queryUserCompanyDataFilter, payload);

      yield put({
        type: 'saveUserCompanyDataFilter',
        payload: {
          list: data,
          pagination: {
            total: meta.total,
            pageSize: meta.per_page,
            current: meta.current_page,
          },
        },
      });
    },

    *fetchUserCompanyNotDataFilter({ payload }, { call, put }) {
      const {
        data: { data, meta },
      } = yield call(queryUserCompanyNotDataFilter, payload);

      yield put({
        type: 'saveUserCompanyNotDataFilter',
        payload: {
          list: data,
          pagination: {
            total: meta.total,
            pageSize: meta.per_page,
            current: meta.current_page,
          },
        },
      });
    },

    *fetchPlatformList({ payload }, { call, put }) {
      const {
        data: { data },
      } = yield call(queryPlatformList, payload);
      yield put({
        type: 'savePlatformList',
        payload: data,
      });
    },
    *bindAgent({ payload }, { call, put }) {
      const { data } = yield call(bindAgentData, payload);
      yield put({
        type: 'saveResult',
        payload: data,
      });
    },
    *bindAgentId({ payload }, { call, put }) {
      const { data } = yield call(bindAgentIdData, payload);
      yield put({
        type: 'saveAgentId',
        payload: data,
      });
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        data: payload,
      };
    },
    saveResult(state, { payload }) {
      return {
        ...state,
        result: payload,
      };
    },
    saveUserInfo(state, { payload }) {
      return {
        ...state,
        userInfo: payload,
      };
    },
    savePlatformList(state, { payload }) {
      return {
        ...state,
        platformList: payload,
      };
    },
    saveUserCompanyDataFilter(state, { payload }) {
      return {
        ...state,
        userCompanyDataFilter: payload,
      };
    },
    saveUserCompanyNotDataFilter(state, { payload }) {
      return {
        ...state,
        userCompanyNotDataFilter: payload,
      };
    },
    saveAgentId(state, { payload }) {
      return {
        ...state,
        AgentIdData: payload,
      };
    },
  },
};
